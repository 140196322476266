export const LeftType = {
  BLOCK_CONFIG: 1,
  SELECT_BLOCK: 2,
  LOOP_CONFIG: 3,
  IF_CONFIG: 4,
  SELECT_TRIGGER: 5,
};

export const utilities = [
  'alloy.start',
  'alloy.cron',
  'alloy.if',
  'alloy.pdfConverter',
  'alloy.dataFormatter',
  'alloy.delay',
  'alloy.dateTime',
  'alloy.for',
  'alloy.math',
  'alloy.sendEmail',
  'alloy.smtpService',
  'alloy.sendText',
  'alloy.textFormatter',
  'alloy.cron',
  'alloy.webhook',
  'alloy.sdk',
  'alloy.start',
  'alloy.emailReceiptTrigger',
  'alloy.text',
  'alloy.listOperation',
  'alloy.branch',
  'alloy.contentComposer',
  'alloy.errorHandler',
  'alloy.onInstallationTrigger',
  'alloy.dataStreaming',
];

export const blockCategories = [
  'custom-integration',
  'advertising',
  'calendar-scheduling',
  'data-analytics',
  'developer-tools',
  'ecommerce-platform',
  'erp-accounting',
  'hris',
  'marketing-automation',
  'mobile',
  'payment-processing-fintech',
  'pos',
  'productivity-collaboration',
  'project-management',
  'reviews-subscriptions-loyalty',
  'sales-crm',
  'shipping-logistics',
  'sms',
  'social-media',
  'surveys-feedback',
  'other',
];

export const utilityCategories = [
  'files',
  'messaging',
  'functions',
  'developer-tools',
  'data-manipulation',
];

export const RecipePublishStep = {
  SETUP: 0,
  BLOCKS: 1,
  REVIEW: 2,
};

export const communityCategories = [
  {
    category: 'Analytics',
    fontColor: '#F10777',
    bgColor: '#F1077726',
  },
  {
    category: 'Abandoned Cart',
    fontColor: '#F52FF9',
    bgColor: '#F52FF91A',
  },
  {
    category: 'Customer Management',
    fontColor: '#8700DC',
    bgColor: '#F4E3FF',
  },
  {
    category: 'Customer Support',
    fontColor: '#EB7300',
    bgColor: '#FFECDA',
  },
  {
    category: 'Data Migration',
    fontColor: '#48A101',
    bgColor: '#48A2011A',
  },
  {
    category: 'Fraud',
    fontColor: '#2b2e32',
    bgColor: '#E8F0FD',
  },
  {
    category: 'Inventory',
    fontColor: '#FFA800',
    bgColor: '#FFC22633',
  },
  {
    category: 'Marketing',
    fontColor: '#583BFE',
    bgColor: '#583BFE1A',
  },
  {
    category: 'Order Tagging',
    fontColor: '#25AD7E',
    bgColor: '#25AD7E1A',
  },
  {
    category: 'Product Listings',
    fontColor: '#E0CA06',
    bgColor: '#E0CA061A',
  },
  {
    category: 'Rewards',
    fontColor: '#C62198',
    bgColor: '#C621981A',
  },
  {
    category: 'Shipping/Fulfillment',
    fontColor: '#008EBF',
    bgColor: '#E1F8FF',
  },
  {
    category: 'Store Operations',
    fontColor: '#F73630',
    bgColor: '#FF663333',
  },
  {
    category: 'Upselling',
    fontColor: '#8B6A57',
    bgColor: '#8B6A5733',
  },
];

export const getCategoryData = (category) => {
  return communityCategories.find((e) => e.category === category);
};
