import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Modal,
  Form,
  Input,
  Radio,
  Col,
  Row,
  Popover,
  Divider,
  Select,
  Tooltip,
} from 'antd';

import { upperFirst, get } from 'lodash';

import { CloseIcon } from 'Components/Icons';

import styles from '../Account.less';
import { apiUrl } from 'Utilities';
import { showNotification } from 'Components/Notifications/Notification';

import axios from 'axios';

import * as dayjs from 'dayjs';
import { AppButton, AppCloseIcon } from '../../../components/Base';
import {
  ElementsConsumer,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js/pure';

const isProd = window.location.hostname === 'app.runalloy.com';
const stripeKey = isProd
  ? 'pk_live_D9PJljQ4eqOy76YrfyAjNBwM00EIBXoIOB'
  : 'pk_test_vLQ5sHPwI27wyNyIOaVzkpbG00gbm6L9Ns';
const stripePromise = loadStripe(stripeKey);

const enterprisePlans = ['buyWithPrime', 'stripe'];

export default (props) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Inter&display=swap',
          },
        ],
      }}
    >
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <ChangePlanModal stripe={stripe} elements={elements} {...props} />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

@inject('globalStore')
@observer
class ChangePlanModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: null,
      saving: false,
      changeToPlan: null,
      paymentPhase: false,
      // Promo stuff
      discountCode: null,
      promoApplied: false,
      promoData: {},
      billCycle: null,
    };
  }

  axiosInstance = axios.create({
    baseURL: `${apiUrl}/api`,
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('workflow-accessToken'),
      refreshToken: localStorage.getItem('workflow-refreshToken'),
    },
  });

  formRef = React.createRef();

  componentDidMount() {
    const { globalStore } = this.props;
    const { userInfo } = globalStore.userStore;

    this.axiosInstance
      .get('/plan/list')
      .then((response) => {
        let newPlans = response.data.plans;
        if (userInfo.useNewBillingMethod) {
          if (enterprisePlans.includes(get(userInfo, 'plan.plan'))) {
            newPlans = response.data.plans.filter(
              (p) => !!p.useNewBilling && p.name !== 'free'
            );
          } else {
            newPlans = response.data.plans.filter((p) => !!p.useNewBilling);
          }
        } else {
          newPlans = response.data.plans.filter(
            (p) => !p.useNewBilling || p.name === 'free'
          );
        }

        const modifiedPlans = newPlans.reduce(
          (obj, plan) => {
            return {
              ...obj,
              [plan.billing]: [...obj[plan.billing], plan],
            };
          },
          { monthly: [], yearly: [] }
        );

        this.setState({
          plans: modifiedPlans,
        });
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message);
      });
  }

  getButtonText = () => {
    const { changeToPlan } = this.state;
    const { plan } = this.props;
    const plansObj = {
      free: 0,
      starter: 1,
      silver: 2,
      gold: 3,
      platinum: 4,
      admin: 99,
      individual: 6,
    };

    if (!changeToPlan) {
      return 'Upgrade';
    }

    if (get(plansObj, plan.name, 0) > get(plansObj, changeToPlan, 0)) {
      return 'Change';
    } else {
      return 'Upgrade';
    }
  };

  getBenefits = (planName) => {
    switch (planName) {
      case 'starter':
        return (
          <div className={styles.benefits}>
            <div className={styles.header}>
              What's included in the <span>Starter Plan</span>:
            </div>
            <div className={styles.body}>
              <ul>
                <li>Up to 20 active workflows</li>
                <li>Up to 5,000 app actions per month</li>
                <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
                <li>Full access to marketplace</li>
              </ul>
            </div>
          </div>
        );
      case 'individual':
        return (
          <div className={styles.benefits}>
            <div className={styles.header}>
              What's included in the <span>Individual Plan</span>:
            </div>
            <div className={styles.body}>
              <ul>
                <li>Up to 20 active workflows</li>
                <li>Up to 5,000 app actions per month</li>
                <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
                <li>Full access to marketplace</li>
              </ul>
            </div>
          </div>
        );
      case 'business':
        return (
          <div className={styles.benefits}>
            <div className={styles.header}>
              What's included in the <span>Business Plan</span>:
            </div>
            <div className={styles.body}>
              <ul>
                <li>Unlimited active workflows</li>
                <li>Up to 20,000 app actions per month</li>
                <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
                <li>Full access to marketplace</li>
              </ul>
            </div>
          </div>
        );
      case 'silver':
        return (
          <div className={styles.benefits}>
            <div className={styles.header}>
              What's included in the <span>Silver Plan</span>:
            </div>
            <div className={styles.body}>
              <ul>
                <li>Unlimited active workflows</li>
                <li>Up to 30,000 app actions per month</li>
                <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
                <li>Full access to marketplace</li>
              </ul>
            </div>
          </div>
        );
      case 'gold':
        return (
          <div className={styles.benefits}>
            <div className={styles.header}>
              What's included in the <span>Gold Plan</span>:
            </div>
            <div className={styles.body}>
              <ul>
                <li>Unlimited active workflows</li>
                <li>Up to 300,000 app actions per month</li>
                <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
                <li>Full access to marketplace</li>
                <li>Custom API call</li>
                <li>Workspaces</li>
                <li>Unlimited team members</li>
                <li>Personalized training</li>
                <li>1 custom integration</li>
              </ul>
            </div>
          </div>
        );
      case 'platinum':
        return (
          <div className={styles.benefits}>
            <div className={styles.header}>
              What's included in the <span>Platinum Plan</span>:
            </div>
            <div className={styles.body}>
              <ul>
                <li>Unlimited active workflows</li>
                <li>Unlimited app actions per month</li>
                <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
                <li>Full access to marketplace</li>
                <li>Custom API call</li>
                <li>Workspaces</li>
                <li>Unlimited team members</li>
                <li>Personalized training</li>
                <li>2 custom integrations</li>
                <li>Early access to new features</li>
              </ul>
            </div>
          </div>
        );
      case 'free':
      default:
        return (
          <div className={styles.benefits}>
            <div className={styles.header}>
              What's included in the <span>Free Plan</span>:
            </div>
            <div className={styles.body}>
              <ul>
                <li>Up to 5 active workflows</li>
                <li>Up to 1,000 app actions per month</li>
                <li>No custom logic (&ldquo;Start from Scratch&rdquo;)</li>
                <li>Full access to marketplace</li>
              </ul>
            </div>
          </div>
        );
    }
  };

  upgradePlanFirstStage = (planName) => {
    const { plan } = this.props;
    const { currentBilling } = this.state;

    if (planName !== plan.name || plan.billing !== currentBilling) {
      let canChangePlan = true;
      if (plan.name === 'gold' || plan.name === 'platinum') {
        canChangePlan = false;
        showNotification(
          'error',
          'Gold or Platinum plans cannot downgrade manually. Please contact sales@runalloy.com.'
        );
      }

      if (planName === 'business') {
        canChangePlan = false;
      }

      if (canChangePlan) {
        this.setState({ changeToPlan: planName });
      }
    }
  };

  upgradePlan = (values) => {
    const { globalStore } = this.props;
    const { changeToPlan } = this.state;
    const { userInfo } = globalStore.userStore;
    const { promoCode } = values;

    this.setState({ saving: true });

    if (userInfo.billsWithShopify) {
      this.props.globalStore.myAccountStore
        .changePlan(changeToPlan, null, promoCode)
        .then((responseData) => {
          if (responseData) {
            window.location.href = responseData;
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err.message);
        });
    } else if (this.props.stripe) {
      if (changeToPlan !== 'free') {
        // Process with stripe
        const element = this.props.elements.getElement(CardNumberElement);

        this.props.stripe.createToken(element).then(async (payload) => {
          if (payload.error) {
            this.setState({ saving: false });
            showNotification(
              'error',
              'Please enter a valid Credit Card number!'
            );
          } else {
            try {
              if (!payload.token) {
                showNotification(
                  'error',
                  'Could not create account. Please completely fill out the payment fields!'
                );
              } else {
                const creditCard = payload.token.id;
                const billingSchedule =
                  this.formRef.current.getFieldValue('billing');

                this.props.globalStore.myAccountStore
                  .changePlan(
                    changeToPlan,
                    creditCard,
                    promoCode,
                    billingSchedule
                  )
                  .then((responseData) => {
                    if (responseData) {
                      // Update local storage
                      const parsedUserInfo = JSON.parse(
                        localStorage.getItem('workflow-userInfo')
                      );

                      parsedUserInfo.plan = changeToPlan;

                      localStorage.setItem(
                        'workflow-userInfo',
                        JSON.stringify(parsedUserInfo)
                      );

                      const successMessage = get(
                        responseData,
                        `message`,
                        'Plan changed successfully!'
                      );

                      const changePlanType = get(responseData, `type`, null);

                      if (
                        changePlanType !== null &&
                        changePlanType === 'upgrade' &&
                        this.props.upgradeSuccessFxn
                      ) {
                        this.props.upgradeSuccessFxn(changeToPlan);
                        this.props.closeFxn();
                      } else {
                        showNotification('success', successMessage);

                        if (this.props.closeSuccessFxn) {
                          this.props.closeSuccessFxn();
                        } else {
                          this.props.closeFxn();
                        }
                      }
                    } else {
                      this.setState({ saving: false });
                    }
                  })
                  .catch(() => {
                    this.setState({ saving: false });
                  });
              }
            } catch (error) {
              this.setState({ saving: false });
              showNotification(
                'error',
                'Something went wrong, please try again!'
              );
            }
          }
        });
      } else {
        const creditCard = null;

        const { discountCode } = this.state;

        this.props.globalStore.myAccountStore
          .changePlan(changeToPlan, creditCard, discountCode)
          .then((responseData) => {
            this.props.globalStore.userStore.getUserInfo().then(() => {
              if (responseData) {
                const successMessage = get(
                  responseData,
                  `message`,
                  'Plan changed successfully!'
                );

                showNotification('success', successMessage);

                if (this.props.closeSuccessFxn) {
                  this.props.closeSuccessFxn();
                } else {
                  this.props.closeFxn();
                }
              } else {
                this.setState({ saving: false });
              }
            });
          })
          .catch(() => {
            this.setState({ saving: false });
          });
      }
    }
  };

  getPrice = () => {
    const { plan } = this.props;
    const { changeToPlan, plans } = this.state;

    const thePlans = get(plans, plan.billing, null);

    let price = 0;

    if (thePlans) {
      for (const thePlan of thePlans) {
        if (thePlan.name === changeToPlan) {
          // eslint-disable-next-line prefer-destructuring
          price = thePlan.price;
        }
      }
    }

    return price;
  };

  proceedToPaymentPhase = () => {
    const { changeToPlan } = this.state;

    if (changeToPlan !== 'free') {
      this.setState({ paymentPhase: true });
    } else {
      this.upgradePlan({});
    }
  };

  applyDiscount = () => {
    const promoCode = this.formRef.current.getFieldValue('promoCode');
    const billingSchedule = this.formRef.current.getFieldValue('billing');
    const { billingStore } = this.props.globalStore;
    if (promoCode) {
      this.setState({
        saving: true,
      });
      billingStore
        .validatePromoCode({
          promoCode,
          billingSchedule,
        })
        .then((response) => {
          this.setState({
            promoData: response,
            promoApplied: true,
            saving: false,
          });
        })
        .catch((err) => {
          const errorMessage = get(err, 'message', 'Invalid promo code!');
          showNotification('error', errorMessage);
          this.setState({
            promoData: {},
            promoApplied: false,
            saving: false,
          });
        });
    }
  };

  promoCodeChanged = () => {
    this.setState({
      promoData: {},
      promoApplied: false,
      saving: false,
    });
  };

  billingPeriodChanged = (e) => {
    this.setState({ billCycle: e.target.value });
  };

  handleBillingChange = (value) => {
    this.setState({
      billCycle: value,
      promoData: {},
      promoApplied: false,
      saving: false,
    });
  };

  showPlanPrice = (planObj, currentBilling, currentPlan) => {
    const { globalStore } = this.props;
    const { myAccountStore, userStore } = globalStore;
    const { userInfo } = userStore;
    const { isTrial } = userInfo;

    const { analytics } = myAccountStore;

    const today = dayjs();
    const renewalDate = dayjs(analytics?.renewalDate);

    const daysTilTrialEnds = renewalDate?.diff(today, 'days') + 1;

    if (planObj.name !== 'free') {
      if (isTrial && planObj.name === currentPlan) {
        return (
          <>
            <span className={styles.big}>Trial</span>
            <span className={styles.small}>
              {' '}
              / {daysTilTrialEnds > 0 ? daysTilTrialEnds : 0} days
            </span>
          </>
        );
      } else {
        return (
          <>
            <span className={styles.big}>
              $
              {currentBilling === 'monthly'
                ? planObj.price
                : planObj.price / 12}
            </span>
            <span className={styles.small}> / Month</span>
          </>
        );
      }
    } else {
      return <span className={styles.big}>Free</span>;
    }
  };

  render() {
    const { plan } = this.props;

    const {
      saving,
      changeToPlan,
      paymentPhase,
      promoApplied,
      plans,
      promoData,
      billCycle,
    } = this.state;

    const { globalStore } = this.props;
    const { userInfo } = globalStore.userStore;
    const { billsWithShopify } = userInfo;

    const changePlanModalVisible = this.props.open;

    const closable = this.props.canClose === false ? this.props.canClose : true;

    let selectedBilling = null;
    let selectedPlan = null;
    let highlitPlan = null;
    let currentBilling = billCycle;

    if (plan) {
      selectedBilling = plan.billing;
      selectedPlan = plan.name;
      highlitPlan = plan.name;
    }

    if (plan && !currentBilling) {
      currentBilling = plan.billing;
    } else if (!plan && !currentBilling) {
      currentBilling = 'monthly';
    }

    if (changeToPlan) {
      highlitPlan = changeToPlan;
    }

    const CARD_OPTIONS = {
      style: {
        base: {
          fontFamily: `"Inter", sans-serif`,
          letterSpacing: '0.8px',
          paddingTop: '10px',
          color: '#555555',
          '::placeholder': {
            color: '#C0C0C0',
          },
        },
      },
    };

    const innerGutterSettings = { sm: 24, xs: 24 };
    const singleColSettings = { span: 24, offset: 0 };
    const doubleColSettings = { span: 12, offset: 0 };

    return (
      <Modal
        closeIcon={
          <AppButton icon={<AppCloseIcon />} size="small" type="tertiary" />
        }
        maskClosable={false}
        title={!paymentPhase ? 'Choose Plan' : 'Payment Information'}
        open={changePlanModalVisible}
        onCancel={this.props.closeFxn}
        wrapClassName={styles.accountModal}
        className={styles.planModal}
        destroyOnClose
        width={740}
        centered
        closable={closable}
        footer={
          !paymentPhase
            ? [
                closable ? (
                  <AppButton onClick={this.props.closeFxn} disabled={saving}>
                    Cancel
                  </AppButton>
                ) : null,
                // eslint-disable-next-line react/jsx-indent
                <AppButton
                  type="primary"
                  disabled={!changeToPlan}
                  onClick={this.proceedToPaymentPhase}
                >
                  {this.getButtonText()}
                </AppButton>,
              ]
            : null
        }
      >
        {!paymentPhase ? (
          <>
            <Row gutter={innerGutterSettings}>
              <Col sm={singleColSettings} xs={{ span: 22, offset: 1 }}>
                <div className={styles.customRadioGroup}>
                  <Radio.Group
                    style={{ marginBottom: 16 }}
                    size="large"
                    value={currentBilling}
                    onChange={this.billingPeriodChanged}
                  >
                    <Radio.Button value="monthly">Monthly</Radio.Button>
                    <Radio.Button value="yearly">Annual</Radio.Button>
                  </Radio.Group>
                </div>
              </Col>
            </Row>
            {plans &&
              plans[currentBilling] &&
              plans[currentBilling].map((planObj) => {
                if (planObj.name !== 'gold') {
                  return (
                    <div
                      className={`${styles.upgradePlanCard} ${
                        highlitPlan === planObj.name ? styles.selected : ''
                      }`}
                      onClick={() => {
                        this.upgradePlanFirstStage(planObj.name);
                      }}
                    >
                      <div className={styles.planDetails}>
                        <div className={styles.planName}>
                          {planObj.name}{' '}
                          {planObj.name === selectedPlan &&
                            selectedBilling === currentBilling && (
                              <span className={styles.recommended}>
                                Your Current Plan
                              </span>
                            )}
                        </div>
                        <div className={styles.planDescription}>
                          {planObj.description}
                        </div>
                        <div className={styles.whatIsIncluded}>
                          <Popover
                            placement="right"
                            content={this.getBenefits(planObj.name)}
                            trigger="hover"
                            overlayClassName={styles.blackOverlay}
                          >
                            <div className={styles.whatIsIncludedText}>
                              What is included?
                            </div>
                          </Popover>
                        </div>
                      </div>
                      {planObj.name !== 'business' ? (
                        <div className={styles.planPriceContainer}>
                          <div className={styles.planPrice}>
                            {this.showPlanPrice(
                              planObj,
                              currentBilling,
                              selectedPlan
                            )}
                          </div>
                          {planObj.name !== 'free' &&
                            currentBilling === 'yearly' && (
                              <span className={styles.save}>Save 20%</span>
                            )}
                        </div>
                      ) : (
                        <div className={styles.bookDemoContainer}>
                          <AppButton
                            className={styles.accountModalPlanButton}
                            type="primary"
                            href="https://runalloy.com/book-demo/"
                            target="_blank"
                          >
                            Book Demo
                          </AppButton>
                        </div>
                      )}
                    </div>
                  );
                }

                return <></>;
              })}
            {plans &&
              plans[currentBilling] &&
              plans[currentBilling].filter((e) => e.name === 'business')
                .length === 0 && (
                <div className={`${styles.upgradePlanCard}`}>
                  <div className={styles.planDetails}>
                    <div className={styles.planName}>Business</div>
                    <div className={styles.planDescription}>
                      Ideal for companies with ERPs and complex tech stack
                      needs.
                    </div>
                    <div className={styles.whatIsIncluded}>
                      <Popover
                        placement="right"
                        content={this.getBenefits('business')}
                        trigger="hover"
                        overlayClassName={styles.blackOverlay}
                      >
                        <div className={styles.whatIsIncludedText}>
                          What is included?
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div className={styles.bookDemoContainer}>
                    <AppButton
                      className={styles.accountModalPlanButton}
                      type="primary"
                      href="https://runalloy.com/book-demo/"
                      target="_blank"
                    >
                      Book Demo
                    </AppButton>
                  </div>
                </div>
              )}
          </>
        ) : (
          <>
            <Row gutter={innerGutterSettings}>
              <Col sm={singleColSettings} xs={{ span: 22, offset: 1 }}>
                <div
                  className={styles.goBack}
                  onClick={() => {
                    this.setState({ paymentPhase: false });
                  }}
                >
                  <span>← Back to Plans</span>
                  <Divider />
                </div>
              </Col>
            </Row>
            <Row gutter={innerGutterSettings}>
              <Col sm={singleColSettings} xs={{ span: 22, offset: 1 }}>
                <div className={styles.paymentSummary}>
                  <div className={styles.paymentPrice}>
                    <span className={styles.big}>
                      {upperFirst(changeToPlan)} Plan{' '}
                    </span>
                    <span className={styles.small}>
                      $
                      {selectedBilling === 'monthly'
                        ? this.getPrice()
                        : this.getPrice() / 12}
                      /month
                    </span>
                  </div>
                  {selectedBilling !== 'monthly' && (
                    <div className={styles.savings}>
                      <span className={styles.text}>
                        Billed as one payment of ${this.getPrice()}
                      </span>
                      <span className={styles.tag}>Save 20%</span>
                    </div>
                  )}
                  <div className={styles.needDemo}>
                    Need a demo?{' '}
                    <a
                      href="https://meetings.hubspot.com/alloy-sales/demo?_ga=2.200869934.1827842672.1615251154-925407025.1613692461"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Schedule at your convenience
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Form
              onFinish={this.upgradePlan}
              name="accountForm"
              layout="vertical"
              requiredMark={false}
              initialValues={{
                billing: currentBilling,
              }}
              ref={this.formRef}
            >
              <Row gutter={innerGutterSettings}>
                <Col sm={{ span: 8, offset: 0 }} xs={11}>
                  <Form.Item
                    label="Billing"
                    name="billing"
                    rules={[
                      {
                        required: true,
                        message: 'Please select billing.',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select billing"
                      onChange={this.handleBillingChange}
                      size="large"
                      popupClassName={styles.styledDropdown}
                    >
                      {Object.keys(plans).map((billingType) => (
                        <Select.Option value={billingType} key={billingType}>
                          <span className={styles.txtOnlyOption}>
                            {billingType === 'yearly'
                              ? 'Annual'
                              : upperFirst(billingType)}
                          </span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={innerGutterSettings}>
                <Col sm={{ span: 16, offset: 0 }} xs={{ span: 11, offset: 1 }}>
                  <Form.Item label="Promo Code" name="promoCode">
                    <Input
                      placeholder="XXXXXXXX"
                      size="large"
                      onChange={this.promoCodeChanged}
                    />
                  </Form.Item>
                </Col>
                <Col sm={8} xs={11}>
                  <Form.Item name="promoCodeApply" label={` `}>
                    {promoApplied ? (
                      <Tooltip
                        placement="right"
                        title={`${promoData?.description}`}
                        open
                      >
                        <AppButton
                          className={`${styles.btnFullWidth}`}
                          disabled={saving}
                          onClick={() => {
                            this.applyDiscount();
                          }}
                        >
                          Apply
                        </AppButton>
                      </Tooltip>
                    ) : (
                      <AppButton
                        className={`${styles.btnFullWidth}`}
                        disabled={saving}
                        loading={saving}
                        onClick={() => {
                          this.applyDiscount();
                        }}
                      >
                        Apply
                      </AppButton>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {/* {!promoApplied ||
                  (promoApplied && !promoIsFreeTrial) ||
                  (promoApplied && requiresCreditCard) ? (
                    <> */}
              {!billsWithShopify && (
                <>
                  <Row gutter={innerGutterSettings}>
                    <Col sm={singleColSettings} xs={{ span: 22, offset: 1 }}>
                      <Form.Item
                        label="Card Number"
                        name="cardNumber"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your credit card number.',
                          },
                        ]}
                      >
                        <CardNumberElement
                          className="ant-input ant-input-lg stripeElementCustom"
                          options={CARD_OPTIONS}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={innerGutterSettings}>
                    <Col sm={doubleColSettings} xs={{ span: 11, offset: 1 }}>
                      <Form.Item
                        label="Expiration"
                        name="expiration"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter card expiry.',
                          },
                        ]}
                      >
                        <CardExpiryElement
                          className="ant-input ant-input-lg stripeElementCustom"
                          options={CARD_OPTIONS}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={11}>
                      <Form.Item
                        label="CVV"
                        name="cvv"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter card CVV.',
                          },
                        ]}
                      >
                        <CardCvcElement
                          className="ant-input ant-input-lg stripeElementCustom"
                          options={CARD_OPTIONS}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Row
                gutter={innerGutterSettings}
                className={styles.submitButtonRow}
              >
                <Col sm={singleColSettings} xs={{ span: 11, offset: 1 }}>
                  <AppButton
                    type="primary"
                    className={`${styles.btnFullWidth}`}
                    disabled={saving}
                    loading={saving}
                    htmlType="submit"
                  >
                    {this.getButtonText()}
                  </AppButton>
                </Col>
              </Row>
              {/* </>
                  ) : (
                    ''
                  )} */}
            </Form>
          </>
        )}
      </Modal>
    );
  }
}
