import { get, isUndefined } from 'lodash';
import { toJS } from 'mobx';
import { NON_TESTABLE_NODE_TYPES } from 'Constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  BLOCK_PREFIX_VALUE,
  COLLECTION_COMPONENTS,
  COLLECTION_TYPES,
  DATE_PICKER_FIELD_VALUES,
  EXCLUDE_RECIPE_COLLECTION,
  EXPRESSION_EDITOR_POPOVER_CUSTOM_TYPE,
  EXPRESSION_EDITOR_POPOVER_OPTIONS,
  NODE_TYPES,
  NON_MULTIPLE_TYPES,
  PARAM_TYPES,
} from '../constants';

dayjs.extend(utc);

export const getParameterValue = (blockValues, parameterName, path) => {
  return get(blockValues, path ? `${path}.${parameterName}` : parameterName);
};

export const displayParameter = (blockValues, parameter, blockValuesRoot) => {
  if (!parameter.displayOptions) {
    return true;
  }
  // FIXME: Mutating function parameter
  blockValuesRoot = blockValuesRoot || blockValues;
  let value;
  if (parameter.displayOptions.show) {
    for (const propertyName of Object.keys(parameter.displayOptions.show)) {
      if (propertyName.charAt(0) === '/') {
        value = blockValuesRoot[propertyName.slice(1)];
      } else {
        value = blockValues ? blockValues[propertyName] : undefined;
      }
      if (
        value === undefined ||
        !parameter.displayOptions.show[propertyName].includes(value)
      ) {
        return false;
      }
    }
  }
  if (parameter.displayOptions.hide) {
    for (const propertyName of Object.keys(parameter.displayOptions.hide)) {
      if (propertyName.charAt(0) === '/') {
        value = blockValuesRoot[propertyName.slice(1)];
      } else {
        value = blockValues ? blockValues[propertyName] : undefined;
      }
      if (
        value !== undefined &&
        parameter.displayOptions.hide[propertyName].includes(value)
      ) {
        return false;
      }
    }
  }
  return true;
};

export const displayParameterPath = (blockValues, parameter, path) => {
  let resolvedBlockValues = blockValues;
  if (path !== '') {
    resolvedBlockValues = get(blockValues, path);
  }
  let blockValuesRoot = blockValues;
  if (path && path.split('.').indexOf('parameters') === 0) {
    blockValuesRoot = get(blockValues, 'parameters');
  }
  return displayParameter(resolvedBlockValues, parameter, blockValuesRoot);
};

export const displayParameterPathRecipe = (
  blockValues,
  parameter,
  path,
  blockId
) => {
  let resolvedBlockValues = blockValues;
  if (path !== '') {
    resolvedBlockValues = get(blockValues, path);
  }
  let blockValuesRoot = blockValues;
  if (path && path.split('.').indexOf('parameters') === 0) {
    blockValuesRoot = get(blockValues, 'parameters');
  }
  if (blockId && !resolvedBlockValues) {
    const keys = parameter.name.split('.');
    keys.pop();
    const key = keys.join('.');

    resolvedBlockValues = get(blockValues, `${blockId}::${key}`);
  }
  return displayParameter(resolvedBlockValues, parameter, blockValuesRoot);
};

// Returns all the credential-issues of the block
export const getBlockCredentialIssues = (
  block,
  blockType,
  credentialTypes,
  userCredentials
) => {
  if (!blockType.credentials) {
    // Block does not need any credentials
    return null;
  }

  const foundIssues = {};

  let credentialType;
  let credentialDisplayName;
  let selectedCredentials;
  for (const credentialTypeDescription of blockType.credentials) {
    // Check if credentials should be displayed else ignore
    if (
      displayParameter(block.parameters, credentialTypeDescription, '') !== true
    ) {
      continue;
    }

    // Get the display name of the credential type
    credentialType = credentialTypes.find(
      (cred) => cred.name === credentialTypeDescription.name
    );
    if (!credentialType) {
      credentialDisplayName = credentialTypeDescription.name;
    } else {
      credentialDisplayName = credentialType.displayName;
    }

    if (
      !block.credentials ||
      !block.credentials[credentialTypeDescription.name]
    ) {
      // Credentials are not set
      if (credentialTypeDescription.required === true) {
        foundIssues[credentialTypeDescription.name] = [
          `Credentials for "${credentialDisplayName}" are not set.`,
        ];
      }
    } else {
      // If they are set check if the value is valid
      selectedCredentials = block.credentials[credentialTypeDescription.name];

      if (!userCredentials) {
        // FIXME: Avoid assignment to function parameter
        userCredentials = [];
      }

      if (
        userCredentials.find(
          (credentialData) => credentialData._id === selectedCredentials
        ) === undefined
      ) {
        foundIssues[credentialTypeDescription.name] = [
          `Credentials with name "${selectedCredentials}" do not exist for "${credentialDisplayName}".`,
        ];
      }

      const selectedCredFromUserData = userCredentials.find(
        (credentialData) => credentialData._id === selectedCredentials
      );
      if (selectedCredFromUserData && selectedCredFromUserData.isExpired) {
        foundIssues[credentialTypeDescription.name] = [
          `Credentials with name "${selectedCredFromUserData.name}" are expired`,
        ];
      }
    }
  }

  // TODO: Could later check also if the block has access to the credentials
  if (Object.keys(foundIssues).length === 0) {
    return null;
  }

  return {
    credentials: foundIssues,
  };
};

export const validateParameters = (blockType, blockValues) => {
  // blockType is the block from blockTypes where name = type of blockValues
  // blockValues is the value of the current block
  const parameters = blockType.properties
    .filter((p) => p.name !== 'resource' && p.name !== 'operation')
    .filter((p) => parameterFilter(p, blockValues) && p.required);
  const vlength = parameters.reduce((acc, p) => {
    const v = getParameterValue(blockValues, p.name, 'parameters');
    return acc + (v !== '' && v !== null && v !== undefined ? 1 : 0);
  }, 0);
  return parameters.length === vlength;
};

const parameterFilter = (parameter, blockValues) => {
  if (parameter.displayOptions === undefined) return true;
  return displayParameterPath(blockValues, parameter, 'parameters');
};

export const getIfBlockSubtitle = (blockValues) => {
  let subtitle = 'Block not completed';

  if (blockValues.parameters.conditions) {
    const keys = Object.keys(blockValues.parameters.conditions);
    if (keys) {
      for (let i = 0; i < keys.length; i += 1) {
        const firstCondition = blockValues.parameters.conditions[keys[i]];
        if (firstCondition) {
          if (firstCondition.length) {
            let { value1 } = firstCondition[0];
            let { value2 } = firstCondition[0];
            const conditionType = keys[i];
            if (
              value1 &&
              typeof value1 === 'string' &&
              (firstCondition[0].value1.includes('{{$block') ||
                firstCondition[0].value1.includes('{{$workflow'))
            ) {
              const path = `parameters.conditions.${conditionType}[0].value1`;
              let previewObj = findPreviewText(blockValues.preview, path);
              if (previewObj) {
                previewObj = toJS(previewObj);
                previewObj = previewObj[0].children.find(
                  (text) =>
                    text.fieldKey ===
                    value1.substring(
                      value1.indexOf('{{$block') + 2,
                      value1.indexOf(']}}') + 1
                    )
                );
                value1 = previewObj ? previewObj.fieldName : undefined;
              }
            }
            if (
              value2 &&
              typeof value2 === 'string' &&
              (firstCondition[0].value2.includes('{{$block') ||
                firstCondition[0].value2.includes('{{$workflow'))
            ) {
              const path = `parameters.conditions.${conditionType}[0].value2`;
              let previewObj = findPreviewText(blockValues.preview, path);
              if (previewObj) {
                previewObj = toJS(previewObj);
                previewObj = previewObj[0].children.find(
                  (text) =>
                    text.fieldKey ===
                    value2.substring(
                      value2.indexOf('{{$block') + 2,
                      value2.indexOf(']}}') + 1
                    )
                );
                value2 = previewObj.fieldName;
              }
            }
            subtitle = `If ${value1} ${firstCondition[0].operation} ${value2}`;
            break;
          }
        }
      }
    }
  }
  return subtitle;
};

export const formatSubtitle = (currentBlockType, blockValues) => {
  // get variable from subtitle
  if (currentBlockType.subtitle) {
    let { subtitle } = currentBlockType;
    let param = subtitle.slice(
      subtitle.indexOf('${') + 2,
      subtitle.indexOf('}')
    );
    param = param.split('.');
    try {
      let variable = blockValues[param[0]][param[1]];
      // gsheetstrigger exception
      if (
        currentBlockType.name === 'alloy.googleSheetsIntervalTrigger' &&
        variable
      ) {
        subtitle = subtitle.slice(0, subtitle.indexOf('${')) + variable;
        return subtitle;
      }
      if (variable) {
        const requiredProp = currentBlockType.properties.find(
          (prop) => prop.name === param[1]
        );
        if (requiredProp.type === 'options') {
          if (requiredProp.options) {
            const variableName = requiredProp.options.find(
              (opt) => opt.value === variable
            );
            if (variableName) {
              variable = variableName.name;
            }
          } else if (requiredProp.typeOptions.loadOptionsMethod) {
            subtitle = subtitle.slice(0, subtitle.indexOf('${')) + variable;
          } else {
            subtitle = 'Block not completed';
          }
        } else if (requiredProp.type === 'multiOptions') {
          const variableList = [];
          variable.forEach((v) => {
            const variableName = requiredProp.options.find(
              (opt) => opt.value === v
            );
            if (variableName) {
              variableList.push(variableName.name);
            }
          });
          variable = variableList.join(', ');
        }
        subtitle = subtitle.slice(0, subtitle.indexOf('${')) + variable;
      } else {
        subtitle = 'Block not completed';
      }
    } catch (e) {
      subtitle = 'Block not completed';
    }
    return subtitle;
  }
};

export const findPreviewText = (preview, path) => {
  const converted = btoa(path);
  if (preview && preview[converted]) {
    return preview[converted];
  }
  return null;
};

/**
 * Wether a Block is testable by the user or not
 * @param {Object} block A block object
 */
export const isBlockTestable = (block, blockValues) => {
  let blockIsTestable = true;
  let blockManuallyTestable = false;

  const isInNonTestablesList = NON_TESTABLE_NODE_TYPES.includes(block.name);

  if (isInNonTestablesList) {
    blockIsTestable = false;
    return { blockIsTestable, blockManuallyTestable };
  }

  const blockProperties = block.properties;
  const blockParameters = blockValues.parameters;

  // check if block has resource
  if (blockProperties && blockProperties.length > 0) {
    if (
      blockParameters &&
      (blockParameters.resource || blockParameters.operation)
    ) {
      blockProperties.forEach((property) => {
        let propertyHasResource = false;

        if (
          property.displayOptions &&
          property.displayOptions.show &&
          property.displayOptions.show.resource &&
          property.displayOptions.show.resource.length > 0
        ) {
          propertyHasResource = true;
        }

        if (
          property.name === 'operation' &&
          (!propertyHasResource ||
            (propertyHasResource &&
              property.displayOptions.show.resource.includes(
                blockParameters.resource
              )))
        ) {
          const propertyOptions = property.options;

          propertyOptions.forEach((propertyOption) => {
            if (propertyOption.value === blockParameters.operation) {
              if (!propertyOption.type) {
                blockIsTestable = true;
              }

              if (
                propertyOption.type === 'post' ||
                propertyOption.type === 'update' ||
                propertyOption.type === 'delete'
              ) {
                blockManuallyTestable = true;
                blockIsTestable = false;
              }
            }
          });
        }
      });
    }
  }

  return { blockIsTestable, blockManuallyTestable };
};

export const isBlockWithExpressions = (parameters) => {
  const parameterString = JSON.stringify(parameters);
  return (
    parameterString.indexOf('{{$block') > -1 ||
    parameterString.indexOf('{{$workflow') > -1
  );
};

export const getRunData = (workflow, blockValues) => {
  const blockId = blockValues.id;
  const workflowBlockData = workflow.blocks.find(
    (workflowBlock) => workflowBlock.id === blockId
  );

  if (workflowBlockData && workflowBlockData.testBlockRunData) {
    let runData = null;
    const jsRunData = toJS(workflowBlockData.testBlockRunData);

    if (
      jsRunData &&
      jsRunData[blockId] &&
      jsRunData[blockId].data &&
      jsRunData[blockId].data[0] &&
      jsRunData[blockId].data[0][0] &&
      jsRunData[blockId].data[0][0].json
    ) {
      runData = jsRunData[blockId].data[0][0].json;
    }

    return runData;
  }

  return null;
};

export const getSampleLiveData = (workflow, blockValues) => {
  const blockId = blockValues.id;
  const workflowBlockData = workflow.blocks.find(
    (workflowBlock) => workflowBlock.id === blockId
  );

  if (workflowBlockData && workflowBlockData.sampleLiveData) {
    return workflowBlockData.sampleLiveData;
  }

  return null;
};

export const getCustomBlockData = (workflow, blockValues) => {
  const blockId = blockValues.id;
  const workflowBlockData = workflow.blocks.find(
    (workflowBlock) => workflowBlock.id === blockId
  );

  if (workflowBlockData && workflowBlockData.customBlockRunData) {
    return workflowBlockData.customBlockRunData;
  }

  return null;
};

export const credentialIssueExist = (
  credentialIssues,
  credentialType,
  credentialError
) => {
  if (!credentialIssues || !credentialType) {
    return false;
  }

  const credentialIssuesString = credentialIssues[credentialType];
  if (credentialIssuesString.length > 0) {
    const flatCredentialIssues = credentialIssuesString.join('-');
    return credentialError.some((errorString) =>
      flatCredentialIssues.includes(errorString)
    );
  }
  return false;
};

export const getArgument = (argumentName, parameter) => {
  if (parameter?.typeOptions) {
    return parameter?.typeOptions?.[argumentName];
  }
  return undefined;
};

export const displayBlockParameter = (parameter, path, blockValues) => {
  if (parameter.displayOptions === undefined) {
    return true;
  }

  return displayParameterPath(blockValues, parameter, path);
};

export const isCollectionType = (parameter, isRecipe) => {
  if (!COLLECTION_TYPES.includes(parameter.type)) return false;

  if (isRecipe && EXCLUDE_RECIPE_COLLECTION.includes(parameter.blockType)) {
    return false;
  }

  return true;
};

export const isMultipleType = (parameter) => {
  if (
    getArgument('multipleValues', parameter) &&
    !NON_MULTIPLE_TYPES.includes(parameter.type) &&
    !NON_MULTIPLE_TYPES.includes(parameter.blockType)
  ) {
    return true;
  }
  return false;
};

export const getPath = (path, parameter) => {
  const fieldKey = parameter?.fieldKey || parameter?.fieldBlockKey;
  if (parameter?.fieldKey || parameter?.fieldBlockKey) {
    return fieldKey || parameter.name;
  }

  return (path ? `${path}.` : '') + parameter.name;
};

export const getParameterComponentType = (parameter, isRecipe) => {
  if (isMultipleType(parameter)) {
    return 'multipleParameter';
  }

  if (isCollectionType(parameter, true)) {
    return COLLECTION_COMPONENTS[parameter.type];
  }

  if (parameter.blockType === NODE_TYPES.IF) {
    return 'ifParameter';
  }

  return 'parameterInput';
};

export const getParameterFieldComponent = (type) => {
  if (EXPRESSION_EDITOR_POPOVER_OPTIONS.includes(type)) {
    return 'multi';
  }

  if (EXPRESSION_EDITOR_POPOVER_CUSTOM_TYPE.includes(type)) {
    return type;
  }
  return 'default';
};

export const getIfBlockDisplayParameter = (parameter, blockValues, blockId) => {
  if (parameter.displayOptions === undefined) return true;

  const showParam = displayParameterPathRecipe(
    blockValues,
    parameter,
    parameter.name,
    blockId
  );

  return showParam;
};

export const getDisplayBlockParameter = (parameter, blockValues, blockId) => {
  const newchildrenBlocks = parameter.childrenBlocks.map((block) => {
    const newConditionSets = block.conditionSets?.map((conditionSet) => {
      const newFields = conditionSet.fields.filter((field) => {
        return getIfBlockDisplayParameter(field, blockValues, blockId);
      });
      return { ...conditionSet, fields: newFields };
    });
    return { ...block, conditionSets: newConditionSets };
  });
  return { ...parameter, childrenBlocks: newchildrenBlocks };
};

export const getRecipeFieldInitialValue = (
  parameter,
  isTriggerBlock,
  value,
  preview,
  paramOptions
) => {
  if (
    (!isUndefined(paramOptions) &&
      paramOptions.length &&
      paramOptions.includes(parameter?.name)) ||
    value === ''
  ) {
    return undefined;
  }

  if (!value || isTriggerBlock) {
    return value;
  }

  if (parameter?.type === PARAM_TYPES.BOOLEAN) {
    return value;
  }

  if (!value?.includes?.(BLOCK_PREFIX_VALUE)) {
    if (DATE_PICKER_FIELD_VALUES.includes(parameter?.type)) {
      return dayjs.utc(value, 'YYYY-MM-DD HH:mm');
    }
    if (parameter?.type === PARAM_TYPES.PHONE_NUMBER) {
      return value;
    }
  }

  if (parameter?.type === PARAM_TYPES.TIME_INTERVAL_PICKER) {
    return value;
  }

  if (parameter.type === 'image' && value !== '') {
    return value;
  }
  if (parameter.type === 'csv' && value !== '') {
    return value;
  }
  if (preview) {
    return toJS(preview);
  }

  return;
};

export const getBlockCredentialType = (block, selectedBlock) => {
  const selectedBlockParameters = selectedBlock?.parameters;
  let credentialType = block.credentials[0].name;

  if (selectedBlockParameters?.apiVersion) {
    const credential = block.credentials.find(
      (cred) =>
        cred?.displayOptions?.show?.apiVersion?.[0] ===
        selectedBlockParameters?.apiVersion
    );
    if (credential) {
      credentialType = credential.name;
    }
  }
  return credentialType;
};

export const getOptionsFromBlock = (blockType, field) => {
  if (
    field.fieldType === 'options' &&
    (field.fieldValue !== 'operation' || field.fieldName !== 'alloy.branch')
  ) {
    const properties = blockType?.properties;
    const condition =
      get(properties, '0.options.0.options') || get(properties, '0');
    const operationProperties =
      (condition &&
        condition.length &&
        condition.find((con) => con.type === 'options')) ||
      condition;
    return operationProperties;
  } else if (field.fieldValue === 'operation') {
    const properties = blockType?.properties;
    const condition = get(properties, '1.options.1.options.0.options.1');
    const operationProperties =
      (condition &&
        condition.length &&
        condition.find((con) => con.type === 'options')) ||
      condition;
    return operationProperties;
  }
  return;
};

export const arrayGroupBy = (arr, key) => {
  return arr.reduce(function (accumulator, currentValue) {
    (accumulator[currentValue[key]] =
      accumulator[currentValue[key]] || []).push(currentValue);
    return accumulator;
  }, {});
};

export const countIssues = (issues) => {
  const count =
    issues && Object.keys(issues).length
      ? Object.keys(issues).filter(
          (key) => key !== undefined && key !== 'undefined'
        ).length
      : 0;

  return count;
};

export const getParameterType = (parameter, blockType) => {
  if (parameter.type === 'string') {
    const typeOptions = get(parameter, 'typeOptions', null);
    if (typeOptions && typeOptions.rows > 0) {
      if (typeOptions.json) {
        return 'jsonBlock';
      }

      if (typeOptions.code) {
        return 'code';
      }
      if (typeOptions.graphql) {
        return 'graphql';
      }

      if (
        blockType !== 'alloy.sendEmail' &&
        blockType !== 'alloy.smtpService' &&
        blockType !== 'alloy.klaviyo'
      ) {
        return 'blockText';
      }
    }
  }
  return parameter.type;
};
