import React from 'react';
import { message } from 'antd';
import { getId } from 'Utilities';
import { IconLoader } from '../Icons';
import styles from './index.less';
import classNames from 'classnames';
import {
  MessageCloseIcon,
  MessageErrorIcon,
  MessageInfoIcon,
  MessageSuccessIcon,
} from './icons';

const messageConfiguration = {
  error: {
    id: 'errorMessage',
    prefix: () => <MessageErrorIcon />,
    duration: 5,
    type: 'error',
  },
  success: {
    id: 'successMessage',
    prefix: () => <MessageSuccessIcon />,
    duration: 5,
    type: 'success',
  },
  info: {
    id: 'infoMessage',
    prefix: () => <MessageInfoIcon />,
    duration: 5,
    type: 'info',
  },
  custom: {
    id: 'successMessage',
    prefix: (customType) => customTypeIcons[customType],
    duration: 5,
    type: 'success',
    styles: styles.customMessage,
  },
  'custom-auth-success': {
    id: 'successMessage',
    prefix: () => <MessageSuccessIcon />,
    duration: 2,
    type: 'success',
    getContainer: () => document.getElementById('customIntegrationBody'),
  },
  'custom-auth-error': {
    id: 'errorMessage',
    prefix: () => <MessageErrorIcon />,
    duration: 2,
    type: 'error',
    getContainer: () => document.getElementById('customIntegrationBody'),
  },
  'custom-auth-loading': {
    id: 'successMessage',
    prefix: () => <IconLoader color="blue" />,
    duration: 2,
    type: 'loading',
    top: 100,
    getContainer: () => document.getElementById('customIntegrationBody'),
  },
  action: {
    id: 'customAction',
    prefix: () => <MessageErrorIcon />,
    duration: 99999,
    type: 'success',
    styles: styles.customMessage,
  },
};

const customTypeIcons = {
  success: <MessageSuccessIcon />,
  error: <MessageErrorIcon />,
  info: <MessageInfoIcon />,
  default: <></>,
};

export const appMessage = (
  type = 'success',
  msg,
  callback,
  customMessage,
  customType = 'default',
  customActionText
) => {
  if (callback) callback();
  let currentMessage;

  const currentMessageConfiguration = messageConfiguration[type];
  currentMessage = message[currentMessageConfiguration.type]({
    content: (
      <div
        className={classNames(styles.messageContainer, {
          [currentMessageConfiguration.styles]:
            !!currentMessageConfiguration.styles,
        })}
      >
        <span className={styles.icon}>
          {currentMessageConfiguration.prefix(customType)}
        </span>
        <span>
          {customMessage
            ? customMessage
            : msg.message
            ? msg.message
            : msg.title
            ? msg.title
            : msg}
        </span>
        {customActionText ? customActionText : null}
        <span className={styles.closeBtn} onClick={() => onClose()}>
          <MessageCloseIcon />
        </span>
      </div>
    ),
    id: getId(currentMessageConfiguration.id),
    className: classNames(styles.message),
    onClose,
    duration: currentMessageConfiguration.duration,
    ...(currentMessageConfiguration.top
      ? { top: currentMessageConfiguration.top }
      : {}),
    ...(currentMessageConfiguration.getContainer
      ? { getContainer: currentMessageConfiguration.getContainer }
      : {}),
  });

  const onClose = () => {
    setTimeout(currentMessage, 30);
  };
};
