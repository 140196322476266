import React from 'react';
import styles from './index.less';
import classNames from 'classnames';
import { Switch } from 'antd';

export const AppSwitch = (props) => {
  const currentSize =
    props?.size && props.size === 'extra-small' ? 'small' : props.size;
  return (
    <Switch
      className={classNames(styles.switch, {
        [styles[currentSize]]: !!currentSize,
      })}
      {...props}
      size={currentSize}
    />
  );
};
