import { GET, PUT, PATCH, POST, DELETE } from './wrappers';

export const getInstallations = (params) => {
  return POST(`/api/installations/`, params);
};

export const getInstallationWorkflows = (integrationId, childUserId) => {
  return GET(`/api/installations/${integrationId}?childUserId=${childUserId}`);
};

export const deleteInstallations = (installations) => {
  return DELETE(`/api/installations`, installations);
};

export const deleteAllInstallations = (allInstallations) => {
  return DELETE(`/api/installations/deleteAllInstallations`, allInstallations);
};

export const deleteWorkflowInstallations = (workflows) => {
  return DELETE(`/api/installations/workflows`, workflows);
};

export const getInstallationCount = (params) => {
  return POST(`/api/installations/count`, params);
};

export const deactivateInstallationWorkflow = (workflowId) => {
  return PATCH(`/api/installations/${workflowId}/deactivate`);
};

export const activateInstallationWorkflow = (workflowId) => {
  return PATCH(`/api/installations/${workflowId}/activate`);
};
