import { observable, action, runInAction } from 'mobx';

import {
  runWorkflow,
  updateWorkflow,
  getLatestExecution,
  getExecutionSummaries,
  getEmbeddedExecutionSummaries,
  getWorkflowLogs,
  getExecution,
  segmentTrackWorkflowActivation,
  rerunExecution,
  getLatestNotifications,
} from 'Services/workflowApi';

import { uuid4 } from 'Utilities/uuid';
import { showNotification } from 'Components/Notifications/Notification';

export class ExecutionStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  sessionId = uuid4();

  @observable
  workflowExecutionData = null;

  @observable
  executionId = null;

  @observable
  executingBlock = null;

  @observable
  executionWaitingForWebhook = false;

  @observable
  reconnectTimeout = null;

  @observable
  activeExecutions = [];

  notificationPollingId = null;
  notificationPollingWorkflowId = null;

  @observable
  hasError = false;

  @observable
  runningWorkflowIds = new Set();

  @observable
  isWorkflowStillUpdating = false;

  @observable
  aWorkflowIsActivating = false;

  @observable
  aWorkflowIsDeactivating = false;

  @observable
  workflowRecentlyDeactivated = false;

  @observable
  executionSummaries = [];

  @observable
  executionDetails = [];

  @observable
  workflowExecutions = [];

  @observable
  showMore = true;

  @observable
  showLimitModal = false;

  @observable
  rerunningExecutionId = null;

  @action
  findLatestExecution = () => {
    const workflowId = this.globalStore.workflowStore.activeWorkflowId;
    return getLatestExecution(workflowId).then(
      action((res) => {
        if (res.data) {
          this.workflowExecutionData = {
            data: res.data.executionData,
          };
          this.executionId = res.data._id;
        } else {
          this.executionId = null;
          this.workflowExecutionData = null;
        }
      })
    );
  };

  @action
  getWorkflowExecutions = () => {
    const { workflow } = this.globalStore.workflowStore;
    let executions = [];
    const workflowSummaries =
      workflow &&
      this.executionDetails &&
      this.executionDetails.length &&
      this.executionDetails.find((row) => row._id === workflow._id);

    if (
      workflowSummaries &&
      !Array.isArray(workflowSummaries) &&
      workflowSummaries.hasOwnProperty('executionSummaries')
    ) {
      executions = workflowSummaries.executionSummaries;
    } else if (workflowSummaries && Array.isArray(workflowSummaries)) {
      executions = workflowSummaries;
    }
    // moved to backend
    // executions = executions.filter(
    //   (exe) =>{
    //    return (exe.message === 'executionFinished' ||
    //       exe.message === 'executionError') &&
    //     exe.data &&
    //     exe.data.executionId
    // }
    // );
    this.workflowExecutions = executions;
  };

  @action
  rerunExecution = async (id, executionUuid) => {
    this.rerunningExecutionId = id;
    rerunExecution(id)
      .then(
        action((res) => {
          this.rerunningExecutionId = null;
          showNotification(
            'success',
            `Log ${`${
              executionUuid ? executionUuid.substring(0, 8) : ''
            } `}rerunning. Refresh this page in a few minutes to see it.`
          );
        })
      )
      .catch((err) => {
        this.rerunningExecutionId = null;
        showNotification(
          'error',
          `Log ${`${
            executionUuid ? executionUuid.substring(0, 8) : ''
          } `}rerun failed.`
        );
      });
  };

  @action
  getAnExecution = async (id) => {
    const details = await getExecution(id);
    this.workflowExecutions.forEach((exe) => {
      if (exe.data.executionId === id) {
        exe.data.executionData = details.runData;
        exe.data.metadata = details?.metadata || null;
      }
    });
    return details.runData;
  };

  @action
  getWorkflowLogs = (
    id,
    isInitial,
    startDate,
    endDate,
    filterFailed,
    filterSucceeded,
    value
  ) => {
    const {
      activeVersionIsCustom,
      activeChildUserId,
      forgeMode,
      workflow,
      activeEmbeddedUsername,
    } = this.globalStore.workflowStore;

    const workflowId = activeEmbeddedUsername
      ? workflow._id
      : id || this.globalStore.workflowStore.activeWorkflowId;

    const workflowSummaries =
      workflowId && this.executionDetails && this.executionDetails.length
        ? this.executionDetails.find((row) => row._id === workflowId)
        : [];

    const workflowSummariesCount =
      !isInitial && workflowSummaries && workflowSummaries.executionSummaries
        ? workflowSummaries.executionSummaries.length
        : 0;
    if (
      (startDate || endDate || filterFailed || filterSucceeded) &&
      isInitial
    ) {
      this.executionDetails = [];
    }
    if (workflowId) {
      return getWorkflowLogs(
        workflowId,
        workflowSummariesCount,
        startDate,
        endDate,
        activeVersionIsCustom,
        activeChildUserId,
        filterFailed,
        filterSucceeded,
        value
      )
        .then(
          action((res) => {
            if (this.globalStore.workflowStore.activeChildUserId) {
              this.getExecutionSummaries(
                this.globalStore.workflowStore.activeChildUserId
              );
            } else if (forgeMode) {
              this.getEmbeddedExecutionSummaries({
                workflowIds: [{ workflowId }],
              });
            }
            if (res.data && res.data.length) {
              const isThere = this.executionDetails.find(
                (exe) => exe._id === workflowId
              );
              if (isThere) {
                this.executionDetails.forEach((exe) => {
                  if (exe._id === workflowId) {
                    if (isInitial) {
                      exe.executionSummaries = res.data || [];
                    } else {
                      exe.executionSummaries =
                        [...exe.executionSummaries, ...res.data] || [];
                    }
                  }
                });
              } else {
                this.executionDetails.push({
                  _id: workflowId,
                  executionSummaries: res.data,
                });
              }

              if (
                !res.data ||
                (res.data && !res.data.length) ||
                (res.data && res.data.length && res.data.length < 15)
              ) {
                this.showMore = false;
              } else {
                this.showMore = true;
              }
            }
            this.getWorkflowExecutions();
          })
        )
        .catch((err) => {});
    }
  };

  @action
  getExecutionSummaries = () => {
    return getExecutionSummaries(
      this.globalStore.workflowStore.activeChildUserId
    )
      .then(
        action((res) => {
          this.executionSummaries = res.data;
        })
      )
      .catch((err) => {});
  };

  @action
  getEmbeddedExecutionSummaries = ({
    workflowIds = [],
    integrationIds = [],
  }) => {
    const userId = this.globalStore.workflowStore.activeChildUserId;
    return getEmbeddedExecutionSummaries(userId, workflowIds, integrationIds)
      .then(
        action((res) => {
          this.executionSummaries = res.data;
        })
      )
      .catch((err) => {});
  };

  maybeShowNotification = (title, body, workflowId) => {
    const { activeWorkflowId } = this.globalStore.workflowStore;
    const currentPath = this.globalStore.routingStore.location.pathname;

    if (currentPath === '/workflow/build' && activeWorkflowId !== workflowId)
      return;

    showNotification(title, body);
  };

  @action
  runWorkflow = async (blockId, workflowGiven) => {
    const workflow = workflowGiven || this.globalStore.workflowStore.workflow;
    const { activeWorkflowCount } = this.globalStore.workflowStore;
    if (this.isWorkflowRunning(workflow._id)) {
      return;
    }

    try {
      // Check first if the workflow has any issues before execute it
      let issuesExist = false;
      workflow.blocks.forEach((block) => {
        if (
          (block.issues &&
            block.issues.parameters &&
            Object.keys(block.issues.parameters).length) ||
          (block.issues.credentials &&
            Object.keys(block.issues.credentials).length)
        ) {
          issuesExist = true;
        }
      });

      // If user is trying to run an entire workflow that has errors
      if (issuesExist && !blockId) {
        showNotification(
          'error',
          'Workflow can not be executed! The workflow has issues. Please fix them first.'
        );
        return;
      }

      // Track segment event
      segmentTrackWorkflowActivation(
        workflow,
        activeWorkflowCount,
        this.globalStore.userStore.userInfo.email
      );

      const { userInfo } = this.globalStore.userStore;
      const runData = {
        workflowId: workflow._id,
        sessionId: this.sessionId,
        userId: userInfo._id,
      };
      // Run test for a single block
      if (blockId) {
        runData.destinationBlock = blockId;
      }

      const triggerBlockName = workflow.blocks[0].type;

      if (triggerBlockName !== 'alloy.start') {
        this.aWorkflowIsActivating = true;

        try {
          workflow.active = true;
          this.isWorkflowStillUpdating = true;
          const activationMessage = {
            method: 'manual',
            message: `Workflow activated by ${userInfo.fullName}`,
          };
          await updateWorkflow(workflow._id, {
            activationMessage,
            ...workflow,
          });

          runInAction(() => {
            this.isWorkflowStillUpdating = false;
            this.runningWorkflowIds.add(workflow._id);
            this.aWorkflowIsActivating = false;
            this.workflowRecentlyDeactivated = false;
            if (this.notificationPollingWorkflowId !== workflow._id) {
              this.startNotificationPolling({ workflowId: workflow._id });
            }
          });

          return;
        } catch (err) {
          runInAction(() => {
            if (
              err.message &&
              err.message ===
                'Cannot activate workflow. You have reached your limit of activated workflows.'
            ) {
              this.showLimitModal = true;
            } else {
              showNotification(
                'error',
                err.message ||
                  'Problem running workflow: There was a problem running the workflow.'
              );
            }

            this.aWorkflowIsActivating = false;
            this.removeRunningWorkflow(workflow._id);
            workflow.active = false;
          });
          return;
        }
      }

      const response = await runWorkflow(runData);

      if (response && response.data && response.data.executionId) {
        // not sure if this is still needed in mobx 5, leaving it in just in case
        // using awaits obscures the fact this is technically running in a Promise.then scope, so
        // we need to re-specify that mobx needs to react to changes here
        runInAction(() => {
          this.runningWorkflowIds.add(workflow._id);
          this.executionId = response.data.executionId;
          this.executionWaitingForWebhook = !!response.data.waitingForWebhook;
          // Init the execution data to represent the start of the execution
          // that data which gets reused is already set and data of newly executed
          // blocks can be added as it gets pushed in
          this.workflowExecutionData = {
            id: '__IN_PROGRESS__',
            finished: false,
            mode: triggerBlockName === 'alloy.start' ? 'manual' : 'trigger',
            startedAt: new Date(),
            stoppedAt: undefined,
            workflowId: workflow._id,
            data: {
              resultData: {
                runData: {},
                startBlocks: [],
                workflowData: workflow,
              },
            },
            workflowData: workflow,
          };
        });
      }
    } catch (error) {
      // TODO bugsnag
      this.hasError = true;
      showNotification(
        'error',
        'Problem running workflow: There was a problem running the workflow.'
      );
      return undefined;
    } finally {
      this.getWorkflowLogs(workflow._id, true);
    }
  };

  @action
  stopWorkflow = async (givenWorkflow) => {
    const workflow = givenWorkflow || this.globalStore.workflowStore.workflow;
    try {
      if (workflow.active) {
        workflow.active = false;
        this.aWorkflowIsDeactivating = true;
        this.removeRunningWorkflow(workflow._id);
        const deactivationError = {
          method: 'manual',
          message: `Workflow deactivated manually by ${this.globalStore.userStore.userInfo.fullName}`,
        };
        await updateWorkflow(workflow._id, { deactivationError, ...workflow });

        runInAction(() => {
          this.aWorkflowIsDeactivating = false;
        });
      }
    } catch (err) {
      runInAction(() => {
        this.aWorkflowIsDeactivating = false;
      });
    } finally {
      this.getWorkflowLogs(workflow._id, true);
    }
  };

  @action
  stopNotificationPolling = () => {
    if (this.notificationPollingId !== null) {
      clearInterval(this.notificationPollingId);
      this.notificationPollingId = null;
    }

    this.notificationPollingWorkflowId = null;
  };

  @action
  pushMessageReceived = async (event) => {
    let receivedData;

    try {
      receivedData = JSON.parse(event.data);
    } catch (error) {
      // TODO bugsnag
      // console.error('The received push data is not valid JSON.');
      return;
    }
    if (receivedData.type === 'executionError') {
      this.globalStore.workflowStore.hasWorkflowErrors = true;
      this.globalStore.workflowStore.tempWorkflowErrors = receivedData.data;

      this.getWorkflowLogs(receivedData.workflowId, true);
      this.removeRunningWorkflow(receivedData.workflowId);
    }
    if (receivedData.type === 'executionStarted') {
      this.getWorkflowLogs(receivedData.workflowId, true);
    }
    if (receivedData.type === 'executionFinished') {
      // The workflow finished executing
      const pushData = receivedData.data;
      this.finishActiveExecution(pushData);
      const workflowRunInThisWindow = this.runningWorkflowIds.has(
        pushData.workflowId
      );

      const runDataExecuted = pushData.data;

      if (runDataExecuted.error) {
        if (
          workflowRunInThisWindow &&
          this.globalStore.workflowStore.hasWorkflowErrors
        ) {
          this.globalStore.workflowStore.addExecutionError(
            this.globalStore.workflowStore.tempWorkflowErrors
          );
          this.globalStore.workflowStore.tempWorkflowErrors = null;
          this.globalStore.workflowStore.hasWorkflowErrors = false;
        }

        // otherwise, already got a message about API error that removed the workflowId
        if (this.runningWorkflowIds.has(pushData.workflowId)) {
          this.maybeShowNotification(
            'error',
            'Problem executing workflow: There was a problem executing the workflow.',
            pushData.workflowId
          );
        }
      }

      if (workflowRunInThisWindow) {
        this.executingBlock = null;
        this.workflowExecutionData = runDataExecuted;
        this.globalStore.myAccountStore.getAnalytics();
        this.removeRunningWorkflow(pushData.workflowId);
        this.getWorkflowLogs(pushData.workflowId, true);
      }
      showNotification('success', 'Workflow executed');
      // Set the block execution issues on all the blocks which produced an error so that
      // it can be displayed in the block-view
      // this.updateBlocksExecutionIssues();
    }
  };

  @action
  removeRunningWorkflow = (workflowId) => {
    this.runningWorkflowIds.delete(workflowId);
  };

  isWorkflowRunning = (workflowId) => {
    return !!this.runningWorkflowIds.has(workflowId);
  };

  @action
  addActiveExecution = (newActiveExecution) => {
    const index = this.activeExecutions.findIndex((execution) => {
      return execution.idActive === newActiveExecution.idActive;
    });

    if (index !== -1) {
      // Exists already so no need to add it again
      if (this.activeExecutions[index].workflowName === undefined) {
        this.activeExecutions[index].workflowName =
          newActiveExecution.workflowName;
      }
    } else {
      this.activeExecutions.push(newActiveExecution);
    }
  };

  @action
  finishActiveExecution = (finishedActiveExecution) => {
    const index = this.activeExecutions.findIndex((execution) => {
      return execution.idActive === finishedActiveExecution.executionidActive;
    });

    if (index === -1) return;

    if (finishedActiveExecution.executionIdDb !== undefined) {
      this.activeExecutions[index].id = finishedActiveExecution.executionIdDb;
    }

    this.activeExecutions[index].finished =
      finishedActiveExecution.data.finished;
    this.activeExecutions[index].stoppedAt =
      finishedActiveExecution.data.stoppedAt;
  };

  @action
  addBlockExecutionData = (pushData) => {
    if (this.workflowExecutionData === null) {
      return;
    }
    if (
      this.workflowExecutionData.data.resultData.runData[pushData.blockId] ===
      undefined
    ) {
      this.workflowExecutionData.data.resultData.runData[pushData.blockId] = [];
    }
    this.workflowExecutionData.data.resultData.runData[pushData.blockId].push(
      pushData.data
    );
  };

  @action
  updateBlocksExecutionIssues = () => {
    if (this.workflowExecutionData === null) return;

    const { blocks } = this.globalStore.workflowStore.workflow;
    const workflowResultData =
      this.workflowExecutionData.data.resultData.runData;
    const hasBlockExecutionIssues = (block) => {
      if (
        workflowResultData === null ||
        !workflowResultData.hasOwnProperty(block.id)
      ) {
        return false;
      }

      let hasError = false;
      const errorExecution = [];
      for (const taskData of workflowResultData[block.id]) {
        if (taskData.error !== undefined) {
          hasError = true;
          errorExecution.push(taskData.error.message);
        }
      }
      if (hasError) {
        return {
          execution: errorExecution,
        };
      }

      return false;
    };

    for (const block of blocks) {
      this.setBlockIssue(block.id, 'execution', hasBlockExecutionIssues(block));
    }
  };

  @action
  setBlockIssue = (blockId, type, value) => {
    const { workflow } = this.globalStore.workflowStore;
    const blockIndex = workflow.blocks.findIndex((block) => {
      return block.id === blockId;
    });
    if (blockIndex === -1) {
      return false;
    }

    if (!value) {
      // Remove the value if one exists
      if (
        workflow.blocks[blockIndex].issues === undefined ||
        workflow.blocks[blockIndex].issues[type] === undefined
      ) {
        // No values for type exist so nothing has to get removed
        return true;
      }
      delete workflow.blocks[blockIndex].issues[type];
    } else {
      if (workflow.blocks[blockIndex].issues === undefined) {
        workflow.blocks[blockIndex].issues = {};
      }

      // Set/Overwrite the value
      workflow.blocks[blockIndex].issues[type] = value;
    }
    updateWorkflow(workflow._id, workflow);
    return true;
  };

  @action
  startNotificationPolling = (data) => {
    this.stopNotificationPolling();

    this.notificationPollingId = setInterval(() => {
      getLatestNotifications(data).then(
        action((res) => {
          if (res.data && res.data.length) {
            const workflowId = data.workflowId || data.forgeWorkflowId;
            let isThere = false;
            this.executionDetails.forEach(
              action((exe) => {
                if (exe._id === workflowId) {
                  isThere = true;
                  if (exe.executionSummaries.length) {
                    res.data.forEach((newExe) => {
                      const index = exe.executionSummaries.findIndex(
                        (oldExe) =>
                          oldExe.data.executionId === newExe.data.executionId
                      );
                      if (index === -1) {
                        exe.executionSummaries.unshift(newExe);
                      } else {
                        exe.executionSummaries[index] = newExe;
                      }
                    });
                  } else {
                    exe.executionSummaries = res.data;
                  }
                }
              })
            );
            if (!isThere) {
              this.executionDetails.push({
                _id: workflowId,
                executionSummaries: res.data,
              });
            }
            this.removeRunningWorkflow(workflowId);

            if (data.forgeWorkflowId) {
              this.getEmbeddedExecutionSummaries({
                workflowIds: [{ workflowId: data.forgeWorkflowId }],
              });
            } else {
              this.getExecutionSummaries(
                this.globalStore.workflowStore.activeChildUserId
              );
            }
            this.globalStore.myAccountStore.getAnalytics();
          }
        })
      );
    }, 15000);

    this.notificationPollingWorkflowId =
      data.workflowId || data.forgeWorkflowId;
  };
}
