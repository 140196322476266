/* eslint-disable */
let apiUrl = 'http://localhost:4040';

if (window.location.hostname == 'localhost') {
  apiUrl = 'http://localhost:4040';
} else if (
  window.location.hostname == 'runalloy.com' ||
  window.location.hostname == 'www.runalloy.com' ||
  window.location.hostname == 'app.runalloy.com'
) {
  apiUrl = 'https://api.runalloy.com';
} else if (window.location.hostname == 'dev-app.runalloy.com') {
  apiUrl = 'https://dev-api.runalloy.com';
} else if (window.location.hostname == 'staging-app.runalloy.com') {
  apiUrl = 'https://staging-api.runalloy.com';
} else {
  //is a PR deployment
  const wildcard = window.location.hostname.split('.')[0];
  apiUrl = `https://${wildcard}-api.runalloy.com`;
}

export default apiUrl;
