import { GET, POST } from './wrappers';

export async function getPaymentData() {
  return GET(`/api/payments/data-v2`);
}

export async function applyPromoCode(params) {
  return POST(`/api/payments/promo-code/apply`, params);
}

export async function validatePromoCode(params) {
  return POST(`/api/payments/promo-code/validate`, params);
}

export async function updateCreditCard(cardToken) {
  return POST(`/api/payments/update-credit-card-v2`, { cardToken });
}
