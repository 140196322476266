import { observable, action } from 'mobx';
import {
  createCustomIntegration,
  getCustomIntegrations,
  getCustomIntegrationById,
  editCustomIntegration,
  editCustomAuthentication,
  getCustomAuthentication,
  deleteCustomIntegration,
  sendTestRequest,
  deleteTestCredential,
} from '../services/customIntegrations';

export class CustomIntegrationStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  customIntegration = {};

  @observable
  customAuthentication = {};

  @observable
  customIntegrations = [];

  @observable
  credential = null;

  @observable
  responseData = null;

  @observable
  isCustomIntegrationsLoading = false;

  @action
  resetCustomIntegration = () => {
    this.customIntegration = {};
    this.customAuthentication = {};
    this.credential = null;
    this.responseData = null;
  };

  @action
  createCustomIntegration = (body) => {
    return createCustomIntegration(body).then(
      action(async (res) => {
        this.customIntegration = res.customIntegration;
        this.customAuthentication = res.customAuthentication;
        await this.getCustomIntegrations();
      })
    );
  };

  @action
  editCustomIntegration = (id, body) => {
    return editCustomIntegration(id, body)
      .then(
        action((res) => {
          this.customIntegration = res;
          return res.customIntegration;
        })
      )
      .catch(() => {});
  };

  @action
  editCustomAuthentication = () => {
    return editCustomAuthentication(
      this.customAuthentication._id,
      this.customAuthentication
    )
      .then(
        action((res) => {
          this.customAuthentication = res.data;
          this.credential = res.credential;
          return res.data;
        })
      )
      .catch((error) => {});
  };

  @action
  getCustomAuthentication = (id) => {
    return getCustomAuthentication(id || this.customAuthentication._id)
      .then(
        action((res) => {
          this.customAuthentication = res.data;
          this.credential = res.credential;
          return res.data;
        })
      )
      .catch((error) => {});
  };

  @action
  sendTestRequest = (body) => {
    return sendTestRequest(this.customAuthentication._id, body)
      .then(
        action((res) => {
          this.responseData = res.data;
        })
      )
      .catch((error) => {});
  };

  @action
  editCredentialName = async (name) => {
    await this.globalStore.credentialStore.renameCredentials(
      this.credential._id,
      {
        name: name.trim(),
      }
    );
    await this.getCustomAuthentication();
  };

  @action
  getCustomIntegrations = async () => {
    if (this.isCustomIntegrationsLoading) return;

    this.isCustomIntegrationsLoading = true;
    try {
      const { customIntegrations } = await getCustomIntegrations();
      this.customIntegrations = customIntegrations;
    } catch {} finally {
      this.isCustomIntegrationsLoading = false;
    }
  };

  @action
  getCustomIntegrationById = (block) => {
    return getCustomIntegrationById(block)
      .then(
        action((res) => {
          this.customIntegration = res.customIntegration;
          this.customAuthentication = res.customAuthentication;
          this.credential = res.credential;
          return res.customIntegration;
        })
      )
      .catch(() => {});
  };

  @action
  deleteTestCredential = () => {
    return deleteTestCredential(this.customAuthentication._id)
      .then(
        action((res) => {
          this.customAuthentication = res.customAuthentication;
          this.credential = null;
          return res.customAuthentication;
        })
      )
      .catch(() => {});
  };

  @action
  deleteCustomIntegration = (id) => {
    return deleteCustomIntegration(id)
      .then(
        action((res) => {
          return res.success;
        })
      )
      .catch(() => {});
  };
}
