/* eslint-disable prefer-destructuring */
import { observable, action, toJS } from 'mobx';
import { showNotification } from '../components/Notifications/Notification';
import { forIn, get, find, size, isObject, map, isArray, filter } from 'lodash';

export class RecipeCreatorStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  fillableBlocks = [];

  @observable
  currentBlockId = '';

  @observable
  formFields = {};

  @observable
  newPost = {};

  @observable
  currentStep = 0;

  @observable
  requiredParams = [];

  @observable
  fieldTags = [];

  @observable
  blockDetails = {};

  @observable
  activeBlocks = [];

  @observable
  markedComplete = [];

  @observable
  showLeftLoader = false;

  @observable
  isSaving = false;

  @observable
  treeData = {};

  @observable
  treeState = [];

  @observable
  selectedWorkflowFields = {};

  @observable
  optionalInfo = {};

  @observable
  recipeSaved = null;

  @action
  reset = () => {
    this.fillableBlocks = [];
    this.currentBlockId = '';
    this.formFields = {};
    this.newPost = {};
    this.currentStep = 0;
    this.requiredParams = [];
    this.fieldTags = [];
    this.selectedWorkflowFields = {};
    this.blockDetails = {};
    this.activeBlocks = [];
    this.markedComplete = [];
    this.treeData = {};
    this.treeState = [];
    this.optionalInfo = {};
    this.recipeSaved = null;
  };

  @action
  setTreeData = (treeData) => {
    this.treeData = treeData;
    this.treeState = this.setTreeState(this.treeData);
  };

  isComplete = (id) => {
    const { fillableBlocks, fieldTags } = this;
    const currentBlock =
      fillableBlocks && fillableBlocks.find((block) => block.id === id);
    const unusedFields =
      currentBlock &&
      currentBlock.blockParameters &&
      currentBlock.blockParameters.filter(
        (param) =>
          !fieldTags.find((tag) => tag.fieldKey === `${id}::${param.name}`)
      );
    return !unusedFields || (unusedFields && unusedFields.length === 0);
  };

  @action
  setLeftLoading = (bool) => {
    this.showLeftLoader = bool;
  };

  @action
  setInitBlockDetails = () => {
    const blockDetails = {};
    this.newPost.blocks.forEach((block) => {
      blockDetails[block.id] = {
        title: block.name,
        subtitle: block.subtitle,
        description: block.subtitle,
      };
    });
    this.blockDetails = blockDetails;
    this.setCurrentBlockId(this.newPost.blocks[0].id);
  };

  @action
  setNewPost = (newPost) => {
    this.newPost = newPost;
    this.selectedWorkflowFields = {};
    this.setTitle(newPost?.name);
  };

  setTreeState = (obj, results = []) => {
    if (isArray(obj)) {
      for (let k in obj) {
        this.setTreeState(obj[k], results);
      }
    } else if (obj && obj.blockData) {
      results.push(obj.blockData.id);
      if (obj.children && obj.children.length) {
        this.setTreeState(obj.children, results);
      }
    } else if (obj && !obj.children.length) {
      results.push(obj?.blockData?.id);
    }

    return results;
  };

  nextBlock = async () => {
    const { currentBlockId } = this.globalStore.blockEditStore;
    let currentBlockIndex = this.treeState.findIndex(
      (block) => block === currentBlockId
    );
    if (currentBlockIndex === this.treeState.length - 1) {
      currentBlockIndex = -1;
    }
    const { workflowStore } = this.globalStore;
    const nextBlockId = this.treeState[currentBlockIndex + 1];
    this.setCurrentBlockId(nextBlockId);

    const { blocks } = workflowStore.workflow;
    const block = blocks.find((nd) => nd.id === nextBlockId);

    await this.globalStore.blockEditStore.setActiveBlock({
      blockType: block.type,
      id: nextBlockId,
      activeEdit: true,
    });
  };

  isLastBlock = () => {
    const { currentBlockId } = this.globalStore.blockEditStore;
    let currentBlockIndex = this.treeState.findIndex(
      (block) => block === currentBlockId
    );
    if (currentBlockIndex == this.treeState.length - 1) {
      return true;
    } else {
      return false;
    }
  };

  previousStep = () => {
    let currentBlockIndex = this.treeState.findIndex(
      (block) => block === this.currentBlockId
    );
    if (currentBlockIndex <= 0) {
      currentBlockIndex = this.treeState.length;
    }
    this.setCurrentBlockId(this.treeState[currentBlockIndex - 1]);
  };

  @action
  incrementStep = () => {
    if (this.currentStep === 0) {
      this.currentStep = 1;
    } else if (this.currentStep === 1) {
      this.createRecipe();
    }
  };

  @action
  setTitle = (title) => {
    this.formFields.title = title;
  };

  @action
  setBlockTitle = (title) => {
    const { currentBlockId } = this;
    if (!this.blockDetails[this.currentBlockId]) {
      this.blockDetails[currentBlockId] = {};
    }
    this.blockDetails[currentBlockId].subtitle = title;
    if (!title) {
      this.removeBlockFromComplete();
    }
  };

  @action
  setBody = (body) => {
    this.formFields.body = body;
  };

  @action
  setTags = (tags) => {
    if (typeof tags === 'string') {
      tags = [tags];
    }
    this.formFields.tags = tags;
  };

  @action
  decrementStep = () => {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
    }
  };

  @action
  markBlockAsComplete = () => {
    if (
      this.markedComplete.indexOf(this.currentBlockId) === -1 &&
      this.blockDetails[this.currentBlockId].title
    ) {
      this.markedComplete.push(this.currentBlockId);
    }
    this.nextBlock();
  };

  @action
  removeBlockFromComplete = () => {
    const blockIndex = this.markedComplete.indexOf(this.currentBlockId);
    if (blockIndex !== -1) {
      this.markedComplete.splice(blockIndex, 1);
    }
  };

  @action
  setCurrentStep = (currentStep) => {
    this.currentStep = currentStep;
  };

  @action
  setBlockDetails = (blockDetails) => {
    this.blockDetails = blockDetails;
  };

  @action
  setCurrentBlockId = (id) => {
    this.currentBlockId = id;
    this.insertFields(id);
  };

  @action
  setIfBlockRequiredFields = ({
    checked,
    id,
    conditionKey = null,
    optional,
  } = {}) => {
    const { fieldTags } = this;
    fieldTags.forEach((tag) => {
      if (tag.fieldBlockId === id) {
        tag.fieldRequired = checked;
        tag.required = checked;
        tag.optional = !!optional;

        const fieldIndex = tag.childrenBlocks.findIndex(
          (child) => child.name === conditionKey
        );

        if (fieldIndex > -1) {
          tag.childrenBlocks[fieldIndex].conditionSets.forEach((block) => {
            if (block.blockId === id) {
              block.required = checked;
              block.fieldRequired = checked;
              block.optional = !!optional;
              block.fields.forEach((field) => {
                field.required = checked;
                field.fieldRequired = checked;
                field.optional = !!optional;
              });
            }
          });
          if (checked) {
            tag.childrenBlocks[fieldIndex].required = checked;
            tag.childrenBlocks[fieldIndex].fieldRequired = checked;
            tag.childrenBlocks[fieldIndex].optional = !!optional;
            tag.fieldRequired = checked;
            tag.required = checked;
            tag.optional = !!optional;
          }
        }
      }
    });
  };

  @action
  isRequired = ({
    checked,
    id,
    branchKey = null,
    conditionKey = null,
    selectAll = false,
    ifBlock = false,
    optional = false,
  } = {}) => {
    if (ifBlock) {
      this.setIfBlockRequiredFields({
        checked,
        id,
        conditionKey,
        optional,
      });
    } else {
      const { fieldTags } = this;

      if (fieldTags && fieldTags.length) {
        fieldTags.forEach((tag) => {
          if (tag.fieldKey === id || tag.fieldKey === branchKey) {
            tag.fieldRequired = checked;
            tag.required = checked;
            tag.optional = !!optional;
          }
          if (tag.fieldKey === branchKey) {
            const fieldIndex = tag.childrenBlocks.findIndex(
              (child) => child.fieldKey === conditionKey
            );

            if (fieldIndex > -1) {
              tag.childrenBlocks[fieldIndex].childrenBlocks.forEach((block) => {
                if (block.fieldKey === id) {
                  block.required = checked;
                  block.fieldRequired = checked;
                  block.optional = !!optional;
                }
              });
              if (checked) {
                tag.childrenBlocks[fieldIndex].required = checked;
                tag.childrenBlocks[fieldIndex].fieldRequired = checked;
                tag.childrenBlocks[fieldIndex].optional = !!optional;
                tag.fieldRequired = checked;
                tag.required = checked;
                tag.optional = !!optional;
              }
            }
          } else if (tag && tag.childrenBlocks && tag.childrenBlocks.length) {
            if (selectAll) {
              tag.fieldRequired = checked;
              tag.required = checked;
              tag.optional = !!optional;
              tag.childrenBlocks.forEach((child) => {
                child.fieldRequired = checked;
                child.required = checked;
                child.optional = !!optional;
              });
            } else {
              const fieldIndex = tag.childrenBlocks.findIndex(
                (child) => child.fieldKey === id
              );
              if (fieldIndex > -1) {
                tag.childrenBlocks[fieldIndex].required = checked;
                tag.childrenBlocks[fieldIndex].fieldRequired = checked;
                tag.childrenBlocks[fieldIndex].optional = !!optional;
                if (checked) {
                  tag.fieldRequired = checked;
                  tag.required = checked;
                  tag.optional = !!optional;
                }
              }
              if (!checked) {
                const hasCheckedChild = tag.childrenBlocks.find(
                  (child) => child.required
                );
                if (!hasCheckedChild) {
                  tag.fieldRequired = false;
                  tag.required = false;
                  tag.optional = false;
                }
              }
            }
          }
          return tag;
        });
      }
    }
  };

  @action
  editField = (id, fieldType) => {
    this.isEditing = { id, field: fieldType };
    this.editInput.focus();
  };

  @action
  saveField = (e, id, field) => {
    const { fieldTags } = this;
    const tags = fieldTags.map((tag) => {
      if (tag.fieldKey === id) {
        if (field === 'title') {
          tag.fieldDisplayName = e.target.value;
        } else {
          tag.fieldDescription = e.target.value;
        }
      }
      return tag;
    });
    this.fieldTags = tags;
    this.isEditing = undefined;
  };

  @action
  removeTag = (id) => {
    const { fieldTags, activeBlocks } = this;

    if (fieldTags && fieldTags.length) {
      const tags = fieldTags.filter((tag) => tag.fieldKey !== id);
      const activeTags = activeBlocks.filter((tag) => tag !== id);
      this.fieldTags = tags;
      this.activeBlocks = activeTags;
    }
  };

  @action
  onChangeFieldTitle = (oldTagId, blockId, fieldValue, value) => {
    const isActive = this.isActiveBlock(blockId, fieldValue);
    if (isActive) {
      const fieldTagIndex = this.fieldTags.findIndex(
        (tg) => tg.fieldKey === oldTagId
      );
      this.fieldTags[fieldTagIndex].fieldDisplayName = value;
    }
  };

  @action
  replaceTag = (oldTagId, blockId, fieldName) => {
    const isActive = this.isActiveBlock(blockId, fieldName);
    if (!isActive) {
      const block = this.fillableBlocks.find((blk) => blk.id === blockId);
      let displayName = '';
      let description = '';
      let type = '';

      block.blockParameters.forEach((param) => {
        if (param.name === fieldName) {
          type = param.fieldType;
          displayName = param.displayName;
          description = param.description;
        }
      });

      const tag = {
        type,
        icon: block.blockDetails.icon,
        fieldName: block.type,
        fieldBlockId: block.id,
        fieldValue: fieldName,
        name: fieldName,
        fieldDisplayName: displayName,
        fieldDescription: description,
        displayName,
        description,
        fieldPlaceholder: '',
        fieldType: type,
        fieldBlockTypeName: block.blockDetails.displayName,
        fieldKey: `${block.id}::${fieldName}`,
        children: [{ text: '' }],
        fieldRequired: false,
        optional: false,
        blockType: block.blockType,
        typeOptions: block.typeOptions,
        displayOptions: block.displayOptions,
      };
      const activeIndex = this.activeBlocks.findIndex((id) => id === oldTagId);
      this.activeBlocks.splice(activeIndex, 1, tag.fieldKey);
      const fieldTagIndex = this.fieldTags.findIndex(
        (tg) => tg.fieldKey === oldTagId
      );
      this.fieldTags.splice(fieldTagIndex, 1, tag);
    }
  };

  @action
  handleSubmit = (fieldsValue) => {
    this.formFields = { ...fieldsValue };
    this.currentStep = 1;
  };

  @action
  setIsSaving = (input) => {
    this.isSaving = !!input;
  };

  @action
  setRecipeSaved = (id) => {
    this.recipeSaved = id;
  };

  createRecipe = (props) => {
    const isPreview = props?.preview || false;

    const {
      globalStore,
      formFields,
      blockDetails,
      newPost,
      selectedWorkflowFields,
      optionalInfo,
    } = this;
    const { userInfo } = globalStore.userStore;

    const values = {
      ...formFields,
      userId: userInfo ? userInfo._id : null,
      upvotes: 1,
      likedBy: [userInfo ? userInfo._id : null],
      workflowId: newPost._id,
      lightning: true,
      lightningData: Object.values(selectedWorkflowFields),
      optionalInfo,
      blockDetails,
      isPreview,
    };

    this.setIsSaving(true);

    if (isPreview) {
      return globalStore.marketplaceStore
        .newCommunityPost(values)
        .then((data) => {
          this.setIsSaving(false);
          this.setRecipeSaved(data?.template?._id);
          return data;
        })
        .catch((err) => {
          this.setIsSaving(false);
          showNotification(
            'error',
            err.response.message
              ? err.response.message
              : 'Unable to create new post, please try again'
          );
        });
    } else {
      globalStore.marketplaceStore
        .newCommunityPost(values)
        .then(() => {
          this.setIsSaving(false);
          globalStore.workflowStore.disableRecipePublisherMode();
          globalStore.routingStore.push('/marketplace/my-recipes');
          showNotification('success', 'Workflow recipe created successfully.');
        })
        .catch((err) => {
          this.setIsSaving(false);
          showNotification(
            'error',
            err.response.message
              ? err.response.message
              : 'Unable to create new post, please try again'
          );
        });
    }
  };

  updateRecipe = () => {
    const {
      globalStore,
      formFields,
      blockDetails,
      newPost,
      selectedWorkflowFields,
      optionalInfo,
    } = this;
    const { userInfo } = globalStore.userStore;

    const values = {
      ...formFields,
      userId: userInfo ? userInfo._id : null,
      upvotes: 1,
      likedBy: [userInfo ? userInfo._id : null],
      workflowId: newPost._id,
      lightning: true,
      lightningData: Object.values(selectedWorkflowFields),
      optionalInfo,
      blockDetails,
    };

    this.setIsSaving(true);

    globalStore.marketplaceStore
      .newCommunityPost(values)
      .then(() => {
        this.setIsSaving(false);
        globalStore.workflowStore.disableRecipePublisherMode();
        globalStore.routingStore.push('/marketplace/my-recipes');
        showNotification('success', 'Workflow recipe created successfully.');
      })
      .catch((err) => {
        this.setIsSaving(false);
        showNotification(
          'error',
          err.response.message
            ? err.response.message
            : 'Unable to create new post, please try again'
        );
      });
  };

  isActiveBlock = (blockId, blockParameter) => {
    const { activeBlocks } = this;
    return activeBlocks.includes(`${blockId}::${blockParameter}`);
  };

  insertFields = (blockId) => {
    const { fillableBlocks } = this;
    const currentBlock =
      fillableBlocks && fillableBlocks.find((block) => block.id === blockId);

    if (this.fieldTags.find((item) => item.fieldBlockId === currentBlock.id)) {
      return;
    }

    let displayName = '';
    let description = '';
    let type = '';

    if (currentBlock && currentBlock.blockParameters) {
      currentBlock.blockParameters.forEach((param) => {
        type = param.fieldType;
        displayName = param.displayName;
        description = param.description;
        const tag = {
          type,
          icon: currentBlock.blockDetails.icon,
          fieldName: currentBlock.type,
          fieldBlockId: currentBlock.id,
          fieldValue: param.name,
          name: param.name,
          fieldDisplayName: displayName,
          fieldDescription: description,
          displayName,
          description,
          fieldPlaceholder: '',
          fieldType: type,
          fieldBlockTypeName: currentBlock.blockDetails.displayName,
          fieldKey: `${currentBlock.id}::${param.name}`,
          children: [{ text: '' }],
          fieldRequired: false,
          childrenBlocks: param.childrenBlocks,
          blockType: param.blockType,
          typeOptions: param.typeOptions,
          displayOptions: param.displayOptions,
          optional: false,
        };

        this.activeBlocks.push(`${tag.fieldBlockId}::${tag.fieldValue}`);

        this.fieldTags = [...this.fieldTags, tag];
      });
    }
  };

  @action
  insertField = (block, parameterName) => {
    let displayName = '';
    let description = '';
    let type = '';

    block.blockParameters.forEach((param) => {
      if (param.name === parameterName) {
        type = param.fieldType;
        displayName = param.displayName;
        description = param.description;
      }
    });

    const tag = {
      type,
      icon: block.blockDetails.icon,
      fieldName: block.type,
      fieldBlockId: block.id,
      fieldValue: parameterName,
      name: parameterName,
      fieldDisplayName: displayName,
      fieldDescription: description,
      displayName,
      description,
      fieldPlaceholder: '',
      fieldType: type,
      fieldBlockTypeName: block.blockDetails.displayName,
      fieldKey: `${block.id}::${parameterName}`,
      children: [{ text: '' }],
      fieldRequired: false,
      blockType: block.blockType,
      typeOptions: block.typeOptions,
      displayOptions: block.displayOptions,
      optional: false,
    };

    this.activeBlocks.push(`${tag.fieldBlockId}::${tag.fieldValue}`);

    this.fieldTags = [...this.fieldTags, tag];
  };

  @action
  getFillableBlocks = (blocks) => {
    const { globalStore } = this;
    const fillableBlocks = [];

    blocks.forEach((block) => {
      const blockId = block.id;
      const blockName = block.name;
      const blockType = block.type;
      const blockParameters = block.parameters;

      const blockSubtitle = block.subtitle;
      const fillableBlockParameters = [];
      const autoShare = [
        'alloy.cron',
        'alloy.for',
        'alloy.merge',
        'alloy.dataFormatter',
        // 'alloy.math',
        'alloy.delay',
        'alloy.dateTime',
      ];

      const blockDetails = globalStore.blockTypeStore.findByName(blockType);

      let ifBlockHasConditions = false;

      if (blockDetails.name === 'alloy.if') {
        const ifConditions = get(blockParameters, 'conditionSets', null);

        if (ifConditions !== null) {
          if (size(ifConditions) > 0) {
            ifBlockHasConditions = true;
          }
        }
      }

      if (
        !blockDetails ||
        autoShare.includes(blockDetails.name) ||
        (blockDetails.name === 'alloy.if' && !ifBlockHasConditions)
      )
        return;

      const blockProperties = blockDetails.properties;

      const blockResource = block.parameters.resource
        ? block.parameters.resource
        : null;
      const blockOperation = block.parameters.operation
        ? block.parameters.operation
        : null;

      const resourceProperties = blockProperties.filter((prop) => {
        if (prop.name === 'resource') {
          return true;
        }
        return false;
      });

      const operationProperties = blockProperties.filter((prop) => {
        if (prop.name === 'operation') {
          return true;
        }
        return false;
      });

      forIn(blockParameters, (value, key) => {
        let valueIsExpression = false;
        const valueIsObject = isObject(value);

        if (
          typeof value === 'string' &&
          (value.includes('{{$block') || value.includes('{{$workflow'))
        ) {
          valueIsExpression = true;
        }

        if (key === 'resource' || key === 'operation') {
          return;
        }
        const properties = blockProperties.filter((prop) => {
          if (prop.name === key) {
            if (!prop.displayOptions) {
              return true;
            }
            if (prop.displayOptions && prop.displayOptions.show) {
              if (prop.type !== 'boolean') {
                if (
                  resourceProperties.length > 0 &&
                  operationProperties.length > 0 &&
                  blockResource &&
                  blockOperation &&
                  prop.displayOptions.show.resource.includes(blockResource) &&
                  prop.displayOptions.show.operation.includes(blockOperation)
                ) {
                  return true;
                } else if (
                  resourceProperties.length <= 0 &&
                  operationProperties.length > 0 &&
                  blockOperation &&
                  prop.displayOptions.show.operation.includes(blockOperation)
                ) {
                  if (this.hasStaticOptions(prop)) {
                    return false;
                  }
                  return true;
                } else if (
                  resourceProperties.length <= 0 &&
                  operationProperties.length <= 0 &&
                  prop.displayOptions.show
                ) {
                  if (this.hasStaticOptions(prop)) {
                    return false;
                  }
                  const showKeys = Object.keys(prop.displayOptions.show);
                  return showKeys.some((showKey) => {
                    const values = prop.displayOptions.show[showKey];
                    if (values.includes(blockParameters[showKey])) {
                      return true;
                    }
                    return false;
                  });
                }
              } else if (prop.type === 'boolean' && value) {
                return true;
              }
            } else if (
              resourceProperties.length <= 0 &&
              operationProperties.length <= 0 &&
              prop.required
            ) {
              if (this.hasStaticOptions(prop)) {
                return false;
              }
              return true;
            }
          }
          return false;
        });

        if (properties.length > 0) {
          if (!valueIsExpression && !valueIsObject) {
            if (properties[0].type !== 'collection') {
              fillableBlockParameters.push({
                blockId,
                name: key,
                fieldType: properties[0].type,
                displayName: properties[0].displayName,
                description: properties[0].description,
                required: true,
                value: blockParameters[key],
                typeOptions: properties[0].typeOptions,
                displayOptions: properties[0].displayOptions,
              });
            } else {
              const mappedCollection = map(
                value,
                (collectionValue, collectionKey) => {
                  const collectionOptions = properties[0].options;
                  let collectionValueIsExpression = false;

                  if (
                    typeof collectionValue === 'string' &&
                    (collectionValue.includes('{{$block') ||
                      collectionValue.includes('{{$workflow'))
                  ) {
                    collectionValueIsExpression = true;
                  }

                  for (let i = 0; i < collectionOptions.length; i++) {
                    if (
                      collectionOptions[i].name === collectionKey &&
                      !collectionValueIsExpression
                    ) {
                      return {
                        blockId,
                        name: `${key}::${collectionKey}`,
                        fieldType: collectionOptions[i].type,
                        displayName: collectionOptions[i].displayName,
                        description: collectionOptions[i].displayName,
                        required: true,
                      };
                    }
                  }
                }
              );

              for (let x = 0; x < mappedCollection.length; x++) {
                if (mappedCollection[x]) {
                  fillableBlockParameters.push(mappedCollection[x]);
                }
              }
            }
          }
          if (valueIsExpression) {
            if (properties[0].type !== 'collection') {
              fillableBlockParameters.push({
                blockId,
                name: key,
                fieldType: properties[0].type,
                displayName: properties[0].displayName,
                description: properties[0].description,
                required: true,
                value: blockParameters[key],
                typeOptions: properties[0].typeOptions,
                displayOptions: properties[0].displayOptions,
              });
            }
          } else if (valueIsObject) {
            if (
              properties[0].type === 'fixedCollection' &&
              blockDetails.name === 'alloy.if'
            ) {
              const fillableBlock = this.getIfBlockFields(
                value,
                block,
                blockId,
                properties[0]
              );
              fillableBlockParameters.push(fillableBlock);
            } else if (
              properties[0].type === 'fixedCollection' &&
              blockDetails.name === 'alloy.branch'
            ) {
              /**
               * get all branches
               * get all conditionSets for each branch
               * get condition for each conditionSet
               * conditions properties should be the child for each branch
               */
              const branches = value;

              branches.forEach((branch) => {
                const label = branch.label;
                const conditionSets = branch.conditionSets;
                const fillableBlock = {
                  blockId,
                  name: label,
                  fieldType: properties[0].type,
                  displayName: label,
                  description: label,
                  required: false,
                  fieldRequired: false,
                  optional: false,
                  blockType: 'alloy.branch',
                  fieldKey: blockId,
                  // value: blockParameters[key],
                  childrenBlocks: [],
                };
                conditionSets.forEach((conditionSet) => {
                  const conditions = conditionSet.conditionSet;

                  conditions.forEach((item, index) => {
                    const conditionBlock = {
                      blockId,
                      name: `[${index}]::Condition[${index}]`,
                      fieldType: 'alloy.branch.condition',
                      displayName: `Condition ${index + 1}`,
                      description: conditionSet.label,
                      required: false,
                      fieldRequired: false,
                      optional: false,
                      fieldKey: `${blockId}[${index}]::Condition[${index}]`,
                      // value: blockParameters[key],
                      blockType: 'alloy.branch',
                      childrenBlocks: [],
                    };
                    Object.keys(item).forEach((prop) => {
                      if (prop !== 'uuid' && prop !== 'dateValue2') {
                        conditionBlock.childrenBlocks.push({
                          blockId,
                          name: prop,
                          fieldType: 'string',
                          displayName: prop,
                          description: prop,
                          fieldName: block.type,
                          fieldDisplayName: prop,
                          fieldBlockId: block.id,
                          fieldValue: item[prop],
                          fieldDescription: prop,
                          fieldPlaceholder: '',
                          fieldKey: `${blockId}[${index}]::Condition[${index}]::${prop}`,
                          required: false,
                          fieldRequired: false,
                          optional: false,
                          value: item[prop],
                        });
                      }
                    });
                    fillableBlock.childrenBlocks.push(conditionBlock);
                  });
                });
                fillableBlockParameters.push(fillableBlock);
              });
            } else if (properties[0].type === 'string') {
              fillableBlockParameters.push({
                blockId,
                name: key,
                fieldType: properties[0].type,
                displayName: properties[0].displayName,
                description: properties[0].description,
                required: true,
                value: blockParameters[key],
                typeOptions: properties[0].typeOptions,
                displayOptions: properties[0].displayOptions,
              });
            } else if (properties[0].type === 'fixedCollection') {
              const fillableBlock = {
                blockId,
                name: key,
                fieldType: properties[0].type,
                displayName: properties[0].displayName,
                description: properties[0].description,
                required: false,
                fieldRequired: false,
                optional: false,
                childrenBlocks: [],
                blockType: blockDetails.name,
                typeOptions: properties[0].typeOptions,
                displayOptions: properties[0].displayOptions,
              };

              if (blockDetails.name === 'alloy.googleSheets') {
                fillableBlock.fieldKey = `${blockId}::${key}`;
                fillableBlock.displayName = 'Columns';
              }

              if (
                properties[0].typeOptions &&
                properties[0].typeOptions.multiple
              ) {
                fillableBlock.childrenBlocks = map(value, (collectionValue) => {
                  const name =
                    collectionValue.name ||
                    collectionValue.label ||
                    collectionValue.column;
                  return {
                    blockId,
                    name,
                    fieldType: collectionValue.type || 'string',
                    displayName: name,
                    description: name,
                    fieldName: block.type,
                    fieldDisplayName: name,
                    fieldBlockId: block.id,
                    fieldValue: collectionValue.value,
                    fieldDescription: name,
                    fieldPlaceholder: '',
                    fieldKey: `${key}::${name}`,
                    required: false,
                    fieldRequired: false,
                    optional: false,
                    value: collectionValue.value,
                    blockType: blockDetails.name,
                  };
                });
              } else {
                fillableBlock.childrenBlocks = properties[0].options.map(
                  (row) => {
                    const defaultValue = get(
                      blockParameters,
                      `${key}[0].${row.name}`,
                      row.default
                    );
                    return {
                      blockId,
                      name: row.name,
                      fieldType: row.type || 'string',
                      displayName: row.displayName,
                      description: row.description || '',
                      fieldName: block.type,
                      fieldDisplayName: row.displayName,
                      fieldBlockId: block.id,
                      fieldValue: defaultValue,
                      fieldDescription: row.name,
                      fieldPlaceholder: '',
                      fieldKey: `${key}::${row.name}`,
                      required: false,
                      fieldRequired: false,
                      optional: false,
                      value: defaultValue,
                      blockType: blockDetails.name,
                    };
                  }
                );
              }
              fillableBlockParameters.push(fillableBlock);
            } else if (blockDetails.name === 'alloy.math') {
              for (
                let valueSetsIndex = 0;
                valueSetsIndex < value.length;
                valueSetsIndex++
              ) {
                fillableBlockParameters.push({
                  blockId,
                  name: `${key}[${valueSetsIndex}]`,
                  fieldType: 'number',
                  displayName: `Math Field Value ${valueSetsIndex + 1}`,
                  description: `The fields to ${blockParameters.mathOperation}`,
                  required: false,
                  value: blockParameters[key],
                });
              }
            }
          }
        }
      });

      if (fillableBlockParameters.length > 0) {
        fillableBlocks.push({
          id: blockId,
          name: blockName,
          subtitle: blockSubtitle,
          type: blockType,
          blockParameters: fillableBlockParameters,
          blockDetails,
        });
      }
    });
    this.fillableBlocks = fillableBlocks;
  };

  hasStaticOptions = (prop) => {
    return prop.type === 'options' && prop.options && prop.options.length !== 0;
  };

  getIfBlockFields = (value, block, blockId, property) => {
    const ifDateOperations = [
      'dateSmaller',
      'dateSmallerEqual',
      'dateEqual',
      'dateNotEqual',
      'dateLarger',
      'dateLargerEqual',
    ];

    const ifNumberOperations = [
      'smaller',
      'smallerEqual',
      'equal',
      'notEqual',
      'larger',
      'largerEqual',
    ];

    const conditionSets = value;

    const label = block.subtitle;

    // top level if block
    const fillableBlock = {
      blockId,
      name: label,
      fieldType: property.type,
      displayName: label,
      description: label,
      fieldRequired: false,
      optional: false,
      blockType: 'alloy.if',
      fieldKey: blockId,
      childrenBlocks: [],
    };

    // loop throguh all top level condition sets
    for (
      let conditionSetsIndex = 0;
      conditionSetsIndex < conditionSets.length;
      conditionSetsIndex++
    ) {
      // get conditions set for each  set

      const conditionGroup = get(
        conditionSets[conditionSetsIndex],
        `conditionSet`,
        []
      );
      const conditionSetGroup = {
        blockId,
        name: `[${conditionSetsIndex}]::Condition[${conditionSetsIndex}]`,
        fieldType: 'alloy.if.condition',
        displayName: `Condition ${conditionSetsIndex + 1}`,
        description: conditionSets[conditionSetsIndex].label,
        required: false,
        fieldRequired: false,
        optional: false,
        fieldKey: `${blockId}[${conditionSetsIndex}]::Condition[${conditionSetsIndex}]`,
        conditionSets: [],
      };

      const propertyOptions = get(property, 'options', null);

      if (conditionGroup.length > 0) {
        for (let i = 0; i < conditionGroup.length; i++) {
          const conditionSet = {
            blockId,
            fields: [],
          };
          const operation = get(conditionGroup[i], 'operation', '');
          // get options field which has the field info like type, displayName, etc.
          const fieldOptions = get(propertyOptions, '0.options', null);
          forIn(conditionGroup[i], (fieldValue, fieldKey) => {
            if (fieldKey !== 'uuid' && fieldValue) {
              const fieldInfo = find(fieldOptions, ['name', fieldKey]);

              let { type } = fieldInfo;
              if (ifDateOperations.includes(operation)) {
                type = 'dateTimePicker';
              }

              conditionSet.fields.push({
                blockId,
                name: `conditionSets[${conditionSetsIndex}].conditionSet[${i}].${fieldKey}`,
                fieldType: type,
                displayName: fieldInfo.displayName,
                description: '',
                required: false,
                value: null, // need to be null to clear values
                typeOptions: fieldInfo.typeOptions,
                displayOptions: fieldInfo.displayOptions,
                fieldName: fieldInfo.displayName,
                fieldDisplayName: fieldInfo.displayName,
                fieldBlockId: block.id,
                fieldValue,
                fieldDescription: '',
                fieldPlaceholder: '',
                fieldKey: `conditionSets[${conditionSetsIndex}].conditionSet[${i}].${fieldKey}`,
                fieldRequired: false,
                optional: false,
                type,
                options: fieldInfo.options,
              });
            }
          });

          conditionSetGroup.conditionSets.push(conditionSet);
        }
        fillableBlock.childrenBlocks.push(conditionSetGroup);
      }
    }
    return fillableBlock;
  };

  getNumberOfRequiredFieldsForBlock = (blockId) => {
    const { selectedWorkflowFields } = this;
    const blockFields = filter(selectedWorkflowFields, {
      fieldBlockId: blockId,
    });
    if (blockFields.length > 0) {
      return blockFields.length;
    }
    return null;
  };

  getFieldString = (path) => {
    const { currentBlockId } = this.globalStore.blockEditStore;
    return `${currentBlockId}::${path.slice(11)}`;
  };

  @action
  setRecipeRequiredFields = (path, parameter) => {
    const fieldKey = this.getFieldString(path);
    const existingRecord = this.selectedWorkflowFields[fieldKey];
    if (!existingRecord) {
      const { currentBlockType } = this.globalStore.blockTypeStore;
      this.selectedWorkflowFields[fieldKey] = {
        type: parameter.type,
        icon: currentBlockType.icon,
        fieldName: currentBlockType.name,
        fieldBlockId: this.globalStore.blockEditStore.currentBlockId,
        fieldValue: parameter.name,
        fieldDisplayName: parameter.displayName,
        fieldDescription: parameter.description,
        fieldPlaceholder: '',
        fieldType: parameter.type,
        fieldBlockTypeName: currentBlockType.displayName,
        fieldKey,
        fieldRequired: true,
        children: [{ text: '' }],
      };
    } else {
      delete this.selectedWorkflowFields[fieldKey];
    }
  };

  @action
  setBlockHideOutput = (hideOutput) => {
    const { currentBlockId } = this.globalStore.blockEditStore;
    const fieldKey = currentBlockId;
    this.optionalInfo[fieldKey] = {
      ...this.optionalInfo[fieldKey],
      hideOutput,
    };
  };

  getBlockHideOutput = () => {
    const { optionalInfo } = this;
    const { currentBlockId } = this.globalStore.blockEditStore;
    return optionalInfo?.[currentBlockId];
  };

  @action
  setBlockSetupInstructions = (setupInstructions) => {
    const { currentBlockId } = this.globalStore.blockEditStore;
    const fieldKey = currentBlockId;
    this.optionalInfo[fieldKey] = {
      ...this.optionalInfo[fieldKey],
      setupInstructions,
    };
  };
}
