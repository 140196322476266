import React from 'react';
import { AppModal } from 'Components/Base/Modal';
import styles from './Popup.less';
import { IconOpenExternal } from 'Components/Base/Icons/actions';
import { Divider } from 'antd';
import {
  SupportDocIcon,
  SupportVideoTutorialIcon,
  Support,
  WalkthroughIcon,
} from './SupportIcons';

const SupportModal = ({ open, onSupportToggle, globalStore }) => {
  const handleClick = (key) => {
    switch (key) {
      case 'tutorials':
        window.open('https://runalloy.com/blog/tutorials');
        onSupportToggle();
        break;
      case 'videoTutorials':
        window.open(
          'https://www.youtube.com/channel/UCLEz0DLG7UmXC63jRYeQzTg/videos'
        );
        onSupportToggle();
        break;

      case 'documentation':
        window.open('https://docs.runalloy.com/');
        onSupportToggle();
        break;
      case 'restartWalkThrough':
        globalStore.walkThroughStore.restartWalkThrough();
        onSupportToggle();
        break;
      case 'support':
        window.open(
          'https://alloyautomation.atlassian.net/servicedesk/customer/portals'
        );
        onSupportToggle();
        break;
      default:
        break;
    }
  };
  return (
    <AppModal
      open={open}
      onCancel={onSupportToggle}
      title="Feel stuck? Get help"
      wrapClassName={styles.supportContainer}
    >
      <div className={styles.title} style={{ marginBottom: '24px' }}>
        We are here to free your time up, so no more getting stuck on automating
        your store. Here it's some helpful resources for you:
      </div>
      <div
        className={styles.supportRaw}
        id="documentation"
        onClick={() => handleClick('documentation')}
      >
        <div className={styles.iconAlign}>
          <SupportDocIcon />
        </div>
        <div className={styles.textContent}>
          <div className={styles.title}>Automation docs</div>
          <div className={styles.description}>
            Browser the product guidelines and FAQs.
          </div>
        </div>
        <div
          className={styles.iconAlign}
          style={{ margin: '0px 0px 0px 47px' }}
        >
          <div className={styles.subIcon}>
            <IconOpenExternal />
          </div>
        </div>
      </div>
      <Divider />
      <div
        className={styles.supportRaw}
        id="videoTutorials"
        onClick={() => handleClick('videoTutorials')}
      >
        <div className={styles.iconAlign}>
          <SupportVideoTutorialIcon />
        </div>
        <div className={styles.textContent}>
          <div className={styles.title}>Video tutorials</div>
          <div className={styles.description}>
            Learn by watching with our YouTube videos.
          </div>
        </div>
        <div
          className={styles.iconAlign}
          style={{ margin: '0px 0px 0px 47px' }}
        >
          <div className={styles.subIcon}>
            <IconOpenExternal />
          </div>
        </div>
      </div>
      <Divider />
      <div
        className={styles.supportRaw}
        id="support"
        onClick={() => handleClick('support')}
      >
        <div className={styles.iconAlign}>
          <Support />
        </div>
        <div className={styles.textContent}>
          <div className={styles.title}>Support</div>
          <div className={styles.description}>
            Get help from our product specialists.
          </div>
        </div>
        <div
          className={styles.iconAlign}
          style={{ margin: '0px 0px 0px 47px' }}
        >
          <div className={styles.subIcon}>
            <IconOpenExternal />
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default SupportModal;
