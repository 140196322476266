import { isNil } from 'lodash';
import { observable, action, runInAction } from 'mobx';

export class ActionStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
    this.initialize();
  }

  @observable
  leftBarScrolling = false;

  @observable
  scrollingTimer = null;

  @observable
  showHelpCenterPopover = false;

  @action
  leftBarStartScrolling = (event) => {
    if (event.target.id === 'leftSidePanel') {
      this.leftBarScrolling = true;
      clearTimeout(this.scrollingTimer);
      this.scrollingTimer = setTimeout(() => {
        this.leftBarScrolling = false;
      }, 250);
    }
  };

  @action
  initialize = () => {
    const helpCenter = localStorage.getItem('helpCenterPopover');

    if (isNil(helpCenter) || helpCenter === 'true') {
      setTimeout(() => this.openHelpCenterPopover(), 3000);
    }
  };

  @action
  openHelpCenterPopover = () => {
    this.showHelpCenterPopover = true;
    localStorage.setItem('helpCenterPopover', true);
  };

  @action
  closeHelpCenterPopover = () => {
    this.showHelpCenterPopover = false;
    localStorage.setItem('helpCenterPopover', false);
  };
}
