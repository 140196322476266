import { keyBy } from 'lodash';

export const listToTreeData = (blocks, connections) => {
  const blocksObject = keyBy(blocks, 'id');
  const triggerKey = blocks[0].id; // Don't assume that object keys are in any particular order

  const listToGraph = (key, parentId) => {
    if (!key) {
      return undefined;
    }

    const connection = connections[key];
    if (!connection) {
      return {
        blockData: blocksObject[key],
        parentId,
        children: [],
      };
    }
    const childrenBlocks = (connections[key].main || []).map((t) => {
      return listToGraph(t && t.length > 0 ? t[0].block : undefined, key);
    });

    return {
      blockData: blocksObject[key],
      parentId,
      children: childrenBlocks || [],
    };
  };
  return listToGraph(triggerKey, undefined);
};

export const checkIfHasIFBlock = (tree) => {
  if (!tree) {
    return false;
  }
  if (tree.blockData && tree.blockData.type === 'alloy.if') {
    return true;
  } else {
    return checkIfHasIFBlock(tree.children[0]);
  }
};

export const checkIfHasBranchBlock = (tree) => {
  if (!tree) {
    return false;
  }
  if (tree.blockData && tree.blockData.type === 'alloy.branch') {
    return true;
  } else {
    return checkIfHasIFBlock(tree.children[0]);
  }
};

export const checkIfHasForBlock = (tree) => {
  if (!tree) {
    return false;
  }
  if (tree.blockData && tree.blockData.type === 'alloy.for') {
    return true;
  } else {
    return checkIfHasForBlock(tree.children[0]);
  }
};

export const checkIFBlockHaschild = (tree) => {
  if (!tree) {
    return [];
  }
  if (tree.blockData && tree.blockData.type === 'alloy.if') {
    return tree.children;
  } else {
    return checkIFBlockHaschild(tree.children[0]);
  }
};

export const countBranches = (tree) => {
  if (!tree || !tree.blockData) {
    return 0;
  }
  let numBranches = 0;
  if (tree.blockData.type === 'alloy.if') {
    numBranches += 2;
    if (tree.children[0]) {
      numBranches += countBranches(tree.children[0]);
    }
    if (tree.children[1]) {
      numBranches += countBranches(tree.children[1]);
    }
  } else if (tree.blockData.type === 'alloy.for') {
    numBranches += 1.5;
    if (tree.children[0]) {
      numBranches += countBranches(tree.children[0]);
    }
  } else if (tree.blockData.type === 'alloy.branch') {
    const thisBranches = tree.blockData.parameters.branches
      ? tree.blockData.parameters.branches.length
      : 0;
    numBranches += thisBranches;
    for (let i = 0; i < thisBranches; i++) {
      numBranches += countBranches(tree.children[i]);
    }
  } else if (tree.children[0]) {
    numBranches += countBranches(tree.children[0]);
  }
  return numBranches;
};

export const countChildren = (tree, count) => {
  if (!tree) {
    return count;
  }
  if (tree.children.length === 0) {
    return ++count;
  } else {
    return countChildren(tree.children[0], ++count);
  }
};
