import { observable, action, runInAction, computed } from 'mobx';
import {
  login,
  logout,
  updateUser,
  updateApps,
  signUp,
  checkEmail,
  requestReset,
  resetPassword,
  changePassword,
  checkInvitationCode,
  getHealthStatus,
  userInfo,
  resendAuthLink,
  getAllAccessLogs,
  apiEnableSso,
} from 'Services/auth';
import { teamMemberInfo } from 'Services/teams';
import {
  getForgeAccount,
  updateForgeAccount,
  createForgeUnifiedApiAccount,
  loginIntoUnifiedApiAccount,
} from 'Services/forge';
import { showNotification } from 'Components/Notifications/Notification';
import { get, size } from 'lodash';
import { verifyMFA } from '../services/auth';
import ColorContrastChecker from 'color-contrast-checker';

export class UserStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
    this.initialize();
  }

  @observable
  userInfo = {};

  @observable
  status = {};

  @observable
  showWelcomeScreen = false;

  @observable
  forgeAccount = {};

  @observable
  accessLogs = [];

  @observable
  accessLogLoadMore = false;

  @observable
  memberInfo = {};

  @observable
  mfaAuthRequired = false;

  @observable
  readOnlyMode = false;

  @observable
  teamFeatureAccess = [];

  @observable
  isUserInfoLoading = false;

  @observable
  openMenuKeys = [
    'forge-integrations-container',
    'unified-api-container',
    'embedded-settings-container',
  ];

  featureAccess = observable.set();

  @computed
  get showOnboarding() {
    const urlRedirect = localStorage.getItem('login-redirection');
    const oneMoreUrlRedirect = localStorage.getItem('redirectAfterLogin');
    return !!urlRedirect || !!oneMoreUrlRedirect
      ? false
      : get(this.userInfo, 'showOnboarding', false);
  }

  @computed
  get hasWriteAccess() {
    const { plans } = this.globalStore.myAccountStore;

    if (plans && plans.name === 'free' && !this.userInfo.partnerHubEnabled) {
      return false;
    }

    return true;
  }

  get isUserLoaded() {
    try {
      const keys = Object.keys(this.userInfo);

      return keys.length > 0;
    } catch (e) {
      console.log('e', e);
      return false;
    }
  }

  @action
  setReadOnlyMode = (isReadOnly) => {
    this.readOnlyMode = isReadOnly;
  };

  @action
  setTeamFeatureAccess = (featureAccess) => {
    this.teamFeatureAccess = featureAccess;
  };

  @action
  login = async (loginData) => {
    return login(loginData).then(
      action((data) => {
        if (data && data.success) {
          if (!data?.user?.forgeId && data?.user?.failedAttemptCount === 0) {
            showNotification('success', 'Login successful!');
          }
          if (!data.tempAccessToken) {
            localStorage.setItem('workflow-refreshToken', data.refreshToken);
            localStorage.setItem('workflow-accessToken', data.accessToken);
            localStorage.setItem(
              'workflow-userInfo',
              JSON.stringify(data.user)
            );
            this.userInfo = data.user;
            teamMemberInfo().then((response) => {
              if (response?.success) {
                const memberInfo = response?.memberInfo || response;
                this.memberInfo = memberInfo;
                localStorage.setItem(
                  'workflow-memberInfo',
                  JSON.stringify(memberInfo)
                );
                if (memberInfo?.featureAccess) {
                  this.setTeamFeatureAccess(memberInfo.featureAccess);
                }
              }
            });
            this.featureAccess.clear();
            if (
              this.userInfo.featureAccess &&
              this.userInfo.featureAccess.length
            ) {
              this.userInfo.featureAccess.forEach((feature) => {
                this.featureAccess.add(feature);
              });
            }
            if (this.userInfo.forgeId && this.userInfo.plan) {
              return this.getForgeAccount();
            }
          } else {
            localStorage.setItem(
              'workflow-tempAccessToken',
              data.tempAccessToken
            );
            localStorage.setItem(
              'workflow-tempRefreshToken',
              data.tempRefreshToken
            );
            this.mfaAuthRequired = true;
          }
        } else if (!data?.success && data?.message === 'jwt expired') {
          return Promise.reject(data.message);
        } else {
          if (!data?.user?.failedAttemptCount) {
            const errorMessage = get(
              data,
              `message`,
              "This email password combo doesn't exist"
            );
            showNotification('error', errorMessage);
          }

          return Promise.reject(data?.user);
        }
      })
    );
  };

  @action
  enableSso = async () => {
    return apiEnableSso(data).then(data);
  };

  @action
  loginUnifiedApiAccount = async ({ email, password }) => {
    return loginIntoUnifiedApiAccount({ email, password }).then(
      action((data) => {
        if (data && data.success) {
          if (!data?.user?.forgeId && data?.user?.failedAttemptCount === 0) {
            showNotification('success', 'Login successful!');
          }
          if (!data.tempAccessToken) {
            localStorage.setItem('workflow-refreshToken', data.refreshToken);
            localStorage.setItem('workflow-accessToken', data.accessToken);
            localStorage.setItem(
              'workflow-userInfo',
              JSON.stringify(data.user)
            );
            this.userInfo = data.user;
            teamMemberInfo().then((response) => {
              if (response?.success) {
                const memberInfo = response?.memberInfo || response;
                this.memberInfo = memberInfo;
                if (memberInfo?.featureAccess) {
                  this.setTeamFeatureAccess(memberInfo.featureAccess);
                }
                localStorage.setItem(
                  'workflow-memberInfo',
                  JSON.stringify(memberInfo)
                );
              }
            });

            this.featureAccess.clear();
            if (
              this.userInfo.featureAccess &&
              this.userInfo.featureAccess.length
            ) {
              this.userInfo.featureAccess.forEach((feature) => {
                this.featureAccess.add(feature);
              });
            }
            if (this.userInfo.forgeId && this.userInfo.plan) {
              return this.getForgeAccount();
            }
          } else {
            localStorage.setItem(
              'workflow-tempAccessToken',
              data.tempAccessToken
            );
            localStorage.setItem(
              'workflow-tempRefreshToken',
              data.tempRefreshToken
            );
            this.mfaAuthRequired = true;
          }

          return data.user;
        } else if (!data?.success && data?.message === 'jwt expired') {
          return Promise.reject(data.message);
        } else {
          if (!data?.user?.failedAttemptCount) {
            const errorMessage = get(
              data,
              `message`,
              "This email password combo doesn't exist"
            );
            showNotification('error', errorMessage);
          }

          return Promise.reject(data?.user);
        }
      })
    );
  };

  @action
  mfaLogin = async (body) => {
    return verifyMFA(body).then(
      action((data) => {
        if (data && data.success) {
          if (!data?.user?.forgeId && data?.user?.failedAttemptCount === 0) {
            showNotification('success', 'Login successful!');
          }
          if (!data.tempAccessToken) {
            localStorage.setItem('workflow-refreshToken', data.refreshToken);
            localStorage.setItem('workflow-accessToken', data.accessToken);
            localStorage.setItem(
              'workflow-userInfo',
              JSON.stringify(data.user)
            );
            localStorage.removeItem('workflow-tempAccessToken');
            localStorage.removeItem('workflow-tempRefreshToken');
            this.userInfo = data.user;
            this.mfaAuthRequired = false;
            teamMemberInfo().then((response) => {
              if (response?.success) {
                const memberInfo = response?.memberInfo || response;
                this.memberInfo = memberInfo;
                if (memberInfo?.featureAccess) {
                  this.setTeamFeatureAccess(memberInfo.featureAccess);
                }
                localStorage.setItem(
                  'workflow-memberInfo',
                  JSON.stringify(memberInfo)
                );
              }
            });
            if (this.userInfo.forgeId && this.userInfo.plan) {
              return this.getForgeAccount();
            }
          } else {
            localStorage.setItem(
              'workflow-tempAccessToken',
              data.tempAccessToken
            );
            localStorage.setItem(
              'workflow-tempRefreshToken',
              data.tempRefreshToken
            );
            this.mfaAuthRequired = true;
          }
        } else if (!data?.success && data?.message === 'jwt expired') {
          return Promise.reject(data.message);
        } else {
          if (!data?.user?.failedAttemptCount) {
            const errorMessage = get(
              data,
              `message`,
              "This email password combo doesn't exist"
            );
            showNotification('error', errorMessage);
          }

          return Promise.reject(data?.user);
        }
      })
    );
  };

  @action
  getAccessLogs = (page) => {
    return getAllAccessLogs(page).then((res) => {
      this.accessLogs = [...this.accessLogs, ...res?.accessLog];
      if (res?.accessLog?.length < 10) {
        this.accessLogLoadMore = false;
      } else {
        this.accessLogLoadMore = this.accessLogs.length < res.accessLogCount;
      }
      return res;
    });
  };

  @action
  requestReset = (resetData) => {
    return requestReset(resetData)
      .then(
        action((data) => {
          if (data && data.success) {
            showNotification(
              'success',
              'Check your email for the confirmation code'
            );
            return data;
          } else {
            showNotification(
              'error',
              'There was an error, make sure your info is correct!'
            );
            return Promise.reject();
          }
        })
      )
      .catch((err) => {
        if (err.statusCode === 404) {
          showNotification(
            'error',
            'No account exists for this email. Check your email or try contacting our customer support.'
          );
        } else if (err.statusCode === 400) {
          showNotification('error', err.response.message);
        } else {
          showNotification(
            'error',
            'There was an error, make sure the email is valid!'
          );
        }
      });
  };

  @action
  resetPassword = (resetData) => {
    return resetPassword(resetData)
      .then(
        action((data) => {
          if (data && data.success) {
            showNotification('success', 'Your password has been reset');
            return data;
          } else {
            showNotification(
              'error',
              'There was an error, make sure your info is correct!'
            );
            return Promise.reject();
          }
        })
      )
      .catch((err) => {
        if (err.statusCode === 404) {
          showNotification(
            'error',
            'No account exists for this email. Check your email or try contacting our customer support.'
          );
        }
        if (err.statusCode === 400) {
          showNotification('error', err.response.message);
        }

        return Promise.reject();
      });
  };

  @action
  changePassword = (resetData) => {
    return changePassword(resetData)
      .then(
        action((data) => {
          if (data && data.success) {
            showNotification('success', 'Password changed!');
            return data;
          } else {
            showNotification(
              'error',
              'There was an error, make sure your info is correct!'
            );
            return Promise.reject();
          }
        })
      )
      .catch((err) => {
        showNotification('error', `${err?.response?.message}`);
      });
  };

  @action
  resendAuthLink = (email) => {
    return resendAuthLink(email).then((res) => {
      return res;
    });
  };

  @action
  logout = () => {
    return logout().then(
      action(() => {
        localStorage.clear();
        this.featureAccess.clear();
        this.globalStore.workspaceStore.workspaces = [];
        this.globalStore.resetStores();
      })
    );
  };

  @action
  getStatus = () => {
    return getHealthStatus().then(
      action((res) => {
        this.status = res.status;
        return res.status;
      })
    );
  };

  @action
  updateUser = (payload) => {
    const user = payload;

    return updateUser(user).then(
      action((res) => {
        localStorage.setItem('workflow-userInfo', JSON.stringify(res.user));
        this.userInfo = res.user;
      })
    );
  };

  @action
  updateApps = (payload) => {
    return updateApps(payload).then(
      action(() => {
        Promise.resolve();
      })
    );
  };

  @action
  signUp = async (payload) => {
    const data = await signUp(payload);

    if (data && data.success) {
      if (data.refreshToken) {
        localStorage.setItem('workflow-refreshToken', data.refreshToken);
      }
      if (data.accessToken) {
        localStorage.setItem('workflow-accessToken', data.accessToken);
      }
      if (data.user && size(data.user)) {
        localStorage.setItem('workflow-userInfo', JSON.stringify(data.user));
      }

      runInAction(() => {
        this.userInfo = data.user;
      });

      const urlRedirect = localStorage.getItem('login-redirection');
      if (!urlRedirect) this.openWelcomeScreen();
      return data;
    } else {
      showNotification('error', 'Sign up failed, please try again');
      return Promise.reject(data);
    }
  };

  // wrappers to help with testing signup page
  checkEmail = (email) => {
    return checkEmail(email);
  };

  checkInvitationCode = (invitationCode) => {
    return checkInvitationCode(invitationCode);
  };

  @action
  initialize = () => {
    let user;
    let memberInfo;

    try {
      user = localStorage.getItem('workflow-userInfo');
      memberInfo = localStorage.getItem('workflow-memberInfo');

      this.globalStore.teamsStore.initialize();
    } catch (e) {
      // error probably means the browser doesn't support local storage; maybe pop a warning modal?
    }

    // const team = this?.globalStore?.teamsStore?.team;
    // try {
    //   if (!team || !Object.keys(team)?.length) {
    //     this.globalStore.teamsStore.initialize();
    //   }
    // } catch (e) {
    //   console.error(e);
    // }

    // if there is a token param, then the user is getting logged out in a millisecond, so skip
    const url = new URL(window.location);
    const hasTokenParam = url.searchParams.has('token');
    if (memberInfo) {
      this.memberInfo = JSON.parse(memberInfo);
    }

    if (user && !hasTokenParam) {
      try {
        this.userInfo = JSON.parse(user);
        this.getUserInfo();
      } catch (e) {
        console.log(e);
      }
    }
  };

  @action
  getUserInfo = async () => {
    const accessToken = localStorage.getItem('workflow-accessToken');

    if (!accessToken) return;
    if (this.isUserInfoLoading) return;

    this.isUserInfoLoading = true;

    const res = await userInfo();
    this.userInfo = res.user;

    if (get(res, 'user.featureAccess', false)) {
      let shouldClear = res.user.featureAccess.some(
        (feature) => !this.featureAccess.has(feature)
      );
      res.user.featureAccess.forEach((feature) => {
        if (shouldClear) {
          this.featureAccess.clear();
          shouldClear = false;
        }
        this.featureAccess.add(feature);
      });
    }
    try {
      if (!localStorage.getItem('workflow-accessToken')) return;

      const accessLogs = await getAllAccessLogs(1);
      this.accessLogs = accessLogs?.accessLog;
      this.accessLogLoadMore =
        this.accessLogs.length < accessLogs.accessLogCount;
      if (accessLogs?.accessLog < 10) {
        this.accessLogLoadMore = false;
      } else {
        this.accessLogLoadMore =
          this.accessLogs.length < accessLogs.accessLogCount;
      }
    } catch (e) {}
    if (this.userInfo.forgeId && this.userInfo.plan) {
      await this.getForgeAccount();
    }
    teamMemberInfo().then((response) => {
      if (response?.success) {
        const memberInfo = response?.memberInfo || response;
        this.memberInfo = memberInfo;
        if (this.memberInfo?.role === 'readOnly' && !this.readOnlyMode) {
          this.setReadOnlyMode(true);
        } else {
          this.setReadOnlyMode(false);
        }

        if (memberInfo?.featureAccess) {
          this.setTeamFeatureAccess(memberInfo.featureAccess);
        }

        localStorage.setItem('workflow-memberInfo', JSON.stringify(memberInfo));
      }
    });

    localStorage.setItem('workflow-userInfo', JSON.stringify(res.user));

    this.isUserInfoLoading = false;
    return res.user;
  };

  @action
  openWelcomeScreen = () => {
    setTimeout(() => {
      this.showWelcomeScreen = true;
    }, 1500);
  };

  @action
  closeWelcomeScreen = () => {
    this.showWelcomeScreen = false;
  };

  @action
  getForgeAccount = () => {
    if (this.userInfo.forgeId && this.userInfo.plan) {
      return getForgeAccount().then(
        action((res) => {
          this.forgeAccount = res.data;
          if (res.data.backgroundColor) {
            this.userInfo.backgroundColor = res.data.backgroundColor;
            const ccc = new ColorContrastChecker();
            const color1 = res.data.backgroundColor;
            const color2 = '#2B2E32';
            if (ccc.isLevelAA(color1, color2, 14)) {
              this.userInfo.fontColor = '#2B2E32';
            } else {
              this.userInfo.fontColor = '#fff';
            }
          }
          return res.data;
        })
      );
    }
  };

  getAccessPermissions = (feature, subFeature) => {
    // this should never be async function otherwise it will break multiple functionality.
    let access = 'edit';
    const { team } = this.globalStore.teamsStore;

    const isTeamAdmin = this.userInfo._id === team?.admin?._id;

    if (isTeamAdmin) {
      return 'edit';
    }

    const { teamFeatureAccess } = this;
    if (teamFeatureAccess && teamFeatureAccess.length) {
      const featureAccess = teamFeatureAccess.find(
        (f) => f.featureCode === feature
      );

      if (subFeature) {
        const subFeatureAccess = featureAccess?.subFeatures?.find(
          (f) => f.featureCode === subFeature
        );
        if (subFeatureAccess) {
          access =
            !subFeatureAccess?.edit && !subFeatureAccess?.read
              ? null
              : subFeatureAccess?.edit
              ? 'edit'
              : 'read';
        }
        return access;
      }

      access =
        !featureAccess?.edit && !featureAccess?.read
          ? null
          : featureAccess?.edit
          ? 'edit'
          : 'read';
    }

    return access;
  };

  @action
  signUpForUnifiedApiAccount = async ({
    email,
    fullName,
    password,
    teamInviteCode,
    isAlternate,
  }) => {
    try {
      const response = await createForgeUnifiedApiAccount({
        email,
        fullName,
        password,
        teamInviteCode,
        isAlternate,
      });

      return response;
    } catch (e) {
      showNotification('error', 'Sign up failed, please try again');
      return Promise.reject(e);
    }
  };

  // @action
  // setOpenMenuKeys = (keys) => {
  //   this.openMenuKeys = [
  //     'forge-integrations-container',
  //     'unified-api-container',
  //     'embedded-settings-container',
  //   ];
  // };

  @action
  updateForgeAccount = ({
    name,
    redirectUrls,
    icon,
    webhookDescription,
    errorWebhookUrl,
    credentialExpiredWebhookUrl,
    backgroundColor,
    customDisposalDays,
    installationCallbackUrl,
    brandName,
    brandImageFileName,
  }) => {
    return updateForgeAccount({
      name,
      redirectUrls,
      icon,
      webhookDescription,
      errorWebhookUrl,
      credentialExpiredWebhookUrl,
      backgroundColor,
      customDisposalDays,
      installationCallbackUrl,
      brandName,
      brandImageFileName,
    }).then(
      action(() => {
        this.forgeAccount.name = name;
        this.forgeAccount.redirectUrls = redirectUrls;
        this.forgeAccount.icon = icon;
        this.forgeAccount.webhookDescription = webhookDescription;
        this.forgeAccount.errorWebhookUrl = errorWebhookUrl;
        this.forgeAccount.credentialExpiredWebhookUrl =
          credentialExpiredWebhookUrl;
        this.forgeAccount.backgroundColor = backgroundColor;
        this.forgeAccount.brandName = brandName;
        this.forgeAccount.brandImageFileName = brandImageFileName;
        this.forgeAccount.customDisposalDays = customDisposalDays;
        this.forgeAccount.installationCallbackUrl = installationCallbackUrl;
        this.userInfo.backgroundColor = backgroundColor;
      })
    );
  };

  @action
  cleanUserInfo() {
    try {
      this.userInfo = {};
      this.globalStore.teamsStore.isTeamLoaded = false;
    } catch (e) {
      console.log(e);
    }
  }
}
