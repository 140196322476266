import { GET, POST, PATCH, DELETE } from './wrappers';

export function getWorkspaces() {
  return GET(`/api/workspaces`);
}

export function moveWorkflowToWorkspace(data) {
  return POST('/api/workspaces/workflow', data);
}

export function bulkMoveWorkflowToWorkspace(data) {
  return POST('/api/workspaces/workflow/bulk-move', data);
}

export function modifyWorkspaces(data) {
  return PATCH('/api/workspaces', data);
}

export function createWorkspace(data) {
  return POST('/api/workspaces', data);
}

export function deleteWorkspaces(key, data) {
  return DELETE(`/api/workspaces/${key}`, data);
}

export function getWorkspacesMine() {
  return GET(`/api/workspaces/mine`);
}
