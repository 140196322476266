import { observable, action, computed } from 'mobx';
import { STATUS, EVENTS, ACTIONS, LIFECYCLE } from 'react-joyride';

export class WalkThroughStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  walkThroughSteps = [];

  @observable
  run = false;

  @observable
  stepIndex = 0;

  @observable
  elements = {};

  @computed
  get getElements() {
    return this.elements;
  }

  @action
  setSteps = (steps) => {
    this.walkThroughSteps = [this.walkThroughSteps, steps];
  };

  @action
  clearWalkThroughSteps = () => {
    this.walkThroughSteps = [];
  };

  @action
  setRun = (run) => {
    this.run = run;
  };

  @action
  endWalkThrough = async () => {
    this.run = false;
    this.stepIndex = 0;
    const user = this.globalStore.userStore.userInfo;
    user.isWalkthroughComplete = true;
    this.clearWalkThroughSteps();
    await this.globalStore.userStore.updateUser(user);
    await this.globalStore.oneStore.updateUapiInfo({
      isWalkthroughComplete: true,
    });
  };

  @action
  restartWalkThrough = async () => {
    const user = this.globalStore.userStore.userInfo;
    if (user.isWalkthroughComplete) {
      user.isWalkthroughComplete = false;
      await this.globalStore.userStore.updateUser(user);
    }
    this.run = true;
    this.stepIndex = 0;
    this.clearWalkThroughSteps();
  };

  @action
  handleJoyrideCallback = async (data) => {
    const { action, index, type, status, lifecycle } = data;

    if (
      (action === ACTIONS.STOP,
      index === 0,
      type === EVENTS.TOUR_STATUS,
      status === STATUS.PAUSED && index > 1)
    ) {
      this.endWalkThrough();
    }

    if (action === ACTIONS.NEXT && lifecycle === LIFECYCLE.COMPLETE) {
      this.endWalkThrough();
    }

    if (
      (action === ACTIONS.STOP,
      index === 0,
      type === EVENTS.TOUR_STATUS,
      status === STATUS.PAUSED)
    ) {
      this.stepIndex += 1;
      setTimeout(() => {
        this.run = true;
      }, 1500);
    }
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      this.run = false;
      this.stepIndex += 1;
      setTimeout(() => {
        this.run = true;
      }, 400);
    }
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
      this.run = false;
      this.stepIndex -= 1;
      setTimeout(() => {
        this.run = true;
      }, 400);
    }

    if (action === ACTIONS.CLOSE) {
      this.endWalkThrough();
    }
  };

  @action
  setRef = (el) => {
    if (el) {
      const { dataset } = el;
      if (dataset.name) {
        this.elements[dataset.name] = el;
      }
    }
  };
}
