import { action, observable } from 'mobx';
import {
  testCustomAction,
  saveCustomAction,
  getAllCustomAction,
  deleteCustomAction,
  updateCustomAction,
} from 'Services/workflowApi';
import { showNotification } from 'Components/Notifications/Notification';
import { get, isArray, set } from 'lodash';
import { uuid4 } from '../utils';
import 'core-js-pure/stable/atob';
import 'core-js-pure/stable/btoa';

export class CustomActionStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  updatingCustomActionId = null;

  @observable
  customActions = [];

  @observable
  modalVisible = false;

  @observable
  discardModalVisible = false;

  @observable
  name = '';

  @observable
  description = '';

  @observable
  responseKeys = [];

  @observable
  responseArrayKeys = [];

  @observable
  editMode = false;

  @observable
  saveModalVisible = false;

  @observable
  credentialName = null;

  @observable
  credentialsLoading = false;

  @observable
  parameters = {
    resource: 'custom',
    operation: 'createCustomAction',
    authentication: 'none',
    requestMethod: 'GET',
    url: '',
    bodyContentType: 'form-urlencoded',
    headerParameters: [],
    requestParameters: [],
    rawJsonBody: '',
    bodyParametersSource: 'rawJsonString', // "blockOutput"
    bodyOutputSelection: ``,
    bodyParameters: [],
    queryParameters: [],
    variables: [],
    paginationEnabled: false,
    pagination: {
      uuid: uuid4(),
      pageSize: '',
      rootKeyArray: '',
      offsetPageSize: '',
      paginationMethod: 'pageBased',
      pointerKey: '',
      nextPointerKey: '',
    },
    ignoreResponseCode: false,
    rawGraphqlVariables: '',
  };
  @observable
  preview = {};

  @observable
  credentials = {};

  @observable
  testSampleData = null;

  @observable
  sampleRequestInProgress = false;

  @observable
  htmlContent = null;

  @action
  init = () => {
    this.modalVisible = true;
    this.editMode = false;
    this.discardModalVisible = false;
    this.credentialName = null;
    this.htmlContent = null;
    this.parameters = {
      resource: 'custom',
      operation: 'createCustomAction',
      requestMethod: 'GET',
      authentication: 'none',
      url: '',
      bodyContentType: 'form-urlencoded',
      headerParameters: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      rawJsonBody: '',
      bodyParametersSource: 'rawJsonString', // "blockOutput"
      bodyOutputSelection: ``,
      bodyParameters: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      queryParameters: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      requestParameters: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      variables: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      ignoreResponseCode: false,
      rawGraphqlVariables: '',
      pagination: {
        uuid: uuid4(),
        pageSize: '',
        rootKeyArray: '',
        offsetPageSize: '',
        paginationMethod: '',
        pointerKey: '',
        nextPointerKey: '',
      },
    };
    this.testSampleData = null;
    this.preview = {};
    this.credentials =
      this.globalStore?.blockEditStore?.blockValues?.credentials || {};
    this.name = '';
    this.description = '';
    this.updatingCustomActionId = null;
    this.saveModalVisible = false;
    setTimeout(() => {
      const focusInput = document.getElementById('endpoint-input');
      focusInput.focus();
    }, 1000);

    const blockType = this.globalStore.blockTypeStore.currentBlockType;

    let apiVersion;

    if (this.globalStore?.blockEditStore?.blockValues?.parameters?.apiVersion) {
      apiVersion =
        this.globalStore?.blockEditStore?.blockValues?.parameters?.apiVersion;
    }

    const credTypeIndex =
      blockType?.credentials &&
      blockType?.credentials?.filter(
        (cred) => !!get(cred, 'displayOptions.show.apiVersion')
      );

    if (apiVersion && credTypeIndex?.length !== 0) {
      const credential =
        blockType?.credentials &&
        blockType.credentials.length === 1 &&
        !blockType.credentials[0]?.displayOptions
          ? blockType?.credentials[0]
          : blockType.credentials?.find(
              (cred) =>
                cred?.displayOptions?.show?.apiVersion?.findIndex(
                  (v) => v === apiVersion
                ) >= 0
            );
      this.credentialName = credential.name;
    } else {
      this.credentialName =
        blockType.credentials && blockType?.credentials[0]?.name;
    }
    const credentialType =
      this.globalStore.credentialStore.credentialTypes.find(
        (credType) => credType.name === this.credentialName
      );

    this.parameters.authentication =
      credentialType?.oauthAuthorizeUrl || credentialType?.properties?.length
        ? ''
        : 'none';
  };

  @action
  togglePagination = (toggle) => {
    this.parameters.paginationEnabled = toggle;
  };

  @action
  setPagination = (pagination) => {
    this.parameters.pagination = pagination;
  };

  @action
  setPaginationValue = (key, value) => {
    this.parameters.pagination[key] = value;
    this.handleContentChange(this.parameters.url);
  };

  @action
  initialize = async () => {
    try {
      const actions = await getAllCustomAction();
      this.customActions = actions?.customActions || [];
    } catch {}
  };

  @action
  deleteAction = async (id) => {
    try {
      await deleteCustomAction(id);
      showNotification('success', 'Custom Action deleted.');
      this.initialize();
    } catch (err) {
      showNotification(
        'error',
        'Something went wrong, could not delete the custom action.'
      );
    }
  };

  @action
  closeSaveModal = () => {
    this.saveModalVisible = false;
  };

  @action
  openSaveModal = () => {
    this.saveModalVisible = true;
  };

  @action
  closeDiscardModal = () => {
    this.discardModalVisible = false;
  };

  @action
  showDiscardModal = () => {
    this.discardModalVisible = true;
  };

  @action
  updateAction = async (id) => {
    const action = this.customActions.find((sc) => sc._id === id);
    const actionDeepCopy = JSON.parse(JSON.stringify(action));
    this.updatingCustomActionId = id;
    this.parameters = actionDeepCopy.parameters;
    this.parameters['ignoreResponseCode'] = false;
    (this.testSampleData = actionDeepCopy.testSampleData),
      (this.preview = actionDeepCopy.preview);
    this.credentials =
      actionDeepCopy?.credentials ||
      this.globalStore.blockEditStore.blockValues?.credentials;
    this.name = actionDeepCopy.name;
    this.description = actionDeepCopy.description;
    this.modalVisible = true;
    this.editMode = true;
    this.discardModalVisible = false;
    this.saveModalVisible = false;
    this.handleContentChange(this.parameters.url);
    setTimeout(() => {
      const focusInput = document.getElementById('endpoint-input');
      focusInput.focus();
    }, 1000);
    const responseJson = get(this.testSampleData, 'json', {});
    this.responseArrayKeys = this.findArrayParams(responseJson);
    this.responseKeys = this.keyParams(responseJson);
    const blockType = this.globalStore.blockTypeStore.currentBlockType;

    let apiVersion;

    if (this.globalStore?.blockEditStore?.blockValues?.parameters?.apiVersion) {
      apiVersion =
        this.globalStore?.blockEditStore?.blockValues?.parameters?.apiVersion;
    }

    const credTypeIndex =
      blockType?.credentials &&
      blockType?.credentials?.filter(
        (cred) => !!get(cred, 'displayOptions.show.apiVersion')
      );

    if (apiVersion && credTypeIndex?.length !== 0) {
      const credential =
        blockType?.credentials &&
        blockType.credentials.length === 1 &&
        !blockType.credentials[0]?.displayOptions
          ? blockType?.credentials[0]
          : blockType.credentials?.find(
              (cred) =>
                cred?.displayOptions?.show?.apiVersion?.findIndex(
                  (v) => v === apiVersion
                ) >= 0
            );
      this.credentialName = credential?.name || null;
    } else {
      this.credentialName =
        (Object.keys(this.credentials)?.length &&
          Object.keys(this.credentials)[0]) ||
        (blockType.credentials && blockType?.credentials[0]?.name);
    }
  };

  @action
  edit = () => {};

  @action
  processQueryParams = () => {};

  @action
  testData = async () => {
    try {
      this.sampleRequestInProgress = true;
      const workflow = this.globalStore.workflowStore.workflow;
      const block = this.globalStore.blockEditStore.blockValues;
      block.parameters = this.parameters;
      block.preview = this.preview;
      if (Object.keys(this.credentials)?.length)
        block.credentials = this.credentials;
      const blockId = this.globalStore.blockEditStore.blockValues.id;

      const data = await testCustomAction({
        workflow,
        block,
        blockId,
        blockType: block.type,
      });
      const responseJson = get(data, 'data.response[0][0].json', {});
      this.responseArrayKeys = this.findArrayParams(responseJson);
      this.responseKeys = this.keyParams(responseJson);
      this.testSampleData = get(data, 'data.response[0][0]', {});
    } catch (err) {
      this.testSampleData = {
        statusCode: err.statusCode,
        json: {
          message: err.message,
        },
      };
    } finally {
      this.sampleRequestInProgress = false;
    }
  };

  @action
  findArrayParams = (item, prefix = '') => {
    const returnVals = [];
    const itemKeys = Object.keys(item);

    itemKeys.forEach((key) => {
      const value = item[key];
      if (isArray(value)) {
        const data = prefix ? `${prefix}${key}` : key;
        returnVals.push(data);
      } else if (typeof value === 'object' && value !== null) {
        returnVals.push(...this.findArrayParams(value, `${prefix}${key}.`));
      }
    });

    return returnVals;
  };

  @action
  keyParams = (item, prefix = '') => {
    const returnVals = [];
    const itemKeys = Object.keys(item);

    itemKeys.forEach((key) => {
      const data = prefix ? `${prefix}${key}` : key;
      returnVals.push(data);
      const value = item[key];
      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        returnVals.push(...this.keyParams(value, `${prefix}${key}.`));
      }
    });

    return returnVals;
  };

  @action
  setUrl = (e) => {
    this.parameters['url'] = e;
  };

  @action
  closeModal = async () => {
    this.modalVisible = false;
    this.parameters = {
      resource: 'custom',
      operation: 'createCustomAction',
      requestMethod: 'GET',
      authentication: 'none',
      url: '',
      bodyContentType: 'form-urlencoded',
      headerParameters: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      rawJsonBody: '',
      bodyParametersSource: 'rawJsonString', // "blockOutput"
      bodyOutputSelection: ``,
      bodyParameters: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      queryParameters: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      requestParameters: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      variables: [
        {
          uuid: uuid4(),
          name: '',
          value: '',
        },
      ],
      pagination: {
        uuid: uuid4(),
        pageSize: '',
        rootKeyArray: '',
        offsetPageSize: '',
        paginationMethod: '',
        pointerKey: '',
        nextPointerKey: '',
      },
      rawGraphqlVariables: '',
    };
    this.testSampleData = null;
    this.preview = {};
    this.name = '';
    this.description = '';
    this.saveModalVisible = false;
    this.discardModalVisible = false;
    await this.globalStore.blockEditStore.changeCustomAction(
      this.globalStore.blockEditStore.blockValues?.parameters?.customActionId
    );
  };

  @action
  saveCustomAction = async () => {
    const block = this.globalStore.blockEditStore.blockValues;
    if (this.updatingCustomActionId) {
      try {
        const data = await updateCustomAction(this.updatingCustomActionId, {
          description: this.description,
          parameters: this.parameters,
          name: this.name,
          credentials: this.credentials,
          preview: this.preview,
          sampleLiveData: this.testSampleData?.json,
          blockType: block.type,
          testSampleData: this.testSampleData,
        });

        if (data) {
          showNotification('success', 'Custom Action updated.');
          this.initialize();
          await this.closeModal();
          return data;
        } else {
          showNotification(
            'error',
            'Something went wrong while updating the custom action.'
          );
        }
      } catch (err) {
        showNotification(
          'error',
          err?.message ||
            'Something went wrong while updating the custom action.'
        );
      }
    } else {
      try {
        const data = await saveCustomAction({
          description: this.description,
          parameters: this.parameters,
          name: this.name,
          credentials: this.credentials,
          preview: this.preview,
          sampleLiveData: this.testSampleData?.json,
          blockType: block.type,
          testSampleData: this.testSampleData,
        });
        if (data) {
          showNotification('success', 'Custom Action created.');
          this.initialize();
          await this.closeModal();
          return data;
        } else {
          showNotification(
            'error',
            'Something went wrong while updating the custom action.'
          );
        }
      } catch (err) {
        showNotification(
          'error',
          err?.message ||
            'Something went wrong while updating the custom action.'
        );
      }
    }
  };

  @action
  saveExpression = (parameter, path, value, preview) => {
    if (value && typeof value === 'string') {
      value = value.trim();
    }

    if (value.length > 0 && value[0].trim()) {
      // check if preview has text only,
      if (preview) {
        const hasPreview = preview[0].children.filter(
          (prvw) => prvw.type && prvw.type === 'fieldPreview'
        );

        if (hasPreview.length) {
          this.valueChanged(parameter, path, '=' + value, preview);
        } else if (preview[0].value) {
          this.valueChanged(parameter, path, preview[0].value, preview);
        } else {
          if (Array.isArray(value)) {
            value = value[0].trim();
          }
          this.valueChanged(parameter, path, value, preview);
        }
      }
    } else {
      this.valueChanged(parameter, path, value, preview);
    }
  };

  @action
  valueChanged = (parameter, path, value, preview) => {
    if (typeof value === 'string') {
      set(this, path, value);
      this.handleContentChange(this.parameters.url);
      if (preview && Array.isArray(preview)) {
        this.setPreview(path, preview);
      }
    }

    if (
      parameter &&
      this.parameters[parameter].filter((rw) => !rw?.name && !rw?.value)
        ?.length === 0
    ) {
      this.parameters[parameter].push({
        uuid: uuid4(),
        name: '',
        value: '',
      });
    }
  };

  highlightText = (text) => {
    const regex = /(\{\{.*?\}\})/g;
    return (
      text?.replace(
        regex,
        (match) =>
          `<span style="color: #286EFA; font-weight: 600; font-size: 12px; ">${match}</span>`
      ) || null
    );
  };

  @action
  handleContentChange = (newText) => {
    const url = newText?.split('?')[0];
    const queryParams = this.parameters?.queryParameters; // Fetching query parameters from the store or a method
    const queryStringLength = queryParams?.filter((ob) => !!ob.name)?.length;
    const paginationKeys = this.parameters?.pagination
      ? Object.values(this.parameters?.pagination)
      : [];
    let newHtmlContent;
    if (!queryStringLength) {
      newHtmlContent = this.highlightText(url);
    } else {
      const queryString = queryParams
        .filter((ob) => !!ob.name)
        .map((obj) => {
          if (
            (obj.value && obj.value.indexOf('{{$') !== -1) ||
            paginationKeys.includes(obj.name)
          ) {
            return `${obj.name}={{${obj.name}}}`;
          }

          return `${obj.name}=${obj.value}`;
        })
        .join('&');
      const dynamicUrl = `${url}<span>?${queryString}</span>`; // Constructing the dynamic URL with query parameters
      newHtmlContent = this.highlightText(dynamicUrl);
    }
    this.setUrl(url);
    this.htmlContent = newHtmlContent;
  };

  findPreviewText = (path) => {
    const converted = btoa(path);
    if (this.preview && this.preview[converted]) {
      return this.preview[converted];
    }
    return null;
  };

  @action
  setPreview = (path, preview) => {
    set(this.preview, `${btoa(path)}`, preview);
  };

  @action
  deleteParameter = (path, index) => {
    const data = get(this.parameters, path);

    if (Array.isArray(data)) {
      const previousMaxIndex = data.length - 1;
      data.splice(parseInt(index, 10), 1);

      set(this.parameters, path, data);
      const oldPreview = this.preview;
      const newPreview = {};
      const currentPreviewKeys = this.preview
        ? Object.keys(this.preview).map((key) => ({
            b64: key,
            text: atob(key),
          }))
        : [];
      currentPreviewKeys.forEach((key) => {
        if (!key.text.includes(path)) {
          newPreview[key.b64] = oldPreview[key.b64];
        }
      });
      if (previousMaxIndex === parseInt(index, 10)) {
        // just copy all the old data over except the fields that are in the deleted object at the end
        currentPreviewKeys.forEach((key) => {
          if (!key.text.includes(path)) {
            newPreview[key.b64] = oldPreview[key.b64];
          }
        });
        this.preview = newPreview;
      } else {
        let newPreviewIndex = 0;

        for (let i = 0; i <= previousMaxIndex; i++) {
          if (i !== parseInt(index)) {
            const oldParentPath = `${path}[${i}]`;

            const oldFoundKeys = currentPreviewKeys.filter((key) =>
              key.text.includes(oldParentPath)
            );

            const oldParentLength = oldParentPath.length;

            oldFoundKeys.forEach((key) => {
              const child = key.text.substring(oldParentLength); // ex: '.value'
              const newKey = `${path}[${newPreviewIndex}]${child}`;
              set(newPreview, btoa(newKey), oldPreview[key.b64]);
            });

            newPreviewIndex++;
          }
        }

        this.preview = newPreview;
      }
    }

    if (get(this.parameters, path).length === 0) {
      this.parameters[path].push({
        uuid: uuid4(),
        name: '',
        value: '',
      });
    }
    this.handleContentChange(this.parameters.url);
  };

  @action
  savePaginationConfig = async (paginationConfig) => {
    try {
      this.parameters.pagination = {
        ...this.parameters.pagination,
        ...paginationConfig,
      };
      if (paginationConfig.paginationMethod === 'pageBased') {
        delete this.parameters.pagination.pointerKey;
        delete this.parameters.pagination.nextPointerKey;
      } else {
        delete this.parameters.pagination.pageSize;
        delete this.parameters.pagination.offsetPageSize;
      }

      const response = await updateCustomAction(
        this.updatingCustomActionId,
        this.parameters
      );
      if (response.data.success) {
        showNotification('success', 'Pagination Method Saved Successfully');
        this.getAllCustomActions();
      }
    } catch (error) {
      // showNotification('error', error.message);
    }
  };
}
