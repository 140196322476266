import { observable, action, runInAction } from 'mobx';
import {
  getInstallations,
  getInstallationWorkflows,
  deleteInstallations,
  deleteWorkflowInstallations,
  getInstallationCount,
  deactivateInstallationWorkflow,
  activateInstallationWorkflow,
  deleteAllInstallations,
} from 'Services/installations';
import { showNotification } from 'Components/Notifications/Notification';

export class InstallationStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  filterRow = {
    conditions: {
      value: 'and',
      options: [
        { name: 'and', value: 'and' },
        { name: 'or', value: 'or' },
      ],
    },
    columns: {
      options: [
        { name: 'Integration', value: 'integrationName' },
        { name: 'User', value: 'embeddedUsername' },
        { name: 'Status', value: 'active' },
        { name: 'Workflows', value: 'workflowCount' },
        { name: 'Date', value: 'updatedAt' },
      ],
    },
    operators: {
      value: 'contains',
      options: [
        { name: 'contains', value: 'contains' },
        { name: 'does not contain', value: 'notcontains' },
        { name: 'equals', value: 'equals' },
        { name: 'does not equal', value: 'notequals' },
        { name: 'Greater Than', value: 'greaterthan' },
        { name: 'Less Than', value: 'lessthan' },
      ],
    },
    userinput: {
      value: '',
    },
  };

  @observable
  installations = [];

  @observable
  integrationInstallations = [];

  @observable
  integration = null;

  @observable
  installation = {};

  @observable
  installationWorkflows = [];

  @observable
  filteredInstallations = [];

  @observable
  childUser = null;

  @observable
  filterTimeout = null;

  @observable
  filterRows = [];

  @observable
  filledFilterRows = [];

  @observable
  hasFilters = false;

  @observable
  installationsLoading = true;

  @observable
  forgeMode = true;

  @observable
  integrationWorkflowsLoading = true;

  @observable
  userId = this.globalStore.userStore.userInfo._id;

  @observable
  installationCount = 0;

  @observable
  filteredInstallationCount = 0;

  @observable
  limit = 20;

  @observable
  skip = 0;
  // @observable
  // installationsToDelete = [];

  @observable
  currentPage = 1;

  @observable
  sortBy = 'createdAt';

  @observable
  sortOrder = 'desc';

  @observable
  sortOrderFull = 'descend';

  @observable
  showLoader = true;

  @observable
  isFilterButtonLoading = false;

  @observable
  isFilterButtonDisabled = false;

  @observable
  isInstallationsCountLoading = false;

  @action
  handleHasFilters = (condition) => {
    this.hasFilters = condition;
  };

  @action
  handleFilterButtonLoading = (condition) => {
    this.isFilterButtonLoading = condition;
  };

  @action
  handleFilterButtonDisabled = (condition) => {
    this.isFilterButtonDisabled = condition;
  };

  @action
  handleShowLoader = (condition) => {
    this.showLoader = condition;
  };

  @action
  resetSortOrder = () => {
    this.sortBy = 'createdAt';
    this.sortOrder = 'desc';
    this.sortOrderFull = 'descend';
  };

  @action
  handlePageNavigation = (pagination) => {
    this.skip = (pagination.current - 1) * pagination.pageSize;
    this.limit = pagination.pageSize;
    this.fetchInstallations(this.userId);
    this.currentPage = pagination.current;
  };

  @action
  handleSorting = (sorter) => {
    if (sorter.field && sorter.order) {
      this.sortBy = sorter.field;
      this.sortOrder = sorter.order === 'descend' ? 'desc' : 'asc';
      this.sortOrderFull = sorter.order;
    }

    if (sorter.field === 'createdAt' && !sorter.order) {
      this.sortBy = 'createdAt';
      this.sortOrder = 'asc';
      this.sortOrderFull = 'ascend';
    }

    if (sorter.field && !sorter.order && sorter.field !== 'createdAt') {
      this.sortBy = 'createdAt';
      this.sortOrder = 'desc';
      this.sortOrderFull = 'descend';
    }
    this.handleShowLoader(false);

    this.fetchInstallations(this.userId);
  };

  @action
  setInstallation = (installation) => {
    this.installation = installation;
  };

  @action
  clearFilters = () => {
    // this.initializeFilterRows();
    this.initialize();
    // this.hasFilters = false;
    // this.filteredInstallations = this.installations;
  };

  @action
  updateFilterRowsValue = (idx, key, value) => {
    this.filterRows[idx][key].value = value;
  };

  @action
  getInstallationWorkflows = async (integrationId, childUserId) => {
    this.integrationWorkflowsLoading = true;
    try {
      const installationWorkflows = await getInstallationWorkflows(
        integrationId,
        childUserId
      );

      this.installationWorkflows =
        installationWorkflows?.data?.installationWorkflows || [];

      this.integration = installationWorkflows?.data?.integration || null;
      this.childUser = installationWorkflows?.data?.user || null;
    } catch {
      this.installationWorkflows = [];
    } finally {
      this.integrationWorkflowsLoading = false;
    }
  };

  @action
  filterData = () => {
    let hasFilters = false;
    this.filledFilterRows = this.filterRows.filter((row) => {
      if (row.columns.value && row.operators.value && row.userinput.value) {
        hasFilters = true;
        return row;
      }
    });
    this.handleHasFilters(hasFilters);
    this.handleFilterButtonLoading(true);
    this.fetchInstallations(this.userId);
  };

  deactivateInstallationWorkflow = async (workflowId) => {
    try {
      await deactivateInstallationWorkflow(workflowId);
    } catch {
      // Handle Delete Error
    } finally {
      showNotification('success', `Workflow deactivated`);
    }
  };

  activateInstallationWorkflow = async (workflowId) => {
    try {
      await activateInstallationWorkflow(workflowId);
    } catch {
      // Handle Delete Error
    } finally {
      showNotification('success', `Workflow activated`);
    }
  };

  @action
  initializeFilterRows = () => {
    this.filterRows = [];
    this.filterRows.push(this.filterRow, this.filterRow);
  };

  deleteInstallations = async (installations) => {
    try {
      await deleteInstallations(installations);
    } catch {
      // Handle Delete Error
    } finally {
      if (installations.installations.length === 1) {
        showNotification('success', `Installation deleted`);
      }
      if (installations.installations.length > 1) {
        showNotification(
          'success',
          `${installations.installations.length} installations deleted`
        );
      }
      this.initialize();
    }
  };

  @action
  funcDeleteAllInstallations = async (allInstallations) => {
    try {
      const res = await deleteAllInstallations(allInstallations);

      if (res.data) {
        if (res.data.deletedinstallationscount > 1) {
          showNotification(
            'success',
            `${res.data.deletedinstallationscount} installations deleted`
          );
        }
      }
    } catch {
      // Handle Delete Error
    } finally {
      this.initialize();
    }
  };

  @action
  funcDeleteWorkflow = async (workflows) => {
    this.integrationWorkflowsLoading = true;
    try {
      await deleteWorkflowInstallations(workflows);
    } catch {
      this.installationWorkflows = [];
    } finally {
      if (workflows.workflows.length === 1) {
        showNotification('success', `Workflow deleted`);
      }
      if (workflows.workflows.length > 1) {
        showNotification(
          'success',
          `${workflows.workflows.length} Workflow deleted`
        );
      }
    }
  };

  @action
  fetchInstallations = async (userId) => {
    if (this.showLoader && !this.filterRows.length) {
      this.installationsLoading = true;
    } else {
      this.handleShowLoader(true);
    }

    try {
      const res = await getInstallations({
        userId,
        filterRows: this.filledFilterRows,
        limit: this.limit,
        skip: this.skip,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        integrationId: this.globalStore.integrationStore.activeIntegrationId,
      });

      if (res.data) {
        runInAction(() => {
          const { installations } = res.data;
          this.installations = installations;
          this.filteredInstallations = installations;
          this.installationsLoading = false;
          this.installationCount = res.data.installationCount;
          this.filteredInstallationCount = res.data.filteredInstallationCount;
          this.globalStore.workflowStore.integrationCount =
            res.data.integrationCount;
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.installationsLoading = false;
      this.isFilterButtonLoading = false;
      this.handleShowLoader(false);
    }
  };

  @action
  initialize = (integrationId) => {
    const userId = this.userId;
    this.globalStore.integrationStore.activeIntegrationId =
      integrationId || null;
    this.funcGetInstallationCount();
    this.resetSortOrder();
    this.handleShowLoader(true);
    this.initializeFilterRows();
    this.filledFilterRows = [];
    this.fetchInstallations(userId);
    this.hasFilters = false;
  };

  @action
  funcGetInstallationCount = async () => {
    if (this.isInstallationsCountLoading) return;

    this.isInstallationsCountLoading = true;

    try {
      const { data } = await getInstallationCount({
        userId: this.globalStore.userStore.userInfo._id,
      });

      if (data) {
        this.installationCount = data?.installationCount;
      }
    } catch {
      this.installationCount = 0;
    } finally {
      this.isInstallationsCountLoading = false;
    }
  };
}
