import { GET, PATCH, POST, DELETE, PUT } from './wrappers';

export const getIntegrations = async (limit, skip) => {
  let request = `/api/embedded-integrations?limit=${limit}&skip=${skip}`;
  return GET(request);
};

export const getIntegration = async (integrationId) => {
  return GET(`/api/embedded-integrations/${integrationId}`);
};

export const createIntegration = async (body) => {
  return POST('/api/embedded-integrations', body);
};

export const editIntegration = async (id, body) => {
  return PUT(`/api/embedded-integrations/${id}`, body);
};

export const editIntegrationBaseUrl = async (id, body) => {
  return PUT(`/api/embedded-integrations/baseUrl/${id}`, body);
};

export const deleteIntegration = async (id) => {
  return DELETE(`/api/embedded-integrations/${id}`);
};

export const setIntegrationStatus = async (id, body) => {
  return PUT(`/api/embedded-integrations/${id}/status`, body);
};

export const getAllIntegrationSecrets = async (integrationId) => {
  return GET(`/api/embedded-integrations/${integrationId}/secrets`);
};
