import React from 'react';
import * as dayjs from 'dayjs';
import { parse, stringify } from 'qs';
import { pull, uniqBy } from 'lodash';
import { namingValidationErrors } from '../constants';
import { displayParameter } from './index';

export const numberOfErrors = (blocks, forgeEditableFields, forgeMode) => {
  let count = 0;
  blocks.forEach((block) => {
    count += countErrors(
      {
        ...block,
        forgeEditableFields,
        blockId: block.id,
        forgeInternalUse: block?.forgeInternalUse,
      },
      forgeMode
    );
  });

  if (!Boolean(forgeMode)) {
    if (blocks && blocks.length && blocks[0] && !blocks[0].isTrigger) {
      count += 1;
    }
  }

  return count;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const countErrors = (block, forgeMode) => {
  const { issues, type, forgeEditableFields, blockId, forgeInternalUse } =
    block;

  if (type === 'alloy.start') return 0;
  if (!issues) return 0;
  // count is number of "steps"; resource + operation is one step, all other parameters are another step
  let count = 0;
  const parameterIssues = issues.parameters
    ? Object.keys(issues.parameters)
    : [];
  if (
    parameterIssues.includes('resource') ||
    parameterIssues.includes('operation')
  ) {
    count += 1;
  }

  const countCredentialIssues = (forgeMode && !!forgeInternalUse) || !forgeMode;

  if (countCredentialIssues && issues.credentials) {
    count++;
  }

  pull(parameterIssues, 'resource', 'operation');

  const forgeParameterFields = [];
  if (forgeEditableFields && parameterIssues.length && blockId) {
    for (const blockParameter of parameterIssues) {
      const blockParameterPath = blockId + '::' + blockParameter;
      if (forgeEditableFields[blockParameterPath]) {
        forgeParameterFields.push(blockParameter);
      }
      if (type === 'alloy.branch') {
        const blockParam = blockParameter.split('::')[0];
        const uuid = blockParameter.split('::')[1];
        const forgeSelected = Object.values(forgeEditableFields).find(
          (field) => {
            return (
              field.fieldBlockId === blockId &&
              field.fieldValue === blockParam &&
              field.branchUuid === uuid
            );
          }
        );
        if (forgeSelected) forgeParameterFields.push(blockParameter);
      }
      if (type === 'alloy.if') {
        const blockParam = blockParameter.split('::')[0];

        const forgeSelected = Object.values(forgeEditableFields).find(
          (field) => {
            return (
              field.fieldBlockId === blockId && field.fieldValue === blockParam
            );
          }
        );
        if (forgeSelected) forgeParameterFields.push(blockParameter);
      }
    }
  }

  pull(parameterIssues, ...forgeParameterFields);
  if (parameterIssues.length) count++;

  return count;
};

export const countExecutionErrors = (
  blocks,
  workflowId,
  executionSummaries
) => {
  let count = 0;

  const workflowSummaries = executionSummaries.find(
    (row) => row._id === workflowId
  );
  if (workflowSummaries)
    workflowSummaries.executionSummaries.forEach((execution) => {
      if (execution.errorMessage) {
        count += 1;
      }
    });

  blocks.forEach((block) => {
    if (block.issues && block.issues.execution) count++;
  });

  return count;
};

export const validParams = (blockData) => {
  const { resource, operation } = blockData.parameters;
  if (!(operation || resource) && !Object.values(blockData.credentials)[0]) {
    return false;
  }
  return true;
};

export const validCredintial = (blockData) => {
  if (Object.values(blockData.credentials)[0]) {
    return true;
  }
  return false;
};

export const forTriggerErrors = (block) => {
  const { resource, triggerTimes, event } = block.parameters;
  let error = 0;
  const hasError = countErrors(block);

  if (block.type === 'alloy.calendlyTrigger') {
    if (!validParams(block)) error = 2;
    if (validCredintial(block)) error = 1;
  } else if (isWebhookBlock(block.type)) {
    if (!validParams(block)) error = 1;
  } else if (block.type === 'alloy.cron') {
    if (!triggerTimes || Array.isArray(triggerTimes)) error = 1;
  } else if (block.type === 'alloy.gmailIntervalTrigger') {
    if (!validCredintial(block)) error = 1;
  } else if (block.type === 'alloy.copperTrigger') {
    if (!resource) {
      error = 3;
    } else if (!validCredintial(block)) {
      error = 2;
    } else if (!event) {
      error = 1;
    }
  } else {
    if (!validParams(block)) error = 2;
    if (validCredintial(block)) error = 1;
    return hasError > 0 ? hasError : error;
  }

  return error;
};

export const blockTypeWithoutCredentials = (type) => {
  const alloyType = [
    'alloy.text',
    'alloy.if',
    'alloy.for',
    'alloy.dateTime',
    'alloy.sendEmail',
    'alloy.contentComposer',
    'alloy.mixpanelTrigger',
  ];
  return alloyType.includes(type);
};

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [dayjs(now), dayjs(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [dayjs(beginTime), dayjs(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = dayjs(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      dayjs(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      dayjs(
        dayjs(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() -
          1000
      ),
    ];
  }

  if (type === 'year') {
    const year = now.getFullYear();

    return [dayjs(`${year}-01-01 00:00:00`), dayjs(`${year}-12-31 23:59:59`)];
  }
}

export function getPlainBlock(blockList, parentPath = '') {
  const arr = [];
  blockList.forEach((block) => {
    const item = block;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainBlock(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

function accMul(arg1, arg2) {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  m += s1.split('.').length > 1 ? s1.split('.')[1].length : 0;
  m += s2.split('.').length > 1 ? s2.split('.')[1].length : 0;
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m;
}

export function digitUppercase(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟', '万'],
  ];
  let num = Math.abs(n);
  let s = '';
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(accMul(num, 10 * 10 ** index)) % 10] + item).replace(
      /零./,
      ''
    );
  });
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i += 1) {
    let p = '';
    for (let j = 0; j < unit[1].length && num > 0; j += 1) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }

  return s
    .replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整');
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter((item) => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every((item) => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    (routePath) => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map((item) => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map((item) => {
    const exact = !routes.some(
      (route) => route !== item && getRelation(route, item) === 1
    );
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export const isWhitelistedArrayKey = (key, blockType) => {
  switch (blockType) {
    case 'Copper':
    case 'Copper Trigger':
      if (key.includes('custom_fields')) {
        return true;
      }
      break;

    case 'Gmail':
    case 'Gmail Trigger':
      if (key.includes('headers')) {
        return true;
      }
      break;

    case 'Shopify':
    case 'Shopify Trigger':
      if (
        key.includes('note_attributes') ||
        key.includes('line_items') /* && key.includes('properties') */
      ) {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
};

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getCredentialType = (credentials, parameters) => {
  let credentialType = credentials[0].name;
  if (credentials.length > 1) {
    credentials.forEach((cred) => {
      const isVisible = displayParameter(parameters, cred);
      if (isVisible) {
        credentialType = cred.name;
      }
    });
  }
  return credentialType;
};

export const isWebhookBlock = (type) => {
  return type === 'alloy.webhook' || type === 'alloy.sdk';
};

export const kFormatter = (num) => {
  if (num === 'unlimited') {
    return 'Unlimited';
  }

  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
};

/*
 *  @function hasValidUrl
 *  Check if URL is valid
 */
export const hasValidUrl = (url) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator

  return !!pattern.test(url);
};

export function randomArrayShuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

/**
 * Remove emoji
 * https://stackoverflow.com/a/63464318
 * @param {input} string
 * @returns
 */
export const removeEmojis = (string) => {
  return string.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
};

/**
 * Check string if has emoji
 * https://stackoverflow.com/a/63464318
 * @param {input} string
 * @returns
 */
export const checkStringIfContainsEmoji = (string) => {
  // const regexExp = /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu;
  const regexExp = /<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu;
  return regexExp.test(string);
};

/**
 * Remove whitespace
 * https://stackoverflow.com/a/18066013
 * @param {input} string
 * @returns
 */
export const removeWhitespace = (string) => {
  return string.replace(/^\s+|\s+$/g, '');
};

export const getPageTitle = (hash) => {
  const pageTitle = {
    '#workspace': 'Workspace Settings',
    '#credentials': 'Credentials',
    '#billing': 'Billing',
    '#recentlyDeleted': 'Recently Deleted',
    '#partner': 'Partner Settings',
    '#team': 'Teams',
    '#security': 'Security',
  };

  return pageTitle[hash] || 'Settings';
};

export const namingValidator = (string, type) => {
  const hasEmoji = checkStringIfContainsEmoji(string);
  if (hasEmoji) {
    return namingValidationErrors.EMOJI;
  }
  const isEmpty = string.trim().length === 0;
  if (isEmpty) {
    return type === 'workflow'
      ? namingValidationErrors.WORKFLOW_RENAME_EMPTY
      : namingValidationErrors.WORKSPACE_RENAME_EMPTY;
  }

  const emptySpaceRegexp = /\s\s+/g;
  const hasEmptySpaces = emptySpaceRegexp.test(string);
  if (hasEmptySpaces) {
    return namingValidationErrors.MULTIPLE_SPACE;
  }

  return;
};

export const getTextSpacing = (text) => {
  const splittedText = text.split('&nbsp;');
  const splitLength = splittedText.length - 1;
  const leftMarginSize = 12 * splitLength;
  return splittedText
    .filter((value) => value)
    .map((string) => (
      <p style={{ marginLeft: leftMarginSize, marginBottom: 0 }}>{string}</p>
    ));
};

export const getInitials = (name) => {
  if (!name) {
    return '';
  }
  let initials;
  const nameSplit = name.split(' ');
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

// export const extractDomain = (url) => {
//   let domain;

//   // Find & remove protocol (http, ftp, etc.) and get domain
//   if (url.indexOf('://') > -1) {
//     domain = url.split('/')[2];
//   } else {
//     domain = url.split('/')[0];
//   }

//   // Find & remove port number
//   domain = domain.split(':')[0];

//   return domain;
// };

export const changeFavicon = (type) => {
  const link = document.querySelector("link[rel~='icon']");
  const favicon = 'https://cdn.runalloy.com/favicon.ico';

  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  link.href = favicon;
};

export const getUniqueBlocks = (blocks) => {
  const allBlocks = blocks.map((block) => {
    block.type = block.type.toLowerCase().replace('trigger', '');
    block.name = block.name.toLowerCase().replace('trigger', '');
    return block;
  });
  return uniqBy(allBlocks, 'type');
};

export const cleanBlockName = (name) => {
  return name?.toLowerCase()?.replace('trigger', '');
};
