import React, { useEffect } from 'react';
import styles from './NoPage.less';
import { inject, observer } from 'mobx-react';
import { NoPageIcon } from 'Components/Icons';
import { ArrowRightOutlined } from '@ant-design/icons';

export const NoPage = inject('globalStore')(
  observer((props) => {
    const { globalStore } = props;
    const { push } = globalStore.routingStore;
    useEffect(() => {
      document.body.style.overflow = 'hidden';
      setTimeout(() => {
        push(`/dashboard`);
      }, 5000);
    }, []);

    return (
      <div className={styles.noPage}>
        <div>
          <NoPageIcon />
        </div>
        <div className={styles.smallTitle}>Page not found</div>
        <div className={styles.desc}>
          Make sure you typed in the URL correctly.
        </div>
        <div
          onClick={() => globalStore.routingStore.push(`/dashboard`)}
          className={styles.backLink}
        >
          Go to Dashboard &nbsp;
          <ArrowRightOutlined />
        </div>
      </div>
    );
  })
);
