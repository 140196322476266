import React from 'react';
import styles from './index.less';

export const IconLoader = ({ color = 'black' }) => (
  <span role="img" aria-label="icon-loader" className="anticon">
    <div
      className={`${styles.loader} ${
        color === 'blue' ? styles.loader__blue : null
      }`}
    />
  </span>
);
