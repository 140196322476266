import { action } from 'mobx';
import { getMyRecipes, getReferralData } from 'Services/partnerhub';

export class PartnerHubStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @action
  getMyRecipes = async () => {
    return getMyRecipes();
  };

  @action
  getReferralData = async () => {
    return getReferralData();
  };
}
