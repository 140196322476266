import React from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import styles from './index.less';

export const AppTooltip = ({ arrow = false, ...props }) => {
  return (
    <Tooltip
      rootClassName={classNames(styles.tooltip, {
        [props.customClass]: !!props.customClass,
      })}
      arrow={arrow}
      {...props}
      overlayStyle={{ ...props.overlayStyle, width: props?.width }}
    />
  );
};
