import { NODE_TYPES } from './blockTypes';

export const PARAM_TYPES = {
  DATE_TIME: 'dateTime',
  BOOLEAN: 'boolean',
  PHONE_NUMBER: 'phoneNumber',
  NUMBER: 'number',
  STRING: 'string',
  FIXED_COLLECTION: 'fixedCollection',
  COLLECTION: 'collection',
  TAG: 'tag',
  OPTIONS: 'options',
  MULTI_OPTIONS: 'multiOptions',
  JSON: 'json',
  EMAIL: 'email',
  DATE_TIME_PICKER: 'dateTimePicker',
  TIME_PICKER: 'timePicker',
  ANY: 'any',
  FIELD_PREVIEW: 'fieldPreview',
  MULTIPLE: 'multiple',
  TIME_INTERVAL_PICKER: 'timeIntervalPicker',
  ARRAY: 'array',
  DROP_DOWN: 'dropdown',
  DYNAMIC_COLLECTION: 'dynamicCollection',
  CODE: 'code',
  BLOCK_TEXT: 'blockText',
  JSON_BLOCK: 'jsonBlock',
};

export const BLOCK_PREFIX_VALUE = '{{$block';

export const SHOW_FIXED_OPTIONS = [
  PARAM_TYPES.OPTIONS,
  // PARAM_TYPES.DATE_TIME,
  PARAM_TYPES.BOOLEAN,
  // PARAM_TYPES.DATE_TIME_PICKER,
  PARAM_TYPES.TIME_INTERVAL_PICKER,
];

export const COLLECTION_TYPES = [
  PARAM_TYPES.COLLECTION,
  PARAM_TYPES.FIXED_COLLECTION,
  PARAM_TYPES.DYNAMIC_COLLECTION,
];

export const EXCLUDE_RECIPE_COLLECTION = [NODE_TYPES.IF];

export const NON_MULTIPLE_TYPES = [PARAM_TYPES.FIXED_COLLECTION, NODE_TYPES.IF];

export const COLLECTION_COMPONENTS = {
  collection: 'collection',
  fixedCollection: 'fixedCollection',
};

export const EXPRESSION_EDITOR_POPOVER_OPTIONS = [
  PARAM_TYPES.OPTIONS,
  PARAM_TYPES.MULTI_OPTIONS,
];

export const EXPRESSION_EDITOR_POPOVER_CUSTOM_TYPE = [
  'image',
  'csv',
  'jsonBlock',
  'blockText',
  'graphql',
  'code',
];

export const EXPRESSION_EDITOR_POPOVER = [
  PARAM_TYPES.JSON,
  PARAM_TYPES.STRING,
  PARAM_TYPES.BOOLEAN,
  PARAM_TYPES.PHONE_NUMBER,
  PARAM_TYPES.NUMBER,
  PARAM_TYPES.DATE_TIME,
  PARAM_TYPES.DATE_TIME_PICKER,
  PARAM_TYPES.TIME_PICKER,
  PARAM_TYPES.TIME_INTERVAL_PICKER,
  PARAM_TYPES.EMAIL,
  PARAM_TYPES.ANY,
];

export const DATE_PICKER_FIELD_VALUES = [
  PARAM_TYPES.DATE_TIME,
  PARAM_TYPES.DATE_TIME_PICKER,
  PARAM_TYPES.TIME_PICKER,
];
export const NON_TEXT_FIELDS = [
  PARAM_TYPES.DATE_TIME,
  PARAM_TYPES.DATE_TIME_PICKER,
  PARAM_TYPES.BOOLEAN,
  PARAM_TYPES.OPTIONS,
  PARAM_TYPES.MULTI_OPTIONS,
  PARAM_TYPES.PHONE_NUMBER,
  PARAM_TYPES.TIME_PICKER,
  PARAM_TYPES.TIME_INTERVAL_PICKER,
  PARAM_TYPES.ARRAY,
];

export const BLOCK_TEXT_FIELDS = [
  PARAM_TYPES.CODE,
  PARAM_TYPES.BLOCK_TEXT,
  PARAM_TYPES.JSON_BLOCK,
];

export const CODE_EDITOR_FIELDS = [PARAM_TYPES.CODE, PARAM_TYPES.JSON_BLOCK];
