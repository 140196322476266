import { observable, action, runInAction, toJS, computed } from 'mobx';
import { showNotification } from 'Components/Notifications/Notification';
// import { unset, get, forIn, set, has } from 'lodash';
import { createRecipeDraft } from 'Services/marketplace';
import { NODE_TYPES, BLACKLISTED_TRIGGERS } from 'Constants';
import {
  unset,
  get,
  forIn,
  set,
  has,
  map,
  startCase,
  isObject,
  isArray,
  size,
  forEach,
  uniqBy,
  debounce,
} from 'lodash';
import { parse } from 'url';
import { isWebhookBlock } from 'Utilities';
import { uuid4 } from 'Utilities/uuid';
import { getBlockTypeByName } from '../services/workflowApi';
import { arrayGroupBy } from '../utils';
export class RecipeImportStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  blockValues = {};

  @observable
  credentialsData = [];

  @observable
  currentStep = 0;

  @observable
  leftLoading = false;

  @observable
  recipeLoading = true;

  @observable
  stepNum = 0;

  @observable
  recipe = {};

  @observable
  recipeTitle = '';

  @observable
  recipeAuthor = '';

  @observable
  recipeCreator = '';

  @observable
  forgeMode = false;

  @observable
  alwaysShowCredentials = false;

  @observable
  allowInstantWorkflowCreation = false;

  @observable
  activeBlockId = '';

  @observable
  fixedCollectionFields = {};

  @observable
  workflow = {};

  @observable
  tags = [];

  @observable
  postId = '';

  @observable
  currentBlockId = '';

  @observable
  currentBlockType = {};

  @observable
  formData = {};

  @observable
  blocks = [];

  @observable
  fixedCollections = {};

  @observable
  fixedCollectionKeys = [];

  @observable
  preview = {};

  @observable
  isLoading = false;

  @observable
  blocksWithCred = [];

  @observable
  lightningDataFields = [];

  @observable
  currentBlockLightningDataFields = [];

  @observable
  addNewType = '';

  @observable
  blockParameters = {};

  @observable
  blockCredTypes = {};

  @observable
  dummyValueChecker = {};

  @observable
  draftId = '';

  @observable
  draftIds = [];

  @observable
  draftCsvData = {};

  @observable
  tempBlockTree = [];

  @observable
  showVariableSelector = false;

  @observable
  parameter = null;

  @observable
  editor = null;

  @observable
  isForgeError = false;

  @observable
  forgeWorkflows = [];

  @observable
  forgeCurrentStep = 0;

  @observable
  forgeLightningFields = [];

  @observable
  currentWorkflowId = null;

  @observable
  selectedWorkflow = {};

  @observable
  forgeCurrentBlockId = null;

  @observable
  recipeInstaller = false;

  @observable
  multipleParamKeys = {};

  @observable
  isPreview = false;

  @observable
  hideOutput = [];

  @observable
  recipes = [];

  @observable
  recipeWorkflows = [];

  @observable
  collectionId = null;

  @observable
  postIds = [];

  @observable
  workflowSettings = null;

  @observable
  currentBlock = '';

  @observable
  workflowIdsCreated = [];

  @observable
  blockIdsMap = {};

  @observable
  recipeIntegrationId = '';

  ACCOUNT_SELECTOR_FIELD_KEY = '__account_selector_field_key__';

  // need to set this variable in case of a forge workflow update
  @observable
  isForgeUpdate = false;

  @action
  resetWorkflowIdsCreated = () => {
    this.workflowIdsCreated = [];
  };

  @action
  initialize = async (
    forgeMode,
    alwaysShowCredentials,
    allowInstantWorkflowCreation
  ) => {
    this.credentialsData = [];
    // always start form zero
    this.currentStep = 0;
    this.lightningDataFields = [];
    this.preview = {};
    this.formData = {};
    this.blockValues = {};
    this.blockParameters = {};
    this.forgeMode = forgeMode;
    this.alwaysShowCredentials = alwaysShowCredentials;
    this.allowInstantWorkflowCreation = allowInstantWorkflowCreation;
    this.recipeLoading = true;
    this.currentBlock = '';
  };

  @action
  setupRecipe = (recipe) => {
    this.setRecipe(recipe);
    this.setBlocksData();
    this.filterCredBlocks();
    this.recipeTitle = recipe.title;
    this.recipeAuthor = recipe.user ? recipe.user.fullName : '';
    this.recipeCreator = recipe.creator ? recipe.creator : '';
    this.isPreview = !!recipe.isPreview;
    if (recipe.credentials) {
      forEach(recipe.credentials, (cred) => {
        this.credentialsData.push({
          credentialType: cred.type,
          data: cred._id,
        });
        this.globalStore.credentialStore.credentialsByType[cred.type] = [cred];
      });
    }

    if (this.forgeMode) {
      if (
        this.allowInstantWorkflowCreation &&
        !this.alwaysShowCredentials &&
        recipe.hasAllCreds &&
        (!recipe.lightningData || recipe.lightningData.length === 0)
      ) {
        this.handleFinish();
        return;
      } else if (recipe.hasAllCreds && !this.alwaysShowCredentials) {
        this.currentStep = 1;
      }
      window.parent.postMessage({ showModal: true }, '*');
    }
    this.recipeLoading = false;
  };

  @action
  updateDuplicateBlockIds = (workflows) => {
    workflows.forEach((workflow) => {
      const workflowId = workflow.workflow._id;
      this.blockIdsMap[workflowId] = [];

      workflow.workflow.blocks.forEach((block) => {
        const newId = uuid4();
        const oldId = block.id;
        this.blockIdsMap[workflowId].push({ oldId, newId, workflowId });
        block.id = newId;
        block.oldId = oldId;
        // update lightning data fields
        workflow.lightningData.forEach((field) => {
          if (field.fieldBlockId === oldId) {
            field.fieldBlockId = newId;
            field.fieldKey = field.fieldKey.replace(oldId, newId);
          }
        });
      });
    });
    return workflows;
  };

  revertFormDataKeys = (formData) => {
    const newFormData = {};
    const blockIdsMap = Object.values(this.blockIdsMap).flat();

    forEach(formData, (value, key) => {
      const blockId = key.split('::')[0];
      const oldBlock = blockIdsMap.find((block) => block.newId === blockId);
      const newKey = oldBlock.workflowId + ':::' + oldBlock.oldId;
      newFormData[key.replace(blockId, newKey)] = value;
    });
    return newFormData;
  };

  @action
  setupForgeWorkflows = (workflows) => {
    this.forgeWorkflows = this.updateDuplicateBlockIds(workflows);
    this.forgeCurrentStep = 0;
    let allRecipesHasCreds = true;
    let lightningData = true;

    //hack to get oauth popup to close
    this.recipe = this.forgeWorkflows[0];

    this.forgeWorkflows.forEach((recipe) => {
      if (recipe.credentials) {
        forEach(recipe.credentials, (cred) => {
          this.credentialsData.push({
            credentialType: cred.type,
            data: cred._id,
          });
          this.globalStore.credentialStore.credentialsByType[cred.type] = [
            cred,
          ];
        });
      }
      if (!recipe.hasAllCreds) {
        allRecipesHasCreds = false;
      }

      if (recipe?.lightningData?.length) {
        lightningData = false;
      }
      this.credentialsData = uniqBy(this.credentialsData, 'data');
    });

    if (
      this.allowInstantWorkflowCreation &&
      !this.alwaysShowCredentials &&
      allRecipesHasCreds &&
      lightningData
    ) {
      this.handleForgeFinish();
      return;
    } else if (allRecipesHasCreds && !this.alwaysShowCredentials) {
      this.forgeCurrentStep = 1;
    }
    window.parent.postMessage({ showModal: true }, '*');

    this.recipeLoading = false;

    this.getUniqueForgeCredentialBlocks();
    this.setupForgeLightningBlocks();
    this.setForgeBlocksData();

    const workflowsWithRequiredFields = this.forgeWorkflows.filter(
      (workflow) => workflow.lightningData.length > 0
    );

    const numberOfWorkflowsWithRequiredFields =
      (workflowsWithRequiredFields && workflowsWithRequiredFields?.length) || 0;

    if (numberOfWorkflowsWithRequiredFields === 1) {
      this.currentWorkflowId = workflowsWithRequiredFields[0]._id;
      this.setSelectedWorkflow(
        workflowsWithRequiredFields[0]._id,
        workflowsWithRequiredFields[0]
      );
    }
  };

  @action
  getUniqueForgeCredentialBlocks = () => {
    try {
      const { globalStore } = this;
      const blockTypesArray = [];
      const uniqueBlockTypesArrayWithCreds = [];
      const blockTypes = [];

      const allBlocks = [];
      this.forgeWorkflows.forEach((template) => {
        if (template.workflow.blocks) {
          template.workflow.blocks.forEach((block) => {
            allBlocks.push(block);
          });
        }
      });

      const withCreds = allBlocks.filter((block) => {
        return this.blockTypeHasCredentials(block);
      });

      const withCredFormattedData = JSON.parse(JSON.stringify(withCreds));

      withCreds.forEach((block) => {
        if (!blockTypesArray.includes(block.type)) {
          blockTypesArray.push(block.type);
        }
      });

      blockTypesArray.forEach((block, index) => {
        const isTriggerBlock = block.indexOf('Trigger');
        if (isTriggerBlock > -1) {
          const subString = block.substring(0, isTriggerBlock);
          const count = blockTypesArray.filter(
            (type) => type.indexOf(subString) !== -1
          );
          if (count.length > 1) {
            delete blockTypesArray[index];
          }
        }
      });

      for (const blockTypeIndex in blockTypesArray) {
        const blockObject = globalStore.blockTypeStore.findByName(
          blockTypesArray[blockTypeIndex]
        );

        const credentials = blockObject.credentials;
        let credentialType = credentials[0].name;

        if (credentials.length > 1) {
          const apiVersion = withCredFormattedData[0]?.parameters?.apiVersion;
          if (apiVersion) {
            const credential = credentials.find(
              (cred) =>
                cred?.displayOptions?.show?.apiVersion?.findIndex(
                  (v) => v === apiVersion
                ) >= 0
            );
            if (credential) {
              credentialType = credential.name;
            }
          }
        }
        // const credType = get(blockObject, 'credentials.0.name', null);
        if (
          credentialType &&
          !uniqueBlockTypesArrayWithCreds.includes(credentialType)
        ) {
          uniqueBlockTypesArrayWithCreds.push(credentialType);
          if (blockObject) {
            blockTypes.push(blockObject);
          }
        }
      }

      this.blockCredTypes = uniqueBlockTypesArrayWithCreds;
      this.blocks = blockTypes;
    } catch (error) {
      console.log('error', error);
    }
  };

  @action
  setSelectedWorkflow(currentWorkflowId, selectedWorkflow) {
    this.currentWorkflowId = currentWorkflowId;
    this.selectedWorkflow = selectedWorkflow;
    this.workflow = selectedWorkflow?.workflow;
    this.tags = selectedWorkflow?.tags;
    this.postId = selectedWorkflow?._id;
    this.recipeTitle = selectedWorkflow?.title;
    this.recipeAuthor = selectedWorkflow?.user
      ? selectedWorkflow.user.fullName
      : '';
    this.recipeCreator = selectedWorkflow?.creator
      ? selectedWorkflow?.creator
      : '';
    // this.setupRecipe(selectedWorkflow);
  }

  @action
  setRecipeWorkflow(currentWorkflowId, selectedWorkflow) {
    this.currentWorkflowId = currentWorkflowId;
    this.workflow = selectedWorkflow;

    this.createDraftId(currentWorkflowId);
  }

  @action
  setForgeError = (error) => {
    this.isForgeError = error;
  };

  @action
  setTempTree = (tree) => {
    this.tempBlockTree = tree;
  };

  @action
  setAddNewType = (type) => {
    this.addNewType = type;
  };

  @action
  setDraftCsvData = (data) => {
    this.draftCsvData = data;
  };

  @action
  setRecipeInstaller = (bool) => {
    this.recipeInstaller = bool;
  };

  @action
  setShowVariableSelector = (show, parameter, editor, blockId) => {
    this.showVariableSelector = show;
    this.parameter = parameter;
    this.editor = editor;
    this.forgeCurrentBlockId = blockId;
  };

  @action
  setRecipe = (recipe) => {
    this.recipe = recipe;

    if (recipe.workflow) {
      this.workflow = recipe.workflow;
      this.tags = recipe.tags;
      this.postId = recipe._id;
      this.recipeTitle = recipe.title;
      this.recipeAuthor = recipe.user ? recipe.user.fullName : '';
      this.recipeCreator = recipe.creator ? recipe.creator : '';
      this.getUniqueBlocks();
      // TEMPORARY REMOVE DUE RECIPE MULTIPLE WORKFLOWS
      // this.lightningDataFields =
      //   (this.recipe.lightningData && this.recipe.lightningData.length === 0) ||
      //   (this.recipe.lightningData &&
      //     this.recipe.lightningData[0] &&
      //     this.recipe.lightningData[0].hasOwnProperty('fieldKey'))
      //     ? this.recipe.lightningData
      //     : this.recipe.lightningData[0].children;
    }
    const { optionalInfo } = recipe;
    if (optionalInfo) {
      for (const key in optionalInfo) {
        if (optionalInfo[key].hideOutput) {
          this.hideOutput.push(key);
        }
      }
    }
  };

  @action
  setFixedCollections = (collection) => {
    this.fixedCollections = collection;
  };

  @action
  setBlockValues = () => {
    this.leftLoading = true;

    this.allBlocks.forEach((block) => {
      const newBlock = {
        credentials: {},
        parameters: {},
        preview: {},
      };
      const creds = this.credentialsData.find((cred) => cred.id === block.id);
      const keys = Object.keys(this.formData);
      if (keys && keys.length) {
        keys.forEach((key) => {
          if (key.indexOf(this.currentBlockId) > -1) {
            newBlock.parameters[key] = this.formData[key];
          }
        });
      }
      if (creds) {
        newBlock.credentials[creds.credentialType] = creds.data;
      }
      this.blockValues[block.id] = newBlock;
    });
    this.leftLoading = false;
  };

  @action
  handleChange = (change) => {
    this.currentStep = change;
  };

  @action
  handleForgeStepchange = (step) => {
    this.forgeCurrentStep = step;
  };

  findPreviewText = (path) => {
    // const converted = btoa(path);
    if (this.preview && this.preview[path]) {
      return this.preview[path];
    }
    return null;
  };

  @action
  setBlocksData = () => {
    this.leftLoading = true;
    this.workflow.blocks.forEach((block) => {
      const currentBlockLightningDataFields =
        this.lightningDataFields && this.lightningDataFields.length
          ? this.lightningDataFields.filter(
              (tg) => tg.fieldBlockId === block.id
            )
          : [];
      const currentBlockType = this.globalStore.blockTypeStore.blockTypes.find(
        (blk) => blk.name === block.type
      );
      this.currentBlockType = currentBlockType;
      if (
        [
          'alloy.if',
          'alloy.branch',
          'alloy.math',
          'alloy.text',
          'alloy.errorHandler',
        ].includes(block.type)
      ) {
        this.blockParameters[block.id] = currentBlockLightningDataFields.map(
          (field) => {
            return {
              displayName: field.fieldDisplayName,
              name: field.fieldValue,
              type: field.fieldType,
              default: '',
              required: field.fieldRequired,
              description: field.fieldDescription,
              ...field,
            };
          }
        );
      } else {
        this.blockParameters[block.id] = this.currentBlockType?.properties
          ?.filter((prp) => {
            const field = currentBlockLightningDataFields.find(
              (fld) =>
                fld.fieldValue === prp.name &&
                (!prp.displayOptions ||
                  (prp.displayOptions &&
                    prp.displayOptions.show &&
                    prp.displayOptions.show.operation &&
                    ((!block.parameters.resource &&
                      prp.displayOptions.show.operation.includes(
                        block.parameters.operation
                      )) ||
                      (prp.displayOptions.show.resource &&
                        prp.displayOptions.show.resource.includes(
                          block.parameters.resource
                        ) &&
                        prp.displayOptions.show.operation.includes(
                          block.parameters.operation
                        )))) ||
                  (!prp.displayOptions.show.operation &&
                    prp.displayOptions.show.resource &&
                    prp.displayOptions.show.resource.includes(
                      block.parameters.resource
                    )))
            );
            if (field) {
              prp.required = field.fieldRequired;
              prp.optional = field.optional;
              return prp;
            } else {
              return null;
            }
          })
          .filter((field) => !!field);
      }
      const blockParams = this.blockParameters[block.id];
      if (blockParams?.length) {
        const filteredParams = blockParams.map((param) => {
          let { options } = param;
          if (param.type === 'fixedCollection') {
            const collection = currentBlockLightningDataFields.find(
              (fld) => fld.name === param.name
            );
            if (collection) {
              options = param.options.filter((prp) => {
                const field = collection.childrenBlocks.find((fld) => {
                  return (
                    fld.name === prp.name && fld.required && fld.fieldRequired
                  );
                });

                if (field) {
                  return true;
                } else {
                  return false;
                }
              });
            }
          }
          if (options?.length) {
            // if there are fields selected form the fixed collection then show them, otherwise show all of the options
            return { ...param, options };
          } else {
            return param;
          }
        });

        this.blockParameters[block.id] = filteredParams;
      }
    });
    this.leftLoading = false;
  };

  @action
  nextBlock = () => {
    const currentBlockIndex = this.workflow.blocks.indexOf(
      (block) => block.id === this.currentBlockId
    );
    if (currentBlockIndex === this.workflow.blocks.length) {
      this.incrementStep();
    } else {
      this.setCurrentBlockId(this.workflow.blocks[currentBlockIndex + 1].id);
    }
  };

  @action
  setCurrentBlockId = (id) => {
    this.currentBlockId = id;
  };

  @action
  incrementStep = (stepLength, currentKey) => {
    if (stepLength <= currentKey + 1) {
      this.nextBlock();
    } else {
      this.setBlockValues();
      this.leftLoading = false;
      this.currentStep += 1;
    }
  };

  @action
  incrementForgeStep = () => {
    this.leftLoading = false;
    this.forgeCurrentStep += 1;
  };

  @action
  decrementForgeStep = () => {
    this.leftLoading = false;
    this.forgeCurrentStep -= 1;
  };

  @action
  decrementStep = () => {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
      this.currentBlock = '';
    }
  };

  isComplete = (id) => {
    const block = this.newPost.blocks.find((bk) => bk.id === id);
    const type = this.globalStore.blockTypeStore.findByName(block.type);
    const credType = get(type, 'credentials[0].name');
    const hasCred =
      this.blocksWithCred.indexOf(id) === -1 ||
      (credType &&
        this.credentialsData.find((cred) => cred.credentialType === credType) &&
        this.blocksWithCred.indexOf(id) > -1);

    if (
      !hasCred ||
      !this.recipe ||
      !this.recipe.lightningData ||
      !this.recipe.lightningData.length
    )
      return false;

    const lightningDataChildren = this.recipe.lightningData[0].hasOwnProperty(
      'fieldKey'
    )
      ? this.recipe.lightningData
      : this.recipe.lightningData[0].children;

    const currentBlockFields = lightningDataChildren.length
      ? lightningDataChildren.filter((ld) => ld.fieldBlockId === id)
      : [];
    if (!currentBlockFields) return false;

    const keys = Object.keys(this.formData);

    const emptyField = currentBlockFields.find((field) => {
      const wasFilledIn = keys.find((key) => key.indexOf(field.fieldKey) > -1);
      return !wasFilledIn;
    });
    return !emptyField;
  };

  @action
  handleFormDataChanges = (key, value, isMultipleParamField) => {
    // This Try, Catch, Finally is to make sure the array's specific index shared to be input given by user works properly.
    let setStatus = true;
    try {
      set(this.dummyValueChecker, key, value);
    } catch {
      setStatus = false;
      this.formData[key] = value;
      this.blockValues[key] = value;
    } finally {
      if (setStatus) {
        set(this.formData, key, value);
        set(this.blockValues, key, value);
      }
    }
    if (isMultipleParamField) {
      set(this.multipleParamKeys, key, value);
    }
  };

  @action
  handleFormDataDelete = (key, index) => {
    const collection = get(this.formData, key);
    collection.splice(index, 1);
    set(this.formData, key, collection);
    set(this.blockValues, key, collection);
  };

  @action
  handlePreviewChanges = (key, value) => {
    this.preview[key] = value;
  };

  @action
  deleteFixedParam = (key, index, values, size) => {
    const fieldToRemove = this.fixedCollectionFields?.[key].splice(index, 1);
    const addedFields = this.fixedCollectionFields?.[key].filter((field) =>
      Object.values(field).some((currField) =>
        get(currField, 'isRemovable', true)
      )
    );
    if (fieldToRemove) {
      this.fixedCollectionFields[key] = this.fixedCollectionFields[key].filter(
        (field) =>
          Object.values(field).some(
            (currField) => !get(currField, 'isRemovable', true)
          )
      );
      const valuesFieldsToRemove = Object.values(fieldToRemove[0]);
      valuesFieldsToRemove.forEach((currValue) => {
        unset(this.formData, [currValue?.fieldKey]);
        unset(this.preview, [currValue?.fieldKey]);
      });
    }

    const unsetFixedParam = unset(this.blockValues, [key, index]);
    if (unsetFixedParam) {
      unset(this.formData, [key, index]);
      this.formData[key] = this.formData[key].filter((val) => !!val);
      this.blockValues[key] = this.blockValues[key]?.filter((val) => !!val);
    }

    if (fieldToRemove && this.fixedCollectionFields?.[key]) {
      addedFields.forEach((value, index, currentFields) => {
        let newFixedField = {};
        values.forEach((valKey) => {
          const newKey = `${key}[${size + index}].${valKey}`;
          const oldKey = `${value[valKey]?.fieldKey}`;
          const formDataValue = get(this.formData, [oldKey]);
          const previewDataValue = get(this.preview, [oldKey]);
          unset(this.formData, [oldKey]);
          unset(this.blockValues, [oldKey]);
          unset(this.preview, [oldKey]);
          set(this.formData, [newKey], formDataValue);
          set(this.blockValues, [newKey], formDataValue);
          set(this.preview, [newKey], previewDataValue);
          newFixedField[valKey] = {
            ...value[valKey],
            fieldKey: `${key}[${size + index}].${valKey}`,
            fieldBlockKey: `${key}[${size + index}]`,
            uuid: uuid4(),
          };
        });
        currentFields[index] = newFixedField;
      });

      this.fixedCollectionFields[key] = [
        ...this.fixedCollectionFields[key],
        ...addedFields,
      ];
    } else {
      this.blockValues[key].forEach((value, index) => {
        const newKey = `${key}[${index}]`;
        const valueKeys = Object.keys(value);
        valueKeys.forEach((valKey) => {
          if (valKey === 'uuid') return;
          set(this.formData, [`${newKey}.${valKey}`], value[valKey]);
          set(this.preview, [`${newKey}.${valKey}`], value[valKey]);
        });
      });
    }
  };

  @action
  filterCredBlocks = () => {
    const { globalStore } = this;

    this.workflow.blocks.forEach((block) => {
      const blockObject = globalStore.blockTypeStore.findByName(block.type);
      if (get(blockObject, 'credentials[0].name')) {
        this.blocksWithCred.push(block.id);

        if (this.isForgeUpdate) {
          const credentialType = get(blockObject, 'credentials[0].name');
          const data = block.credentials[credentialType];
          if (
            !this.credentialsData.find(
              (cred) => cred.credentialType === credentialType
            )
          ) {
            this.credentialsData.push({
              credentialType,
              data,
            });
          }
        }
      }
    });
  };

  getSetupType = () => {
    const currentPath = this.globalStore.routingStore.location.pathname;
    if (currentPath.indexOf('template-setup') > -1) {
      return 'import';
    } else {
      return 'export';
    }
  };

  @action
  getUniqueBlocks = () => {
    const { globalStore } = this;
    const blockTypesArray = [];
    const uniqueBlockTypesArrayWithCreds = [];
    const blockTypes = [];
    const withCreds = this.workflow.blocks.filter((block) => {
      return this.blockTypeHasCredentials(block);
    });

    withCreds.forEach((block) => {
      if (!blockTypesArray.includes(block.type)) {
        blockTypesArray.push(block.type);
      }
    });

    blockTypesArray.forEach((block, index) => {
      const isTriggerBlock = block.indexOf('Trigger');
      if (isTriggerBlock > -1) {
        const subString = block.substring(0, isTriggerBlock);
        const count = blockTypesArray.filter(
          (type) => type.indexOf(subString) !== -1
        );
        if (count.length > 1) {
          delete blockTypesArray[index];
        }
      }
    });

    for (const blockTypeIndex in blockTypesArray) {
      const blockObject = globalStore.blockTypeStore.findByName(
        blockTypesArray[blockTypeIndex]
      );
      const credType = get(blockObject, 'credentials.0.name', null);
      if (credType && !uniqueBlockTypesArrayWithCreds.includes(credType)) {
        uniqueBlockTypesArrayWithCreds.push(credType);
        if (blockObject) {
          blockTypes.push(blockObject);
        }
      }
    }

    this.blockCredTypes = uniqueBlockTypesArrayWithCreds;
    this.blocks = blockTypes;
  };

  @action
  removeSelectedAccount = (accountType) => {
    const filteredData = this.credentialsData.filter(
      (cred) => cred.credentialType !== accountType
    );

    this.credentialsData = filteredData;
  };

  @action
  setDraftId = (postId, id) => {
    this.draftIds.push({ postId, id });
    this.draftId = id;
  };

  checkCreds = async () => {
    if (this.credentialsData.length >= this.blocks.length) {
      this.currentBlock = '';
      this.incrementStep();
      if (!this.recipeInstaller) {
        try {
          // TODO: we should'nt do this for all recipes, but for the one that has the csv block
          const parentId = this?.recipe?._id;
          const { draftId } = await createRecipeDraft({ parentId });
          this.setDraftId(this.currentWorkflowId, draftId);
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      showNotification('error', 'Please connect all the accounts first!');
    }
  };

  createDraftId = async (parentId) => {
    const existingDraftId = this.draftIds.find(
      (draft) => draft.postId === parentId
    );
    if (!existingDraftId) {
      try {
        const { draftId } = await createRecipeDraft({ parentId });
        this.setDraftId(parentId, draftId);
      } catch (e) {
        console.log(e);
      }
    }
  };

  checkCredsAndSubmit = async (fromEnterprise) => {
    if (this.credentialsData.length === this.blocks.length) {
      this.handleFinish(fromEnterprise);
    } else {
      showNotification('error', 'Please connect all the accounts first!');
    }
  };

  checkForgeCreds = async () => {
    if (this.credentialsData.length >= this.blocks.length) {
      this.incrementForgeStep();
    } else {
      showNotification('error', 'Please connect all the accounts first!');
    }
  };

  @action
  handleCredentialChanges = (credentialType, data) => {
    const { credentialsData } = this;
    let inData = false;
    let index = -1;

    if (get(data, 'selectedCredential._id', false)) {
      for (let i = 0; i < credentialsData.length; i++) {
        if (credentialsData[i].credentialType === credentialType) {
          inData = true;
          index = i;
        }
      }

      if (inData) {
        credentialsData[index] = {
          credentialType,
          data: data.selectedCredential._id,
        };
      } else {
        credentialsData.push({
          credentialType,
          data: data.selectedCredential._id,
        });
      }

      this.credentialsData = credentialsData;
    }
  };

  blockTypeHasCredentials = async (block) => {
    const { globalStore } = this;
    const blockObject = globalStore.blockTypeStore.findByName(block.type);

    if (
      !blockObject ||
      !blockObject.hasOwnProperty('credentials') ||
      !blockObject.credentials ||
      block.forgeInternalUse
      // ||
      // block.type === 'alloy.httpRequest' //can be removed, should be marked as internal use
    ) {
      return false;
    }

    return true;
  };

  @action
  addFixedCollectionKey = (key) => {
    if (!this.fixedCollectionKeys.includes(key)) {
      this.fixedCollectionKeys.push(key);
    }
  };

  @action
  addFixedCollectionField = (newField, path) => {
    if (!this.fixedCollectionFields) {
      this.fixedCollectionFields = {};
      this.fixedCollectionFields[path] = [];
      this.fixedCollectionFields?.[path]?.push(newField);
    } else {
      this.fixedCollectionFields?.[path]?.push(newField);
    }
  };

  @action
  removeFixedCollectionKey = (key) => {
    if (this.fixedCollectionKeys.includes(key)) {
      this.fixedCollectionKeys.splice(this.fixedCollectionKeys.indexOf(key), 1);
    }
  };

  closeForgeModal = () => {
    const { globalStore, forgeMode } = this;
    const {
      location: { search },
    } = globalStore.routingStore;
    const { isFromPreview } = parse(search, true).query;
    if (isFromPreview) {
      window.parent.postMessage({ closeModal: true }, '*');
      return;
    } else if (forgeMode) {
      window.parent.postMessage({ closeModal: true }, '*');
      localStorage.clear();
    }
  };

  handleForgeFinish = debounce(
    action(async (fromEnterprise, props = {}) => {
      const { globalStore, forgeMode } = this;
      const {
        location: { search },
      } = globalStore.routingStore;
      const { isFromPreview, showSuccessPage } = parse(search, true).query;
      if (isFromPreview && JSON.parse(isFromPreview)) {
        if (showSuccessPage && !JSON.parse(showSuccessPage)) {
          window.parent.postMessage({ closeModal: true }, '*');
          return;
        } else {
          this.isLoading = false;
          return;
        }
      }
      const { done = null, isEmbeddedLinkModal = false, token = '' } = props;
      const forgeWorkflowsIds = this.forgeWorkflows.map(
        (workflow) => workflow._id
      );
      this.isLoading = true;
      const { credentialsData, postId, preview, fixedCollectionKeys } = this;

      this.showPlanValidationMessage = false;

      await globalStore.workflowStore.validateWorkflow();

      const planHasErrors = globalStore.workflowStore.planValidationErrors;

      if (!planHasErrors) {
        const { formData } = this;

        const filteredFormData = {};
        Object.assign(filteredFormData, formData);

        forIn(formData, (value, key) => {
          if (has(formData, `countryCode::${key}`)) {
            const phoneInputValue = get(formData, key);

            const phoneValue = phoneInputValue
              .replace('-', '')
              .replace('(', '')
              .replace(')', '')
              .replace(' ', '');
            const countryCode = get(formData, `countryCode::${key}`);
            set(filteredFormData, key, `${countryCode}${phoneValue}`);
            unset(filteredFormData, `countryCode::${key}`);
          }

          const multipleParamField = get(this.multipleParamKeys, key);
          if (multipleParamField) {
            const cleanedValue = value.map((param) => param.value);

            set(filteredFormData, key, cleanedValue);
          }
        });
        const newFilteredFormData = this.revertFormDataKeys(filteredFormData);
        const newPreview = this.revertFormDataKeys(preview);

        globalStore.marketplaceStore
          .saveForgeWorkflow(
            postId,
            credentialsData,
            newFilteredFormData,
            newPreview,
            fixedCollectionKeys,
            forgeMode,
            this.draftId,
            forgeWorkflowsIds,
            isEmbeddedLinkModal,
            token
          )
          .then(
            action(async (res) => {
              if (res.success) {
                globalStore.marketplaceStore.templateImported = true;
                this.useWorkflowBtnDisabled = false;
                this.incrementForgeStep();
                this.isLoading = false;
                globalStore.blockEditStore.blockValues = null;
                if (fromEnterprise) {
                  return;
                }
                globalStore.blockTypeStore.initialize(forgeMode);
                const { parentIds } = globalStore.marketplaceStore;

                const workflowIds = [];

                parentIds.forEach((parentId) => {
                  const workflow = res.newWorkflows.find(
                    (w) => w.forgeWorkflowId === parentId
                  );
                  if (workflow) {
                    workflowIds.push(workflow._id);
                  }
                });
                if (!fromEnterprise && props.showSuccessPage) {
                  this.workflowIdsCreated = workflowIds;
                  window.parent.postMessage(
                    {
                      workflowCreated: true,
                      workflowId: workflowIds,
                      closeModal: false,
                    },
                    '*'
                  );
                } else if (!fromEnterprise) {
                  window.parent.postMessage(
                    {
                      workflowCreated: true,
                      workflowId: workflowIds,
                    },
                    '*'
                  );
                  localStorage.clear();
                }
                if (fromEnterprise) {
                  window.close();
                }
                if (done && typeof done === 'function') {
                  done();
                }
              } else {
                this.isLoading = false;
                if (this.forgeMode) {
                  this.setForgeError(true);
                } else {
                  showNotification('error', 'Something went wrong!');
                }
              }
            })
          )
          .catch((e) => {
            console.log(e);
            this.isLoading = false;
            if (this.forgeMode) {
              this.setForgeError(true);
            } else {
              showNotification('error', 'Something went wrong!');
            }
          });
      } else {
        this.isLoading = false;
        this.showPlanValidationMessage = true;
      }
    }),
    1000,
    { leading: true, trailing: false }
  );

  @action
  handleFinish = async (fromEnterprise) => {
    const { globalStore, forgeMode, workflowSettings } = this;
    const collectionTemplateIds = this.recipes
      .map((recipe) => !recipe?.workflow?.forgeId && recipe._id)
      .filter(Boolean);
    const forgeWorkflowIds = this.recipes
      .map((recipe) => recipe?.workflow?.forgeId && recipe._id)
      .filter(Boolean);
    this.isLoading = true;
    const { credentialsData, postId, preview, fixedCollectionKeys } = this;
    const { push } = globalStore.routingStore;

    this.showPlanValidationMessage = false;

    await globalStore.workflowStore.validateWorkflow();

    const planHasErrors = globalStore.workflowStore.planValidationErrors;

    if (!planHasErrors) {
      setTimeout(() => {
        const { formData } = this;

        const filteredFormData = {};
        Object.assign(filteredFormData, formData);

        forIn(formData, (value, key) => {
          if (has(formData, `countryCode::${key}`)) {
            const phoneInputValue = get(formData, key);

            const phoneValue = phoneInputValue
              .replace('-', '')
              .replace('(', '')
              .replace(')', '')
              .replace(' ', '');
            const countryCode = get(formData, `countryCode::${key}`);
            set(filteredFormData, key, `${countryCode}${phoneValue}`);
            unset(filteredFormData, `countryCode::${key}`);
          }

          const multipleParamField = get(this.multipleParamKeys, key);
          if (multipleParamField) {
            const cleanedValue = value.map((param) => param.value);

            set(filteredFormData, key, cleanedValue);
          }
        });

        globalStore.marketplaceStore
          .saveWorkflow(
            postId,
            credentialsData,
            filteredFormData,
            preview,
            fixedCollectionKeys,
            this.draftIds,
            collectionTemplateIds,
            workflowSettings,
            forgeWorkflowIds,
            true
          )
          .then(
            action(async (res) => {
              if (res.data || res.success) {
                this.useWorkflowBtnDisabled = false;
                this.isLoading = false;
                globalStore.blockEditStore.blockValues = null;

                globalStore.marketplaceStore.templateImported = true;
                globalStore.blockTypeStore.initialize(forgeMode);

                if (
                  (!fromEnterprise || !this.collectionId) &&
                  res.newWorkflows.length > 0
                ) {
                  const workflow = res.newWorkflows[0];

                  push(`/workflow/build?workflowId=${workflow._id}`);

                  const { blocks } = workflow;
                  if (blocks.length && blocks[0].type !== 'alloy.start') {
                    this.globalStore.executionStore.aWorkflowIsActivating = true;
                  }
                }
                // showNotification('success', 'Recipe installed and activated.');
              } else {
                this.isLoading = false;
                showNotification('error', 'Something went wrong!');
              }
            })
          )
          .catch((e) => {
            console.log(e);
            this.isLoading = false;
            showNotification('error', 'Something went wrong!');
          });
      }, 1);
    } else {
      this.isLoading = false;
      this.showPlanValidationMessage = true;
    }
  };

  isTriggerBlacklisted = () => {
    return BLACKLISTED_TRIGGERS.includes(this.workflow.blocks[0].type);
  };

  getParentBlocks = (selectedList) => {
    const { workflow, currentBlockId } = this;

    const hideTrigger = this.isTriggerBlacklisted();

    const parentBlocks = workflow.blocks.filter(
      (block) =>
        (block.id !== currentBlockId ||
          (hideTrigger && BLACKLISTED_TRIGGERS.includes(block.type))) &&
        this.hideOutput.indexOf(block.id) === -1
    );

    if (selectedList) {
      const blockId = selectedList.substring(
        selectedList.indexOf('$block["') + 8,
        selectedList.indexOf('"]')
      );
      const parentBlock = parentBlocks.find((block) => block.id === blockId);
      return [parentBlock];
    }
    return parentBlocks;
  };

  getBlockTree = async (block) => {
    const { globalStore } = this;

    if (isWebhookBlock(block.type)) {
      const bodyParameters =
        block.parameters &&
        block.parameters.bodyParameters &&
        block.parameters.bodyParameters.length
          ? block.parameters.bodyParameters
          : [];
      const bodyOrQuery =
        block.parameters.httpMethod === 'GET' ? 'query' : 'body';
      const outputData = {
        name: 'Output',
        key: `$block["${block.id}"].outputData`,
        options: bodyParameters.map((p) => {
          const key = `$block["${block.id}"].data["${bodyOrQuery}"]["${p.bodyParameter}"]`;
          const value = p.exampleValue || '---UNKNOWN---';

          return {
            name: p.bodyParameter,
            key,
            value,
          };
        }),
        dataType: 'object',
        id: 'output_' + block.id,
      };
      const restOfParams = Object.assign({}, block.parameters);
      delete restOfParams.bodyParameters;
      const parameters = {
        name: 'Input',
        key: `$block["${block.id}"].parameters`,
        options: map(restOfParams, (value, name) => {
          const key = `$block["${block.id}"].parameter["${name}"]`;
          return {
            name,
            key,
            value,
          };
        }),
        dataType: 'object',
        id: 'input_' + block.id,
      };

      return {
        name: block.name,
        key: block.id,
        outputData:
          block.sampleLiveData &&
          block.sampleLiveData.body &&
          Object.keys(block.sampleLiveData.body).length
            ? {
                [`${block.id}`]: {
                  data: [[{ id: block.id, json: block.sampleLiveData.body }]],
                },
              }
            : null,
        options: [outputData, parameters],
        icon: block.icon,
        title: block.subtitle,
        showIcon: true,
        type: block.type,
      };
    } else if (block.type === 'alloy.uploadCSV') {
      let outputData = this.draftCsvData;

      if (!outputData) {
        outputData = null;
      }
      const parameters = {
        name: 'Input',
        key: 'parameters',
        options: map(block.parameters, (value, name) => {
          const key = `$block["${block.id}"].parameter["${name}"]`;

          return {
            name,
            key,
            value,
            id: 'option_' + key,
          };
        }),
        dataType: 'object',
        id: 'input_' + block.id,
      };

      return {
        name: block.name,
        key: block.id,
        outputData,
        inputData: parameters,
        icon: block.icon,
        title: block.subtitle,
        showIcon: true,
        type: block.type,
        realOutputData: outputData, // because output data gets formatted in the next step
      };
    } else {
      let outputData;
      // look for executions of similar blocks on other workflows or predefined templates
      try {
        if (block.type === NODE_TYPES.FOR) {
          const tempTree = this.tempBlockTree;

          const response =
            await globalStore.blockTypeStore.getRecipeBlockOutput(
              block,
              tempTree
            );

          if (response.data) {
            outputData = Object.assign({}, response.data);
          }
        } else {
          const response = await globalStore.blockTypeStore.getBlockOutput(
            block
          );

          if (response.data) {
            outputData = Object.assign({}, response.data);
          }
        }
      } catch (e) {
        if (block.type === NODE_TYPES.FOR) {
          outputData = {
            name: 'Output',
            key: 'outputData',
            options: [],
          };
        }
      }

      // give up and leave output blank
      if (!outputData) {
        outputData = null;
      }
      const parameters = {
        name: 'Input',
        key: 'parameters',
        options: map(block.parameters, (value, name) => {
          const key = `$block["${block.id}"].parameter["${name}"]`;

          return {
            name,
            key,
            value,
            id: 'option_' + key,
          };
        }),
        dataType: 'object',
        id: 'input_' + block.id,
      };

      return {
        name: block.name,
        key: block.id,
        outputData,
        inputData: parameters,
        icon: block.icon,
        title: block.subtitle,
        showIcon: true,
        type: block.type,
      };
    }
  };

  getNewDisplayName = (fieldMapping, field, parentName) => {
    let displayName = get(fieldMapping, field, null);

    if (displayName) return displayName;

    displayName = startCase(field.replace('_', ' '));
    // AS OF NOW COMMENTED THIS CODE AS IT'S NOT CLEAR USE OF parentName.
    // return parentName ? `${parentName} / ${displayName}` : displayName;
    return displayName;
  };

  formatVariables = (
    output,
    blockId,
    fieldMapping,
    keyPrefix,
    icon,
    parentName,
    paramType
  ) => {
    let variables = [];
    // const paramType = this.props.parameter.type;

    if (isObject(output) && !isArray(output)) {
      for (let prop in output) {
        if (isObject(output[prop]) && !isArray(output[prop])) {
          const children = this.formatVariables(
            output[prop],
            blockId,
            fieldMapping,
            `${keyPrefix}["${prop}"]`,
            icon,
            this.getNewDisplayName(fieldMapping, prop, parentName),
            paramType
          );
          variables = [...variables, ...children];
        } else if (isArray(output[prop])) {
          if (paramType === 'array') {
            variables.push({
              type: 'single',
              name: prop,
              displayName: this.getNewDisplayName(
                fieldMapping,
                prop,
                parentName
              ),
              icon,
              value: '<List>',
              key: `${keyPrefix}["${prop}"]`,
            });
          } else {
            const children = [];
            for (let i = 0; i < output[prop].length; i++) {
              if (isObject(output[prop][i]) || isArray(output[prop][i])) {
                children.push({
                  type: 'group',
                  name: `${prop}-${i + 1}`,
                  displayName: `${this.getNewDisplayName(
                    fieldMapping,
                    prop,
                    parentName
                  )} ${i + 1}`,
                  icon,
                  children: this.formatVariables(
                    output[prop][i],
                    blockId,
                    fieldMapping,
                    `${keyPrefix}["${prop}"][${i}]`,
                    icon,
                    this.getNewDisplayName(fieldMapping, prop, parentName),
                    paramType
                  ),
                });
              } else {
                children.push({
                  type: 'single',
                  name: `${prop}-${i + 1}`,
                  displayName: `${this.getNewDisplayName(
                    fieldMapping,
                    prop,
                    parentName
                  )} ${i + 1}`,
                  icon,
                  value: output[prop][i],
                  key: `${keyPrefix}[${i}]`,
                });
              }
            }
            variables.push({
              type: 'group',
              name: prop,
              displayName: this.getNewDisplayName(
                fieldMapping,
                prop,
                parentName
              ),
              children,
            });
          }
        } else if (paramType !== 'array') {
          variables.push({
            type: 'single',
            name: prop,
            displayName: this.getNewDisplayName(fieldMapping, prop, parentName),
            icon,
            value: output[prop],
            key: `${keyPrefix}["${prop}"]`,
          });
        }
      }
    }

    return variables;
  };

  @action
  getData = (paramType, selectedList) => {
    const { globalStore } = this;

    const parentBlocks = this.getParentBlocks(selectedList);
    let rearrangedCleanedData = [];
    const forLoopBlocks = [];
    if (parentBlocks.length) {
      // prepare stuff here
      parentBlocks.forEach((block) => {
        if (block.type === NODE_TYPES.FOR) {
          forLoopBlocks.push(block);
        }
      });

      Promise.all(parentBlocks.map((block) => this.getBlockTree(block))).then(
        (data) => {
          // this.arrayKeysAdded.clear();
          const filteredData = data.filter((block) => {
            return (
              block.key &&
              get(block, `outputData.${block.key}.data[0][0].json`, null)
            );
          });

          const cleanedData = [];
          filteredData.forEach((block) => {
            const blockType = globalStore.blockTypeStore.findByName(block.type);

            let fieldMapping =
              blockType && blockType.fieldMapping ? blockType.fieldMapping : '';

            // If Loop block, get field mapping of that block
            if (block.type === NODE_TYPES.FOR) {
              forLoopBlocks.forEach((block) => {
                if (block.id === block.key) {
                  if (block.parameters.collectionField) {
                    const collectionFieldBlockId =
                      block.parameters.collectionField.substring(
                        block.parameters.collectionField.indexOf('$block["') +
                          8,
                        block.parameters.collectionField.indexOf('"]')
                      );
                    parentBlocks.forEach((parentBlock) => {
                      if (parentBlock.id === collectionFieldBlockId) {
                        const parentBlockType =
                          globalStore.blockTypeStore.findByName(
                            parentBlock.type
                          );
                        fieldMapping =
                          parentBlockType && parentBlockType.fieldMapping
                            ? parentBlockType.fieldMapping
                            : '';
                      }
                    });
                  }
                }
              });
            }
            const actualData = get(
              block,
              `outputData.${block.key}.data[0][0].json`,
              null
            );

            if (actualData && size(actualData)) {
              const formattedData = this.formatVariables(
                actualData,
                block.key,
                fieldMapping,
                `$block["${block.key}"].data`,
                block.icon,
                paramType
              );
              block.outputData = formattedData;
              cleanedData.push(block);
            }
          });
          // rearrange array

          for (const blockData of cleanedData) {
            if (blockData.type === 'alloy.for') {
              rearrangedCleanedData.unshift(blockData);
            } else {
              rearrangedCleanedData.push(blockData);
            }
          }

          runInAction(() => {
            this.tempBlockTree = rearrangedCleanedData;
          });

          return rearrangedCleanedData;
        }
      );
    }
    return rearrangedCleanedData;
  };

  getBlockRequiredFields = (blockId, lightningData) => {
    const filteredLightningData = lightningData.filter((tg) => {
      return !(tg.fieldName === 'alloy.if' && tg.fieldValue === 'conditionSet');
    });
    const previewKeys = Object.keys(this.preview);

    const blockFields = filteredLightningData.filter((field) => {
      return field.fieldBlockId === blockId;
    });

    const filteredBlockParams = blockFields.filter((param) => {
      if (param.type === 'fixedCollection') {
        // find if any of the blockfields have same fieldKey as param.fieldKey
        const isFieldKeyPresent = blockFields.find((blockField) => {
          return (
            blockField.fieldKey.includes(param.fieldKey) &&
            blockField.fieldKey !== param.fieldKey
          );
        });
        return !isFieldKeyPresent;
      } else {
        return true;
      }
    });

    if (filteredBlockParams?.length) {
      const filteredFields = previewKeys.filter(
        (key) => !!get(this.formData, key)
      );
      const dataMap = new Map();
      const map = new Map();
      for (const item of filteredFields) {
        const id = item.split('::')[0];

        if (id === blockId) {
          const block = this.blockParameters[blockId]?.[0];
          const fixedCollectionField = item.split('[')?.[0];
          const fixedCollectionBlock = filteredBlockParams.find(
            (param) => param.fieldKey === fixedCollectionField
          );
          if (
            block &&
            block?.type === 'string' &&
            block?.typeOptions &&
            block?.typeOptions?.multipleValues
          ) {
            if (!dataMap.has(blockId)) {
              dataMap.set(blockId, true);
            }
          } else if (
            block?.type === 'fixedCollection' &&
            fixedCollectionBlock?.childrenBlocks?.length
          ) {
            if (!map.has(item)) {
              map.set(item, true);
            }
            const childIndex = item.split('[')[1].split(']')[0];

            const numberOfFixedCollectionRequiredFields =
              fixedCollectionBlock.childrenBlocks.filter((b) => {
                const key = `${b.blockId}::${b.fieldKey.replace(
                  '::',
                  `[${childIndex}].`
                )}`;
                // fixed collection fields use the params defined for them in the collection itself, so it's not clear which ones are required
                return !!b.fieldRequired && !map.has(key);
              });

            if (!numberOfFixedCollectionRequiredFields.length) {
              dataMap.set(fixedCollectionBlock.fieldKey, true);
            }
          } else if (block?.type === 'fixedCollection') {
            const numOfOptions = block.options?.length;
            // all field has been filled
            if (numOfOptions === filteredFields.length) {
              filteredBlockParams.forEach((field) => {
                dataMap.set(field.fieldKey, true);
              });
            } else {
              dataMap.set(item, true);
            }
          } else if (!dataMap.has(item)) {
            dataMap.set(item, true);
          }
        }
      }
      const returnVal = filteredBlockParams.length - dataMap.size;
      if (returnVal < 0) {
        return 0;
      } else {
        return returnVal;
      }
    } else {
      return 0;
    }
  };

  checkShowOptions = (showData, parameters) => {
    const keys = Object.keys(showData);
    return (
      keys &&
      keys.length &&
      keys.reduce(
        (acc, curr) => acc && showData[curr].indexOf(parameters[curr]) > -1,
        true
      )
    );
  };

  checkIfDisplay = (property, block) => {
    if (['alloy.httpRequest'].indexOf(block.type) > -1) {
      // FOR BLOCKS THAT DOES NOT HAVE RESOURCE OR OPERATION PARAMS
      return (
        !property.displayOptions ||
        (property.displayOptions &&
          property.displayOptions.show &&
          this.checkShowOptions(property.displayOptions.show, block.parameters))
      );
    } else {
      // This is unchanged , just a clean way than before
      return (
        !property.displayOptions ||
        (property.displayOptions &&
          property.displayOptions.show &&
          property.displayOptions.show.operation &&
          ((!block.parameters.resource &&
            property.displayOptions.show.operation.includes(
              block.parameters.operation
            )) ||
            (property.displayOptions.show.resource &&
              property.displayOptions.show.resource.includes(
                block.parameters.resource
              ) &&
              property.displayOptions.show.operation.includes(
                block.parameters.operation
              ))))
      );
    }
  };

  @action
  setupCollectionInfo = async (collectionInfo) => {
    this.recipeTitle = collectionInfo?.title;
    this.recipeAuthor = collectionInfo?.user
      ? collectionInfo.user.fullName
      : '';
    this.recipeCreator = collectionInfo?.creator ? collectionInfo.creator : '';
  };

  @action
  filterRecipeCollectionCredBlocks = () => {
    const { globalStore } = this;
    this.allBlocks.forEach((block) => {
      const blockObject = globalStore.blockTypeStore.findByName(block.type);
      if (get(blockObject, 'credentials[0].name')) {
        this.blocksWithCred.push(block.id);
      }
    });
  };

  @action
  getCollectionUniqueBlocks = () => {
    const { globalStore } = this;
    const blockTypesArray = [];
    const uniqueBlockTypesArrayWithCreds = [];
    const blockTypes = [];

    const withCreds = this.allBlocks.filter((block) => {
      return this.blockTypeHasCredentials(block);
    });

    withCreds.forEach((block) => {
      if (!blockTypesArray.includes(block.type)) {
        blockTypesArray.push(block.type);
      }
    });

    blockTypesArray.forEach((block, index) => {
      const isTriggerBlock = block.indexOf('Trigger');
      if (isTriggerBlock > -1) {
        const subString = block.substring(0, isTriggerBlock);
        const count = blockTypesArray.filter(
          (type) => type.indexOf(subString) !== -1
        );
        if (count.length > 1) {
          delete blockTypesArray[index];
        }
      }
    });

    for (const blockTypeIndex in blockTypesArray) {
      const blockObject = globalStore.blockTypeStore.findByName(
        blockTypesArray[blockTypeIndex]
      );
      const credType = get(blockObject, 'credentials.0.name', null);
      if (credType && !uniqueBlockTypesArrayWithCreds.includes(credType)) {
        uniqueBlockTypesArrayWithCreds.push(credType);
        if (blockObject) {
          blockTypes.push(blockObject);
        }
      }
    }

    this.blockCredTypes = uniqueBlockTypesArrayWithCreds;
    this.blocks = blockTypes;
  };

  updateBlockTypeData = async (blockType) => {
    return getBlockTypeByName(blockType.name).then((res) => {
      Object.assign(blockType, res.data[0]);
    });
  };

  @action
  setRecipeCollection = async (recipes) => {
    this.recipes = recipes;

    let workflowBlockTypes = new Set([]);
    await Promise.all(
      recipes.map(async (recipe) => {
        if (recipe.workflow.blocks) {
          await Promise.all(
            recipe.workflow.blocks.map((block) => {
              const currentBlockType =
                this.globalStore.blockTypeStore.blockTypes.find((blockType) => {
                  return blockType.name === block.type;
                });
              if (
                currentBlockType?.name &&
                !currentBlockType?.properties?.length &&
                !workflowBlockTypes.has(block.type)
              ) {
                workflowBlockTypes.add(block.type);
                return this.updateBlockTypeData(currentBlockType);
              }
            })
          );
        }
        if (recipe.workflow) {
          this.recipeWorkflows.push(recipe.workflow);
          this.postIds.push(recipe._id);

          const lightningData =
            (recipe.lightningData && recipe.lightningData.length === 0) ||
            (recipe.lightningData &&
              recipe.lightningData[0] &&
              recipe.lightningData[0].hasOwnProperty('fieldKey'))
              ? recipe.lightningData
              : recipe.lightningData[0].children;

          this.lightningDataFields.push(...lightningData);
        }
        const { optionalInfo } = recipe;
        if (optionalInfo) {
          for (const key in optionalInfo) {
            if (optionalInfo[key].hideOutput) {
              this.hideOutput.push(key);
            }
          }
        }
      })
    );

    this.getCollectionUniqueBlocks();
  };

  @action
  setupRecipes = async (recipes, collectionId, integrationId) => {
    this.collectionId = collectionId;
    this.postIds = [];
    this.recipeWorkflows = [];
    this.hideOutput = [];
    this.lightningDataFields = [];
    this.recipeIntegrationId = integrationId;

    await this.setRecipeCollection(recipes);
    this.setRecipeCollectionBlocksData();
    this.filterRecipeCollectionCredBlocks();
    // need to pass the collection data here
    this.setupCollectionInfo();

    recipes.forEach((recipe) => {
      if (recipe.credentials) {
        forEach(recipe.credentials, (cred) => {
          this.credentialsData.push({
            credentialType: cred.type,
            data: cred._id,
          });
          this.globalStore.credentialStore.credentialsByType[cred.type] = [
            cred,
          ];
        });
      }
    });
    this.credentialsData = uniqBy(this.credentialsData, 'data');

    this.recipeLoading = false;
  };

  @action
  setRecipeCollectionBlocksData = () => {
    this.leftLoading = true;
    this.recipeWorkflows.forEach((workflow) => {
      workflow.blocks.forEach((block) => {
        const currentBlockLightningDataFields =
          this.lightningDataFields && this.lightningDataFields.length
            ? this.lightningDataFields.filter((tg) => {
                return tg.fieldBlockId === block.id;
              })
            : [];

        const currentBlockType =
          this.globalStore.blockTypeStore.blockTypes.find(
            (blk) => blk.name === block.type
          );
        this.currentBlockType = currentBlockType;
        if (
          [
            'alloy.if',
            'alloy.branch',
            'alloy.math',
            'alloy.text',
            'alloy.errorHandler',
          ].includes(block.type)
        ) {
          this.blockParameters[block.id] = currentBlockLightningDataFields.map(
            (field) => {
              return {
                displayName: field.fieldDisplayName,
                name: field.fieldValue,
                type: field.fieldType,
                default: '',
                required: field.fieldRequired,
                description: field.fieldDescription,
                ...field,
              };
            }
          );
        } else {
          let fixedCollectionSubFields = [];
          this.blockParameters[block.id] = this.currentBlockType?.properties
            ?.filter((prp) => {
              const field = currentBlockLightningDataFields.filter((fld) => {
                let propName = '';
                let fieldKeyArr = [];
                try {
                  fieldKeyArr = fld.fieldKey.split('.');
                  const propertiesArr = fieldKeyArr[0].split('::');
                  propName = propertiesArr[1].substring(
                    0,
                    propertiesArr[1].indexOf('[')
                  );
                } catch (e) {
                  console.log(e);
                }
                if (
                  propName === prp.name &&
                  prp.type === 'fixedCollection' &&
                  fieldKeyArr.length >= 2 &&
                  this.checkIfDisplay(prp, block)
                ) {
                  // 1 level of fixedCollection's field
                  const { options } = prp;
                  if (fieldKeyArr?.length === 2) {
                    const stockField = options.find(
                      (opt) => opt.name === fieldKeyArr[1]
                    );
                    if (stockField) {
                      const fieldBlockKey = fld?.fieldKey
                        .slice(38)
                        .split(`.${fld?.fieldValue}`)[0];

                      const data = {
                        displayName: fld?.fieldDisplayName,
                        name: fld?.fieldValue,
                        type: fld?.fieldType,
                        fieldKey: fld?.fieldKey,
                        fieldBlockKey,
                        default: '',
                        required: fld?.fieldRequired,
                        description: fld?.fieldDescription,
                        fieldDisplayName: fld?.fieldDisplayName,
                        forgeFieldDataFormat: fld?.forgeFieldDataFormat,
                        fieldPlaceholder: fld?.fieldPlaceholder,
                        forgeFieldDescription: fld?.forgeFieldDescription,
                        isRequired: fld?.isRequired,
                        showHelpText: fld?.showHelpText,
                        forgeFieldDisplayName: fld?.forgeFieldDisplayName,
                        helpText: fld?.helpText,
                        showInfoIcon: fld?.showInfoIcon,
                        infoIconText: fld?.infoIconText,

                        variableSources: fld?.variableSources,

                        defaultValue: 'some test value',
                        ...stockField,
                      };
                      fixedCollectionSubFields.push(data);
                    }
                    return false;
                  } else {
                    const fieldBlockKey = fld?.fieldKey
                      .slice(38)
                      .split(`.${fld?.fieldValue}`)[0];
                    if (
                      fld.fieldType === 'options' &&
                      block.type === 'alloy.if'
                    ) {
                      const data = {
                        displayName: fld?.fieldDisplayName,
                        name: fld?.fieldValue,
                        type: fld?.fieldType,
                        fieldKey: fld?.fieldKey,
                        fieldBlockKey,
                        default: '',
                        required: fld?.fieldRequired,
                        description: fld?.fieldDescription,
                        forgeFieldDataFormat: fld?.forgeFieldDataFormat,
                        fieldPlaceholder: fld?.fieldPlaceholder,
                        forgeFieldDescription: fld?.forgeFieldDescription,
                        isRequired: fld?.isRequired,
                        showHelpText: fld?.showHelpText,
                        forgeFieldDisplayName: fld?.forgeFieldDisplayName,
                        helpText: fld?.helpText,
                        showInfoIcon: fld?.showInfoIcon,
                        infoIconText: fld?.infoIconText,

                        variableSources: fld?.variableSources,
                        defaultValue: 'some test value',
                      };

                      const properties = this.currentBlockType?.properties;
                      const condition = get(properties, '0.options.0.options');
                      const operationProperties =
                        condition &&
                        condition.length &&
                        condition.find((con) => con.name === 'operation');
                      const returnData = { ...data, ...operationProperties };
                      fixedCollectionSubFields.push(returnData);
                    } else {
                      const data = {
                        displayName: fld.fieldDisplayName,
                        name: fld.fieldValue,
                        type: fld.fieldType,
                        fieldKey: fld.fieldKey,
                        fieldBlockKey,
                        default: '',
                        required: fld.fieldRequired,
                        description: fld.fieldDescription,
                        forgeFieldDataFormat: fld?.forgeFieldDataFormat,
                        fieldPlaceholder: fld?.fieldPlaceholder,
                        forgeFieldDescription: fld?.forgeFieldDescription,
                        isRequired: fld?.isRequired,
                        showHelpText: fld?.showHelpText,
                        forgeFieldDisplayName: fld?.forgeFieldDisplayName,
                        helpText: fld?.helpText,
                        showInfoIcon: fld?.showInfoIcon,
                        infoIconText: fld?.infoIconText,

                        variableSources: fld?.variableSources,
                        defaultValue: 'some test value',
                      };
                      fixedCollectionSubFields.push(data);
                    }
                    return false;
                  }
                }
                return (
                  (fld.fieldValue === prp.name || propName === prp.name) &&
                  this.checkIfDisplay(prp, block)
                );
              });
              if (field?.length) {
                prp.required = field[0]?.fieldRequired;
                prp.displayName = field[0]?.fieldDisplayName;
                prp.name = field[0]?.fieldValue;
                prp.type = field[0]?.fieldType;
                prp.fieldKey = field[0]?.fieldKey;
                prp.default = '';
                prp.description = field[0]?.fieldDescription;
                prp.forgeFieldDataFormat = field[0]?.forgeFieldDataFormat;
                prp.fieldPlaceholder = field[0]?.fieldPlaceholder;
                prp.forgeFieldDescription = field[0]?.forgeFieldDescription;
                prp.isRequired = field[0]?.isRequired;
                prp.showHelpText = field[0]?.showHelpText;
                prp.forgeFieldDisplayName = field[0]?.forgeFieldDisplayName;
                prp.helpText = field[0]?.helpText;
                prp.showInfoIcon = field[0]?.showInfoIcon;
                prp.infoIconText = field[0]?.infoIconText;

                prp.variableSources = field[0]?.variableSources;
                return prp;
              } else {
                return null;
              }
            })
            .filter((field) => !!field);

          if (fixedCollectionSubFields?.length) {
            const groupedFixedCollectionSubFields = arrayGroupBy(
              fixedCollectionSubFields,
              'fieldBlockKey'
            );
            const groupedFixedCollectionSubFieldsKeys = Object.keys(
              groupedFixedCollectionSubFields
            ).sort();
            if (groupedFixedCollectionSubFieldsKeys?.length) {
              groupedFixedCollectionSubFieldsKeys.forEach(
                (groupedFieldKey, index) => {
                  groupedFixedCollectionSubFields[groupedFieldKey].forEach(
                    (field) => {
                      const splitKey = field?.fieldBlockKey.split('[')[0];
                      const keyWithoutIndex = `${block.id}::${splitKey}`;
                      const workflowBlockFieldExist = this.blockParameters[
                        block.id
                      ].find((workflowField) => {
                        return (
                          workflowField?.fieldKey === `${block.id}::${splitKey}`
                        );
                      });

                      if (workflowBlockFieldExist) {
                        if (this.formData[workflowId]) {
                          const currentParamFieldKey =
                            field?.fieldKey.split(']')[1];
                          set(
                            this.formData[workflowId],
                            `${keyWithoutIndex}[${index}]${currentParamFieldKey}`,
                            ''
                          );
                          set(
                            this.fixedCollectionFields[workflowId],
                            `${keyWithoutIndex}[${index}]${currentParamFieldKey}`,
                            { ...field, isRemovable: false }
                          );
                        } else {
                          set(this.formData, workflowId, {});
                          if (!this.fixedCollectionFields[workflowId]) {
                            set(this.fixedCollectionFields, workflowId, {});
                          }
                          set(this.formData[workflowId], field.fieldKey, '');
                          set(
                            this.fixedCollectionFields[workflowId],
                            field.fieldKey,
                            { ...field, isRemovable: false }
                          );
                        }
                        fixedCollectionSubFields =
                          fixedCollectionSubFields.filter((fixedField) => {
                            return fixedField?.fieldKey !== field?.fieldKey;
                          });
                      }
                    }
                  );
                }
              );
            }
          }

          if (this.blockParameters?.[block.id]) {
            const blockParamsTest = [
              ...this.blockParameters[block.id],
              ...fixedCollectionSubFields?.filter((field) => !!field),
            ];
            if (blockParamsTest?.length) {
              this.blockParameters[block.id] = JSON.parse(
                JSON.stringify(blockParamsTest)
              );
            }
          }

          if (block.type === 'alloy.googleSheets') {
            return;
          }
        }
      });
    });
    this.leftLoading = false;
  };

  @computed
  get recipeWorkflowBlocks() {
    return this.recipes.map((recipe) => {
      return {
        postId: recipe._id,
        workflow: recipe.workflow,
        blockIds: recipe.workflow.blocks.reduce((acc, block) => {
          acc.push(block.id);
          return acc;
        }, []),
      };
    });
  }

  @computed
  get allBlocks() {
    if (this.recipeWorkflows.length > 0) {
      return this.recipeWorkflows.reduce((acc, workflow) => {
        acc.push(...workflow.blocks);
        return acc;
      }, []);
    } else if (get(this.workflow, 'blocks')) {
      return this.workflow.blocks;
    } else {
      return [];
    }
  }

  @computed
  get allForgeBlocks() {
    if (this.forgeWorkflows.length > 0) {
      return this.forgeWorkflows.reduce((acc, workflow) => {
        acc.push(...workflow.workflow.blocks);
        return acc;
      }, []);
    } else if (get(this.workflow, 'blocks')) {
      return this.workflow.blocks;
    } else {
      return [];
    }
  }

  @action
  setCurrentBlock = (block) => {
    if (this.currentStep > 0 && !block) {
      this.currentBlock = '';
    } else {
      this.currentBlock = block;
    }

    if (block && this.recipeWorkflows.length > 0) {
      const selectedWorkflow = this.recipeWorkflowBlocks.find(
        (wf) => wf.blockIds.indexOf(block?.id) > -1
      );

      if (!selectedWorkflow) {
        return;
      }

      this.setRecipeWorkflow(
        selectedWorkflow.postId,
        selectedWorkflow.workflow
      );

      const recipe = this.recipes.find(
        (recipe) => recipe._id === selectedWorkflow.postId
      );

      this.setRecipe(recipe);
    }
  };

  getRequiredFields = (id) => {
    return this.getBlockRequiredFields(id, this.lightningDataFields);
  };

  getBlockValue = (blockPath) => {
    return get(this.blockValues, blockPath);
  };
}
