import { observable, action, runInAction, toJS, computed } from 'mobx';
import { showNotification } from 'Components/Notifications/Notification';
// import { unset, get, forIn, set, has } from 'lodash';
import { createRecipeDraft } from 'Services/marketplace';
import { NODE_TYPES, BLACKLISTED_TRIGGERS } from 'Constants';
import axios from 'axios';
import {
  unset,
  get,
  forIn,
  set,
  has,
  map,
  startCase,
  isObject,
  isArray,
  size,
  forEach,
  uniqBy,
  debounce,
  isBoolean,
  isString,
} from 'lodash';
import { parse } from 'url';
import { isWebhookBlock, getCredentialType } from 'Utilities';
import { getIntegration } from '../services/embeddedIntegrations';
import { arrayGroupBy, getOptionsFromBlock, uuid4 } from '../utils';
import { PARAM_TYPES } from '../constants';
import {
  getBlockTypeByName,
  getCsvS3PresignedUrl,
  getS3CSV,
  deleteS3CSV,
  copyS3CSV,
} from '../services/workflowApi';

export class EmbeddedInstallerStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  blockValues = {};

  @observable
  credentialsData = [];

  @observable
  currentStep = 0;

  @observable
  embeddedUploadProgress = 0;

  @observable
  leftLoading = false;

  @observable
  recipeLoading = true;

  @observable
  stepNum = 0;

  @observable
  operation = 'install';

  @observable
  forgeMode = false;

  @observable
  alwaysShowAuthentication = false;

  @observable
  activeBlockId = '';

  @observable
  workflow = {};

  @observable
  postId = '';

  @observable
  currentBlockId = '';

  @observable
  currentBlockType = {};

  @observable
  formData = {};

  @observable
  blocks = [];

  @observable
  fixedCollections = {};

  @observable
  fixedCollectionKeys = [];

  @observable
  preview = {};

  @observable
  isLoading = false;

  @observable
  blocksWithCred = [];

  @observable
  lightningDataFields = [];

  @observable
  currentBlockLightningDataFields = [];

  @observable
  versionId = null;

  @observable
  addNewType = '';

  @observable
  blockParameters = {};

  @observable
  blockCredTypes = {};

  @observable
  dummyValueChecker = {};

  @observable
  draftId = '';

  @observable
  draftIds = [];

  @observable
  draftCsvData = {};

  @observable
  tempBlockTree = [];

  @observable
  showVariableSelector = false;

  @observable
  parameter = null;

  @observable
  editor = null;

  @observable
  isForgeError = false;

  @observable
  forgeWorkflows = [];

  @observable
  forgeCurrentStep = 0;

  @observable
  forgeLightningFields = [];

  @observable
  currentWorkflowId = null;

  @observable
  selectedWorkflow = {};

  @observable
  forgeCurrentBlockId = null;

  @observable
  recipeInstaller = false;

  @observable
  multipleParamKeys = {};

  @observable
  isPreview = false;

  @observable
  hideOutput = [];

  @observable
  collectionId = null;

  @observable
  postIds = [];

  @observable
  workflowSettings = null;

  @observable
  currentBlock = '';

  @observable
  workflowIdsCreated = [];

  @observable
  isFirstPageDone = false;

  @observable
  integrationData = {};

  @observable
  workflowBlocks = {};

  @observable
  workflowFields = {};

  @observable
  fixedCollectionFields = {};

  ACCOUNT_SELECTOR_FIELD_KEY = '__account_selector_field_key__';

  // need to set this variable in case of a forge workflow update
  @observable
  isForgeUpdate = false;

  @observable
  onSuccessPage = false;

  @observable
  workflowsToHide = [];

  @observable
  workflowSelection = false;

  @observable
  recipeInstaller = false;

  @observable
  recipes = [];

  @observable
  isEnterprise = false;

  @observable
  fetchEnterpriseTemplates = false;

  @observable
  selectedTemplateIds = [];

  @observable
  initialEnterpriseStep = false;

  @observable
  selectedIntegrationId = '';

  @observable
  showEnterpriseIntro = true;

  @observable
  outputOverride = [];

  @observable
  preFilledValues = [];

  @action
  resetWorkflowIdsCreated = () => {
    this.workflowIdsCreated = [];
  };

  @action
  initialize = async (
    forgeMode,
    alwaysShowAuthentication,
    allowInstantWorkflowCreation,
    workflowSelection,
    fromEnterprise
  ) => {
    this.credentialsData = [];
    this.currentStep = 0;
    this.lightningDataFields = [];
    this.preview = {};
    this.formData = {};
    this.blockValues = {};
    this.blockParameters = {};
    this.forgeMode = forgeMode;
    this.alwaysShowAuthentication = alwaysShowAuthentication;
    this.allowInstantWorkflowCreation = allowInstantWorkflowCreation;
    this.recipeLoading = true;
    this.currentBlock = '';
    this.workflowBlocks = {};
    this.workflowSelection = workflowSelection;
    this.fixedCollectionFields = {};
    this.isEnterprise = fromEnterprise;
    this.outputOverride = [];
    this.preFilledValues = [];
  };

  @action
  setOutputOverride = (data) => {
    this.outputOverride = data;
  };

  @action
  setPreFilledValues = (preFilledValues) => {
    this.preFilledValues = preFilledValues;
    preFilledValues.forEach((val) => {
      const { connectorId, workflowId, data } = val;
      if (!this.formData[workflowId]) {
        this.formData[workflowId] = {};
      }
      if (data && data.length) {
        data.forEach((field) => {
          this.formData[workflowId][`${connectorId}::${field.fieldName}`] =
            field.value;
          // -----------------------------------------------------------------------------------------------
          // set preview data only if its a string, TODO: For other types, need to handle preview gracefully
          // -----------------------------------------------------------------------------------------------
          if (typeof field.value === 'string') {
            this.preview[workflowId][`${connectorId}::${field.fieldName}`] = [
              {
                children: [
                  {
                    text: field.value,
                  },
                ],
                type: 'main',
              },
            ];
          }
        });
      }
    });
  };

  updateBlockTypeData = async (blockType) => {
    return getBlockTypeByName(blockType.name).then((res) => {
      Object.assign(blockType, res.data[0]);
    });
  };

  // -------------------------- CSV  --------------------------------------------
  @action
  setUploadProgress = (i) => {
    this.embeddedUploadProgress = i;
  };

  @action
  getCsvS3PresignedUrl = async (filename, filetype, blockId, draftId) => {
    const response = await getCsvS3PresignedUrl({
      filename,
      filetype,
      blockId,
      workflowId: null,
      draftId: draftId || this.draftId,
    });

    return response;
  };

  getCsvData = async (filename, filetype, blockId, draftId) => {
    const response = await getS3CSV({
      filename,
      filetype,
      blockId,
      workflowId: null,
      draftId: draftId || this.draftId,
    });

    return response;
  };

  copyCSVFile = async (filename, blockId, draftId, workflowId) => {
    const response = await copyS3CSV({
      filename,
      blockId,
      workflowId,
      draftId,
    });

    return response;
  };

  deleteCsvFile = async (filename, blockId, draftId) => {
    const response = await deleteS3CSV({
      filename,
      blockId,
      workflowId: null,
      draftId: draftId || this.draftId,
    });

    return response;
  };

  @action
  putFileCsvS3 = async (signedRequest, file) => {
    let csvUploaded = false;
    const options = {
      headers: {
        'Content-Type': 'text/csv',
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (percent === 100) {
          setTimeout(() => this.setUploadProgress(100), 1000);
        }
        this.setUploadProgress(percent);
      },
    };
    await axios
      .put(
        signedRequest,
        file.data && isString(file.data) ? file.data : file,
        options
      )
      .then(() => {
        csvUploaded = true;
      })
      .catch(() => {
        csvUploaded = false;
      });

    return csvUploaded;
  };

  // --------------------------------------------

  @action
  setupForgeWorkflows = async (workflows) => {
    this.recipes = workflows;
    this.forgeWorkflows = workflows;
    this.forgeCurrentStep = 0;
    let allRecipesHasCreds = true;
    let hasLightningData = true;

    //hack to get oauth popup to close
    this.recipe = workflows[0];
    let workflowBlockTypes = new Set([]);
    await Promise.all(
      workflows.map(async (recipe) => {
        if (recipe.workflow.blocks) {
          await Promise.all(
            recipe.workflow.blocks.map((block) => {
              const currentBlockType =
                this.globalStore.blockTypeStore.blockTypes.find((blockType) => {
                  return blockType.name === block.type;
                });
              if (
                currentBlockType?.name &&
                !currentBlockType?.properties?.length &&
                !workflowBlockTypes.has(block.type)
              ) {
                workflowBlockTypes.add(block.type);
                return this.updateBlockTypeData(currentBlockType);
              }
            })
          );
        }
        if (recipe.credentials) {
          forEach(recipe.credentials, (cred) => {
            this.credentialsData.push({
              credentialType: cred.type,
              data: cred._id,
            });
            this.credentialsData = uniqBy(this.credentialsData, 'data');
            this.globalStore.credentialStore.credentialsByType[cred.type] = [
              cred,
            ];
          });
        }
        if (!recipe.hasAllCreds) {
          allRecipesHasCreds = false;
        }

        if (recipe?.lightningData?.length) {
          hasLightningData = false;
        }
      })
    );

    if (
      this.allowInstantWorkflowCreation &&
      !this.alwaysShowAuthentication &&
      allRecipesHasCreds &&
      hasLightningData
    ) {
      this.handleForgeFinish();
      return;
    } else if (allRecipesHasCreds && !this.alwaysShowAuthentication) {
      this.forgeCurrentStep = 1;
    }
    window.parent.postMessage({ showModal: true }, '*');

    this.recipeLoading = false;
    this.getUniqueForgeCredentialBlocks();
    this.setupForgeLightningBlocks();
    this.setForgeWorkflowBlocks();
    this.setForgeBlocksData();

    const workflowsWithRequiredFields = this.forgeWorkflows.filter(
      (workflow) => workflow.lightningData.length > 0
    );
    const numberOfWorkflowsWithRequiredFields =
      (workflowsWithRequiredFields && workflowsWithRequiredFields?.length) || 0;
    if (this.operation === 'edit' || this.operation === 'update') {
      this.setInitialFormData();
    }

    if (numberOfWorkflowsWithRequiredFields === 1 && !this.isEnterprise) {
      this.currentWorkflowId =
        workflowsWithRequiredFields[0].forgeWorkflowId ||
        workflowsWithRequiredFields[0]._id;
      this.setSelectedWorkflow(
        this.currentWorkflowId,
        workflowsWithRequiredFields[0]
      );
    }
  };

  @action
  setInitialFormData = () => {
    this.forgeWorkflows.forEach((workflow) => {
      const formData = {};
      workflow?.formData?.forEach((row) => (formData[row.key] = row.value));
      this.formData[workflow.forgeWorkflowId] = formData || {};
      this.preview[workflow.forgeWorkflowId] = workflow?.previewData || {};
    });
  };

  @action
  setOperation = (operation) => {
    this.operation = operation;
  };

  @action
  setupForgeLightningBlocks = () => {
    const forgeLightningFields = [];
    const { workflowsToHide } = this;
    let workflowsToShow = this.forgeWorkflows;

    if (workflowsToHide.length) {
      workflowsToShow = this.forgeWorkflows.filter((workflow) => {
        return !workflowsToHide.includes(workflow._id);
      });
    }

    workflowsToShow.forEach((workflow) => {
      const workflowId = workflow.forgeWorkflowId || workflow._id;
      this.workflowFields[workflowId] = [];
      let lightningDataFields =
        (workflow.lightningData && workflow.lightningData.length === 0) ||
        (workflow.lightningData &&
          workflow.lightningData[0] &&
          workflow.lightningData[0].hasOwnProperty('fieldKey'))
          ? workflow.lightningData
          : workflow.lightningData[0].children;

      lightningDataFields = lightningDataFields.filter((tg) => {
        return !(
          tg.fieldName === 'alloy.if' && tg.fieldValue === 'conditionSet'
        );
      });
      forgeLightningFields.push(...lightningDataFields);
      this.workflowFields[workflowId] = lightningDataFields;
    });

    const workflowsWithRequiredFields = workflowsToShow?.filter(
      (workflow) => workflow.lightningData.length > 0
    );

    const numberOfWorkflowsWithRequiredFields =
      (workflowsWithRequiredFields && workflowsWithRequiredFields?.length) || 0;

    if (numberOfWorkflowsWithRequiredFields === 1) {
      this.currentWorkflowId = workflowsWithRequiredFields[0].workflow._id;
      this.setSelectedWorkflow(
        this.currentWorkflowId,
        workflowsWithRequiredFields[0]
      );
    }
    this.forgeLightningFields = forgeLightningFields;
    this.lightningDataFields = forgeLightningFields;
    if (forgeLightningFields.length === 0 && this.forgeCurrentStep === 1) {
      this.forgeCurrentStep = 0;
    }
  };

  @action
  getUniqueForgeCredentialBlocks = () => {
    try {
      const { globalStore } = this;
      const uniqueBlockTypesArrayWithCreds = [];
      const blockTypes = [];

      const allBlocks = [];
      if (this.isEnterprise) {
        const currentWorkflowsToUse = this.forgeWorkflows.filter((workflow) => {
          return !this.workflowsToHide.includes(workflow._id);
        });
        currentWorkflowsToUse.forEach((template) => {
          if (template.workflow.blocks) {
            template.workflow.blocks.forEach((block) => {
              allBlocks.push(block);
            });
          }
        });
      } else {
        this.forgeWorkflows.forEach((template) => {
          if (template.workflow.blocks) {
            template.workflow.blocks.forEach((block) => {
              allBlocks.push(block);
            });
          }
        });
      }

      const withCreds = allBlocks.filter((block) => {
        return this.blockTypeHasCredentials(block);
      });

      let withCredFormattedData = [];
      try {
        withCredFormattedData = JSON.parse(JSON.stringify(withCreds));
      } catch {
        withCredFormattedData = [];
      }

      withCreds.forEach((block) => {
        const blockType = globalStore.blockTypeStore.findByName(block.type);
        const credentialType = getCredentialType(
          blockType?.credentials,
          block?.parameters
        );

        if (
          credentialType &&
          !uniqueBlockTypesArrayWithCreds.includes(credentialType)
        ) {
          uniqueBlockTypesArrayWithCreds.push(credentialType);
          if (blockType) {
            blockTypes.push(blockType);
          }
        }
      });

      this.blockCredTypes = uniqueBlockTypesArrayWithCreds;
      this.blocks = blockTypes.filter((bk) => bk !== undefined);
    } catch (error) {}
  };

  @action
  setSelectedWorkflow(currentWorkflowId, selectedWorkflow) {
    this.currentWorkflowId = currentWorkflowId;
    this.selectedWorkflow = selectedWorkflow;
    if (this.formData[currentWorkflowId]) {
      this.blockValues = this.formData[currentWorkflowId];
    } else {
      this.formData[currentWorkflowId] = {};
      this.blockValues = {};
    }
    this.workflow = selectedWorkflow?.workflow;
    this.tags = selectedWorkflow?.tags;
    this.postId = selectedWorkflow?._id;
    this.recipeTitle = selectedWorkflow?.title;
    this.recipeAuthor = selectedWorkflow?.user
      ? selectedWorkflow.user.fullName
      : '';
    this.recipeCreator = selectedWorkflow?.creator
      ? selectedWorkflow?.creator
      : '';
  }

  @action
  setForgeError = (error) => {
    this.isForgeError = error;
  };

  @action
  setTempTree = (tree) => {
    this.tempBlockTree = tree;
  };

  @action
  setAddNewType = (type) => {
    this.addNewType = type;
  };

  @action
  setDraftCsvData = (data) => {
    this.draftCsvData = data;
  };

  @action
  setShowVariableSelector = (
    show,
    parameter,
    editor,
    blockId,
    selectedList
  ) => {
    this.showVariableSelector = show;
    this.parameter = parameter;
    this.editor = editor;
    this.forgeCurrentBlockId = blockId;

    this.getData(parameter?.type, selectedList, parameter);
  };

  @action
  setFixedCollections = (collection) => {
    this.fixedCollections = collection;
  };

  @action
  setBlockValues = (workflowId) => {
    this.leftLoading = true;
    if (workflowId) {
      this.blockValues = this.formData[workflowId];
    }
    this.allBlocks.forEach((block) => {
      const newBlock = {
        credentials: {},
        parameters: {},
        preview: {},
      };
      const creds = this.credentialsData.find((cred) => cred.id === block.id);
      const keys = Object.keys(this.formData);
      if (keys && keys.length) {
        keys.forEach((key) => {
          if (key.indexOf(this.currentBlockId) > -1) {
            newBlock.parameters[key] =
              this.formData[this.currentWorkflowId][key];
          }
        });
      }
      if (creds) {
        newBlock.credentials[creds.credentialType] = creds.data;
      }
      this.blockValues[block.id] = newBlock;
    });
    this.leftLoading = false;
  };

  @action
  handleForgeStepchange = (step, fromNavigaton) => {
    this.forgeCurrentStep = step;
    if (!fromNavigaton) {
      if (step > 0) {
        this.setFirstPageDone(true);
      }
      if (step === 0) {
        this.setFirstPageDone(false);
      }
    }
  };

  findPreviewText = (path) => {
    const previewValue = get(this.preview, [this.currentWorkflowId, path]);
    return previewValue || null;
  };

  @action
  setBlocksData = () => {
    this.leftLoading = true;
    this.workflow.blocks.forEach((block) => {
      const currentBlockLightningDataFields =
        this.lightningDataFields && this.lightningDataFields.length
          ? this.lightningDataFields.filter(
              (tg) => tg.fieldBlockId === block.id
            )
          : [];
      const currentBlockType = this.globalStore.blockTypeStore.blockTypes.find(
        (blk) => blk.name === block.type
      );
      this.currentBlockType = currentBlockType;
      if (
        ['alloy.if', 'alloy.branch', 'alloy.math', 'alloy.text'].includes(
          block.type
        )
      ) {
        this.blockParameters[block.id] = currentBlockLightningDataFields.map(
          (field) => {
            return {
              displayName: field.fieldDisplayName,
              name: field.fieldValue,
              type: field.fieldType,
              default: '',
              required: field.fieldRequired,
              description: field.fieldDescription,
              ...field,
            };
          }
        );
      } else {
        this.blockParameters[block.id] = this.currentBlockType?.properties
          ?.filter((prp) => {
            const field = currentBlockLightningDataFields.find(
              (fld) =>
                fld.fieldValue === prp.name &&
                (!prp.displayOptions ||
                  (prp.displayOptions &&
                    prp.displayOptions.show &&
                    prp.displayOptions.show.operation &&
                    ((!block.parameters.resource &&
                      prp.displayOptions.show.operation.includes(
                        block.parameters.operation
                      )) ||
                      (prp.displayOptions.show.resource &&
                        prp.displayOptions.show.resource.includes(
                          block.parameters.resource
                        ) &&
                        prp.displayOptions.show.operation.includes(
                          block.parameters.operation
                        )))) ||
                  (!prp.displayOptions.show.operation &&
                    prp.displayOptions.show.resource &&
                    prp.displayOptions.show.resource.includes(
                      block.parameters.resource
                    )))
            );
            if (field) {
              prp.required = field.fieldRequired;
              prp.optional = field.optional;
              return prp;
            } else {
              return null;
            }
          })
          .filter((field) => !!field);
      }
      const blockParams = this.blockParameters[block.id];
      if (blockParams?.length) {
        const filteredParams = blockParams.map((param) => {
          let { options } = param;
          if (param.type === 'fixedCollection') {
            const collection = currentBlockLightningDataFields.find(
              (fld) => fld.name === param.name
            );
            if (collection) {
              options = param.options.filter((prp) => {
                const field = collection.childrenBlocks.find((fld) => {
                  return (
                    fld.name === prp.name && fld.required && fld.fieldRequired
                  );
                });

                if (field) {
                  return true;
                } else {
                  return false;
                }
              });
            }
          }
          if (options?.length) {
            // if there are fields selected form the fixed collection then show them, otherwise show all of the options
            return { ...param, options };
          } else {
            return param;
          }
        });

        this.blockParameters[block.id] = filteredParams;
      }
    });
    this.leftLoading = false;
  };

  @action
  nextBlock = () => {
    const currentBlockIndex = this.workflow.blocks.indexOf(
      (block) => block.id === this.currentBlockId
    );
    if (currentBlockIndex === this.workflow.blocks.length) {
      this.incrementStep();
    } else {
      this.setCurrentBlockId(this.workflow.blocks[currentBlockIndex + 1].id);
    }
  };

  @action
  setCurrentBlockId = (id) => {
    this.currentBlockId = id;
  };

  @action
  incrementForgeStep = () => {
    this.leftLoading = false;
    this.forgeCurrentStep += 1;
  };

  @action
  decrementForgeStep = () => {
    this.leftLoading = false;
    this.currentBlock = '';
    if (this.forgeCurrentStep > 0) {
      this.forgeCurrentStep -= 1;
    }
  };

  isComplete = (id) => {
    const block = this.newPost.blocks.find((bk) => bk.id === id);
    const type = this.globalStore.blockTypeStore.findByName(block.type);
    const credType = get(type, 'credentials[0].name');
    const hasCred =
      this.blocksWithCred.indexOf(id) === -1 ||
      (credType &&
        this.credentialsData.find((cred) => cred.credentialType === credType) &&
        this.blocksWithCred.indexOf(id) > -1);

    if (
      !hasCred ||
      !this.recipe ||
      !this.recipe.lightningData ||
      !this.recipe.lightningData.length
    )
      return false;

    const lightningDataChildren = this.recipe.lightningData[0].hasOwnProperty(
      'fieldKey'
    )
      ? this.recipe.lightningData
      : this.recipe.lightningData[0].children;

    const currentBlockFields = lightningDataChildren.length
      ? lightningDataChildren.filter((ld) => ld.fieldBlockId === id)
      : [];
    if (!currentBlockFields) return false;

    const keys = Object.keys(this.formData);

    const emptyField = currentBlockFields.find((field) => {
      const wasFilledIn = keys.find((key) => key.indexOf(field.fieldKey) > -1);
      return !wasFilledIn;
    });
    return !emptyField;
  };

  @action
  addFixedCollectionField = (newField, path) => {
    if (!this.fixedCollectionFields?.[this.currentWorkflowId]) {
      this.fixedCollectionFields[this.currentWorkflowId] = {};
      this.fixedCollectionFields[this.currentWorkflowId][path] = [];
      this.fixedCollectionFields?.[this.currentWorkflowId]?.[path]?.push(
        newField
      );
    } else {
      this.fixedCollectionFields?.[this.currentWorkflowId]?.[path]?.push(
        newField
      );
    }
  };
  @action
  handleFormDataChanges = (key, value, isMultipleParamField) => {
    // This Try, Catch, Finally is to make sure the array's specific index shared to be input given by user works properly.
    let setStatus = true;
    try {
      set(this.dummyValueChecker, key, value);
    } catch {
      setStatus = false;
      this.formData[this.currentWorkflowId][key] = value;
      this.blockValues[key] = value;
    } finally {
      if (setStatus) {
        set(this.formData, [this.currentWorkflowId, key], value);
        set(this.blockValues, key, value);
      }
    }
    if (isMultipleParamField) {
      set(this.multipleParamKeys, [this.currentWorkflowId, key], value);
    }
  };

  @action
  handleFormDataDelete = (key, index) => {
    const collection = get(this.formData, [this.currentWorkflowId, key]);
    collection.splice(index, 1);
    set(this.formData, [this.currentWorkflowId, key], collection);
    set(this.blockValues, key, collection);
  };

  @action
  handlePreviewChanges = (key, value) => {
    set(this.preview, [this.currentWorkflowId, key], value);
  };

  @action
  deleteFixedParam = (key, index, values, size, type) => {
    const fieldToRemove = this.fixedCollectionFields?.[
      this.currentWorkflowId
    ]?.[key].splice(index, 1);
    const addedFields = this.fixedCollectionFields?.[this.currentWorkflowId]?.[
      key
    ].filter((field) =>
      Object.values(field).some((currField) =>
        get(currField, 'isRemovable', true)
      )
    );
    if (fieldToRemove) {
      this.fixedCollectionFields[this.currentWorkflowId][key] =
        this.fixedCollectionFields[this.currentWorkflowId][key].filter(
          (field) =>
            Object.values(field).some(
              (currField) => !get(currField, 'isRemovable', true)
            )
        );
      const valuesFieldsToRemove = Object.values(fieldToRemove[0]);
      valuesFieldsToRemove.forEach((currValue) => {
        unset(this.formData, [this.currentWorkflowId, currValue?.fieldKey]);
        unset(this.preview, [this.currentWorkflowId, currValue?.fieldKey]);
      });
    }

    if (type === 'multiple') {
      const itemKey = key.split('[')[0];
      const unsetFixedParam = unset(this.blockValues, [
        this.currentWorkflowId,
        itemKey,
        index,
      ]);
      if (unsetFixedParam) {
        unset(this.formData[this.currentWorkflowId], key);
        unset(this.blockValues, `${itemKey}.${index}`);

        if (get(this.preview, `${this.currentWorkflowId}.${key}`)) {
          unset(this.preview[this.currentWorkflowId], key);
        }
        if (get(this.multipleParamKeys, `${this.currentWorkflowId}.${key}`)) {
          unset(this.multipleParamKeys[this.currentWorkflowId], key);
        }

        this.blockValues[itemKey] = this.blockValues[itemKey].filter(
          (val) => !!val
        );

        return;
      }
    } else {
      const unsetFixedParam = unset(this.blockValues, [key, index]);
      if (unsetFixedParam) {
        unset(this.formData, [this.currentWorkflowId, key, index]);
        this.formData[this.currentWorkflowId][key] = this.formData[
          this.currentWorkflowId
        ][key].filter((val) => !!val);
        this.blockValues[key] = this.blockValues[key]?.filter((val) => !!val);
      }
    }

    if (
      fieldToRemove &&
      this.fixedCollectionFields?.[this.currentWorkflowId]?.[key]
    ) {
      addedFields.forEach((value, index, currentFields) => {
        let newFixedField = {};
        values.forEach((valKey) => {
          const newKey = `${key}[${size + index}].${valKey}`;
          const oldKey = `${value[valKey]?.fieldKey}`;
          const formDataValue = get(this.formData, [
            this.currentWorkflowId,
            oldKey,
          ]);
          const previewDataValue = get(this.preview, [
            this.currentWorkflowId,
            oldKey,
          ]);
          unset(this.formData[this.currentWorkflowId], [oldKey]);
          unset(this.blockValues, [oldKey]);
          unset(this.preview, [this.currentWorkflowId, oldKey]);
          set(this.formData[this.currentWorkflowId], [newKey], formDataValue);
          set(this.blockValues, [newKey], formDataValue);
          set(this.preview, [this.currentWorkflowId, newKey], previewDataValue);
          newFixedField[valKey] = {
            ...value[valKey],
            fieldKey: `${key}[${size + index}].${valKey}`,
            fieldBlockKey: `${key}[${size + index}]`,
            uuid: uuid4(),
          };
        });
        currentFields[index] = newFixedField;
      });

      this.fixedCollectionFields[this.currentWorkflowId][key] = [
        ...this.fixedCollectionFields[this.currentWorkflowId][key],
        ...addedFields,
      ];
    } else {
      this.blockValues[key].forEach((value, index) => {
        const newKey = `${key}[${index}]`;
        const valueKeys = Object.keys(value);
        valueKeys.forEach((valKey) => {
          if (valKey === 'uuid') return;
          set(
            this.formData,
            [this.currentWorkflowId, `${newKey}.${valKey}`],
            value[valKey]
          );
          set(
            this.preview,
            [this.currentWorkflowId, `${newKey}.${valKey}`],
            value[valKey]
          );
        });
      });
    }
  };

  @action
  filterCredBlocks = () => {
    const { globalStore } = this;

    this.workflow.blocks.forEach((block) => {
      const blockObject = globalStore.blockTypeStore.findByName(block.type);
      if (get(blockObject, 'credentials[0].name')) {
        this.blocksWithCred.push(block.id);

        if (this.isForgeUpdate) {
          const credentialType = get(blockObject, 'credentials[0].name');
          const data = block.credentials[credentialType];
          if (
            !this.credentialsData.find(
              (cred) => cred.credentialType === credentialType
            )
          ) {
            this.credentialsData.push({
              credentialType,
              data,
            });
          }
        }
      }
    });
  };

  getSetupType = () => {
    const currentPath = this.globalStore.routingStore.location.pathname;
    if (currentPath.indexOf('template-setup') > -1) {
      return 'import';
    } else {
      return 'import';
    }
  };

  @action
  removeSelectedAccount = (accountType) => {
    const filteredData = this.credentialsData.filter(
      (cred) => cred.credentialType !== accountType
    );
    this.credentialsData = filteredData;
  };

  @action
  setDraftId = (postId, id) => {
    this.draftIds.push({ postId, id });
    this.draftId = id;
  };

  checkCreds = async () => {
    if (this.credentialsData.length >= this.blocks.length) {
      this.currentBlock = '';
      this.incrementStep();
      if (!this.recipeInstaller && !this.isEnterprise) {
        try {
          // TODO: we should'nt do this for all recipes, but for the one that has the csv block
          const parentId = this?.recipe?._id;
          const { draftId } = await createRecipeDraft({ parentId });
          this.setDraftId(this.currentWorkflowId, draftId);
        } catch (e) {}
      }
    } else {
      showNotification('error', 'Please connect all the accounts first!');
    }
  };

  createDraftId = async (parentId) => {
    const existingDraftId = this.draftIds.find(
      (draft) => draft.postId === parentId
    );
    if (!existingDraftId && !this.isEnterprise) {
      try {
        const { draftId } = await createRecipeDraft({ parentId });
        this.setDraftId(parentId, draftId);
      } catch (e) {}
    }
  };

  checkForgeCreds = async () => {
    if (this.credentialsData.length >= this.blocks.length) {
      this.incrementForgeStep();
    } else {
      showNotification('error', 'Please connect all the accounts first!');
    }
  };

  checkForgeCredsAndSave = async () => {
    if (this.credentialsData.length >= this.blocks.length) {
      return true;
    } else {
      showNotification('error', 'Please connect all the accounts first!');
      return false;
    }
  };

  @action
  handleCredentialChanges = (credentialType, data) => {
    const { credentialsData } = this;
    let inData = false;
    let index = -1;
    for (let i = 0; i < credentialsData.length; i++) {
      if (credentialsData[i].credentialType === credentialType) {
        inData = true;
        index = i;
      }
    }

    if (inData) {
      credentialsData[index] = {
        credentialType,
        data: data.selectedCredential._id,
      };
    } else {
      credentialsData.push({
        credentialType,
        data: data.selectedCredential._id,
      });
    }

    this.credentialsData = credentialsData;
    this.currentBlock = '';
  };

  blockTypeHasCredentials = (block) => {
    const { globalStore } = this;
    const blockObject = globalStore.blockTypeStore.findByName(block.type);
    if (
      !blockObject ||
      !blockObject.hasOwnProperty('credentials') ||
      !blockObject.credentials ||
      block.forgeInternalUse ||
      block.type === 'alloy.httpRequest' //can be removed, should be marked as internal use
    ) {
      return false;
    }

    return true;
  };

  closeForgeModal = (success) => {
    const { globalStore, forgeMode, isEnterprise } = this;
    const {
      location: { search },
    } = globalStore.routingStore;
    const { isFromPreview } = parse(search, true).query;
    if (isFromPreview) {
      window.parent.postMessage({ closeModal: true }, '*');
      return;
    } else if (forgeMode && !isEnterprise) {
      if (
        success === true ||
        (this.workflowIdsCreated &&
          Array.isArray(this.workflowIdsCreated) &&
          this.workflowIdsCreated?.length > 0)
      ) {
        window.parent.postMessage(
          {
            workflowCreated: true,
            workflowId: JSON.stringify(this.workflowIdsCreated),
          },
          '*'
        );
        this.resetWorkflowIdsCreated();
      } else {
        window.parent.postMessage({ closeModal: true }, '*');
        this.resetWorkflowIdsCreated();
        localStorage.clear();
      }
    } else if (isEnterprise) {
      window.close();
    }
  };

  handleForgeFinish = debounce(
    action(async (fromEnterprise, props = {}) => {
      const { globalStore, forgeMode } = this;
      const {
        location: { search },
      } = globalStore.routingStore;
      const { isFromPreview, showSuccessPage } = parse(search, true).query;
      let isPreviewJSON = false;
      let isSuccessPageJSON = false;
      try {
        isPreviewJSON = !!JSON.parse(isFromPreview);
      } catch {
        isPreviewJSON = false;
      }
      try {
        isSuccessPageJSON = !!JSON.parse(showSuccessPage);
      } catch {
        isSuccessPageJSON = false;
      }
      if (isFromPreview && isPreviewJSON) {
        if (showSuccessPage && !isSuccessPageJSON) {
          window.parent.postMessage({ closeModal: true }, '*');
          return;
        } else {
          this.isLoading = false;
          return;
        }
      }

      const { done = null, isEmbeddedLinkModal = false, token = '' } = props;
      const forgeWorkflowsIds = this.forgeWorkflows
        .map((workflow) => workflow._id)
        .filter((i) => {
          return !this.workflowsToHide.includes(i);
        });
      this.isLoading = true;
      const { credentialsData, postId, preview, fixedCollectionKeys } = this;

      this.showPlanValidationMessage = false;

      await globalStore.workflowStore.validateWorkflow();

      const planHasErrors = globalStore.workflowStore.planValidationErrors;

      if (!planHasErrors) {
        const { formData } = this;

        const filteredFormData = {};
        Object.assign(filteredFormData, formData);

        forIn(formData, (value, key) => {
          if (has(formData, `countryCode::${key}`)) {
            const phoneInputValue = get(formData, key);

            const phoneValue = phoneInputValue
              .replace('-', '')
              .replace('(', '')
              .replace(')', '')
              .replace(' ', '');
            const countryCode = get(formData, `countryCode::${key}`);
            set(filteredFormData, key, `${countryCode}${phoneValue}`);
            unset(filteredFormData, `countryCode::${key}`);
          }

          const multipleParamField = get(this.multipleParamKeys, [
            this.currentWorkflowId,
            key,
          ]);
          if (multipleParamField) {
            const cleanedValue = value.map((param) => param.value);

            set(filteredFormData, key, cleanedValue);
          }
        });
        globalStore.marketplaceStore
          .saveForgeWorkflow(
            postId,
            credentialsData,
            filteredFormData,
            preview,
            fixedCollectionKeys,
            forgeMode,
            this.draftIds,
            forgeWorkflowsIds,
            isEmbeddedLinkModal,
            token,
            this.versionId
          )
          .then(
            action(async (res) => {
              if (res.success) {
                globalStore.marketplaceStore.templateImported = true;
                this.useWorkflowBtnDisabled = false;
                this.incrementForgeStep();
                this.isLoading = false;
                globalStore.blockEditStore.blockValues = null;
                if (fromEnterprise) {
                  return;
                }
                globalStore.blockTypeStore.initialize(forgeMode);
                const { parentIds } = globalStore.marketplaceStore;

                const workflowIds = [];

                parentIds.forEach((parentId) => {
                  const workflow = res.newWorkflows.find(
                    (w) => w.forgeWorkflowId === parentId
                  );
                  if (workflow) {
                    workflowIds.push(workflow._id);
                  }
                });

                if (!fromEnterprise && props.showSuccessPage) {
                  this.workflowIdsCreated = workflowIds;
                } else if (!fromEnterprise) {
                  window.parent.postMessage(
                    {
                      workflowCreated: true,
                      workflowId: workflowIds,
                    },
                    '*'
                  );
                  localStorage.clear();
                }
                if (fromEnterprise) {
                  window.close();
                }
                if (done && typeof done === 'function') {
                  done();
                }
              } else {
                this.isLoading = false;
                if (this.forgeMode) {
                  this.setForgeError(true);
                } else {
                  showNotification('error', 'Something went wrong!');
                }
              }
            })
          )
          .catch((e) => {
            this.isLoading = false;
            if (this.forgeMode) {
              this.setForgeError(true);
            } else {
              showNotification('error', 'Something went wrong!');
            }
          });
      } else {
        this.isLoading = false;
        this.showPlanValidationMessage = true;
      }
    }),
    1000,
    { leading: true, trailing: false }
  );

  isTriggerBlacklisted = () => {
    return BLACKLISTED_TRIGGERS.includes(this.workflow.blocks[0].type);
  };

  getParentBlocks = (selectedList, parameter) => {
    const { workflow, currentBlockId } = this;

    const hideTrigger = this.isTriggerBlacklisted();

    let parentBlocks = workflow.blocks.filter(
      (block) =>
        (block.id !== currentBlockId ||
          (hideTrigger && BLACKLISTED_TRIGGERS.includes(block.type))) &&
        this.hideOutput.indexOf(block.id) === -1
    );

    const variableSources = parameter?.variableSources;

    if (variableSources?.length) {
      parentBlocks = parentBlocks.filter((block) => {
        const source = variableSources.find(
          (variableSource) => variableSource === block.id
        );
        return source;
      });
    }

    if (selectedList) {
      const blockId = selectedList.substring(
        selectedList.indexOf('$block["') + 8,
        selectedList.indexOf('"]')
      );
      const parentBlock = parentBlocks.find((block) => block.id === blockId);
      return [parentBlock];
    }
    return parentBlocks;
  };

  getOutputOverride = (block) => {
    const { currentWorkflowId, outputOverride } = this;
    if (!outputOverride || !outputOverride.length) return null;
    const override = outputOverride.find(
      (ovr) =>
        ovr.connectorId === block.id && ovr.workflowId === currentWorkflowId
    );
    if (!override) return null;
    return override.data;
  };

  getBlockTree = async (block) => {
    const { globalStore } = this;

    if (isWebhookBlock(block.type)) {
      const bodyParameters =
        block.parameters &&
        block.parameters.bodyParameters &&
        block.parameters.bodyParameters.length
          ? block.parameters.bodyParameters
          : [];
      const bodyOrQuery =
        block.parameters.httpMethod === 'GET' ? 'query' : 'body';
      const outputData = {
        name: 'Output',
        key: `$block["${block.id}"].outputData`,
        options: bodyParameters.map((p) => {
          const key = `$block["${block.id}"].data["${bodyOrQuery}"]["${p.bodyParameter}"]`;
          const value = p.exampleValue || '---UNKNOWN---';

          return {
            name: p.bodyParameter,
            key,
            value,
          };
        }),
        dataType: 'object',
        id: 'output_' + block.id,
      };
      const restOfParams = Object.assign({}, block.parameters);
      delete restOfParams.bodyParameters;
      const parameters = {
        name: 'Input',
        key: `$block["${block.id}"].parameters`,
        options: map(restOfParams, (value, name) => {
          const key = `$block["${block.id}"].parameter["${name}"]`;
          return {
            name,
            key,
            value,
          };
        }),
        dataType: 'object',
        id: 'input_' + block.id,
      };

      return {
        name: block.name,
        key: block.id,
        outputData:
          block.sampleLiveData &&
          block.sampleLiveData.body &&
          Object.keys(block.sampleLiveData.body).length
            ? {
                [`${block.id}`]: {
                  data: [[{ id: block.id, json: block.sampleLiveData.body }]],
                },
              }
            : null,
        options: [outputData, parameters],
        icon: block.icon,
        title: block.subtitle,
        showIcon: true,
        type: block.type,
      };
    } else if (block.type === 'alloy.uploadCSV') {
      let outputData = this.draftCsvData;

      if (!outputData) {
        outputData = null;
      }
      const parameters = {
        name: 'Input',
        key: 'parameters',
        options: map(block.parameters, (value, name) => {
          const key = `$block["${block.id}"].parameter["${name}"]`;

          return {
            name,
            key,
            value,
            id: 'option_' + key,
          };
        }),
        dataType: 'object',
        id: 'input_' + block.id,
      };

      return {
        name: block.name,
        key: block.id,
        outputData,
        inputData: parameters,
        icon: block.icon,
        title: block.subtitle,
        showIcon: true,
        type: block.type,
        realOutputData: outputData, // because output data gets formatted in the next step
      };
    } else {
      let outputData;
      // look for executions of similar blocks on other workflows or predefined templates
      try {
        if (block.type === NODE_TYPES.FOR) {
          const tempTree = this.tempBlockTree;

          const response =
            await globalStore.blockTypeStore.getRecipeBlockOutput(
              block,
              tempTree
            );

          if (response.data) {
            outputData = Object.assign({}, response.data);
          }
        } else {
          const outputOverride = this.getOutputOverride(block);
          if (outputOverride) {
            outputData = {
              [`${block.id}`]: {
                data: [[{ id: block.id, json: outputOverride }]],
              },
            };
          } else {
            const response = await globalStore.blockTypeStore.getBlockOutput(
              block,
              true
            );
            if (response.data) {
              outputData = Object.assign({}, response.data);
            }
          }
        }
      } catch (e) {
        if (block.type === NODE_TYPES.FOR) {
          outputData = {
            name: 'Output',
            key: 'outputData',
            options: [],
          };
        }
      }

      // give up and leave output blank
      if (!outputData) {
        outputData = null;
      }
      const parameters = {
        name: 'Input',
        key: 'parameters',
        options: map(block.parameters, (value, name) => {
          const key = `$block["${block.id}"].parameter["${name}"]`;

          return {
            name,
            key,
            value,
            id: 'option_' + key,
          };
        }),
        dataType: 'object',
        id: 'input_' + block.id,
      };

      return {
        name: block.name,
        key: block.id,
        outputData,
        inputData: parameters,
        icon: block.icon,
        title: block.subtitle,
        showIcon: true,
        type: block.type,
      };
    }
  };

  getNewDisplayName = (fieldMapping, field, parentName) => {
    let displayName = get(fieldMapping, field, null);

    if (displayName) return displayName;

    displayName = startCase(field.replace('_', ' '));
    // AS OF NOW COMMENTED THIS CODE AS IT'S NOT CLEAR USE OF parentName.
    // return parentName ? `${parentName} / ${displayName}` : displayName;
    return displayName;
  };

  formatVariables = (
    output,
    blockId,
    fieldMapping,
    keyPrefix,
    icon,
    parentName,
    paramType
  ) => {
    let variables = [];
    // const paramType = this.props.parameter.type;

    if (isObject(output) && !isArray(output)) {
      for (let prop in output) {
        if (isObject(output[prop]) && !isArray(output[prop])) {
          const children = this.formatVariables(
            output[prop],
            blockId,
            fieldMapping,
            `${keyPrefix}["${prop}"]`,
            icon,
            this.getNewDisplayName(fieldMapping, prop, parentName),
            paramType
          );
          variables = [...variables, ...children];
        } else if (isArray(output[prop])) {
          if (paramType === 'array') {
            variables.push({
              type: 'single',
              name: prop,
              displayName: this.getNewDisplayName(
                fieldMapping,
                prop,
                parentName
              ),
              icon,
              value: '<List>',
              key: `${keyPrefix}["${prop}"]`,
            });
          } else {
            const children = [];
            for (let i = 0; i < output[prop].length; i++) {
              if (isObject(output[prop][i]) || isArray(output[prop][i])) {
                children.push({
                  type: 'group',
                  name: `${prop}-${i + 1}`,
                  displayName: `${this.getNewDisplayName(
                    fieldMapping,
                    prop,
                    parentName
                  )} ${i + 1}`,
                  icon,
                  children: this.formatVariables(
                    output[prop][i],
                    blockId,
                    fieldMapping,
                    `${keyPrefix}["${prop}"][${i}]`,
                    icon,
                    this.getNewDisplayName(fieldMapping, prop, parentName),
                    paramType
                  ),
                });
              } else {
                children.push({
                  type: 'single',
                  name: `${prop}-${i + 1}`,
                  displayName: `${this.getNewDisplayName(
                    fieldMapping,
                    prop,
                    parentName
                  )} ${i + 1}`,
                  icon,
                  value: output[prop][i],
                  key: `${keyPrefix}[${i}]`,
                });
              }
            }
            variables.push({
              type: 'group',
              name: prop,
              displayName: this.getNewDisplayName(
                fieldMapping,
                prop,
                parentName
              ),
              children,
            });
          }
        } else if (paramType !== 'array') {
          variables.push({
            type: 'single',
            name: prop,
            displayName: this.getNewDisplayName(fieldMapping, prop, parentName),
            icon,
            value: output[prop],
            key: `${keyPrefix}["${prop}"]`,
          });
        }
      }
    }

    return variables;
  };

  @action
  getData = (paramType, selectedList, parameter) => {
    const { globalStore } = this;

    let parentBlocks = this.getParentBlocks(selectedList);
    const { userStore } = this.globalStore;
    const { forgeAccount } = userStore;
    const customEventBlock = parentBlocks.find(
      (block) => block.type === 'alloy.customEvent'
    );

    if (customEventBlock) {
      parentBlocks = parentBlocks.map((block) => {
        if (block.type === 'alloy.customEvent') {
          return {
            ...block,
            name: forgeAccount.brandName || customEventBlock?.name,
            icon: forgeAccount?.icon || customEventBlock?.icon,
          };
        }
        return block;
      });
    }

    let rearrangedCleanedData = [];
    const forLoopBlocks = [];
    if (parentBlocks.length) {
      // prepare stuff here
      parentBlocks.forEach((block) => {
        if (block.type === NODE_TYPES.FOR) {
          forLoopBlocks.push(block);
        }
      });

      Promise.all(parentBlocks.map((block) => this.getBlockTree(block))).then(
        (data) => {
          // this.arrayKeysAdded.clear();
          const filteredData = data.filter((block) => {
            return (
              block.key &&
              get(block, `outputData.${block.key}.data[0][0].json`, null)
            );
          });

          const cleanedData = [];
          filteredData.forEach((block) => {
            const blockType = globalStore.blockTypeStore.findByName(block.type);

            let fieldMapping =
              blockType && blockType.fieldMapping ? blockType.fieldMapping : '';

            // If Loop block, get field mapping of that block
            if (block.type === NODE_TYPES.FOR) {
              forLoopBlocks.forEach((block) => {
                if (block.id === block.key) {
                  if (block.parameters.collectionField) {
                    const collectionFieldBlockId =
                      block.parameters.collectionField.substring(
                        block.parameters.collectionField.indexOf('$block["') +
                          8,
                        block.parameters.collectionField.indexOf('"]')
                      );
                    parentBlocks.forEach((parentBlock) => {
                      if (parentBlock.id === collectionFieldBlockId) {
                        const parentBlockType =
                          globalStore.blockTypeStore.findByName(
                            parentBlock.type
                          );
                        fieldMapping =
                          parentBlockType && parentBlockType.fieldMapping
                            ? parentBlockType.fieldMapping
                            : '';
                      }
                    });
                  }
                }
              });
            }
            const actualData = get(
              block,
              `outputData.${block.key}.data[0][0].json`,
              null
            );

            if (actualData && size(actualData)) {
              const formattedData = this.formatVariables(
                actualData,
                block.key,
                fieldMapping,
                `$block["${block.key}"].data`,
                block.icon,
                paramType
              );
              block.outputData = formattedData;
              cleanedData.push(block);
            }
          });
          // rearrange array

          for (const blockData of cleanedData) {
            if (blockData.type === 'alloy.for') {
              rearrangedCleanedData.unshift(blockData);
            } else {
              rearrangedCleanedData.push(blockData);
            }
          }

          runInAction(() => {
            this.tempBlockTree = rearrangedCleanedData;
          });

          return rearrangedCleanedData;
        }
      );
    }
    return rearrangedCleanedData;
  };

  getBlockRequiredFields = (blockId, lightningData, workflowId) => {
    const useLightningData = lightningData || this.lightningDataFields;
    const filteredLightningData = useLightningData.filter((tg) => {
      return !(tg.fieldName === 'alloy.if' && tg.fieldValue === 'conditionSet');
    });
    const workflowIdToUse = this.currentWorkflowId || workflowId;
    const previewKeys = Object.keys(get(this.preview, workflowIdToUse) || {});

    const blockFields = filteredLightningData.filter((field) => {
      return field.fieldBlockId === blockId;
    });

    const filteredBlockParams = blockFields.filter((param) => {
      if (param.type === 'fixedCollection' && param?.isRequired !== 'no') {
        // find if any of the blockfields have same fieldKey as param.fieldKey
        const isFieldKeyPresent = blockFields.find((blockField) => {
          return (
            blockField.fieldKey.includes(param.fieldKey) &&
            blockField.fieldKey !== param.fieldKey
          );
        });
        if (!isFieldKeyPresent) {
          return true;
        } else {
          return false;
        }
      } else if (param?.isRequired !== 'no') {
        return true;
      } else {
        return false;
      }
    });

    if (filteredBlockParams?.length) {
      const filteredFields = previewKeys.filter((key) => {
        const booleanParameter = filteredBlockParams.find((blockParam) => {
          if (blockParam?.fieldType === PARAM_TYPES.BOOLEAN) {
            return blockParam?.fieldKey === key;
          }
          return false;
        });

        if (booleanParameter) {
          return isBoolean(get(this.formData, [workflowIdToUse, key]));
        }
        return !!get(this.formData, [workflowIdToUse, key]);
      });

      const dataMap = new Map();
      const map = new Map();
      for (const item of filteredFields) {
        const id = item.split('::')[0];

        if (id === blockId) {
          const block = this.blockParameters[blockId]?.[0];
          const fixedCollectionField = item.split('[')?.[0];
          const fixedCollectionBlock = filteredBlockParams.find(
            (param) => param.fieldKey === fixedCollectionField
          );
          if (
            block &&
            block?.type === 'string' &&
            block?.typeOptions &&
            block?.typeOptions?.multipleValues
          ) {
            if (!dataMap.has(blockId)) {
              dataMap.set(blockId, true);
            }
          } else if (
            block?.type === 'fixedCollection' &&
            fixedCollectionBlock?.childrenBlocks?.length
          ) {
            if (!map.has(item)) {
              map.set(item, true);
            }
            const childIndex = item.split('[')[1].split(']')[0];

            const numberOfFixedCollectionRequiredFields =
              fixedCollectionBlock.childrenBlocks.filter((b) => {
                const key = `${b.blockId}::${b.fieldKey.replace(
                  '::',
                  `[${childIndex}].`
                )}`;
                // fixed collection fields use the params defined for them in the collection itself, so it's not clear which ones are required
                return !!b.fieldRequired && !map.has(key);
              });

            if (!numberOfFixedCollectionRequiredFields.length) {
              dataMap.set(fixedCollectionBlock.fieldKey, true);
            }
          } else if (block?.type === 'fixedCollection') {
            const numOfOptions = block.options?.length;
            // all field has been filled
            if (numOfOptions === filteredFields.length) {
              filteredBlockParams.forEach((field) => {
                dataMap.set(field.fieldKey, true);
              });
            } else {
              dataMap.set(item, true);
            }
          } else if (!dataMap.has(item)) {
            dataMap.set(item, true);
          }
        }
      }

      const returnVal = filteredBlockParams.length - dataMap.size;
      if (returnVal < 0) {
        return 0;
      } else {
        return returnVal;
      }
    } else {
      return 0;
    }
  };

  @action
  setForgeWorkflowBlocks = () => {
    this.forgeWorkflows.forEach((template) => {
      if (template?.forgeWorkflowId === template.workflow._id) {
        this.workflowBlocks[template.workflow._id] = {};
      } else {
        this.workflowBlocks[template._id] = {};
      }
    });
  };

  checkShowOptions = (showData, parameters) => {
    const keys = Object.keys(showData);
    return (
      keys &&
      keys.length &&
      keys.reduce(
        (acc, curr) => acc && showData[curr].indexOf(parameters[curr]) > -1,
        true
      )
    );
  };

  checkIfDisplay = (property, block) => {
    if (['alloy.httpRequest'].indexOf(block.type) > -1) {
      // FOR BLOCKS THAT DOES NOT HAVE RESOURCE OR OPERATION PARAMS
      return (
        !property.displayOptions ||
        (property.displayOptions &&
          property.displayOptions.show &&
          this.checkShowOptions(property.displayOptions.show, block.parameters))
      );
    } else {
      // This is unchanged , just a clean way than before
      return (
        !property.displayOptions ||
        (property.displayOptions &&
          property.displayOptions.show &&
          property.displayOptions.show.operation &&
          ((!block.parameters.resource &&
            property.displayOptions.show.operation.includes(
              block.parameters.operation
            )) ||
            (property.displayOptions.show.resource &&
              property.displayOptions.show.resource.includes(
                block.parameters.resource
              ) &&
              property.displayOptions.show.operation.includes(
                block.parameters.operation
              )))) ||
        (property.displayOptions &&
          property.displayOptions.show &&
          property.displayOptions.show.action &&
          property.displayOptions.show.action.includes(block.parameters.action))
      );
    }
  };

  @action
  setForgeBlocksData = () => {
    this.leftLoading = true;
    this.forgeWorkflows.forEach((template) => {
      const workflowId =
        template.workflow.forgeWorkflowId || template.workflow._id;
      template.workflow.blocks.forEach((block) => {
        const currentBlockLightningDataFields =
          this.workflowFields[workflowId] &&
          this.workflowFields[workflowId].length
            ? this.workflowFields[workflowId].filter(
                (tg) => tg.fieldBlockId === block.id
              )
            : [];
        const currentBlockType =
          this.globalStore.blockTypeStore.blockTypes.find(
            (blk) => blk.name === block.type
          );

        this.currentBlockType = currentBlockType;
        this.workflowBlocks[workflowId][block.id] =
          currentBlockLightningDataFields.map((field) => {
            const blockData = {
              displayName: field.fieldDisplayName,
              name: field.fieldValue,
              type: field.fieldType,
              fieldKey: field.fieldKey,
              default: '',
              required: field.fieldRequired,
              description: field.fieldDescription,
              forgeFieldDataFormat: field?.forgeFieldDataFormat,
              fieldPlaceholder: field?.fieldPlaceholder,
              forgeFieldDescription: field?.forgeFieldDescription,
              isRequired: field?.isRequired,
              showHelpText: field?.showHelpText,
              forgeFieldDisplayName: field?.forgeFieldDisplayName,
              helpText: field?.helpText,
              showInfoIcon: field?.showInfoIcon,
              infoIconText: field?.infoIconText,

              variableSources: field?.variableSources,
              defaultValue: 'some test value',
            };
            const operationProperties = getOptionsFromBlock(
              this.currentBlockType,
              field
            );
            return operationProperties
              ? { ...blockData, ...operationProperties }
              : blockData;
          });
        let fixedCollectionSubFields = [];
        if (
          !(
            block.parameters?.resource === 'custom' &&
            !['createCustom', 'customGraphqlQuery'].includes(
              block.parameters.operation
            )
          )
        ) {
          this.workflowBlocks[workflowId][block.id] =
            this.currentBlockType?.properties
              ?.filter((prp) => {
                const field = currentBlockLightningDataFields.filter((fld) => {
                  let propName = '';
                  let fieldKeyArr = [];
                  try {
                    fieldKeyArr = fld.fieldKey.split('.');
                    const propertiesArr = fieldKeyArr[0].split('::');
                    propName = propertiesArr[1].substring(
                      0,
                      propertiesArr[1].indexOf('[')
                    );
                  } catch (e) {}
                  if (
                    propName === prp.name &&
                    prp.type === 'fixedCollection' &&
                    fieldKeyArr.length >= 2 &&
                    this.checkIfDisplay(prp, block)
                  ) {
                    // 1 level of fixedCollection's field
                    const { options } = prp;
                    if (fieldKeyArr.length === 2) {
                      const stockField = options.find(
                        (opt) => opt.name === fieldKeyArr[1]
                      );
                      if (stockField) {
                        const fieldBlockKey = fld.fieldKey
                          .slice(38)
                          .split(`.${fld.fieldValue}`)[0];

                        const data = {
                          displayName: fld.fieldDisplayName,
                          name: fld.fieldValue,
                          type: fld.fieldType,
                          fieldKey: fld.fieldKey,
                          fieldBlockKey,
                          default: '',
                          required: fld.fieldRequired,
                          description: fld.fieldDescription,
                          forgeFieldDataFormat: fld?.forgeFieldDataFormat,
                          fieldPlaceholder: fld?.fieldPlaceholder,
                          forgeFieldDescription: fld?.forgeFieldDescription,
                          isRequired: fld?.isRequired,
                          showHelpText: fld?.showHelpText,
                          forgeFieldDisplayName: fld?.forgeFieldDisplayName,
                          helpText: fld?.helpText,
                          showInfoIcon: fld?.showInfoIcon,
                          infoIconText: fld?.infoIconText,

                          variableSources: fld?.variableSources,
                          defaultValue: 'some test value',
                          ...stockField,
                        };
                        fixedCollectionSubFields.push(data);
                      }
                      return false;
                    } else {
                      const fieldBlockKey = fld.fieldKey
                        .slice(38)
                        .split(`.${fld.fieldValue}`)[0];
                      if (
                        fld.fieldType === 'options' &&
                        block.type === 'alloy.if'
                      ) {
                        const data = {
                          displayName: fld.fieldDisplayName,
                          name: fld.fieldValue,
                          type: fld.fieldType,
                          fieldKey: fld.fieldKey,
                          fieldBlockKey,
                          default: '',
                          required: fld.fieldRequired,
                          description: fld.fieldDescription,
                          forgeFieldDataFormat: fld?.forgeFieldDataFormat,
                          fieldPlaceholder: fld?.fieldPlaceholder,
                          forgeFieldDescription: fld?.forgeFieldDescription,
                          isRequired: fld?.isRequired,
                          showHelpText: fld?.showHelpText,
                          forgeFieldDisplayName: fld?.forgeFieldDisplayName,
                          helpText: fld?.helpText,
                          showInfoIcon: fld?.showInfoIcon,
                          infoIconText: fld?.infoIconText,

                          variableSources: fld?.variableSources,
                          defaultValue: 'some test value',
                        };

                        const properties = this.currentBlockType?.properties;
                        const condition = get(
                          properties,
                          '0.options.0.options'
                        );
                        const operationProperties =
                          condition &&
                          condition.length &&
                          condition.find((con) => con.name === 'operation');
                        const returnData = { ...data, ...operationProperties };
                        fixedCollectionSubFields.push(returnData);
                      } else {
                        const data = {
                          displayName: fld.fieldDisplayName,
                          name: fld.fieldValue,
                          type: fld.fieldType,
                          fieldKey: fld.fieldKey,
                          fieldBlockKey,
                          default: '',
                          required: fld.fieldRequired,
                          description: fld.fieldDescription,
                          forgeFieldDataFormat: fld?.forgeFieldDataFormat,
                          fieldPlaceholder: fld?.fieldPlaceholder,
                          forgeFieldDescription: fld?.forgeFieldDescription,
                          isRequired: fld?.isRequired,
                          showHelpText: fld?.showHelpText,
                          forgeFieldDisplayName: fld?.forgeFieldDisplayName,
                          helpText: fld?.helpText,
                          showInfoIcon: fld?.showInfoIcon,
                          infoIconText: fld?.infoIconText,

                          variableSources: fld?.variableSources,
                          defaultValue: 'some test value',
                        };
                        fixedCollectionSubFields.push(data);
                      }
                      return false;
                    }
                  }
                  return (
                    (fld.fieldValue === prp.name || propName === prp.name) &&
                    this.checkIfDisplay(prp, block)
                  );
                });
                if (field?.length) {
                  prp.required = field[0]?.fieldRequired;
                  prp.displayName = field[0]?.fieldDisplayName;
                  prp.name = field[0]?.fieldValue;
                  prp.type = field[0]?.fieldType;
                  prp.fieldKey = field[0]?.fieldKey;
                  prp.default = '';
                  prp.description = field[0]?.fieldDescription;
                  prp.forgeFieldDataFormat = field[0]?.forgeFieldDataFormat;
                  prp.fieldPlaceholder = field[0]?.fieldPlaceholder;
                  prp.forgeFieldDescription = field[0]?.forgeFieldDescription;
                  prp.isRequired = field[0]?.isRequired;
                  prp.showHelpText = field[0]?.showHelpText;
                  prp.forgeFieldDisplayName = field[0]?.forgeFieldDisplayName;
                  prp.helpText = field[0]?.helpText;
                  prp.showInfoIcon = field[0]?.showInfoIcon;
                  prp.infoIconText = field[0]?.infoIconText;

                  prp.variableSources = field[0]?.variableSources;
                  return prp;
                } else {
                  return null;
                }
              })
              .filter((field) => !!field);

          if (fixedCollectionSubFields?.length) {
            const groupedFixedCollectionSubFields = arrayGroupBy(
              fixedCollectionSubFields,
              'fieldBlockKey'
            );
            const groupedFixedCollectionSubFieldsKeys = Object.keys(
              groupedFixedCollectionSubFields
            ).sort();
            if (groupedFixedCollectionSubFieldsKeys?.length) {
              groupedFixedCollectionSubFieldsKeys.forEach(
                (groupedFieldKey, index) => {
                  groupedFixedCollectionSubFields[groupedFieldKey].forEach(
                    (field) => {
                      const splitKey = field?.fieldBlockKey.split('[')[0];
                      const keyWithoutIndex = `${block.id}::${splitKey}`;
                      const workflowBlockFieldExist = this.workflowBlocks[
                        workflowId
                      ][block.id].find((workflowField) => {
                        return (
                          workflowField?.fieldKey === `${block.id}::${splitKey}`
                        );
                      });

                      if (workflowBlockFieldExist) {
                        if (this.formData[workflowId]) {
                          const currentParamFieldKey =
                            field?.fieldKey.split(']')[1];
                          set(
                            this.formData[workflowId],
                            `${keyWithoutIndex}[${index}]${currentParamFieldKey}`,
                            ''
                          );
                          set(
                            this.fixedCollectionFields[workflowId],
                            `${keyWithoutIndex}[${index}]${currentParamFieldKey}`,
                            { ...field, isRemovable: false }
                          );
                        } else {
                          set(this.formData, workflowId, {});
                          if (!this.fixedCollectionFields[workflowId]) {
                            set(this.fixedCollectionFields, workflowId, {});
                          }
                          set(this.formData[workflowId], field.fieldKey, '');
                          set(
                            this.fixedCollectionFields[workflowId],
                            field.fieldKey,
                            { ...field, isRemovable: false }
                          );
                        }
                        fixedCollectionSubFields =
                          fixedCollectionSubFields.filter((fixedField) => {
                            return fixedField?.fieldKey !== field?.fieldKey;
                          });
                      }
                    }
                  );
                }
              );
            }
          }
        }
        if (this.workflowBlocks?.[workflowId][block.id]) {
          const blockParams = [
            ...this.workflowBlocks[workflowId][block.id],
            ...fixedCollectionSubFields?.filter((field) => !!field),
          ];
          if (blockParams?.length) {
            this.blockParameters[block.id] = blockParams;
            this.workflowBlocks[workflowId][block.id] = JSON.parse(
              JSON.stringify(blockParams)
            );
          }
        }
      });
    });
    this.leftLoading = false;
  };

  @computed
  get recipeWorkflowBlocks() {
    return this.recipes.map((recipe) => {
      return {
        postId: recipe._id,
        workflow: recipe.workflow,
        blockIds: recipe.workflow.blocks.reduce((acc, block) => {
          acc.push(block.id);
          return acc;
        }, []),
      };
    });
  }

  @computed
  get allBlocks() {
    if (this.forgeWorkflows.length > 0) {
      return this.forgeWorkflows.reduce((acc, workflow) => {
        acc.push(...workflow?.workflow?.blocks);
        return acc;
      }, []);
    } else if (get(this.workflow, 'blocks')) {
      return this.workflow.blocks;
    } else {
      return [];
    }
  }

  @computed
  get allForgeBlocks() {
    if (this.forgeWorkflows.length > 0) {
      return this.forgeWorkflows.reduce((acc, workflow) => {
        acc.push(...workflow.workflow.blocks);
        return acc;
      }, []);
    } else if (get(this.workflow, 'blocks')) {
      return this.workflow.blocks;
    } else {
      return [];
    }
  }

  @action
  setCurrentBlock = (block) => {
    this.currentBlock = block;
  };

  getBlockValue = (blockPath) => {
    return get(this.blockValues, blockPath);
  };

  @action
  setFirstPageDone = (flag) => {
    this.isFirstPageDone = flag;
  };

  @action
  setInstallationVersion = (versionId) => {
    this.versionId = versionId;
  };

  @action
  setEmbeddedRecipe = async (
    recipes,
    title,
    fromEnterprise,
    recipeIntegration
  ) => {
    const { blockTypeStore } = this.globalStore;
    this.isEnterprise = fromEnterprise;
    let currentIntegration;
    if (recipes?.length > 0) {
      let integrationId;
      if (fromEnterprise) {
        currentIntegration = recipeIntegration;
      } else {
        integrationId = recipes[0]?.workflow?.integrationId;
      }
      const data = integrationId && (await getIntegration(integrationId));
      const integration = currentIntegration || data?.integration;

      if (integration && Object.keys(integration).length > 0) {
        const a = await blockTypeStore.findByName(integration.blockId);
        const showFirstPage =
          integration?.description ||
          integration?.allowWorkflowSelection ||
          this.workflowSelection;

        if (integration?.allowWorkflowSelection && fromEnterprise) {
          this.workflowSelection = true;
        }

        this.setFirstPageDone(!showFirstPage);
        this.integrationData = {
          integration,
          name: title || integration?.name,
          description: integration?.description,
          icon: a?.icon,
          showSuccessPage: integration?.showSuccessPage,
          allowWorkflowSelection:
            integration?.allowWorkflowSelection || this.workflowSelection,
          showThreeSteps: !!(
            integration?.allowWorkflowSelection ||
            integration?.description ||
            this.workflowSelection
          ),
        };
      } else {
        const integrationName = recipes[0]?.workflow?.blocks?.[0]?.name || '';
        const name =
          integrationName.replace(/trigger/gi, '').trim() + ' Integration';
        this.integrationData = {
          ...this.integrationData,
          name: title || name,
          allowWorkflowSelection: this.workflowSelection,
          showThreeSteps: !!this.workflowSelection,
        };
        this.setFirstPageDone(true);
      }
    } else {
      this.integrationData = {
        ...this.integrationData,
        name: title,
      };
      this.setFirstPageDone(true);
    }
    await this.setupForgeWorkflows(recipes);
  };

  @action
  setOnSuccess = () => {
    this.onSuccessPage = true;
  };

  getWorkflowsRequiredFields = (blockId, lightningData) => {
    let requiredFields = 0;
    this.forgeWorkflows.map((template) => {
      const filteredLightningData = lightningData.filter((tg) => {
        return !(
          tg.fieldName === 'alloy.if' && tg.fieldValue === 'conditionSet'
        );
      });
      const workflowId = template.workflow._id;
      const previewKeys = Object.keys(get(this.preview, workflowId) || {});

      const blockFields = filteredLightningData.filter((field) => {
        return field.fieldBlockId === blockId;
      });

      const filteredBlockParams = blockFields.filter((param) => {
        if (param.type === 'fixedCollection') {
          // find if any of the blockfields have same fieldKey as param.fieldKey
          const isFieldKeyPresent = blockFields.find((blockField) => {
            return (
              blockField.fieldKey.includes(param.fieldKey) &&
              blockField.fieldKey !== param.fieldKey
            );
          });
          return !isFieldKeyPresent;
        } else {
          return true;
        }
      });

      if (filteredBlockParams?.length) {
        const filteredFields = previewKeys.filter(
          (key) => !!get(this.formData, [workflowId, key])
        );
        const dataMap = new Map();
        const map = new Map();
        for (const item of filteredFields) {
          const id = item.split('::')[0];

          if (id === blockId) {
            const block = this.blockParameters[blockId]?.[0];
            const fixedCollectionField = item.split('[')?.[0];
            const fixedCollectionBlock = filteredBlockParams.find(
              (param) => param.fieldKey === fixedCollectionField
            );
            if (
              block &&
              block?.type === 'string' &&
              block?.typeOptions &&
              block?.typeOptions?.multipleValues
            ) {
              if (!dataMap.has(blockId)) {
                dataMap.set(blockId, true);
              }
            } else if (
              block?.type === 'fixedCollection' &&
              fixedCollectionBlock?.childrenBlocks?.length
            ) {
              if (!map.has(item)) {
                map.set(item, true);
              }
              const childIndex = item.split('[')[1].split(']')[0];

              const numberOfFixedCollectionRequiredFields =
                fixedCollectionBlock.childrenBlocks.filter((b) => {
                  const key = `${b.blockId}::${b.fieldKey.replace(
                    '::',
                    `[${childIndex}].`
                  )}`;
                  // fixed collection fields use the params defined for them in the collection itself, so it's not clear which ones are required
                  return !!b.fieldRequired && !map.has(key);
                });

              if (!numberOfFixedCollectionRequiredFields.length) {
                dataMap.set(fixedCollectionBlock.fieldKey, true);
              }
            } else if (block?.type === 'fixedCollection') {
              const numOfOptions = block.options?.length;
              // all field has been filled
              if (numOfOptions === filteredFields.length) {
                filteredBlockParams.forEach((field) => {
                  dataMap.set(field.fieldKey, true);
                });
              } else {
                dataMap.set(item, true);
              }
            } else if (!dataMap.has(item)) {
              dataMap.set(item, true);
            }
          }
        }

        requiredFields += filteredBlockParams.length - dataMap.size;
      }
    });

    return requiredFields;
  };

  @action
  toggleInstallerVisibleWorkflow = (visible, workflowId) => {
    if (visible) {
      this.workflowsToHide = this.workflowsToHide.filter(
        (id) => id !== workflowId
      );
    } else {
      const allWorkflows = this.forgeWorkflows.length;
      if (this.workflowsToHide.length === allWorkflows - 1) {
        showNotification('error', 'At least one workflow must be selected.');

        return;
      }
      this.workflowsToHide.push(workflowId);
    }
    this.setupForgeLightningBlocks();
    this.isEnterprise && this.getUniqueForgeCredentialBlocks();
  };

  getInstallerVisibleWorkflow = (workflowId) => {
    return !this.workflowsToHide.includes(workflowId);
  };

  @action
  getCollectionUniqueBlocks = () => {
    const { globalStore } = this;
    const blockTypesArray = [];
    const uniqueBlockTypesArrayWithCreds = [];
    const blockTypes = [];

    const withCreds = this.allBlocks.filter((block) => {
      return this.blockTypeHasCredentials(block);
    });

    withCreds.forEach((block) => {
      if (!blockTypesArray.includes(block.type)) {
        blockTypesArray.push(block.type);
      }
    });

    blockTypesArray.forEach((block, index) => {
      const isTriggerBlock = block.indexOf('Trigger');
      if (isTriggerBlock > -1) {
        const subString = block.substring(0, isTriggerBlock);
        const count = blockTypesArray.filter(
          (type) => type.indexOf(subString) !== -1
        );
        if (count.length > 1) {
          delete blockTypesArray[index];
        }
      }
    });

    for (const blockTypeIndex in blockTypesArray) {
      const blockObject = globalStore.blockTypeStore.findByName(
        blockTypesArray[blockTypeIndex]
      );
      const credType = get(blockObject, 'credentials.0.name', null);
      if (credType && !uniqueBlockTypesArrayWithCreds.includes(credType)) {
        uniqueBlockTypesArrayWithCreds.push(credType);
        if (blockObject) {
          blockTypes.push(blockObject);
        }
      }
    }

    this.blockCredTypes = uniqueBlockTypesArrayWithCreds;
    this.blocks = blockTypes;
  };

  ///// Added logic for Enterprise Recipes /////
  @action
  setRecipeCollection = async (recipes) => {
    this.recipes = recipes;

    let workflowBlockTypes = new Set([]);
    await Promise.all(
      recipes.map(async (recipe) => {
        if (recipe.workflow.blocks) {
          await Promise.all(
            recipe.workflow.blocks.map((block) => {
              const currentBlockType =
                this.globalStore.blockTypeStore.blockTypes.find((blockType) => {
                  return blockType.name === block.type;
                });
              if (
                currentBlockType?.name &&
                !currentBlockType?.properties?.length &&
                !workflowBlockTypes.has(block.type)
              ) {
                workflowBlockTypes.add(block.type);
                return this.updateBlockTypeData(currentBlockType);
              }
            })
          );
        }
        if (recipe.workflow) {
          this.recipeWorkflows.push(recipe.workflow);
          this.postIds.push(recipe._id);

          const lightningData =
            (recipe.lightningData && recipe.lightningData.length === 0) ||
            (recipe.lightningData &&
              recipe.lightningData[0] &&
              recipe.lightningData[0].hasOwnProperty('fieldKey'))
              ? recipe.lightningData
              : recipe.lightningData[0].children;

          this.lightningDataFields.push(...lightningData);
        }
        const { optionalInfo } = recipe;
        if (optionalInfo) {
          for (const key in optionalInfo) {
            if (optionalInfo[key].hideOutput) {
              this.hideOutput.push(key);
            }
          }
        }
      })
    );

    this.getCollectionUniqueBlocks();
  };

  @action
  setRecipeCollectionBlocksData = () => {
    this.leftLoading = true;
    this.recipeWorkflows.forEach((workflow) => {
      workflow.blocks.forEach((block) => {
        const currentBlockLightningDataFields =
          this.lightningDataFields && this.lightningDataFields.length
            ? this.lightningDataFields.filter((tg) => {
                return tg.fieldBlockId === block.id;
              })
            : [];

        const currentBlockType =
          this.globalStore.blockTypeStore.blockTypes.find(
            (blk) => blk.name === block.type
          );
        this.currentBlockType = currentBlockType;
        if (
          [
            'alloy.if',
            'alloy.branch',
            'alloy.math',
            'alloy.text',
            'alloy.errorHandler',
          ].includes(block.type)
        ) {
          this.blockParameters[block.id] = currentBlockLightningDataFields.map(
            (field) => {
              return {
                displayName: field.fieldDisplayName,
                name: field.fieldValue,
                type: field.fieldType,
                default: '',
                required: field.fieldRequired,
                description: field.fieldDescription,
                ...field,
              };
            }
          );
        } else {
          let fixedCollectionSubFields = [];
          this.blockParameters[block.id] = this.currentBlockType?.properties
            ?.filter((prp) => {
              const field = currentBlockLightningDataFields.filter((fld) => {
                let propName = '';
                let fieldKeyArr = [];
                try {
                  fieldKeyArr = fld.fieldKey.split('.');
                  const propertiesArr = fieldKeyArr[0].split('::');
                  propName = propertiesArr[1].substring(
                    0,
                    propertiesArr[1].indexOf('[')
                  );
                } catch (e) {
                  console.log(e);
                }
                if (
                  propName === prp.name &&
                  prp.type === 'fixedCollection' &&
                  fieldKeyArr.length >= 2 &&
                  this.checkIfDisplay(prp, block)
                ) {
                  // 1 level of fixedCollection's field
                  const { options } = prp;
                  if (fieldKeyArr?.length === 2) {
                    const stockField = options.find(
                      (opt) => opt.name === fieldKeyArr[1]
                    );
                    if (stockField) {
                      const fieldBlockKey = fld?.fieldKey
                        .slice(38)
                        .split(`.${fld?.fieldValue}`)[0];

                      const data = {
                        displayName: fld?.fieldDisplayName,
                        name: fld?.fieldValue,
                        type: fld?.fieldType,
                        fieldKey: fld?.fieldKey,
                        fieldBlockKey,
                        default: '',
                        required: fld?.fieldRequired,
                        description: fld?.fieldDescription,
                        forgeFieldDataFormat: fld?.forgeFieldDataFormat,
                        fieldPlaceholder: fld?.fieldPlaceholder,
                        forgeFieldDescription: fld?.forgeFieldDescription,
                        isRequired: fld?.isRequired,
                        showHelpText: fld?.showHelpText,
                        forgeFieldDisplayName: fld?.forgeFieldDisplayName,
                        helpText: fld?.helpText,
                        showInfoIcon: fld?.showInfoIcon,
                        infoIconText: fld?.infoIconText,

                        variableSources: fld?.variableSources,
                        defaultValue: 'some test value',
                        ...stockField,
                      };
                      fixedCollectionSubFields.push(data);
                    }
                    return false;
                  } else {
                    const fieldBlockKey = fld?.fieldKey
                      .slice(38)
                      .split(`.${fld?.fieldValue}`)[0];
                    if (
                      fld.fieldType === 'options' &&
                      block.type === 'alloy.if'
                    ) {
                      const data = {
                        displayName: fld?.fieldDisplayName,
                        name: fld?.fieldValue,
                        type: fld?.fieldType,
                        fieldKey: fld?.fieldKey,
                        fieldBlockKey,
                        default: '',
                        required: fld?.fieldRequired,
                        description: fld?.fieldDescription,
                        forgeFieldDataFormat: fld?.forgeFieldDataFormat,
                        fieldPlaceholder: fld?.fieldPlaceholder,
                        forgeFieldDescription: fld?.forgeFieldDescription,
                        isRequired: fld?.isRequired,
                        showHelpText: fld?.showHelpText,
                        forgeFieldDisplayName: fld?.forgeFieldDisplayName,
                        helpText: fld?.helpText,
                        showInfoIcon: fld?.showInfoIcon,
                        infoIconText: fld?.infoIconText,

                        variableSources: fld?.variableSources,
                        defaultValue: 'some test value',
                      };

                      const properties = this.currentBlockType?.properties;
                      const condition = get(properties, '0.options.0.options');
                      const operationProperties =
                        condition &&
                        condition.length &&
                        condition.find((con) => con.name === 'operation');
                      const returnData = { ...data, ...operationProperties };
                      fixedCollectionSubFields.push(returnData);
                    } else {
                      const data = {
                        displayName: fld.fieldDisplayName,
                        name: fld.fieldValue,
                        type: fld.fieldType,
                        fieldKey: fld.fieldKey,
                        fieldBlockKey,
                        default: '',
                        required: fld.fieldRequired,
                        description: fld.fieldDescription,
                        forgeFieldDataFormat: fld?.forgeFieldDataFormat,
                        fieldPlaceholder: fld?.fieldPlaceholder,
                        forgeFieldDescription: fld?.forgeFieldDescription,
                        isRequired: fld?.isRequired,
                        showHelpText: fld?.showHelpText,
                        forgeFieldDisplayName: fld?.forgeFieldDisplayName,
                        helpText: fld?.helpText,
                        showInfoIcon: fld?.showInfoIcon,
                        infoIconText: fld?.infoIconText,

                        variableSources: fld?.variableSources,
                        defaultValue: 'some test value',
                      };
                      fixedCollectionSubFields.push(data);
                    }
                    return false;
                  }
                }
                return (
                  (fld.fieldValue === prp.name || propName === prp.name) &&
                  this.checkIfDisplay(prp, block)
                );
              });
              if (field?.length) {
                prp.required = field[0]?.fieldRequired;
                prp.displayName = field[0]?.fieldDisplayName;
                prp.name = field[0]?.fieldValue;
                prp.type = field[0]?.fieldType;
                prp.fieldKey = field[0]?.fieldKey;
                prp.default = '';
                prp.description = field[0]?.fieldDescription;
                prp.forgeFieldDataFormat = field[0]?.forgeFieldDataFormat;
                prp.fieldPlaceholder = field[0]?.fieldPlaceholder;
                prp.forgeFieldDescription = field[0]?.forgeFieldDescription;
                prp.isRequired = field[0]?.isRequired;
                prp.showHelpText = field[0]?.showHelpText;
                prp.forgeFieldDisplayName = field[0]?.forgeFieldDisplayName;
                prp.helpText = field[0]?.helpText;
                prp.showInfoIcon = field[0]?.showInfoIcon;
                prp.infoIconText = field[0]?.infoIconText;

                prp.variableSources = field[0]?.variableSources;
                return prp;
              } else {
                return null;
              }
            })
            .filter((field) => !!field);

          if (fixedCollectionSubFields?.length) {
            const groupedFixedCollectionSubFields = arrayGroupBy(
              fixedCollectionSubFields,
              'fieldBlockKey'
            );
            const groupedFixedCollectionSubFieldsKeys = Object.keys(
              groupedFixedCollectionSubFields
            ).sort();
            if (groupedFixedCollectionSubFieldsKeys?.length) {
              groupedFixedCollectionSubFieldsKeys.forEach(
                (groupedFieldKey, index) => {
                  groupedFixedCollectionSubFields[groupedFieldKey].forEach(
                    (field) => {
                      const splitKey = field?.fieldBlockKey.split('[')[0];
                      const keyWithoutIndex = `${block.id}::${splitKey}`;
                      const workflowBlockFieldExist = this.blockParameters[
                        block.id
                      ].find((workflowField) => {
                        return (
                          workflowField?.fieldKey === `${block.id}::${splitKey}`
                        );
                      });

                      if (workflowBlockFieldExist) {
                        if (this.formData[workflowId]) {
                          const currentParamFieldKey =
                            field?.fieldKey.split(']')[1];
                          set(
                            this.formData[workflowId],
                            `${keyWithoutIndex}[${index}]${currentParamFieldKey}`,
                            ''
                          );
                          set(
                            this.fixedCollectionFields[workflowId],
                            `${keyWithoutIndex}[${index}]${currentParamFieldKey}`,
                            { ...field, isRemovable: false }
                          );
                        } else {
                          set(this.formData, workflowId, {});
                          if (!this.fixedCollectionFields[workflowId]) {
                            set(this.fixedCollectionFields, workflowId, {});
                          }
                          set(this.formData[workflowId], field.fieldKey, '');
                          set(
                            this.fixedCollectionFields[workflowId],
                            field.fieldKey,
                            { ...field, isRemovable: false }
                          );
                        }
                        fixedCollectionSubFields =
                          fixedCollectionSubFields.filter((fixedField) => {
                            return fixedField?.fieldKey !== field?.fieldKey;
                          });
                      }
                    }
                  );
                }
              );
            }
          }

          if (this.blockParameters?.[block.id]) {
            const blockParamsTest = [
              ...this.blockParameters[block.id],
              ...fixedCollectionSubFields?.filter((field) => !!field),
            ];
            if (blockParamsTest?.length) {
              this.blockParameters[block.id] = JSON.parse(
                JSON.stringify(blockParamsTest)
              );
            }
          }

          if (block.type === 'alloy.googleSheets') {
            return;
          }
        }
      });
    });
    this.leftLoading = false;
  };

  @action
  filterRecipeCollectionCredBlocks = () => {
    const { globalStore } = this;
    this.allBlocks.forEach((block) => {
      const blockObject = globalStore.blockTypeStore.findByName(block.type);
      if (get(blockObject, 'credentials[0].name')) {
        this.blocksWithCred.push(block.id);
      }
    });
  };

  @action
  setupCollectionInfo = async (collectionInfo) => {
    this.recipeTitle = collectionInfo?.title;
    this.recipeAuthor = collectionInfo?.user
      ? collectionInfo.user.fullName
      : '';
    this.recipeCreator = collectionInfo?.creator ? collectionInfo.creator : '';
  };

  @action
  setupRecipes = async (recipes, collectionId, integrationId) => {
    this.collectionId = collectionId;
    this.postIds = [];
    this.recipeWorkflows = [];
    this.hideOutput = [];
    this.lightningDataFields = [];
    this.recipeIntegrationId = integrationId;

    await this.setRecipeCollection(recipes);
    this.setRecipeCollectionBlocksData();
    this.filterRecipeCollectionCredBlocks();
    // need to pass the collection data here
    this.setupCollectionInfo();

    recipes.forEach((recipe) => {
      if (recipe.credentials) {
        forEach(recipe.credentials, (cred) => {
          this.credentialsData.push({
            credentialType: cred.type,
            data: cred._id,
          });
          this.globalStore.credentialStore.credentialsByType[cred.type] = [
            cred,
          ];
        });
      }
    });
    this.credentialsData = uniqBy(this.credentialsData, 'data');

    this.recipeLoading = false;
  };

  @action
  setRecipeInstaller = (bool) => {
    this.recipeInstaller = bool;
  };

  @action
  incrementStep = (stepLength, currentKey) => {
    if (stepLength <= currentKey + 1) {
      this.nextBlock();
    } else {
      this.setBlockValues();
      this.leftLoading = false;
      this.currentStep += 1;
      this.setFirstPageDone(true);
    }
  };

  @action
  decrementStep = () => {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
      this.currentBlock = '';
    }

    if (
      this.currentStep === 0 &&
      (this.integrationData?.allowWorkflowSelection ||
        this.integrationData?.integration?.isIntroPage) &&
      this.isFirstPageDone
    ) {
      this.setFirstPageDone(false);
    }
  };

  @action
  handleFinish = async (fromEnterprise) => {
    const { globalStore, forgeMode, workflowSettings } = this;
    const collectionTemplateIds = this.recipes
      .map((recipe) => !recipe?.workflow?.forgeId && recipe._id)
      .filter(Boolean);
    const forgeWorkflowIds = this.recipes
      .map((recipe) => recipe?.workflow?.forgeId && recipe._id)
      .filter(Boolean)
      .filter((workflowId) => !this.workflowsToHide.includes(workflowId));
    this.isLoading = true;
    const { credentialsData, postId, preview, fixedCollectionKeys } = this;
    const { push } = globalStore.routingStore;

    this.showPlanValidationMessage = false;

    await globalStore.workflowStore.validateWorkflow();

    const planHasErrors = globalStore.workflowStore.planValidationErrors;

    if (!planHasErrors) {
      setTimeout(() => {
        const { formData } = this;

        const filteredFormData = {};
        Object.assign(filteredFormData, formData);

        forIn(formData, (value, key) => {
          if (has(formData, `countryCode::${key}`)) {
            const phoneInputValue = get(formData, key);

            const phoneValue = phoneInputValue
              .replace('-', '')
              .replace('(', '')
              .replace(')', '')
              .replace(' ', '');
            const countryCode = get(formData, `countryCode::${key}`);
            set(filteredFormData, key, `${countryCode}${phoneValue}`);
            unset(filteredFormData, `countryCode::${key}`);
          }

          const multipleParamField = get(this.multipleParamKeys, key);
          if (multipleParamField) {
            const cleanedValue = value.map((param) => param.value);

            set(filteredFormData, key, cleanedValue);
          }
        });

        globalStore.marketplaceStore
          .saveWorkflow(
            postId,
            credentialsData,
            filteredFormData,
            preview,
            fixedCollectionKeys,
            this.draftIds,
            collectionTemplateIds,
            workflowSettings,
            forgeWorkflowIds
          )
          .then(
            action(async (res) => {
              if (res.data || res.success) {
                this.useWorkflowBtnDisabled = false;
                this.isLoading = false;
                globalStore.blockEditStore.blockValues = null;

                globalStore.marketplaceStore.templateImported = true;
                globalStore.blockTypeStore.initialize(forgeMode);

                if (
                  (!fromEnterprise || !this.collectionId) &&
                  res.newWorkflows.length > 0
                ) {
                  const workflow = res.newWorkflows[0];
                  const { blocks } = workflow;
                  if (blocks.length && blocks[0].type !== 'alloy.start') {
                    this.globalStore.executionStore.aWorkflowIsActivating = true;
                  }
                }
                // showNotification('success', 'Recipe installed and activated.');
              } else {
                this.isLoading = false;
                showNotification('error', 'Something went wrong!');
              }
            })
          )
          .catch((e) => {
            this.isLoading = false;
            showNotification('error', 'Something went wrong!');
          });
      }, 1);
    } else {
      this.isLoading = false;
      this.showPlanValidationMessage = true;
    }
  };

  checkCredsAndSubmit = async (fromEnterprise) => {
    let actualEnterpriseCredentialData = [];
    if (fromEnterprise) {
      actualEnterpriseCredentialData = this.blocks.filter((block) => {
        return this.credentialsData.some((credentialData) => {
          return block.credentials.some(
            (cred) => credentialData.credentialType === cred.name
          );
        });
      });
    }
    if (
      this.credentialsData.length === this.blocks.length ||
      (fromEnterprise &&
        actualEnterpriseCredentialData?.length === this.blocks.length)
    ) {
      this.handleFinish(fromEnterprise);
    } else {
      showNotification('error', 'Please connect all the accounts first!');
    }
  };
}
