export const NODE_TYPES = {
  CRON: 'alloy.cron',
  START: 'alloy.start',
  INTERVAL: 'alloy.interval',
  SHOPIFY: 'alloy.shopify',
  FOR: 'alloy.for',
  WEBHOOK: 'alloy.webhook',
  SDK: 'alloy.sdk',
  IF: 'alloy.if',
  SHOPIFY_TRIGGER: 'alloy.shopifyTrigger',
  GOOGLE_SHEETS_TRIGGER: 'alloy.googleSheetsIntervalTrigger',
  TRELLO: 'alloy.trello',
  SEND_EMAIL: 'alloy.sendEmail',
  SMTP_SERVICE: 'alloy.smtpService',
  DATA_FORMATTER: 'alloy.dataFormatter',
  CALENDLY_TRIGGER: 'alloy.calendlyTrigger',
  DELAY: 'alloy.delay',
  SEND_TEXT: 'alloy.sendText',
  PUSHOWL: 'alloy.pushowl',
  RECHARGE_TRIGGER: 'alloy.rechargeTrigger',
  TONEMESSAGING: 'alloy.tonemessaging',
  QUICKBOOKS: 'alloy.quickbooks',
  ZENDESKTRIGGER: 'alloy.zendeskTrigger',
  TEMPLATE_COMPOSER: 'alloy.contentComposer',
  ON_INSTALLATION_TRIGGER: 'alloy.onInstallationTrigger',
  ACCOUNT_VARIABLES: 'alloy.accountVariables',
  STREAM_DATA: 'alloy.dataStreaming',
  KLAVIYO: 'alloy.klaviyo',
  CUSTOM_EVENT: 'alloy.customEvent',
};

export const BLACKLISTED_TRIGGERS = [NODE_TYPES.START, NODE_TYPES.INTERVAL];

export const OAUTH_NODES = {
  SLACK: 'slackApi',
  BIGCOMMERCE: 'bigcommerceApi',
  INTERCOM: 'intercomApi',
  GOOGLE: 'googleApi',
  GMAIL: 'gmailApi',
  GOOGLE_SHEETS: 'googleSheetsApi',
  GOOGLE_CALENDAR: 'googleCalendarApi',
  GOOGLE_DRIVE: 'googleDriveApi',
  DOCUSIGN: 'docusignApi',
  FACEBOOK: 'facebookApi',
  TWITTER: 'twitterApi',
  YOTPO: 'yotpoApi',
  HUBSPOT: 'hubspotApi',
  ASANA: 'asanaApi',
  SHIPBOB: 'shipbobApi',
  SHOPIFY: 'shopifyApi',
  MICROSOFT: 'microsoftApi',
  MAILCHIMP: 'mailchimpApi',
  LINKEDIN: 'linkedinApi',
  SKUBANA: 'skubanaApi',
  SPRINKLR: 'sprinklrApi',
  RECHARGE: 'rechargeApi',
  SALESFORCE: 'salesforceApi',
  CUSTOMER_FIELDS: 'customerFieldsApi',
  AIRCALL: 'aircallApi',
  WEBFLOW: 'webflowApi',
  PUSHOWL: 'pushowlApi',
  OKENDO: 'okendoApi',
  PLOBAL: 'plobalApi',
  QUICKBOOKS: 'quickbooksApi',
};

export const GOOGLE_CREDS = [
  OAUTH_NODES.GMAIL,
  OAUTH_NODES.GOOGLE_CALENDAR,
  OAUTH_NODES.GOOGLE_DRIVE,
  OAUTH_NODES.GOOGLE_SHEETS,
  OAUTH_NODES.GMAIL,
];

export const SHOULD_TEST_NODES = [NODE_TYPES.DATA_FORMATTER];

export const NON_TESTABLE_NODE_TYPES = [
  NODE_TYPES.CALENDLY_TRIGGER,
  NODE_TYPES.SDK,
  NODE_TYPES.DELAY,
  NODE_TYPES.SEND_EMAIL,
  NODE_TYPES.SEND_TEXT,
  NODE_TYPES.DELAY,
];

export const SHOPIFY_DEPENDENT_BLOCKS = [
  NODE_TYPES.PUSHOWL,
  NODE_TYPES.TONEMESSAGING,
  NODE_TYPES.OKENDO,
  NODE_TYPES.PLOBAL,
];

export const NOT_ON_FREE_BLOCKS = [NODE_TYPES.SEND_EMAIL, NODE_TYPES.SEND_TEXT];

export const POLLING_TRIGGER_BLOCK_VALUES = [
  'Google Sheets Trigger',
  'Slack Trigger',
  'Airtable Trigger',
  'Faire Trigger',
  'Magento Trigger',
  'Omnisend Trigger',
  'Netsuite Trigger',
  'Okendo Trigger',
  'Klaviyo Trigger',
  'Salesforce CRM Trigger',
];
export const GRAPHQL_BLOCK_TYPES = [
  'alloy.shopify',
  'alloy.shiphero',
  'alloy.bwp',
];

export const VIDEO_TUTORIAL_BLOCK_TYPES = [
  'alloy.text',
  'alloy.dateTime',
  'alloy.for',
  'alloy.if',
  'alloy.branch',
];

export const COMPOSE_EMAIL_BODIES = [
  NODE_TYPES.SEND_EMAIL,
  NODE_TYPES.SMTP_SERVICE,
  NODE_TYPES.KLAVIYO,
];

export const COMPOSE_EMAIL_BODIES_PARAM_NAME = [
  'plainBody',
  'htmlBody',
  'bodyText',
];
