import React, { Component } from 'react';
import { Modal } from 'antd';
import { AppButton } from '../Base';
import styles from './index.less';
import { useLocation } from 'react-router-dom';

export const FallbackError = () => {
  const { search } = useLocation();
  const forgeMode = new URLSearchParams(search).get('forgeMode');
  const redirectUrl = forgeMode ? '/embedded' : '/dashboard';
  return (
    <Modal
      open
      title="Something broke in our app"
      footer={
        <div className={styles.actionButtons}>
          <AppButton type="primary" href={redirectUrl}>
            Return to dashboard
          </AppButton>
        </div>
      }
      closable={false}
      maskClosable={false}
      className={styles.popupContainer}
      centered
    >
      It’s not your fault! We’ll be looking into this soon and will have a fix
      ASAP. For now, stay away from this broken part of the app.
    </Modal>
  );
};
