import React from 'react';
import classNames from 'classnames';
import styles from './index.less';
import { Select } from 'antd';
import { IconDropdown } from '../Icons/actions';
import { IconCloseSmall, IconCloseSmallNew } from '../Icons/actions';

export const AppSelect = ({
  id,
  mode,
  placeholder,
  disabled,
  name,
  onChange,
  onSelect,
  value,
  defaultValue,
  getPopupContainer,
  allowClear,
  size = 'middle',
  options,
  suffixIcon,
  showSearch,
  children,
  label,
  embeddedDropdownInDrawer = false,
  noSelectHighlight = false,
  notFoundContent,
  customClassName,
  dropdownRender,
  customOptions,
  maxTagCount = 5,
  optionLabelProp,
  loading,
  menuItemSelectedIcon = null,
  appLogo,
  filterOption,
  ...props
}) => {
  return (
    <Select
      id={id}
      mode={mode}
      className={classNames(styles.select, {
        [styles.noSelectHighlight]: noSelectHighlight,
        [customClassName]: customClassName,
      })}
      popupClassName={classNames(styles.popupSelect, {
        [styles.embeddedDropdownInDrawer]: embeddedDropdownInDrawer,
        [styles.noSelectHighlight]: noSelectHighlight,
      })}
      placeholder={placeholder}
      suffixIcon={suffixIcon || <IconDropdown />}
      getPopupContainer={getPopupContainer}
      disabled={disabled}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onSelect={onSelect}
      allowClear={allowClear}
      removeIcon={<IconCloseSmallNew />}
      size={size}
      showSearch={showSearch}
      label={label}
      notFoundContent={notFoundContent}
      dropdownRender={dropdownRender}
      options={customOptions}
      maxTagCount={maxTagCount}
      optionLabelProp={optionLabelProp}
      loading={loading}
      menuItemSelectedIcon={menuItemSelectedIcon}
      filterOption={filterOption}
      {...props}
    >
      {options && options.length > 0
        ? options.map((element, index) => {
            return (
              <Select.Option
                key={index}
                value={element.value ? element.value : `option-${index}`}
                disabled={element?.disabled}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  {appLogo && <img src={appLogo} height={16} width={16}></img>}
                  {label ? `${label} ${index + 1}` : element.name}
                </div>
              </Select.Option>
            );
          })
        : children}
    </Select>
  );
};
