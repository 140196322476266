import { observable, computed, action, when, runInAction, toJS } from 'mobx';
import { get, set, isString, kebabCase, remove } from 'lodash';
import {
  getBlockTypes,
  getBlockOutput,
  getBlockParameterOptions,
} from 'Services/workflowApi';
import { displayParameterPath } from 'Utilities/block-helpers';
import { NODE_TYPES } from 'Constants';
import { isWebhookBlock } from '../utils';
import { getBlockTypeByName } from '../services/workflowApi';
import { cloneDeep } from 'lodash';

const ALLOWED_BLOCK_CATEGORIES = ['data-ingest'];
const ALLOWED_BLOCK_GROUP = ['utility'];

const LOGIC_BLOCKS = [
  'alloy.branch',
  'alloy.if',
  'alloy.for',
  'alloy.loopBreak',
  'alloy.errorHandler',
];

export class BlockTypeStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  rawBlockTypes = [];

  @computed
  get firstBlockType() {
    return this.rawBlockTypes?.find(
      (l) =>
        l.integrationId &&
        l.integrationId ===
          this.globalStore?.workflowStore?.workflow?.integrationId
    );
  }

  @computed
  get blockTypes() {
    const { workflowStore, userStore } = this.globalStore;
    if (!this.rawBlockTypes.length) return this.rawBlockTypes;

    if (workflowStore.forgeMode && userStore.forgeAccount) {
      const rawBlockTypes = this.rawBlockTypes.map((block) => {
        if (block.name === 'alloy.customEvent') {
          return this.forgeCustomEventBlock;
        } else {
          return block;
        }
      });
      const returnObj = [this.forgeWebhookBlock, ...rawBlockTypes];

      return returnObj;
    } else if (this.webhookBlock) {
      return [this.webhookBlock, ...this.rawBlockTypes];
    } else {
      return this.rawBlockTypes;
    }
  }

  @observable
  currentBlockType = null;

  @observable
  loadingBlockOutput = {};

  @observable
  webhookBlock = null;

  excludeBlockCall = [];

  @computed
  get forgeWebhookBlock() {
    const { userStore } = this.globalStore;
    const { forgeAccount } = userStore;
    // eslint-disable-next-line prefer-object-spread
    return Object.assign({}, this.webhookBlock, {
      displayName: forgeAccount?.brandName
        ? `${forgeAccount.brandName} `
        : `${this.webhookBlock?.displayName}`,
      icon: forgeAccount?.icon || this.webhookBlock?.icon,
      description:
        forgeAccount.webhookDescription || this.webhookBlock?.description,
    });
  }

  @computed
  get forgeCustomEventBlock() {
    const { userStore } = this.globalStore;
    const { forgeAccount } = userStore;
    const customEventBlock = this.rawBlockTypes.find(
      (block) => block.name === 'alloy.customEvent'
    );

    return {
      ...customEventBlock,
      displayName: forgeAccount?.brandName || customEventBlock?.displayName,
      icon: forgeAccount?.icon || customEventBlock?.icon,
    };
  }

  @computed
  get isTrigger() {
    const { group } = this.currentBlockType;

    if (group.includes('trigger')) {
      return true;
    }

    return false;
  }

  @computed
  get triggers() {
    const { workflowStore, userStore } = this.globalStore;
    const integrationInfo = workflowStore.integrationInfo;
    const { forgeAccount } = userStore;
    const accessAllBlocks = forgeAccount?.accessAllBlocks;

    if (workflowStore.forgeMode && !accessAllBlocks && integrationInfo) {
      const { blockId } = integrationInfo;
      const whitelistedBlocks = forgeAccount.whitelistedBlocks || [];

      return this.blockTypes.filter(
        (block) =>
          ((block.name.includes(blockId) ||
            block.name === 'alloy.cron' ||
            whitelistedBlocks.indexOf(block.name) > -1) &&
            block.group[0] === 'trigger') ||
          block.name === 'alloy.customEvent' ||
          block.name === 'alloy.webhook'
      );
    } else {
      return this.blockTypes.filter((block) => block?.group?.[0] === 'trigger');
    }
  }

  @computed
  get blocks() {
    const findAllowedBlock = (arr1, arr2) =>
      arr1?.some((item) => arr2?.includes(item));

    const { workflowStore, userStore } = this.globalStore;
    const { forgeAccount } = userStore;

    const accessAllBlocks = forgeAccount?.accessAllBlocks;
    const integrationInfo = workflowStore.integrationInfo;
    if (workflowStore.forgeMode && !accessAllBlocks && integrationInfo) {
      const whitelistedBlocks = forgeAccount.whitelistedBlocks || [];
      return this.blockTypes.filter((block) => {
        return (
          block.group[0] !== 'trigger' &&
          (whitelistedBlocks.indexOf(block.name) > -1 ||
            LOGIC_BLOCKS.indexOf(block.name) > -1 ||
            findAllowedBlock(block.category, ALLOWED_BLOCK_CATEGORIES) ||
            findAllowedBlock(block.group, ALLOWED_BLOCK_GROUP) ||
            block.name.includes(integrationInfo.blockId))
        );
      });
    } else {
      return this.blockTypes.filter((block) => {
        return block.group[0] !== 'trigger';
      });
    }
  }

  getParameterOptions = (options) => {
    const { workflowStore } = this.globalStore;
    const req = {
      ...options,
      ...(workflowStore.activeVersion
        ? { version: workflowStore.activeVersion }
        : {}),
      ...(workflowStore.activeVersionIsCustom
        ? { activeVersionIsCustom: workflowStore.activeVersionIsCustom }
        : {}),
      ...(workflowStore.activeVersionIsCustom &&
      workflowStore?.activeChildUserId
        ? { activeChildUserId: workflowStore.activeChildUserId }
        : {}),
    };
    return getBlockParameterOptions(req);
  };

  @computed
  get requiredParameters() {
    if (!this.currentBlockType) return [];
    const { blockValues } = this.globalStore.blockEditStore;

    const data = cloneDeep(blockValues);
    if (
      blockValues.parameters?.resource === 'custom' &&
      !['createCustom', 'customGraphqlQuery'].includes(
        blockValues.parameters?.operation
      )
    ) {
      data.parameters['operation'] = 'createCustomAction';
    }

    const fields = this.currentBlockType.properties.filter((p) => {
      return (
        p.name !== 'resource' &&
        p.name !== 'operation' &&
        p.name !== 'apiVersion' &&
        p.required &&
        (p.displayOptions === undefined ||
          displayParameterPath(data, p, 'parameters'))
      );
    });

    if (
      blockValues.parameters?.resource === 'custom' &&
      !['createCustom', 'customGraphqlQuery'].includes(
        blockValues.parameters?.operation
      )
    ) {
      return fields.filter((p) => {
        return (
          p &&
          p.name &&
          blockValues.parameters[p.name] &&
          blockValues.parameters[p.name].length &&
          blockValues.parameters[p.name][0].name
        );
      });
    } else {
      return fields;
    }
  }

  @computed
  get optionalParameters() {
    if (!this.currentBlockType) return [];
    const { blockValues } = this.globalStore.blockEditStore;

    const data = cloneDeep(blockValues);
    if (
      blockValues.parameters?.resource === 'custom' &&
      !['createCustom', 'customGraphqlQuery'].includes(
        blockValues.parameters?.operation
      )
    ) {
      data.parameters['operation'] = 'createCustomAction';
    }
    return this.currentBlockType.properties.filter((p) => {
      return (
        p.name !== 'resource' &&
        p.name !== 'operation' &&
        p.name !== 'apiVersion' &&
        !p.required &&
        (p.displayOptions === undefined ||
          displayParameterPath(data, p, 'parameters'))
      );
    });
  }

  @computed
  get isWebhookBlock() {
    isWebhookBlock(this.currentBlockType.name);
  }

  findByDisplayName = (displayName) => {
    return this.blockTypes.find(
      (block) => kebabCase(block.displayName.toLowerCase()) === displayName
    );
  };

  findByName = (name) => {
    const { workflowStore } = this.globalStore;
    if (name === 'alloy.webhook') {
      return workflowStore.forgeMode
        ? this.forgeWebhookBlock
        : this.webhookBlock;
    }

    if (name === 'alloy.customEvent' && workflowStore.forgeMode) {
      return this.forgeCustomEventBlock;
    }
    const blockType = this.blockTypes.find(
      (block) => block?.name?.toLowerCase() === name?.toLowerCase()
    );

    if (
      blockType?.name &&
      !blockType?.properties?.length &&
      !this.excludeBlockCall.includes(blockType?.name)
    ) {
      this.excludeBlockCall.push(blockType.name);
      return getBlockTypeByName(blockType.name).then((res) => {
        Object.assign(blockType, res.data[0]);
        return blockType;
      });
    }
    return blockType;
  };

  // Wrapper around request to make expression editor testable with mock store
  getBlockOutput = async (block, hotData = false) => {
    const { workflowStore } = this.globalStore;
    const { activeVersion, activeVersionIsCustom, activeChildUserId } =
      workflowStore;
    const workflowId =
      workflowStore.activeWorkflowId ||
      this.globalStore.embeddedInstallerStore?.currentWorkflowId;
    const blockId = block.id;
    if (this.loadingBlockOutput[blockId]) {
      await when(() => !this.loadingBlockOutput[blockId]);
      return workflowStore.expressionData[blockId];
    } else {
      let currentParameters = {};
      let credentials = {};
      if (hotData) {
        const { embeddedInstallerStore } = this.globalStore;
        const { formData, currentWorkflowId, credentialsData } =
          embeddedInstallerStore;
        const workflowFormData = formData[currentWorkflowId];

        for (const [key, value] of Object.entries(workflowFormData)) {
          const keys = key.split('::');
          if (keys[0] === blockId) {
            currentParameters[keys[1]] = value;
          }
        }

        credentialsData.forEach((credential) => {
          if (credential.credentialType) {
            credentials[credential.credentialType] = credential.data;
          }
        });
      }
      this.loadingBlockOutput[blockId] = true;
      try {
        const outputData = await getBlockOutput(
          block.type,
          Object.assign({}, block.parameters, {
            blockId,
            workflowId,
            activeVersion,
            activeVersionIsCustom,
            activeChildUserId,
            hotData,
            currentBlockParameters: currentParameters,
            credentials,
            blockType: block.type,
          })
        );
        workflowStore.setExpressionData(blockId, outputData);
        runInAction(() => {
          this.loadingBlockOutput[blockId] = false;
        });
        return outputData;
      } catch (err) {
        this.loadingBlockOutput[blockId] = false;
        return {};
      }
    }
  };

  getRecipeBlockOutput = async (block, blocks) => {
    const { workflowStore } = this.globalStore;
    const workflowId = workflowStore.activeWorkflowId;
    const blockId = block.id;
    const { activeVersion, activeVersionId } = workflowStore;
    if (this.loadingBlockOutput[blockId]) {
      await when(() => !this.loadingBlockOutput[blockId]);
      return workflowStore.expressionData[blockId];
    } else {
      this.loadingBlockOutput[blockId] = true;
      try {
        const outputData = await getBlockOutput(
          block.type,
          Object.assign({}, block.parameters, {
            blockId,
            workflowId,
            blocks,
            activeVersion,
            activeVersionId,
          })
        );
        workflowStore.setExpressionData(blockId, outputData);
        runInAction(() => {
          this.loadingBlockOutput[blockId] = false;
        });
        return outputData;
      } catch (err) {
        this.loadingBlockOutput[blockId] = false;
        return {};
      }
    }
  };

  resolveValues = async (block) => {
    const { workflowStore } = this.globalStore;
    const { workflow } = workflowStore;
    const blockParams = Object.assign({}, block.parameters);
    const params = Object.keys(blockParams);
    await Promise.all(
      params.map(async (param) => {
        const value = blockParams[param];
        if (value && isString(value) && value.indexOf('{{$block') > -1) {
          const dependentBlockId = value.substring(
            value.indexOf('{{$block["') + 10,
            value.indexOf('"].data')
          );
          const dependentBlock = workflow.blocks.find(
            (nd) => nd.id === dependentBlockId
          );
          const sampleData = await this.getBlockOutput(block);
          const { testBlockRunData } = dependentBlock;

          if (testBlockRunData) {
            const testData = get(
              testBlockRunData,
              `[${dependentBlock.id}].data[0][0]`
            );

            if (testData) {
              // Data property does not exist or is not set (even though it normally has to)
              const outputData = testBlockRunData[dependentBlock.id].data[0][0];
              const resolvedValue = get(
                outputData.json,
                value.substring(
                  value.indexOf('"].data') + 7,
                  value.indexOf('}}')
                )
              );

              if (resolvedValue) {
                set(blockParams, `${param}`, resolvedValue);
              }
            }
          } else if (sampleData) {
            const outputData = get(sampleData, dependentBlockId);
            const resolvedValue = get(
              outputData,
              'json' +
                value.substring(
                  value.indexOf('"].data') + 7,
                  value.indexOf('}}')
                )
            );
            if (resolvedValue) {
              set(blockParams, param, resolvedValue);
            }
          }
        }
      })
    );

    return blockParams;
  };

  @action
  setCurrentBlockType = (blockType, fromCredentials) => {
    const { workflowStore } = this.globalStore;
    if (blockType === 'alloy.webhook') {
      this.currentBlockType = workflowStore.forgeMode
        ? this.forgeWebhookBlock
        : this.webhookBlock;
      return;
    }

    if (blockType === 'alloy.customEvent' && workflowStore.forgeMode) {
      this.currentBlockType = this.forgeCustomEventBlock;
      return;
    }

    let currentBlockType = null;
    if (fromCredentials) {
      currentBlockType = this.blockTypes.find((block) => {
        if (!block.credentials) return false;
        return block?.credentials[0]?.name === blockType;
      });
    } else {
      currentBlockType = this.blockTypes.find((block) =>
        block?.name?.includes(blockType)
      );
    }

    if (currentBlockType) this.currentBlockType = currentBlockType;
  };

  @action
  updateBlockTypeData = async (blockType, type) => {
    if (!blockType) return;
    const newBlockTypeData = await getBlockTypeByName(type);
    Object.assign(blockType, newBlockTypeData?.data[0]);
    return;
  };

  @action
  initialize = async (forgeMode) => {
    const accessToken = localStorage.getItem('workflow-accessToken');

    if (!accessToken) return;

    let workflowBlockTypes = [];
    if (this.globalStore.workflowStore.workflow) {
      workflowBlockTypes = [
        ...new Set(
          this.globalStore.workflowStore.workflowBlocks.map((block) => {
            return block.type;
          })
        ),
      ];
    }

    try {
      await this.globalStore.customIntegrationStore.getCustomIntegrations();
    } catch {}
    const hasCustomEvent = workflowBlockTypes.includes('alloy.customEvent');
    if (!hasCustomEvent) {
      if (this.rawBlockTypes.length && forgeMode !== false) {
        return Promise.resolve();
      }
      if (
        this.rawBlockTypes.length > 0 &&
        this.webhookBlock &&
        forgeMode !== false
      ) {
        return Promise.resolve();
      }
    }

    return getBlockTypes({ blockTypes: workflowBlockTypes }).then(
      action(async (res) => {
        const webhookBlock = remove(
          res.data,
          (block) => block.name === 'alloy.webhook'
        );
        this.rawBlockTypes = res.data;
        if (
          this.globalStore?.customIntegrationStore?.customIntegrations?.length
        ) {
          this.rawBlockTypes = [
            ...this.rawBlockTypes,
            ...this.globalStore?.customIntegrationStore?.customIntegrations,
          ];
        }
        this.webhookBlock = webhookBlock[0];
      })
    );
  };

  getResourceProperty = () => {
    const { blockValues } = this.globalStore.blockEditStore;
    const { workflowStore, userStore, blockTypeStore } = this.globalStore;
    const { forgeAccount } = userStore;
    const { forgeMode } = workflowStore;
    const { currentBlockType } = blockTypeStore;

    const hasVersions = currentBlockType.properties.find((item) => {
      let hasUnifiedApi = false;
      //dont include unifiedApi resource in regular alloy
      if (item?.options && item?.options.length) {
        for (const option of item.options) {
          const isUnifiedApps = [
            'wooCommerceUnifiedApi',
            'shopifyUnifiedApi',
            'bigCommerceUnifiedApi',
            'eBayUnifiedApi',
            'wixUnifiedApi',
            'squareSpaceUnifiedApi',
            'magentoUnifiedApi',
            'commerceCloudUnifiedApi',
            'amazonSPUnifiedApi',
          ];

          if (isUnifiedApps.includes(option.value)) {
            hasUnifiedApi = true;
          } else {
            hasUnifiedApi = false;
          }
        }
      }

      if (item.name === 'apiVersion' && !forgeMode) {
        if (!hasUnifiedApi) {
          return item.name === 'apiVersion';
        }
      } else if (
        item.name === 'apiVersion' &&
        forgeMode &&
        !forgeAccount?.unifiedApiEnabled &&
        hasUnifiedApi
      ) {
        // Hide version if UAPI is disabled
        return;
      } else {
        return item.name === 'apiVersion';
      }
    });
    return this.currentBlockType.properties.find((item) => {
      if (hasVersions) {
        let apiVersion = blockValues.parameters?.apiVersion;
        const properties = this.currentBlockType?.properties.find(
          (item) => item?.name === 'apiVersion'
        );
        if (apiVersion === undefined) {
          // Set default api action values in UAPI
          apiVersion = properties?.default || properties?.options[0].value;
        }
        return (
          item.name === 'resource' &&
          item?.displayOptions?.show?.apiVersion?.findIndex(
            (v) => v === apiVersion
          ) >= 0
        );
      } else {
        return item.name === 'resource';
      }
    });
  };

  getOperationProperty = () => {
    return this.currentBlockType.properties.find((item) => {
      return item.name === 'operation';
    });
  };
}
