import url from './api-url';

export const imgUrl = url + '/block-icon';
export const workflowUrl = url;

export const headers = {
  'Content-Type': 'application/json',
};

export const initBlock = {
  resource: undefined,
  operation: {
    name: undefined,
    value: undefined,
    description: undefined,
  },
  credentials: {},
  parameters: [],
  key: undefined,
  stepNum: 0,
};
