import { observable, computed, action } from 'mobx';
import { set, remove, get, pullAt, debounce } from 'lodash';
import {
  getAllIntegrations,
  getIntegration,
  updateIntegration,
  removeIntegration,
  createIntegration,
  getCredentials,
  updateCredentials,
  testCredentials,
  getActions,
  updateActions,
  publishIntegration,
  rejectIntegration,
  approveIntegration,
  testAction,
} from 'Services/integrations';

export class IntegrationStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  integrations = [];

  @observable
  credentials = null;

  @observable
  actions = null;

  @observable
  activeIntegrationId = null;

  @observable
  apiTestResult = null;

  @observable
  selectedResource = null;

  @observable
  selectedOperation = null;

  @observable
  operationTestResults = null;

  @observable
  currentView = 'overview';

  @observable
  currentStep = 'overview';

  @observable
  forReview = false;

  @computed
  get integration() {
    return this.integrations.find(
      (integration) => integration._id === this.activeIntegrationId
    );
  }

  @computed
  get operationFields() {
    if (!this.selectedResource || !this.selectedOperation) return [];
    const resource = this.selectedResource._id;
    const operation = this.selectedOperation._id;
    return this.actions.parameters.filter((p) => {
      const { show } = p.displayOptions;
      return (
        show.resource.includes(resource) && show.operation.includes(operation)
      );
    });
  }

  @action
  setCurrentView = (view) => {
    this.currentView = view;
  };

  @action
  setCurrentStep = (step) => {
    this.currentStep = step;
  };

  @action
  setOperationTestResults = (result) => {
    this.operationTestResults = result;
  };

  @action
  setApiTestResults = (result) => {
    this.apiTestResult = result;
  };

  @action
  setForReview = (bool) => {
    this.forReview = bool;
  };

  @action
  loadIntegrations = () => {
    return getAllIntegrations()
      .then(
        action((res) => {
          this.integrations = res.data;
        })
      )
      .catch(() => {
        this.integrations = [];
      });
  };

  @action
  getIntegration = (integrationId) => {
    return getIntegration(integrationId)
      .then(
        action((res) => {
          const integration = res.data;
          const existingIndex = this.integrations.findIndex(
            (i) => i._id === this.activeIntegrationId
          );
          if (existingIndex > -1) {
            this.integrations[existingIndex] = integration;
          } else {
            this.integrations.push(integration);
          }
        })
      )
      .catch(() => {
        // do something?
      });
  };

  @action
  setActiveIntegration = (integrationId) => {
    this.activeIntegrationId = integrationId;
    const integration = this.integration;
    return Promise.all([
      getCredentials(integration.credentialsId).then(
        action((res) => {
          this.credentials = res.data;
        })
      ),
      getActions(integration.actionsId).then(
        action((res) => {
          this.actions = res.data;
        })
      ),
    ]);
  };

  @action
  setIntegrationName = (name) => {
    const { integration } = this;
    integration.name = name;
    this.updateIntegration();
  };

  @action
  setIntegrationDescription = (description) => {
    const { integration } = this;
    integration.description = description;
    this.updateIntegration();
  };

  // TODO Need to upload image to S3 then use that url, get rid of this lol
  @action
  setIntegrationIcon = (icon) => {
    const update = { icon };
    return updateIntegration(this.activeIntegrationId, update)
      .then(
        action(() => {
          const { integration } = this;
          integration.icon = icon;
        })
      )
      .catch(() => {
        // do something?
      });
  };

  @action
  setIntegrationTags = (tags) => {
    const { integration } = this;
    integration.tags = tags;
    this.updateIntegration();
  };

  updateIntegration = debounce(() => {
    const update = {
      tags: this.integration.tags,
      name: this.integration.name,
      description: this.integration.description,
    };
    updateIntegration(this.activeIntegrationId, update);
  }, 500);

  @action
  removeIntegration = (integrationId) => {
    return removeIntegration(integrationId)
      .then(
        action(() => {
          const index = this.integrations.findIndex(
            (i) => i._id === integrationId
          );
          if (index > -1) {
            this.integrations.splice(index, 1);
          }
        })
      )
      .catch(() => {
        // do something?
      });
  };

  @action
  createIntegration = ({ name, icon, tags, description }) => {
    const body = {};
    body.name = name;
    body.icon =
      icon || 'https://cdn.runalloy.com/graphics/svg/placeholder-app-icon.svg';
    body.tags = tags;
    body.description = description;
    return createIntegration(body)
      .then(
        action((res) => {
          this.integrations.push(res.data);
          this.setActiveIntegration(res.data._id);
          return res.data;
        })
      )
      .catch(() => {
        // do something?
      });
  };

  @action
  publishIntegration = () => {
    const integrationId = this.integration._id;
    return publishIntegration(integrationId).then(
      action(() => {
        this.integration.status = 'published';
      })
    );
  };

  @action
  unsubmitIntegration = () => {
    const integrationId = this.integration._id;
    return rejectIntegration(integrationId).then(
      action(() => {
        this.integration.status = 'in-progress';
      })
    );
  };

  @action
  approveIntegration = () => {
    const integrationId = this.integration._id;
    return approveIntegration(integrationId).then(
      action(() => {
        this.integration.status = 'approved';
      })
    );
  };

  @action
  updateCredentialField = (path, value) => {
    set(this.credentials, path, value);
  };

  @action
  setAuthMethod = (method) => {
    this.credentials.authMethod = method;
    // if (method === 'apiKey' && !this.credentials.apiKey) {
    //   this.setApiKeyDefaults();
    // }
  };

  @action
  addOauthParamRow = (path) => {
    const collection = get(this.credentials, path);
    collection.push({ label: '', value: '' });
  };

  @action
  removeOauthParamRow = (path, index) => {
    pullAt(get(this.credentials, path), index);
  };

  @action
  addOauthActionHeader = () => {
    this.credentials.oauth.actionHeaders.push({ header: '', value: '' });
  };

  @action
  saveCredentials = () => {
    return updateCredentials(this.credentials).then(
      action((res) => {
        this.credentials = res.data;
      })
    );
  };

  @action
  testCredentials = (headers) => {
    this.apiTestResult = null;
    const body =
      this.credentials.authMethod === 'apiKey'
        ? {
            apiKey: this.credentials.apiKey,
            headers,
            credentialsId: this.credentials._id,
          }
        : {
            basicAuth: this.credentials.basicAuth,
            data: headers,
            credentialsId: this.credentials._id,
          };
    return testCredentials(body).then(
      action((res) => {
        this.apiTestResult = res.data;
      })
    );
  };

  @action
  addResource = () => {
    this.actions.resources.push({
      label: '',
      operations: [],
    });
    return updateActions(this.actions).then(
      action((res) => {
        this.actions = res.data;
        this.selectedResource =
          this.actions.resources[this.actions.resources.length - 1];
      })
    );
  };

  @action
  removeResource = (id) => {
    remove(this.actions.resources, (r) => r._id === id);
    remove(this.actions.parameters, (p) =>
      p.displayOptions.show.resource.includes(id)
    );
    return updateActions(this.actions).then(
      action((res) => {
        this.actions = res.data;
        this.selectedResource = null;
        this.selectedOperation = null;
      })
    );
  };

  @action
  removeOperation = (resourceId, operationId) => {
    const resource = this.actions.resources.find((r) => r._id === resourceId);
    remove(resource.operations, (o) => o._id === operationId);
    remove(
      this.actions.parameters,
      (p) =>
        p.displayOptions.show.resource.includes(resourceId) &&
        p.displayOptions.show.operation.includes(operationId)
    );
    return updateActions(this.actions).then(
      action((res) => {
        this.actions = res.data;
        this.selectedResource = this.actions.resources.find(
          (r) => r._id === resourceId
        );
        this.selectedOperation = null;
      })
    );
  };

  @action
  editResource = (id) => {
    this.selectedResource = this.actions.resources.find((r) => r._id === id);
  };

  @action
  deselectResource = () => {
    this.selectedResource = null;
  };

  @action
  deselectOperation = () => {
    this.selectedOperation = null;
    this.operationTestResults = null;
  };

  @action
  addOperation = (resourceId) => {
    const resource = this.actions.resources.find((r) => r._id === resourceId);
    resource.operations.push({
      label: '',
      description: '',
      request: {
        method: 'POST',
        url: '',
      },
    });
    return updateActions(this.actions).then(
      action((res) => {
        this.actions = res.data;
        this.selectedResource = this.actions.resources.find(
          (r) => r._id === this.selectedResource._id
        );
        this.selectedOperation =
          this.selectedResource.operations[
            this.selectedResource.operations.length - 1
          ];
      })
    );
  };

  @action
  editOperation = (resourceId, operationId) => {
    const resource = this.actions.resources.find((r) => r._id === resourceId);
    this.selectedResource = resource;
    this.selectedOperation = resource.operations.find(
      (o) => o._id === operationId
    );
  };

  @action
  setResourceLabel = (label) => {
    this.selectedResource.label = label;
    this.updateActions();
  };

  @action
  setActionLabel = (label) => {
    this.selectedOperation.label = label;
    this.updateActions();
  };

  @action
  setActionDescription = (description) => {
    this.selectedOperation.description = description;
    this.updateActions();
  };

  @action
  setActionMethod = (method) => {
    if (!this.selectedOperation.request) {
      this.selectedOperation.request = { method: 'GET', url: '' };
    }
    this.selectedOperation.request.method = method;
    this.updateActions();
  };

  @action
  setActionUrl = (url) => {
    if (!this.selectedOperation.request) {
      this.selectedOperation.request = { method: 'GET', url: '' };
    }
    this.selectedOperation.request.url = url;
    this.updateActions();
  };

  @action
  createOperationField = () => {
    const resourceId = this.selectedResource._id;
    const operationId = this.selectedOperation._id;
    const body = {
      displayOptions: {
        show: {
          resource: [resourceId],
          operation: [operationId],
        },
      },
      name: '',
      displayName: '',
      type: 'string',
      default: '',
      required: false,
      description: '',
    };

    this.actions.parameters.push(body);
    this.updateActions();
  };

  @action
  removeOperationField = (index) => {
    this.actions.parameters.splice(index, 1);
    this.updateActions();
  };

  @action
  setFieldProperty = (fieldId, property, value) => {
    const field = this.actions.parameters.find((p) => p._id === fieldId);

    field[property] = value;

    this.updateActions();
  };

  @action
  setActionField = (body) => {
    if (body._id) {
      const index = this.actions.parameters.findIndex(
        (p) => p._id === body._id
      );
      Object.assign(this.actions.parameters[index], body);
    } else {
      this.actions.parameters.push(body);
    }

    this.updateActions();
  };

  @action
  testOperation = (testData) => {
    this.operationTestResults = null;
    const body = {
      resourceId: this.selectedResource._id,
      operationId: this.selectedOperation._id,
      testData,
    };
    return testAction(this.actions._id, body).then(
      action((res) => {
        this.operationTestResults = res.data;
      })
    );
  };

  updateActions = debounce(() => {
    updateActions(this.actions).then(
      action((res) => {
        this.actions = res.data;
        if (this.selectedResource) {
          const resourceId = this.selectedResource._id;
          this.selectedResource = this.actions.resources.find(
            (r) => r._id === resourceId
          );
        }

        if (this.selectedOperation) {
          const actionId = this.selectedOperation._id;
          this.selectedOperation = this.selectedResource.operations.find(
            (o) => o._id === actionId
          );
        }
      })
    );
  }, 500);
}
