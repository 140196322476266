import React from 'react';
import { Button } from 'antd';
import { IconLoader } from '../Icons/loader';
import classNames from 'classnames';
import styles from './index.less';

export const AppButton = ({
  id,
  label,
  disabled,
  type,
  size = 'medium',
  onClick,
  loading,
  icon,
  target,
  href,
  isEnterprise,
  isReversed,
  danger,
  htmlType,
  customClass,
  style,
  title,
  active,
  block,
  name,
  onMouseEnter,
  onMouseLeave,
  className,
  ghost,
  shape,
  key,
  children,
}) => {
  if (label && label !== '') {
    if (href) {
      return (
        <Button
          id={id}
          name={name}
          rootClassName={classNames(styles.button, {
            [styles.isSecondary]: type === 'secondary',
            [styles.isTertiary]: type === 'tertiary',
            [styles.isEnterprise]: isEnterprise,
            [styles.isReversed]: isReversed,
            [customClass]: !!customClass,
            [styles.isActive]: active,
            [styles.xSmall]: size === 'extra-small',
            [className]: !!className,
            [styles.iconOnly]: icon && !(!!label || !!children),
          })}
          icon={icon}
          type={type}
          size={size === 'middle' ? 'medium' : size}
          href={href}
          target={target}
          danger={danger}
          htmlType={htmlType}
          disabled={disabled || loading}
          style={style}
          title={title}
          block={block}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ghost={ghost}
          shape={shape}
          key={key}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isReversed && loading && <IconLoader />}
            {label || children}
            {!isReversed && loading && <IconLoader />}
          </div>
        </Button>
      );
    } else {
      if (icon) {
        return (
          <Button
            id={id}
            rootClassName={classNames(styles.button, {
              [styles.isSecondary]: type === 'secondary',
              [styles.isTertiary]: type === 'tertiary',
              [styles.isEnterprise]: isEnterprise,
              [styles.isActive]: active,
              [styles.isReversed]: isReversed,
              ['ant-btn-loading']: loading,
              [customClass]: !!customClass,
              [styles.xSmall]: size === 'extra-small',
              [className]: !!className,
              [styles.iconOnly]: icon && !(!!label || !!children),
            })}
            type={type}
            size={size === 'middle' ? 'medium' : size}
            href={href}
            target={target}
            onClick={onClick}
            disabled={disabled || loading}
            icon={icon}
            danger={danger}
            htmlType={htmlType}
            style={style}
            title={title}
            block={block}
            name={name}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ghost={ghost}
            shape={shape}
            key={key}
          >
            {(loading || label || children) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {isReversed && loading && <IconLoader />}
                {label || children}
                {!isReversed && loading && <IconLoader />}
              </div>
            )}
          </Button>
        );
      } else {
        return (
          <Button
            id={id}
            rootClassName={classNames(styles.button, {
              [styles.isSecondary]: type === 'secondary',
              [styles.isTertiary]: type === 'tertiary',
              [styles.isEnterprise]: isEnterprise,
              [styles.isReversed]: isReversed,
              ['ant-btn-loading']: loading,
              [styles.isActive]: active,
              [customClass]: !!customClass,
              [styles.xSmall]: size === 'extra-small',
              [className]: !!className,
              [styles.iconOnly]: icon && !(!!label || !!children),
            })}
            type={type}
            size={size === 'middle' ? 'medium' : size}
            onClick={onClick}
            disabled={disabled || loading}
            icon={icon}
            href={href}
            target={target}
            danger={danger}
            htmlType={htmlType}
            style={style}
            title={title}
            block={block}
            name={name}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ghost={ghost}
            shape={shape}
            key={key}
          >
            {(loading || label || children) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {isReversed && loading && <IconLoader />}
                {label || children}
                {!isReversed && loading && <IconLoader />}
              </div>
            )}
          </Button>
        );
      }
    }
  } else {
    return (
      <Button
        id={id}
        rootClassName={classNames(styles.button, {
          [styles.isSecondary]: type === 'secondary',
          [styles.isTertiary]: type === 'tertiary',
          [styles.isReversed]: isReversed,
          ['ant-btn-loading']: loading,
          [styles.isActive]: active,
          [customClass]: !!customClass,
          [styles.xSmall]: size === 'extra-small',
          [className]: !!className,
          [styles.iconOnly]: icon && !(!!label || !!children),
          [styles.isEnterprise]: isEnterprise,
        })}
        type={type}
        size={size === 'middle' ? 'medium' : size}
        onClick={onClick}
        disabled={disabled || loading}
        icon={loading && !children ? <IconLoader /> : icon}
        danger={danger}
        htmlType={htmlType}
        style={style}
        title={title}
        block={block}
        name={name}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ghost={ghost}
        shape={shape}
        href={href}
        target={target}
        key={key}
      >
        {(loading || children) && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isReversed && loading && children && <IconLoader />}
            {children}
            {!isReversed && loading && children && <IconLoader />}
          </div>
        )}
      </Button>
    );
  }
};
