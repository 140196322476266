import React from 'react';
import styles from './index.less';
import classNames from 'classnames';
import { Modal } from 'antd';
import { AppIntegrationBadge } from '../IntegrationBadge';
import { AppButton, AppCloseIconSmall } from '..';

export const AppModal = ({
  id,
  open,
  title,
  getContainer,
  footer = null,
  zIndex = 1000,
  onCancel,
  wrapClassName,
  width = 520,
  headerIcon,
  destroyOnClose,
  children,
  classes,
  height,
  maskStyle,
  closable = true,
  size = 'small',
  maxHeight,
  rootClassName,
}) => {
  const bodyStyle = height ? { height } : {};
  if (maxHeight) {
    bodyStyle.maxHeight = maxHeight;
    bodyStyle.overflowY = 'auto';
  }
  return (
    <Modal
      id={id}
      className={classNames(styles.modal, rootClassName, {
        [styles[classes]]: !!classes,
        [styles.headless]: !title,
        [styles.xs]: size,
      })}
      wrapClassName={wrapClassName}
      open={open}
      title={
        headerIcon && title ? (
          <>
            <AppIntegrationBadge
              size={size}
              icon={headerIcon}
              bordered={true}
            />
            {title}
          </>
        ) : (
          title
        )
      }
      width={width}
      footer={footer}
      getContainer={getContainer}
      onCancel={onCancel}
      closable={closable && Boolean(title)}
      closeIcon={
        !closable || !title ? null : (
          <AppButton
            type="tertiary"
            icon={<AppCloseIconSmall />}
            size="medium"
          />
        )
      }
      zIndex={zIndex}
      centered
      destroyOnClose={destroyOnClose}
      styles={{ body: bodyStyle, mask: maskStyle }}
    >
      <div className={styles.contents}>{children}</div>
    </Modal>
  );
};
