import React from 'react';
import { message } from 'antd';
import { getId } from 'Utilities';
import {
  IconCheck,
  IconNotificationClose,
  IconExclamation,
  IconInfo,
  IconLoader,
} from '../Base/Icons';
import styles from './Notification.less';
import classNames from 'classnames';

export const showNotification = (
  type = 'success',
  msg,
  callback,
  customMessage,
  customType,
  forgeMode,
  customActionText
) => {
  if (callback) callback();
  let notification;

  if (type === 'error') {
    notification = message.error({
      content: (
        <div className={styles.notificationContainer}>
          <span className={styles.errorIcon}>
            <IconExclamation />
          </span>
          <span>{msg.message ? msg.message : msg.title ? msg.title : msg}</span>
          <span className={styles.closeBtn} onClick={() => onClose()}>
            <IconNotificationClose />
          </span>
        </div>
      ),
      id: getId('errorMessage'),
      className: classNames(styles.toastFeedback, styles.toastFeedbackError),
      onClose,
      duration: 5,
    });
  } else if (type === 'success') {
    notification = message.success({
      content: (
        <div className={styles.notificationContainer}>
          <span className={styles.successIcon}>
            <IconCheck />
          </span>
          <span>{msg.message ? msg.message : msg.title ? msg.title : msg}</span>
          <span className={styles.closeBtn} onClick={() => onClose()}>
            <IconNotificationClose />
          </span>
        </div>
      ),
      id: getId('successMessage'),
      className: styles.toastFeedback,
      onClose,
      duration: 5,
    });
  } else if (type === 'info') {
    notification = message.info({
      content: (
        <div className={styles.notificationContainer}>
          <span className={styles.infoIcon}>
            <IconInfo />
          </span>
          <span>{msg.message ? msg.message : msg.title ? msg.title : msg}</span>
          <span className={styles.closeBtn} onClick={() => onClose()}>
            <IconNotificationClose />
          </span>
        </div>
      ),
      id: getId('infoMessage'),
      className: styles.toastFeedback,
      onClose,
      duration: 5,
    });
  } else if (type == 'custom') {
    notification = message.success({
      content: (
        <span
          className={classNames(styles.customMessage, {
            [styles.alt]: forgeMode,
          })}
        >
          {customType === 'success' ? (
            <span className={styles.successIcon}>
              <IconCheck />
            </span>
          ) : customType === 'error' ? (
            <span className={styles.errorIcon}>
              <IconExclamation />
            </span>
          ) : customType === 'info' ? (
            <span className={styles.infoIcon}>
              <IconInfo />
            </span>
          ) : (
            <></>
          )}
          <span>{customMessage}</span>
          <span className={styles.closeBtn} onClick={() => onClose()}>
            <IconNotificationClose />
          </span>
        </span>
      ),
      id: getId('successMessage'),
      className: styles.toastFeedback,
      onClose,
      duration: 5,
    });
  } else if (type === 'action') {
    notification = message.success({
      content: (
        <span
          className={classNames(styles.customMessage, {
            [styles.alt]: forgeMode,
          })}
        >
          <span>{customMessage}</span>
          {customActionText}
          <span className={styles.closeBtn} onClick={() => onClose()}>
            <IconNotificationClose />
          </span>
        </span>
      ),
      id: getId('customAction'),
      key: 'customAction',
      className: classNames(styles.toastFeedback, styles.customAction),
      onClose,
      duration: 99999,
    });
  } else if (type === 'custom-auth-success') {
    notification = message.success({
      content: (
        <div className={styles.notificationContainer}>
          <span className={styles.successIcon}>
            <IconCheck />
          </span>
          <span>{msg.message ? msg.message : msg.title ? msg.title : msg}</span>
          <span className={styles.closeBtn} onClick={() => onClose()}>
            <IconNotificationClose />
          </span>
        </div>
      ),
      id: getId('successMessage'),
      top: 100,
      getContainer: () => document.getElementById('customIntegrationBody'),
      className: styles.toastFeedback,
      onClose,
      duration: 2,
    });
  } else if (type === 'custom-auth-error') {
    notification = message.error({
      content: (
        <div
          className={styles.notificationContainer}
          id={getId('errorMessage')}
        >
          <span className={styles.errorIcon}>
            <IconExclamation />
          </span>
          <span id="custom-auth-error">
            {msg.message ? msg.message : msg.title ? msg.title : msg}
          </span>
          <span className={styles.closeBtn} onClick={() => onClose()}>
            <IconNotificationClose />
          </span>
        </div>
      ),
      getContainer: () => document.getElementById('customIntegrationBody'),
      id: getId('errorMessage'),
      top: 100,
      className: styles.toastFeedback,
      onClose,
      duration: 2,
    });
  } else if (type === 'custom-auth-loading') {
    notification = message.loading({
      content: (
        <div className={styles.notificationContainer}>
          <span className={styles.successIcon}>
            <IconLoader />
          </span>
          <span>{msg.message ? msg.message : msg.title ? msg.title : msg}</span>
          <span className={styles.closeBtn} onClick={() => onClose()}>
            <IconNotificationClose />
          </span>
        </div>
      ),
      id: getId('successMessage'),
      getContainer: () => document.getElementById('customIntegrationBody'),
      className: styles.toastFeedback,
      onClose,
      top: 100,
      duration: 2,
    });
  }

  const onClose = () => {
    setTimeout(notification, 30);
  };
};
