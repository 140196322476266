import React from 'react';
import classNames from 'classnames';
import styles from './index.less';
import { Checkbox } from 'antd';

export const AppCheckbox = (props) => {
  return (
    <Checkbox
      className={classNames(styles.checkbox, {
        [props.customClass]: !!props.customClass,
        [styles.xs]: props.size === 'small', // DEPRECATED AS PER Product Library in Figma, Kept until migrating and moving this sizes if needed.
      })}
      {...props}
    >
      <div className={classNames({ [props.labelClass]: !!props.labelClass })}>
        {props?.label && props.label}
      </div>
    </Checkbox>
  );
};
