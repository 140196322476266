export const EmbeddedHeaderValues = {
  icon: 'https://cdn.runalloy.com/graphics/svg/alloyForgeLogo.svg',
  name: 'Embedded Variables',
  subHeader: 'Variables of your integrations, workflows, and users',
  showIcon: true,
  outputData: [
    {
      displayName: 'Integration Id',
      icon: 'https://cdn.runalloy.com/graphics/svg/alloyForgeLogo.svg',
      key: '$workflow.data["integrationId"]',
      name: 'integrationId',
      type: 'single',
      value: '63235ceb08d6070008bc3dcd',
    },
    {
      displayName: 'Workflow Id',
      icon: 'https://cdn.runalloy.com/graphics/svg/alloyForgeLogo.svg',
      key: '$workflow.data["workflowId"]',
      name: 'workflowId',
      type: 'single',
      value: '632b7ed3fd2bee00083438c',
    },
    {
      displayName: 'User Id',
      icon: 'https://cdn.runalloy.com/graphics/svg/alloyForgeLogo.svg',
      key: '$workflow.data["userId"]',
      name: 'userId',
      type: 'single',
      value: 'xyz123abc098754',
    },
    {
      displayName: 'Username',
      icon: 'https://cdn.runalloy.com/graphics/svg/alloyForgeLogo.svg',
      key: '$workflow.data["embeddedUsername"]',
      name: 'embeddedUsername',
      type: 'single',
      value: 'example_username',
    },
    {
      displayName: 'Full Name',
      icon: 'https://cdn.runalloy.com/graphics/svg/alloyForgeLogo.svg',
      key: '$workflow.data["fullName"]',
      name: 'fullName',
      type: 'single',
      value: 'example_fullname',
    },
  ],
};
