import { GET, PATCH, POST, DELETE } from './wrappers';

export const getAllIntegrations = () => {
  return GET(`/api/integrations`);
};

export const getIntegration = (integrationId) => {
  return GET(`/api/integrations/${integrationId}`);
};

export const createIntegration = (body) => {
  return POST('/api/integrations', body);
};

export const updateIntegration = (integrationId, body) => {
  return PATCH(`/api/integrations/${integrationId}`, body);
};

export const removeIntegration = (integrationId) => {
  return DELETE(`/api/integrations/${integrationId}`);
};

export const publishIntegration = (integrationId) => {
  return PATCH(`/api/integrations/${integrationId}/publish`);
};

export const approveIntegration = (integrationId) => {
  return PATCH(`/api/integrations/${integrationId}/approve`);
};

export const rejectIntegration = (integrationId) => {
  return PATCH(`/api/integrations/${integrationId}/reject`);
};

export const getCredentials = (credentialsId) => {
  return GET(`/api/integrationCredentials/${credentialsId}`);
};

export const updateCredentials = (credentials) => {
  return PATCH(`/api/integrationCredentials/${credentials._id}`, credentials);
};

export const testCredentials = (body) => {
  return POST('/api/integrationCredentials/test', body);
};

export const getActions = (actionsId) => {
  return GET(`/api/integrationActions/${actionsId}`);
};

export const updateActions = (actions) => {
  return PATCH(`/api/integrationActions/${actions._id}`, actions);
};

export const testAction = (actionId, body) => {
  return POST(`/api/integrationActions/${actionId}/test`, body);
};
