import { observable, action, computed, toJS } from 'mobx';
import {
  getTeam,
  getTeamFromInvite,
  getTeamFeatureList,
  setFeatureAccess,
} from '../services/teams';
import { get } from 'lodash';
import { planTypes, newPlanTypes } from 'Constants';

export class TeamsStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  team = {};

  @observable
  featureList = [];

  @observable
  isGettingFeatures = false;

  @observable
  isGettingTeam = false;

  @observable
  isTeamLoaded = false;

  @action
  initialize = () => {
    if (this.team && this.team?.active) return Promise.resolve();
    if (!this.hasAccessToken()) return;
    if (!this.isGettingFeatures) {
      this.getFeatureList();
    }

    return !this.isGettingTeam && this.getTeamAction();
  };

  @action
  getTeamAction = () => {
    this.isGettingTeam = true;
    return getTeam()
      .then(
        action((res) => {
          this.team = res.team;
          this.isGettingTeam = false;
          this.isTeamLoaded = true;
        })
      )
      .catch(() => {
        this.isGettingTeam = false;
      });
  };

  @action
  getFeatureList = (timeout = 1000) => {
    this.isGettingFeatures = true;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        getTeamFeatureList()
          .then(
            action((res) => {
              this.featureList = res.featureList;
              this.isGettingFeatures = false;
              resolve(res);
            })
          )
          .catch((error) => {
            this.isGettingFeatures = false;
            reject(error);
          });
      }, timeout);
    });
  };

  @action
  getTeamInfo = (inviteId) => {
    // if (!this.hasAccessToken()) return; Need to check with daniel.
    return getTeamFromInvite(inviteId)
      .then((team) => {
        return team;
      })
      .catch(() => {});
  };

  @computed
  get showTeamTab() {
    const { myAccountStore } = this.globalStore;
    const { plans } = myAccountStore;
    const { team } = this;

    if (team && team?.active) {
      return true;
    }

    if (plans) {
      if (get(planTypes, plans.name, 0) >= 5) {
        return true;
      }

      if (get(newPlanTypes, plans.name, 0) === 3) {
        return true;
      }
    } else {
      return false;
    }

    return false;
  }

  @action
  updateFeatureAccess = (member) => {
    return setFeatureAccess(member)
      .then(
        action((res) => {
          return res;
        })
      )
      .catch(() => {});
  };

  hasAccessToken = () => {
    const accessToken = localStorage.getItem('workflow-accessToken');

    return !!accessToken;
  };
}
