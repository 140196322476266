/* eslint-disable no-prototype-builtins */
import { observable, action, toJS, computed } from 'mobx';
import { get } from 'lodash';
import {
  LeftType,
  displayParameterPath,
  getParameterValue,
  isWebhookBlock,
} from 'Utilities';

const parameterFilter = (parameter, blockValues) => {
  if (parameter.displayOptions === undefined) return true;
  return displayParameterPath(blockValues, parameter, 'parameters');
};
export class SelectActionStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  jumpUrl = false;

  @observable
  operationType = 'add'; // operation type, can be add or edit

  @observable
  stepNum = 0;

  @observable
  leftType = LeftType.BLOCK_CONFIG;

  @observable
  replaceTriggerBlock = false;

  @observable
  replaceRegularBlock = null;

  @observable
  showPopover = false;

  @observable
  showLogsDrawer = false;

  @action
  nextStep = () => {
    this.stepNum += 1;
  };

  @action
  resetStep = () => {
    this.stepNum = 0;
  };

  @action
  skipStep = () => {
    this.stepNum += 2;
  };

  @action
  previousStep = () => {
    this.stepNum -= 1;
  };

  @observable
  disableResults = false;

  @action
  setDisableResult = (bool) => {
    this.disableResults = bool;
  };

  @action
  setStep = (stepNum) => {
    this.stepNum = stepNum;
  };

  @action
  setLeftType = (leftType) => {
    this.leftType = leftType;
  };

  @action
  setAddMode = (forceTrigger) => {
    const { blocks } = this.globalStore.workflowStore.workflow;

    this.leftType = forceTrigger
      ? LeftType.SELECT_TRIGGER
      : blocks.length
      ? LeftType.SELECT_BLOCK
      : LeftType.SELECT_TRIGGER;
    this.operationType = 'add';
    this.replaceTriggerBlock = false;
    this.replaceRegularBlock = null;
  };

  @action
  setEditMode = () => {
    const blockType = this.globalStore.blockTypeStore.currentBlockType;
    const blockId = this.globalStore.blockEditStore.currentBlockId;
    const { forgeMode } = this.globalStore.workflowStore;

    let stepNum = 0;
    let hasInputWarnings = false;

    const blockPropsWithWarnings =
      this.globalStore.workflowStore.getBlockPropsWithWarnings();

    const propsWithWarning = get(blockPropsWithWarnings, blockId, null);

    if (propsWithWarning) {
      hasInputWarnings = true;
    }

    if (!blockType) return;

    const hasActions =
      blockType.properties &&
      blockType.properties.some(
        (p) => p.name === 'resource' || p.name === 'operation'
      );

    const errorStep = this.findErrorStep();
    const { workflow } = this.globalStore.workflowStore;

    if (
      workflow &&
      workflow.hasOwnProperty('settings') &&
      workflow.settings &&
      workflow.settings.disableResults &&
      workflow.settings.disableResults[blockId] !== undefined
    ) {
      this.disableResults = workflow.settings.disableResults[blockId];
    } else {
      this.disableResults = false;
    }

    if (
      workflow &&
      workflow.hasOwnProperty('settings') &&
      workflow.settings &&
      workflow.settings.stepTracker &&
      workflow.settings.stepTracker[blockId] !== undefined
    ) {
      stepNum = workflow.settings.stepTracker[blockId];
      if (hasInputWarnings) {
        stepNum = 2;
      }
    } else if (errorStep > -1 && !forgeMode) {
      stepNum = errorStep;
    } else if (
      !hasActions &&
      (blockType.name === 'alloy.start' ||
        blockType.name === 'alloy.mailgun' ||
        (blockType.group && blockType.group[0] === 'trigger'))
    ) {
      if (blockType.name === 'alloy.start' || isWebhookBlock(blockType.name)) {
        stepNum = 0;
      } else if (blockType.name === 'alloy.gmailIntervalTrigger') {
        const { blockValues } = this.globalStore.blockEditStore;
        if (this.validateCredentials()) {
          stepNum += 1;
          if (blockValues.parameters.label) {
            stepNum += 1;
          }
        }
      } else if (blockType.credentials && blockType.credentials.length) {
        if (this.validateCredentials()) {
          if (!forgeMode) {
            stepNum += 1;
          }
          if (this.validateParameters()) {
            stepNum += 1;
          }
        }
      } else if (blockType.name === 'alloy.cron') {
        const { blockValues } = this.globalStore.blockEditStore;
        try {
          if (blockValues.parameters.triggerTimes.item.length) {
            stepNum += 1;
          } else {
            stepNum = 0;
          }
        } catch (e) {
          stepNum = 0;
        }
      } else if (this.validateParameters()) {
        stepNum += 1;
      }
    } else if (
      blockType.group &&
      (blockType.group.includes('transform') ||
        blockType.group.includes('utility'))
    ) {
      if (hasActions) {
        stepNum = 0;
      } else if (this.validateParameters()) {
        stepNum += 1;
      }
    } else if (this.validateAction()) {
      stepNum += 1;
      if (this.validateCredentials()) {
        if (!forgeMode) {
          stepNum += 1;
        }
        if (this.validateParameters()) {
          stepNum += 1;
        }
      }
    }
    if (this.globalStore.workflowStore.recipePublisherMode) {
      stepNum = 0;
    }
    this.stepNum = stepNum;
    this.operationType = 'edit';
    this.leftType = LeftType.BLOCK_CONFIG;
    this.replaceTriggerBlock = false;
    this.replaceRegularBlock = null;
  };

  // Since we removed the step from the error, try to recreate it
  findErrorStep = () => {
    const blockId = this.globalStore.blockEditStore.currentBlockId;
    const { executionErrors } = this.globalStore.workflowStore;
    if (!executionErrors[blockId]) return -1;

    const error = executionErrors[blockId];
    if (!error.path) return 1; // Most likely issue is bad credentials
    // Can't redirect to different states, so just check the first path
    if (error.path[0].includes('credentials')) return 1;
    if (error.path[0].includes('parameters')) return 2;
    return -1;
  };

  @action
  setEditTriggerBlock = () => {
    this.leftType = LeftType.SELECT_TRIGGER;
    this.replaceTriggerBlock = true;
  };

  @action
  setReplaceRegularBlock = (blockId, parentBlockId) => {
    this.leftType = LeftType.SELECT_BLOCK;
    this.replaceRegularBlock = blockId;
    this.globalStore.blockEditStore.parentBlockId = parentBlockId;
  };

  validateCredentials = () => {
    const { blockValues } = this.globalStore.blockEditStore;
    if (!blockValues) return false;
    const { credentials } = this.globalStore.credentialStore;
    const blockType = this.globalStore.blockTypeStore.currentBlockType;
    const { forgeMode } = this.globalStore.workflowStore;

    const credentialsValue = Object.values(blockValues.credentials)[0];
    const selectedCredential =
      credentialsValue &&
      credentials.find((item) => item._id === credentialsValue);

    if (isWebhookBlock(blockType.name)) {
      const selectedOperation = get(blockValues, 'parameters.operation', null);
      if (selectedOperation === 'none') {
        return true;
      }
    }

    return (forgeMode && selectedCredential) || !selectedCredential?.isExpired;
  };

  validateAction = () => {
    const blockType = this.globalStore.blockTypeStore.currentBlockType;
    const { blockValues } = this.globalStore.blockEditStore;
    if (!blockValues) return false;
    const operation =
      blockValues.parameters && blockValues.parameters.operation;
    const resource = blockValues.parameters && blockValues.parameters.resource;

    const resourceProperty = blockType.properties.find(
      (item) => item.name === 'resource'
    );
    const operationProperty = blockType.properties.find((item) => {
      if (resource) {
        return (
          item.name === 'operation' &&
          item.displayOptions &&
          item.displayOptions.show &&
          item.displayOptions.show.resource &&
          item.displayOptions.show.resource[0] === resource
        );
      }
      return item.name === 'operation';
    });
    const resourceOptions = resourceProperty && resourceProperty.options;
    const operationOptions = operationProperty && operationProperty.options;
    const hasResource = !!(
      resourceOptions ||
      (resourceProperty && resourceProperty.required)
    );
    const hasOperation = !!operationOptions;
    if (hasResource && hasOperation) {
      // both resource and operation
      return !!resource && !!operation;
    } else if (!hasResource && hasOperation) {
      // only operation
      return !!operation;
    } else if (hasResource && !hasOperation) {
      // only resource
      return !!resource;
    } else {
      // none
      return true;
    }
  };

  validateParameters = () => {
    const blockType = this.globalStore.blockTypeStore.currentBlockType;
    const { blockValues, currentBlockId } = this.globalStore.blockEditStore;
    const { isTrigger } = this.globalStore.blockTypeStore;
    const { forgeEditableFields, forgeMode } = this.globalStore.workflowStore;
    const parameters = blockType.properties
      .filter((p) => p.name !== 'resource' && p.name !== 'operation')
      .filter((p) => parameterFilter(p, blockValues) && p.required);
    const vlength = parameters.reduce((acc, p) => {
      const v = getParameterValue(blockValues, p.name, 'parameters');
      return acc + (v || v === 0 ? 1 : 0);
    }, 0);
    const parametersLength = parameters.length;
    const forgeEditableFieldsKeys = Object.keys(forgeEditableFields);
    const forgeEditableByBlockId =
      forgeEditableFieldsKeys.length &&
      forgeEditableFieldsKeys.filter((key) => {
        if (key.includes(currentBlockId)) {
          return parameters.filter((requiredParam) => {
            if (key.includes(requiredParam?.name)) return true;
          }).length;
        }
      });

    return forgeMode && !isTrigger
      ? parametersLength === forgeEditableByBlockId.length ||
          parametersLength === vlength
      : parametersLength === vlength;
  };

  getStatus = (stepName, stepConfig) => {
    if (
      this.stepNum < stepConfig.length - 1 &&
      this.disableResults &&
      stepName === 'Output'
    ) {
      return 'wait';
    }
    return null;
  };
}
