import React from 'react';
import classNames from 'classnames';
import styles from './index.less';
import { Input } from 'antd';
import { EyeVisibleIcon, EyeInvisibleIcon, SearchIcon } from '../../Icons';
import { getId } from '../../../utils';

export const AppInput = (props) => {
  switch (props.type) {
    case 'search': {
      return (
        <Input
          className={classNames(styles.input, {
            [props.customClass]: !!props.customClass,
            [styles.paddingButton]: !!props.actionButton,
          })}
          autoComplete="off"
          prefix={<SearchIcon />}
          name="search"
          id={getId('searchInput')}
          placeholder="Search"
          {...props}
          {...(props?.actionButton ? { suffix: props.actionButton } : {})}
          {...(props?.readOnly ? { disabled: props.readOnly } : {})}
        />
      );
    }
    case 'textArea': {
      return (
        <Input.TextArea
          className={classNames(styles.inputTextarea, {
            [props.customClass]: !!props.customClass,
          })}
          {...props}
          {...(props?.readOnly ? { disabled: props.readOnly } : {})}
        />
      );
    }
    case 'password': {
      return (
        <Input.Password
          className={classNames(styles.input, {
            [props.customClass]: !!props.customClass,
          })}
          iconRender={(visible) =>
            visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />
          }
          {...props}
        />
      );
    }
    default: {
      return (
        <Input
          className={classNames(styles.input, {
            [props.customClass]: !!props.customClass,
            [styles.paddingButton]: !!props.actionButton,
          })}
          {...props}
          {...(props?.actionButton ? { suffix: props.actionButton } : {})}
          {...(props?.readOnly ? { disabled: props.readOnly } : {})}
        />
      );
    }
  }
};
