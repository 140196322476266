import { observable, action } from 'mobx';
import { shopifyLinkAccount, shopifySignup } from 'Services/popup';

import { showNotification } from 'Components/Notifications/Notification';
import { get } from 'lodash';

export class PopupStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  analytics = null;

  @observable
  plans = null;

  @action
  shopifyLinkAccount = (body) => {
    return shopifyLinkAccount(body).then(
      action(() => {
        // localStorage.setItem('workflow-refreshToken', res.refreshToken);
        // localStorage.setItem('workflow-accessToken', res.accessToken);
        // localStorage.setItem('workflow-userInfo', JSON.stringify(res.user));
        // this.user.push(res.data);
      })
    );
  };

  @action
  shopifySetup = (body) => {
    return shopifySignup(body).then(
      action((res) => {
        if (res.success !== undefined && res.success === false) {
          const errorMessage = get(
            res,
            `message`,
            "This email password combo doesn't exist"
          );
          showNotification('error', errorMessage);
          return Promise.reject();
        } else {
          localStorage.setItem('workflow-refreshToken', res.refreshToken);
          localStorage.setItem('workflow-accessToken', res.accessToken);
          localStorage.setItem('workflow-userInfo', JSON.stringify(res.user));
        }
        // this.credentials.push(res.data);
      })
    );
  };
}
