import { observable, action, runInAction } from 'mobx';
import { getWorkspaces } from '../services/workspace';
import { showNotification } from '../components/Notifications/Notification';

export class WorkspaceStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  workspaces = [];

  @action
  initialize = () => {
    if (this.workspaces.length) return Promise.resolve();

    const accessToken = localStorage.getItem('workflow-accessToken');
    if (!accessToken) return;
    return getWorkspaces()
      .then(
        action((res) => {
          this.workspaces = res.workspaces.sort((a, b) => {
            return a?.index - b?.index;
          });
        })
      )
      .catch(() => {
        // user doesn't exist, why are we initializing before login?
      });
  };

  @action
  reload = () => {
    const accessToken = localStorage.getItem('workflow-accessToken');
    if (!accessToken) return;

    return getWorkspaces()
      .then(
        action((res) => {
          this.workspaces = res.workspaces.sort((a, b) => {
            return a?.index - b?.index;
          });
        })
      )
      .catch(() => {});
  };
}
