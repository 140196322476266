import React from 'react';

export const SupportDocIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="32"
      height="32"
      rx="4"
      fill="#2B2E32"
      fill-opacity="0.1"
    />
    <rect
      opacity="0.3"
      x="8"
      y="7.16666"
      width="13.3333"
      height="16"
      rx="2"
      fill="#2B2E32"
    />
    <rect
      x="12"
      y="11.1667"
      width="13.3333"
      height="14.6667"
      rx="2"
      fill="#2B2E32"
    />
  </svg>
);

export const SupportVideoTutorialIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="32"
      height="32"
      rx="4"
      fill="#2B2E32"
      fill-opacity="0.1"
    />
    <rect
      opacity="0.3"
      x="6"
      y="7"
      width="20"
      height="20"
      rx="2"
      fill="#2B2E32"
    />
    <path
      d="M20.4265 16.1258L14.4856 12.8254C13.8191 12.4551 13 12.937 13 13.6995V20.3005C13 21.063 13.8191 21.5449 14.4856 21.1746L20.4265 17.8742C21.1123 17.4932 21.1123 16.5068 20.4265 16.1258Z"
      fill="#2B2E32"
    />
  </svg>
);

export const Support = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="32"
      height="32"
      rx="4"
      fill="#2B2E32"
      fill-opacity="0.1"
    />
    <path
      opacity="0.3"
      d="M26 17C26 22.5228 21.5228 27 16 27C10.4772 27 6 22.5228 6 17C6 11.4772 10.4772 7 16 7C21.5228 7 26 11.4772 26 17ZM10 17C10 20.3137 12.6863 23 16 23C19.3137 23 22 20.3137 22 17C22 13.6863 19.3137 11 16 11C12.6863 11 10 13.6863 10 17Z"
      fill="#2B2E32"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0809 8.38494C22.5369 9.24544 23.7559 10.4645 24.6162 11.9207L21.6265 14.9104C21.02 13.2781 19.7235 11.9813 18.0914 11.3745L21.0809 8.38494ZM7.38477 11.9205C8.24512 10.4644 9.4641 9.24529 10.9202 8.38483L13.9097 11.3744C12.2776 11.9812 10.981 13.2779 10.3744 14.9102L7.38477 11.9205ZM10.3748 19.091C10.9817 20.7231 12.2785 22.0196 13.9107 22.6262L10.9211 25.6158C9.4649 24.7555 8.2458 23.5366 7.3853 22.0805L10.3748 19.091ZM24.6157 22.0803C23.7552 23.5364 22.5361 24.7554 21.08 25.6157L18.0903 22.6261C19.7226 22.0195 21.0193 20.7229 21.6261 19.0908L24.6157 22.0803Z"
      fill="#2B2E32"
    />
  </svg>
);

export const WalkthroughIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="32"
      height="32"
      rx="4"
      fill="#2B2E32"
      fill-opacity="0.1"
    />
    <rect
      opacity="0.3"
      x="7"
      y="8.5"
      width="13"
      height="13"
      rx="6.5"
      fill="#2B2E32"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6034 15.8794C14.8598 15.6801 14.1794 16.3605 14.3786 17.1041L16.2729 24.1735C16.4721 24.9171 17.4016 25.1661 17.9459 24.6218L19.3334 23.2343L22.4286 26.3294C22.8191 26.72 23.4523 26.72 23.8428 26.3294L24.8297 25.3425C25.2203 24.952 25.2203 24.3188 24.8297 23.9283L21.7346 20.8331L23.1211 19.4466C23.6654 18.9023 23.4163 17.9728 22.6728 17.7736L15.6034 15.8794Z"
      fill="#2B2E32"
    />
  </svg>
);

export const SupportIconCanvas = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7" stroke="#2B2E32" strokeWidth="2" />
    <circle cx="8" cy="8" r="3" stroke="#2B2E32" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96803 11.4463C5.38559 11.1021 4.89798 10.6145 4.55381 10.0321L1.78015 12.8057L3.19436 14.22L5.96803 11.4463ZM4.55382 5.96815C4.89798 5.38571 5.38559 4.8981 5.96803 4.55394L3.19437 1.78027L1.78015 3.19449L4.55382 5.96815ZM12.8056 14.22L10.032 11.4463C10.6144 11.1021 11.102 10.6145 11.4462 10.0321L14.2198 12.8057L12.8056 14.22ZM11.4462 5.96815L14.2198 3.19449L12.8056 1.78027L10.032 4.55394C10.6144 4.8981 11.102 5.38571 11.4462 5.96815Z"
      fill="#2B2E32"
    />
  </svg>
);

export const DocsIconCanvas = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3C1.5 1.75736 2.50736 0.75 3.75 0.75H9.75C10.1642 0.75 10.5 1.08579 10.5 1.5V7.5V10.5C10.5 10.9142 10.1642 11.25 9.75 11.25H3.75C2.50736 11.25 1.5 10.2426 1.5 9V3ZM9 2.25V6.75H5.25V2.25H9ZM3.75 2.25C3.33579 2.25 3 2.58579 3 3V6.87803C3.23458 6.79512 3.48702 6.75 3.75 6.75V2.25ZM3.75 9.75C3.33579 9.75 3 9.41421 3 9C3 8.58579 3.33579 8.25 3.75 8.25H9V9.75H3.75Z"
      fill="#2B2E32"
    />
  </svg>
);
