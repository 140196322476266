import React from 'react';
import classNames from 'classnames';
import styles from './index.less';
import { Select } from 'antd';
import { IconDropdown } from '../Icons/actions';
import { IconClean, IconCloseSmall } from '../Icons/actions';

export const AppSelectSearch = ({
  id,
  mode,
  placeholder,
  disabled,
  name,
  onChange,
  onSelect,
  value,
  defaultValue,
  getPopupContainer,
  isForge,
  allowClear,
  size,
  options,
  showSearch,
  label,
  embeddedDropdownInDrawer = false,
  optionFilterProp = 'children',
  filterOption,
  filterSort,
  showArrow,
  onClear,
  notFoundContent,
}) => {
  return (
    <Select
      id={id}
      mode={mode}
      className={classNames(styles.select, {
        [styles.isForge]: isForge,
        [styles.middle]: size === 'middle',
      })}
      popupClassName={classNames({
        [styles.isForge]: isForge,
        [styles.embeddedDropdownInDrawer]: embeddedDropdownInDrawer,
        [styles.selectDropdown]: !embeddedDropdownInDrawer,
        [styles.middle]: size === 'middle',
      })}
      placeholder={placeholder}
      suffixIcon={<IconDropdown />}
      getPopupContainer={getPopupContainer}
      disabled={disabled}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onSelect={onSelect}
      allowClear={allowClear}
      clearIcon={<IconClean />}
      removeIcon={<IconCloseSmall />}
      size={size ? size : 'large'}
      showSearch={showSearch}
      label={label}
      options={options}
      optionFilterProp={optionFilterProp}
      filterOption={filterOption}
      filterSort={filterSort}
      showArrow={showArrow}
      onClear={onClear}
      notFoundContent={notFoundContent}
    />
  );
};
