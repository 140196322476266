export const checkCredentialsArray = [
  'alloy.salesforce',
  'alloy.aircall',
  'alloy.acuityScheduling',
  'alloy.acuitySchedulingTrigger',
  'alloy.aftershipTrigger',
  'alloy.asana',
  'alloy.avalara',
  'alloy.calendlyTrigger',
  'alloy.copperTrigger',
  'alloy.clearbit',
  'alloy.bambooHR',
  // 'alloy.drift',
  'alloy.dropbox',
  'alloy.googleCloudStorage',
  'alloy.hunter',
  'alloy.jira',
  'alloy.lob',
  'alloy.magento',
  'alloy.mailgun',
  'alloy.omnisend',
  'alloy.patch',
  'alloy.reviewIo',
  'alloy.reviewIOTrigger',
  'alloy.peopledatalabs',
  'alloy.Plobal',
  'alloy.nexar',
  'alloy.swell',
  'alloy.fairing',
  'alloy.aspireTrigger',
  'alloy.aspire',
  'alloy.okendo',
  'alloy.wooCommerce',
  'alloy.wooCommerceTrigger',
  // 'alloy.stripeTrigger',
  'alloy.eventbriteTrigger',
  'alloy.trello',
  'alloy.trelloTrigger',
  'alloy.twilio',
  'alloy.typeformTrigger',
  'alloy.shippo',
  'alloy.gorgias',
  'alloy.shiphero',
  'alloy.shipheroTrigger',
  'alloy.stampedio',
  'alloy.postscript',
  'alloy.retentionScience',
  'alloy.zendesk',
  'alloy.mondayTrigger',
  'alloy.shipedge',
  'alloy.inveterate',
  'alloy.gladly',
  'alloy.gladlyTrigger',
  'alloy.parrotMob',
  'alloy.easypost',
  'alloy.xero',
  'alloy.commerceCloud',
  'alloy.shipstation',
  'alloy.mysql',
  'alloy.airtable',
  'alloy.cin7',
  'alloy.klaviyo',
  'alloy.octaneAiTrigger',
  'alloy.okendoEnterprisePollingTrigger',
  'alloy.okendoEnterprise',
  'alloy.retently',
  'alloy.netsuite',
  'alloy.netsuiteTrigger',
  'alloy.googleBigQuery',
  'alloy.googlePubsub',
  'alloy.awsRedshiftApi',
  'alloy.googlePubsubPollingTrigger',
  'alloy.smtpService',
  'alloy.attentive',
  'alloy.awsEventBridge',
  'alloy.awsLambda',
  'alloy.awsSes',
  'alloy.awsSns',
  'alloy.awsSnsTrigger',
  'alloy.azureSynapse',
  'alloy.clyde',
  'alloy.commercejsTrigger',
  'alloy.commercejs',
  'alloy.convictionalSeller',
  'alloy.convictionalBuyer',
  'alloy.convictionalTrigger',
  'alloy.customerio',
  'alloy.delighted',
  'alloy.dynamodb',
  'alloy.easyParcelTrigger',
  'alloy.easyshipTrigger',
  'alloy.endear',
  'alloy.enquireTrigger',
  'alloy.fairePollingTrigger',
  'alloy.faire',
  'alloy.fishbowl',
  'alloy.freshdesk',
  'alloy.freshdeskTrigger',
  'alloy.grin',
  'alloy.heap',
  'alloy.junip',
  'alloy.junipPollingTrigger',
  'alloy.loopReturnsTrigger',
  'alloy.loopReturns',
  'alloy.mariadb',
  'alloy.mixpanelTrigger',
  'alloy.mongodb',
  'alloy.monday',
  'alloy.okendoPollingTrigger',
  'alloy.omnisendPollingTrigger',
  'alloy.oracleFusionCloudFinancials',
  'alloy.parrotMobTrigger',
  'alloy.picqerTrigger',
  'alloy.picqer',
  'alloy.pixlee',
  'alloy.postgresql',
  'alloy.postscriptTrigger',
  'alloy.pushowl',
  'alloy.quickbooks',
  'alloy.rebuy',
  'alloy.redis',
  'alloy.retentlyPollingTrigger',
  'alloy.sage',
  'alloy.sapS4Hana',
  'alloy.segment',
  'alloy.sftp',
  'alloy.shipedgeTrigger',
  'alloy.shippoTrigger',
  'alloy.shippyPro',
  'alloy.shipStationTrigger',
  'alloy.smartrr',
  'alloy.smileIO',
  'alloy.smileIOTrigger',
  'alloy.smsbump',
  'alloy.snowflake',
  'alloy.stampedioTrigger',
  'alloy.3PLTrigger',
  'alloy.3PLCentral',
  'alloy.tonemessaging',
  'alloy.uplead',
  'alloy.upscribe',
  'alloy.upscribeTrigger',
  'alloy.wondermentTrigger',
  'alloy.yotpoLoyalty',
  'alloy.yotpoLoyaltyTrigger',
  'alloy.knoCommerce',
  'alloy.knoCommerceTrigger',
  'alloy.kibo',
];

export const NO_CREDENTIAL_WITH_NAME = 'do not exist for';

export const NO_CREDENTIAL_OF_TYPE = 'No credentials of type';

export const NO_CREDENTIALS_CHECK = [
  NO_CREDENTIAL_WITH_NAME,
  NO_CREDENTIAL_OF_TYPE,
];
