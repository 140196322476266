import { observable, action, runInAction } from 'mobx';
import {
  createNewCredentials,
  getCredentialsForBlock,
  getCredentialTypes,
  getBlockParameterOptions,
  deleteCredentials,
  connectExpiredTokens,
  updateCredentials,
  disableCredentials,
  renameCredentials,
  getCredentialsCheck,
  shareCredentials,
  getAllVerifiedApps,
  getAllCredentials,
  associateCredential,
} from 'Services/workflowApi';
import { get } from 'lodash';
import { checkCredentialsArray } from 'Constants';
import { NO_UPDATE_CREDENTIALS } from '../constants/credentialTypes';
import { parse } from 'url';

export class CredentialStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  credentialsByType = {};

  @observable
  credentialTypes = [];

  @observable
  credentials = [];

  @observable
  newAuthModal = false;

  @observable
  showCredentialModal = false;

  @observable
  verifiedApps = [];

  @observable
  expiredCredentials = [];

  @action
  getBlockCredentials = (credentialType) => {
    const { activeVersionIsCustom, activeChildUserId, forgeMode, workflow } =
      this.globalStore.workflowStore;
    const { blockValues } = this.globalStore.blockEditStore;

    const userId =
      activeVersionIsCustom && !!activeChildUserId && forgeMode
        ? activeChildUserId
        : this.globalStore.userStore.userInfo._id;

    const isUserSpecific =
      forgeMode &&
      !!activeChildUserId &&
      activeVersionIsCustom &&
      !blockValues?.forgeInternalUse;

    return getCredentialsForBlock(
      userId,
      credentialType,
      workflow ? workflow._id : undefined,
      isUserSpecific
    ).then(
      action((res) => {
        this.credentials = res.data;
        this.credentialsByType[credentialType] = res.data;
        return res.data;
      })
    );
  };

  @action
  getAllVerifiedApps = () => {
    const userId = this.globalStore.userStore.userInfo._id;
    return getAllVerifiedApps(userId).then(
      action((res) => {
        this.verifiedApps = res.data;
        return res.data;
      })
    );
  };

  @action
  createCredential = (body) => {
    return createNewCredentials(body).then(
      action((res) => {
        this.credentials.push(res.data);
      })
    );
  };

  @action
  setNewAuthModal = (isActive) => {
    this.newAuthModal = isActive;
  };

  @action
  setCredentialModal = (isActive) => {
    this.showCredentialModal = isActive;
  };

  @action
  addCredentials = async (
    accountName,
    credentialType,
    credFields,
    fromAccountPage
  ) => {
    if (fromAccountPage) {
      const userId = this.globalStore.userStore.userInfo._id;
      const { currentBlockType } = this.globalStore.blockTypeStore;
      const integrationId =
        this.globalStore?.workflowStore?.integrationInfo?._id;

      let creds = {
        name: accountName,
        userId,
        type: credentialType,
        data: credFields,
        blocksAccess: [{ blockType: currentBlockType.name }],
      };
      integrationId ? (creds.integrationId = integrationId) : null;

      const credResponse = await createNewCredentials(creds);

      const parameters = currentBlockType.properties.filter(
        (p) =>
          p.name !== 'resource' &&
          p.name !== 'operation' &&
          p.name !== 'apiVersion'
      );

      let remoteMethod;

      const apiVersion =
        this.globalStore?.blockEditStore?.blockValues?.parameters?.apiVersion;

      // check if block has block-parameter-options available
      parameters.forEach((parameter) => {
        if (parameter.typeOptions && parameter.typeOptions.loadOptionsMethod) {
          if (apiVersion) {
            const resourceName = get(
              parameter,
              'displayOptions.show.resource.0'
            );

            const resource = currentBlockType.properties.find(
              (p) =>
                p.name === 'resource' &&
                p.options.find((opt) => opt.value === resourceName)
            );

            const apiVersionArray = get(
              resource,
              'displayOptions.show.apiVersion'
            );

            if (apiVersionArray && apiVersionArray.indexOf(apiVersion) > -1) {
              remoteMethod = parameter.typeOptions.loadOptionsMethod;
            }
          } else {
            remoteMethod = parameter.typeOptions.loadOptionsMethod;
          }
        }
      });

      // use checkCredentials for blocks that have it available
      if (checkCredentialsArray.indexOf(currentBlockType.name) !== -1) {
        remoteMethod = 'checkCredentials';
      }

      // no methods to test, assume creds are valid
      if (
        (!remoteMethod ||
          (remoteMethod && remoteMethod === 'shared.listParentBlocks')) &&
        checkCredentialsArray.indexOf(currentBlockType.name) === -1
      ) {
        runInAction(() => {
          this.credentials.push(credResponse.data);
        });

        return credResponse.data;
      }

      try {
        const creds = {};
        creds[credentialType] = credResponse.data._id;

        const mappedCreds = [
          {
            id: credResponse.data._id,
            credentialType,
            data: credResponse.data._id,
          },
        ];

        await getBlockParameterOptions({
          blockType: currentBlockType.name,
          methodName: remoteMethod,
          lightningSetup: true, // use lightningSetup for settings
          credential: creds,
          credentials: mappedCreds,
        });

        // it worked, so proceed
        runInAction(() => {
          this.credentials.push(credResponse.data);
        });

        this.closeNewCredentialModal = true;
        return credResponse.data;
      } catch (err) {
        // TODO bugsnag
        await deleteCredentials(credResponse.data._id);

        return Promise.reject(new Error('Credentials are invalid!'));
      }
    } else {
      const userId = this.globalStore.userStore.userInfo._id;
      const integrationId =
        this.globalStore?.workflowStore?.integrationInfo?._id;
      const { currentBlockType } = this.globalStore.blockTypeStore;
      const workflowId = this.globalStore?.workflowStore?.workflow._id;
      const { blockValues } = this.globalStore.blockEditStore;
      // list of credentials with checkCredentials endpoint available

      let creds = {
        name: accountName,
        userId,
        type: credentialType,
        data: credFields,
        blocksAccess: [{ blockType: currentBlockType.name }],
      };
      integrationId ? (creds.integrationId = integrationId) : null;

      const credResponse = await createNewCredentials(creds);

      const parameters = currentBlockType.properties.filter(
        (p) => p.name !== 'resource' && p.name !== 'operation'
      );
      let remoteMethod;
      const apiVersion =
        this.globalStore?.blockEditStore?.blockValues?.parameters?.apiVersion;
      // check if block has block-parameter-options available
      parameters.forEach((parameter) => {
        if (parameter.typeOptions && parameter.typeOptions.loadOptionsMethod) {
          if (apiVersion) {
            const resourceName = get(
              parameter,
              'displayOptions.show.resource.0'
            );

            const resource = currentBlockType.properties.find(
              (p) =>
                p.name === 'resource' &&
                p.options.find((opt) => opt.value === resourceName)
            );

            const apiVersionArray = get(
              resource,
              'displayOptions.show.apiVersion'
            );

            if (apiVersionArray && apiVersionArray.indexOf(apiVersion) > -1) {
              remoteMethod = parameter.typeOptions.loadOptionsMethod;
            }
          } else {
            remoteMethod = parameter.typeOptions.loadOptionsMethod;
          }
        }
      });

      // use checkCredentials for blocks that have it available
      if (checkCredentialsArray.indexOf(currentBlockType.name) !== -1) {
        remoteMethod = 'checkCredentials';
      }

      // no methods to test, assume creds are valid
      if (
        !remoteMethod &&
        checkCredentialsArray.indexOf(currentBlockType.name) === -1
      ) {
        runInAction(() => {
          this.credentials.push(credResponse.data);
        });

        await this.globalStore.blockEditStore.parameterValueChanged({
          name: `credentials.${credentialType}`,
          value: credResponse.data._id,
        });
        await this.globalStore.blockEditStore.saveBlockValues();
        return credResponse.data;
      }

      try {
        const newCreds = {};
        newCreds[credentialType] = credResponse.data._id;
        const { version } = parse(
          this.globalStore.routingStore.location.search,
          true
        ).query;

        await getBlockParameterOptions({
          blockType: currentBlockType.name,
          methodName: remoteMethod,
          workflowId,
          credentials: newCreds,
          currentBlockParameters: blockValues?.parameters,
          blockId: blockValues.id,
          version,
        });

        // it worked, so proceed
        runInAction(() => {
          this.credentials.push(credResponse.data);
        });
        await this.globalStore.blockEditStore.parameterValueChanged({
          name: `credentials.${credentialType}`,
          value: credResponse.data._id,
        });
        await this.globalStore.blockEditStore.saveBlockValues();
        return credResponse.data;
      } catch (err) {
        // TODO bugsnag
        await deleteCredentials(credResponse.data._id);
        return Promise.reject(new Error('Credentials are invalid!'));
      }
    }
  };

  @action
  addCredentialsFromTemplate = async (
    accountName,
    credentialType,
    credFields,
    currentBlockType,
    createConnection = false
  ) => {
    const userId = this.globalStore.userStore.userInfo._id;

    const blockType = this.globalStore.blockTypeStore.blockTypes.find(
      (block) => {
        if (
          block.credentials &&
          block.credentials.length &&
          block.credentials[0].name
        ) {
          return block.credentials[0].name === currentBlockType;
        }
        return false;
      }
    );

    const blocksAccess = blockType?.name || credentialType; // credentialType is the blockType name

    const credResponse = await createNewCredentials({
      name: accountName,
      userId,
      type: credentialType,
      data: credFields,
      blocksAccess: [{ blockType: blocksAccess }],
      createConnection,
    });

    const parameters = blockType.properties.filter(
      (p) => p.name !== 'resource' && p.name !== 'operation'
    );
    let remoteMethod;

    // check if block has block-parameter-options available
    parameters.forEach((parameter) => {
      if (parameter.typeOptions && parameter.typeOptions.loadOptionsMethod) {
        remoteMethod = parameter.typeOptions.loadOptionsMethod;
      }
    });

    // use checkCredentials for blocks that have it available
    if (checkCredentialsArray.indexOf(blockType.name) !== -1) {
      remoteMethod = 'checkCredentials';
    }

    if (
      (!remoteMethod ||
        (remoteMethod && remoteMethod === 'shared.listParentBlocks')) &&
      checkCredentialsArray.indexOf(currentBlockType.name) === -1
    ) {
      runInAction(() => {
        this.credentials.push(credResponse.data);
      });

      return credResponse.data;
    }
    // no methods to test, assume creds are valid
    if (
      blockType.name === 'alloy.airtable' ||
      (!remoteMethod && checkCredentialsArray.indexOf(blockType.name) === -1)
    ) {
      runInAction(() => {
        this.credentials.push(credResponse.data);
      });

      return credResponse.data;
    }

    try {
      const creds = {};
      creds[credentialType] = credResponse.data._id;

      const mappedCreds = [
        {
          id: credResponse.data._id,
          credentialType,
          data: credResponse.data._id,
        },
      ];

      await getBlockParameterOptions({
        blockType: blockType.name,
        methodName: remoteMethod,
        lightningSetup: true,
        credential: creds,
        credentials: mappedCreds,
      });

      // it worked, so proceed
      runInAction(() => {
        this.credentials.push(credResponse.data);
      });
      return credResponse.data;
    } catch (err) {
      // TODO bugsnag
      await deleteCredentials(credResponse.data._id);

      return Promise.reject(new Error('Credentials are invalid!'));
    }
  };

  @action
  initialize = () => {
    if (this.credentialTypes.length) return Promise.resolve();
    return getCredentialTypes().then(
      action((res) => {
        this.credentialTypes = res.data;
      })
    );
  };

  @action
  connectExpiredToken = (credentialId, body) => {
    return connectExpiredTokens(credentialId, body);
  };

  updateCredential = async (credentialToEdit, credentialTypeToEdit, body) => {
    const userId = this.globalStore.userStore.userInfo._id;
    const { currentBlockType } = this.globalStore.blockTypeStore;

    const credResponse = await createNewCredentials({
      name: credentialToEdit.name,
      userId,
      type: credentialTypeToEdit.name,
      data: body?.data,
      blocksAccess: [{ blockType: currentBlockType.name }],
    });

    const parameters = currentBlockType.properties.filter(
      (p) =>
        p.name !== 'resource' &&
        p.name !== 'operation' &&
        p.name !== 'apiVersion'
    );

    let remoteMethod;

    const apiVersion =
      this.globalStore?.blockEditStore?.blockValues?.parameters?.apiVersion;

    // check if block has block-parameter-options available
    parameters.forEach((parameter) => {
      if (parameter.typeOptions && parameter.typeOptions.loadOptionsMethod) {
        if (apiVersion) {
          const resourceName = get(parameter, 'displayOptions.show.resource.0');
          const resource = currentBlockType.properties.find(
            (p) =>
              p.name === 'resource' &&
              p.options.find((opt) => opt.value === resourceName)
          );
          const apiVersionArray = get(
            resource,
            'displayOptions.show.apiVersion'
          );
          if (apiVersionArray && apiVersionArray.indexOf(apiVersion) > -1) {
            remoteMethod = parameter.typeOptions.loadOptionsMethod;
          }
        } else {
          remoteMethod = parameter.typeOptions.loadOptionsMethod;
        }
      }
    });

    // use checkCredentials for blocks that have it available
    if (checkCredentialsArray.indexOf(currentBlockType.name) !== -1) {
      remoteMethod = 'checkCredentials';
    }

    try {
      const creds = {};
      creds[credentialTypeToEdit.name] = credResponse.data._id;

      const mappedCreds = [
        {
          id: credResponse.data._id,
          credentialType: credentialTypeToEdit.name,
          data: credResponse.data._id,
        },
      ];

      await getBlockParameterOptions({
        blockType: currentBlockType.name,
        methodName: remoteMethod,
        lightningSetup: true, // use lightningSetup for settings
        credential: creds,
        credentials: mappedCreds,
      });

      // it worked, so proceed
      runInAction(() => {
        this.credentials.push(credResponse.data);
      });

      if (NO_UPDATE_CREDENTIALS.includes(credentialTypeToEdit.name)) {
        return credResponse;
      }

      if (credResponse.data._id !== credentialToEdit._id) {
        await deleteCredentials(credResponse.data._id);
      }

      return updateCredentials(credentialToEdit._id, body);
    } catch (err) {
      // TODO bugsnag
      if (credResponse.data._id !== credentialToEdit._id) {
        await deleteCredentials(credResponse.data._id);
      }
      return Promise.reject(new Error('Credentials are invalid!'));
    }
  };

  connectCredential = (id, body) => {
    return disableCredentials(id, body);
  };

  @action
  getCredentialsCheck = () => {
    return getCredentialsCheck();
  };

  @action
  hideDashExpiredCredentials = async () => {
    const user = this.globalStore.userStore.userInfo;
    user.hideDashExpiredCredentials = true;
    await this.globalStore.userStore.updateUser(user);
  };

  @action
  shareCredentials = (teamId, credentialId) => {
    return shareCredentials({
      teamId: teamId,
      credential: credentialId,
    });
  };

  @action
  getExpiredCredentials = async () => {
    this.expiredCredentials = [];

    try {
      const response = await getAllCredentials({ isExpired: true });
      if (response) {
        if (response) {
          runInAction(() => {
            this.expiredCredentials = response.credentials;
          });
          return response.credentials;
        }
      }
    } catch (error) {
      showNotification('error', `Could not retrieve credentials`);
    }
  };

  @action
  associateCredential = (credentialJwt) => {
    return associateCredential(credentialJwt);
  };

  renameCredentials = (credentialId, body) => {
    return renameCredentials(credentialId, body);
  };
}
