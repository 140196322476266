import { observable, action, runInAction } from 'mobx';
import { get } from 'lodash';
import React from 'react';
import {
  getAnalytics,
  getUserStore,
  getPlans,
  payShopify,
  getReferral,
  getBillingHistory,
  activateShopify,
  activateInitialShopify,
  getCredentials,
  dismissTrialMessage,
  getApiKey,
  changePlan,
  deleteCredential,
  cancelPlan,
  getWorkflowsWithCredentials,
  subscribeCapacityPlan,
} from 'Services/myaccount';
import { dismissCreateTeamMessage } from 'Services/teams';
import { showNotification } from 'Components/Notifications/Notification';
import { cancelCapacityPlan } from '../services/myaccount';

export class MyAccountStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  analytics = null;

  @observable
  shop = null;

  @observable
  plans = null;

  @observable
  isEnterpriseAccount = false;

  @observable
  referralCode = null;

  @observable
  billingHistory = [];

  @observable
  billingInfo = null;

  @observable
  credentials = [];

  @observable
  apiKey = null;

  @observable
  isPlanLoading = false;

  @observable
  isAnalyticsLoading = false;

  @observable
  isStoreLoading = false;

  @observable
  isCredentialsLoading = false;

  @action
  getAnalytics = async () => {
    if (!this.hasAccessToken()) return;
    if (this.isAnalyticsLoading) return;

    this.isAnalyticsLoading = true;
    try {
      const response = await getAnalytics();
      if (response && response.success) {
        runInAction(() => {
          this.analytics = response;
        });
      }
    } catch (error) {
      showNotification(
        'error',
        'There was an error fetching your account information. Please refresh and try again'
      );
    } finally {
      this.isAnalyticsLoading = true;
    }
  };

  @action
  getUserStore = async () => {
    this.shop = null;
    let response;

    if (!this.hasAccessToken()) return;
    if (this.isStoreLoading) return;

    this.isStoreLoading = true;

    try {
      response = await getUserStore();
      if (response && response.success) {
        runInAction(() => {
          this.shop = response.shop;
        });
      }
    } catch (error) {
      // showNotification(
      //   'error',
      //   'User does not have a shopify store'
      // );
    } finally {
      this.isStoreLoading = false;
    }
  };

  @action
  getPlans = async () => {
    this.plans = null;
    let response;

    if (!this.hasAccessToken()) return;
    if (this.isPlanLoading) return;

    this.isPlanLoading = true;

    try {
      response = await getPlans();
      if (response) {
        runInAction(() => {
          this.plans = response;
          if (response && response.isEnterprise) {
            this.isEnterpriseAccount = true;
          }
        });
      }
    } catch (error) {
      showNotification('error', 'Could not find any valid plans');
    } finally {
      this.isPlanLoading = false;;
    }
  };

  @action
  payShopify = async (plan, discountCode) => {
    try {
      const response = await payShopify(plan, discountCode);
      if (response && response.success) {
        const parsedUserInfo = JSON.parse(
          localStorage.getItem('workflow-userInfo')
        );
        parsedUserInfo.accessLevel = 'write';
        parsedUserInfo.isTrial = false;
        parsedUserInfo.plan = plan;
        localStorage.setItem(
          'workflow-userInfo',
          JSON.stringify(parsedUserInfo)
        );
        if (response.confirmationUrl) {
          window.location.href = response.confirmationUrl;
        } else {
          showNotification(
            'success',
            `Your plan has successfully been updated.`
          );
          this.getAnalytics();
        }
        return true;
      } else {
        showNotification(
          'error',
          'Something went wrong and your plan was not updated.'
        );
        return false;
      }
    } catch (err) {
      showNotification(
        'error',
        `Unable to make activate your plan: ${err.message}.`
      );
    }
  };

  @action
  dismissTrialExpiredMessage = () => {
    return dismissTrialMessage().then(
      action(() => {
        const parsedUserInfo = JSON.parse(
          localStorage.getItem('workflow-userInfo')
        );
        parsedUserInfo.showTrialExpiredMessage = false;
        localStorage.setItem(
          'workflow-userInfo',
          JSON.stringify(parsedUserInfo)
        );
        this.globalStore.userStore.userInfo.showTrialExpiredMessage = false;
      })
    );
  };

  @action
  dismissCreateTeamMessage = () => {
    const userId = this.globalStore.userStore.userInfo._id;
    return dismissCreateTeamMessage(userId).then(
      action(() => {
        this.globalStore.userStore.userInfo.isCreateTeamMessageDismissed = true;
      })
    );
  };

  @action
  activateShopify = async () => {
    let response;

    try {
      response = await activateShopify();
      if (response && response.success) {
        const parsedUserInfo = JSON.parse(
          localStorage.getItem('workflow-userInfo')
        );
        parsedUserInfo.accessLevel = 'write';
        localStorage.setItem(
          'workflow-userInfo',
          JSON.stringify(parsedUserInfo)
        );

        showNotification(
          'success',
          'Your plan has been successfully activated.'
        );
        return true;
      } else {
        showNotification(
          'error',
          'Something went wrong and your plan was not updated.'
        );
        return false;
      }
    } catch (error) {
      showNotification(
        'error',
        'Unable to activate your shopify plan. Please try again or contact support.'
      );
    }
  };

  @action
  activateInitialShopify = async () => {
    let response;

    try {
      response = await activateInitialShopify();
      if (response && response.success) {
        const parsedUserInfo = JSON.parse(
          localStorage.getItem('workflow-userInfo')
        );
        parsedUserInfo.accessLevel = 'write';
        localStorage.setItem(
          'workflow-userInfo',
          JSON.stringify(parsedUserInfo)
        );

        showNotification(
          'success',
          'Your plan has been successfully activated.'
        );
        return response;
      } else {
        showNotification(
          'error',
          'Something went wrong and your plan was not updated.'
        );
        return false;
      }
    } catch (error) {
      showNotification(
        'error',
        'Unable to activate your shopify plan. Please try again or contact support.'
      );
    }
  };

  @action
  getReferral = async () => {
    if (!this.hasAccessToken()) return;
    try {
      const response = await getReferral();

      if (response) {
        const responseCode = get(response, 'data.code', null);
        if (responseCode) {
          runInAction(() => {
            this.referralCode = responseCode;
          });
        }
      }
    } catch (err) {
      showNotification('error', 'Invalid referral code.');
    }
  };

  @action
  getBillingHistory = async () => {
    if (!this.hasAccessToken()) return;
    try {
      const response = await getBillingHistory();
      if (response) {
        if (response) {
          runInAction(() => {
            this.billingHistory = response.billing;
            this.billingInfo = response.plan;
          });
        }
      }
    } catch (error) {
      showNotification('error', `Could not retrieve recent billing history`);
    }
  };

  @action
  getCredentials = async () => {
    this.credentials = [];

    if (!this.hasAccessToken()) return;
    if (this.isCredentialsLoading) return;

    this.isCredentialsLoading = true;

    try {
      const response = await getCredentials();
      if (response) {
        runInAction(() => {
          this.credentials = response.credentials;
        });
      }
    } catch (error) {
      showNotification('error', `Could not retrieve credentials`);
    } finally {
      this.isCredentialsLoading = false;
    }
  };

  getWorkflowsWithCredentials = async (credentialId) => {
    try {
      const response = await getWorkflowsWithCredentials(credentialId);
      return response;
    } catch (e) {
      showNotification('error', `Something went wrong`);
    }
  };

  @action
  deleteCredential = async (id) => {
    try {
      const response = await deleteCredential(id);
      this.credentials = this.credentials.filter((credential) => credential._id !== id);

      return response;
    } catch (e) {
      showNotification('error', `Something went wrong`);
    }
  };

  @action
  getApiKey = async () => {
    this.apiKey = null;

    if (!this.hasAccessToken()) return;
    try {
      const response = await getApiKey();
      if (response) {
        if (response) {
          runInAction(() => {
            this.apiKey = response.apiKey;
          });
        }
      }
    } catch (error) {
      showNotification('error', `Could not retrieve API Key`);
    }
  };

  @action
  subscribeCapacityPlan = async (
    creditCard,
    billingSchedule,
    anticipatedConnections,
    currentConnections
  ) => {
    // let response;
    return subscribeCapacityPlan(
      creditCard,
      billingSchedule,
      anticipatedConnections,
      currentConnections
    );
  };

  @action
  cancelCapacityPlan = async () => {
    return cancelCapacityPlan();
  };

  @action
  changePlan = async (plan, creditCard, discountCode, billingSchedule) => {
    let response;
    try {
      response = await changePlan(
        plan,
        creditCard,
        discountCode,
        billingSchedule
      );
      if (response && response.success) {
        if (response.confirmationUrl) {
          return response.confirmationUrl;
        }
        return response;
      } else {
        showNotification(
          'error',
          'Something went wrong and your plan was not updated.'
        );
        return false;
      }
    } catch (error) {
      if (error) {
        const errorMessage = get(
          error,
          'response.message',
          'Something went wrong and your plan was not updated.'
        );
        const refreshCredentials = get(
          error,
          'response.refreshCredentials',
          false
        );

        if (refreshCredentials) {
          const credLink = `/account/settings?skid=${refreshCredentials}&uid=${this.globalStore.userStore.userInfo._id}#credentials`;
          showNotification(
            'custom',
            '',
            '',
            <p>
              Could not update plan because your Shopify credentials have
              expired. Please <a href={credLink}>refresh them</a> then try
              again.
            </p>,
            'error'
          );
        } else {
          showNotification('error', `${errorMessage}`);
        }
      }
    }
  };

  @action
  cancelPlan = async (cancellationReason) => {
    let response;
    try {
      response = await cancelPlan(cancellationReason);
      if (response && response.success) {
        return true;
      } else {
        showNotification(
          'error',
          'Something went wrong and your plan was not cancelled.'
        );
        return false;
      }
    } catch (error) {
      if (error) {
        showNotification(
          'error',
          `Could not cancel your plan. ${error.message}.`
        );
      }
    }
  };

  hasAccessToken = () => {
    const accessToken = localStorage.getItem('workflow-accessToken');

    return !!accessToken;
  }

  updateCredentialByIdUI = (credentialId, data) => {
    if (!data) return;

    const credential = this.credentials.find((cred) => cred._id === credentialId);

    if (!credential) return;

    if (data.name) {
      credential.name = data.name;
    }
  }
}
