import { observable, action } from 'mobx';
import { getTemplate, getAllTemplates } from 'Services/email';

export class EmailStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  template = null;

  html = null;

  templates = null;

  @action
  getTemplate = (plan) => {
    return getTemplate(plan).then(
      action((res) => {
        this.template = res.template;
        this.html = res.html;
        return res;
      })
    );
  };

  @action
  getAllTemplates = () => {
    return getAllTemplates().then(
      action((res) => {
        this.templates = res.templates;
        return res;
      })
    );
  };
}
