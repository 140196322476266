/* eslint-disable */
var landingUrl = 'http://localhost:9000';

if (window.location.hostname == 'localhost') {
  landingUrl = 'http://localhost:9000';
} else if (
  window.location.hostname == 'runalloy.com' ||
  window.location.hostname == 'www.runalloy.com' ||
  window.location.hostname == 'app.runalloy.com'
) {
  landingUrl = 'https://runalloy.com';
} else if (window.location.hostname == 'dev-app.runalloy.com') {
  landingUrl = 'https://dev.runalloy.com';
} else {
  //pr deployment
  landingUrl = 'https://dev.runalloy.com';
}


export default landingUrl;
