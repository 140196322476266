import { observable, action, runInAction, computed, toJS } from 'mobx';
import {
  get,
  set,
  unset,
  isUndefined,
  isNil,
  isString,
  forEach,
  isEmpty,
  isEqual,
} from 'lodash';
import { uuid4 } from 'Utilities/uuid';
import { getBlockCredentialIssues } from 'Utilities/block-helpers';
import * as BlockHelpers from 'Utilities/workflow-classes/BlockHelpers';
import { updateWorkflow, moveBlock, addBlock } from 'Services/workflowApi';
import { showNotification } from 'Components/Notifications/Notification';
import 'core-js-pure/stable/atob';
import 'core-js-pure/stable/btoa';
import { countIssues } from '../utils';

export class BlockEditStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  oneStepBlocks = ['alloy.webhook', 'alloy.cron', 'alloy.sdk'];

  @observable
  triggerDescriptions = '';

  @observable
  parameterChanged = false;

  @observable
  currentBlockId = null;

  @observable
  blockValues = null;

  @observable
  draggingBlock = false;

  @observable
  draggingBlockType = null;

  @observable
  draggingBlockIndex = -1;

  @observable
  draggingBlockId = null;

  @observable
  selectedPlusIndex = 0;

  @observable
  connectionIndex = 0;

  @observable
  isEnd = false;

  @observable
  showReqLabel = false;

  @observable
  showAddLabel = false;

  @observable
  showLeftLoader = false;

  @observable
  droppedBlock = false;

  @observable
  showRequiredWarnings = false;

  @observable
  parameterOptions = [];

  @observable
  actionIssues = {};

  @observable
  credIssues = {};

  @observable
  paramIssues = {};

  @observable
  timer = null;

  @observable
  leftPanelVisible = true;

  @observable
  videoTutorialVisible = false;

  @observable
  dynamicMode = {};

  @observable
  selectedBlockId = null;

  @observable
  accountVariableSelectorValue = null;

  @observable
  blockLoading = false;

  @computed
  get getBlockParameterResource() {
    return this.blockValues?.parameters?.resource;
  }

  @computed
  get getBlockParameterOperation() {
    return this.blockValues?.parameters?.operation;
  }

  @computed
  get countCredIssues() {
    return countIssues(this.credIssues);
  }

  @computed
  get countActionIssues() {
    return countIssues(this.actionIssues);
  }

  @computed
  get countParamIssues() {
    return countIssues(this.paramIssues);
  }

  @action
  updateTriggerDescription = (description) => {
    this.triggerDescriptions = description;
    this.blockValues.subtitle = `Trigger workflow when ${description}`;
  };

  @action
  updateDynamicMode = (blockId, checked) => {
    this.dynamicMode[blockId] = checked;
  };

  @action
  toggleLeftPanel = () => {
    this.leftPanelVisible = !this.leftPanelVisible;
  };

  @action
  toggleVideoTutorial = () => {
    this.videoTutorialVisible = !this.videoTutorialVisible;
  };

  @action
  startDragging = (blockType, blockId) => {
    this.draggingBlock = true;
    this.draggingBlockType = blockType;
    this.showLeftLoader = true;
    if (blockId) {
      this.draggingBlockId = blockId;
      this.draggingBlockIndex =
        this.globalStore.workflowStore.workflow.blocks.findIndex(
          (block) => block.id === blockId
        );
    }
  };

  @action
  finishDragging = () => {
    setTimeout(() => {
      this.draggingBlock = false;
      this.draggingBlockType = null;
      this.draggingBlockIndex = -1;
      if (!this.droppedBlock) this.showLeftLoader = false;
    }, 1000);
  };

  @action
  prepareToAddBlock = (payload) => {
    this.leftPanelVisible = true;
    this.currentBlockId = null;
    this.parentBlockId = payload.parentBlockId;
    this.selectedPlusIndex = payload.selectedPlusIndex;
    this.connectionIndex = payload.connectionIndex;
    this.isEnd = !!payload.isEnd;
    this.selectedBlockId = payload.selectedBlockId;
  };

  @action
  changeRunData = async (index) => {
    const { currentBlockType, isTrigger } = this.globalStore.blockTypeStore;
    if (!this.blockValues.sampleLiveData) {
      return;
    }
    const resources = currentBlockType.properties.find(
      (prop) => prop.name === 'resource'
    );

    const resource =
      this.blockValues.parameters.resource &&
      resources?.options?.find(
        (rs) => rs.value === this.blockValues.parameters.resource
      );

    const operations = currentBlockType.properties.find((prop) =>
      resource
        ? prop.name === 'operation' &&
          get(prop, 'displayOptions.show.resource') &&
          get(prop, 'displayOptions.show.resource').includes(resource.value)
        : prop.name === 'operation'
    );

    const operation = operations?.options?.find(
      (op) => op.value === this.blockValues.parameters.operation
    );
    if (this.blockValues.sampleLiveData) {
      if (isTrigger) {
        if (Array.isArray(this.blockValues.sampleLiveData)) {
          this.blockValues.testBlockRunData = {
            [this.blockValues.id]: {
              data: [
                [
                  {
                    json: this.blockValues.sampleLiveData[index],
                    id: 'whatever',
                  },
                ],
              ],
            },
          };
        } else {
          const keys = Object.keys(this.blockValues.sampleLiveData);
          let actualTestData = {};
          if (Array.isArray(this.blockValues.sampleLiveData[keys[0]])) {
            actualTestData[keys[0]] =
              this.blockValues.sampleLiveData[keys[0]][0];
          } else {
            actualTestData = this.blockValues.sampleLiveData;
          }
          this.blockValues.testBlockRunData = {
            [this.blockValues.id]: {
              data: [[{ json: actualTestData, id: 'whatever' }]],
            },
          };
        }
      } else {
        const keys = Object.keys(this.blockValues.sampleLiveData);
        let actualTestData = {};
        if (
          operation &&
          operation.dummyArray &&
          Array.isArray(this.blockValues.sampleLiveData[keys[0]])
        ) {
          actualTestData[keys[0]] = this.blockValues.sampleLiveData[keys[0]][0];
        } else {
          actualTestData = this.blockValues.sampleLiveData;
        }
        this.blockValues.testBlockRunData = {
          [this.blockValues.id]: {
            data: [[{ json: actualTestData, id: 'whatever' }]],
          },
        };
      }
    }
    const blockIndex = this.globalStore.workflowStore.workflow.blocks.findIndex(
      (n) => n.id === this.blockValues.id
    );
    if (blockIndex > -1) {
      this.globalStore.workflowStore.workflow.blocks[blockIndex] =
        this.blockValues;
      this.globalStore.workflowStore.workflow.blocks[blockIndex].updatedAt =
        new Date();
      // Adding this condition bcs It's updating update __ ago on initial render.
      if (index !== 0) {
        await updateWorkflow(this.globalStore.workflowStore.workflow._id, {
          ...this.globalStore.workflowStore.workflow,
          childUserId: this.globalStore.workflowStore.activeChildUserId,
          activeVersion: this.globalStore.workflowStore.activeVersion,
          isCurrentCustomVersion:
            this.globalStore.workflowStore.isCurrentCustomVersion,
          activeVersionId:
            this.globalStore.workflowStore.activeWorkflowVersion?._id,
        });
      }
    }
  };

  @action
  moveBlock = (moveData) => {
    const { draggingBlockToIndex, connectionIndex, isEnd } = moveData;
    const { draggingBlockIndex } = this;

    // TODO: do not let if block insert its own branch
    if (draggingBlockIndex === -1) return;
    this.droppedBlock = true;
    const {
      workflow,
      activeVersion,
      activeChildUserId,
      activeWorkflowVersion,
    } = this.globalStore.workflowStore;
    return moveBlock(workflow._id, {
      draggingBlockToIndex,
      connectionIndex,
      isEnd,
      draggingBlockIndex,
      ...(activeChildUserId ? { childUserId: activeChildUserId } : {}),
      ...(activeVersion ? { version: activeVersion } : {}),
      ...(activeWorkflowVersion
        ? { activeVersionId: activeWorkflowVersion?._id }
        : {}),
    })
      .then(
        action((res) => {
          const updatedWorkflow = res.data;
          this.globalStore.workflowStore
            .updateWorkflowInStore(updatedWorkflow)
            .then(() => {
              this.showLeftLoader = false;
              this.droppedBlock = false;
            });
        })
      )
      .catch((e) => {
        showNotification('error', 'Could not move block');
        this.showLeftLoader = false;
        this.droppedBlock = false;
      });
  };

  @action
  setBlockLoading = (loading) => {
    this.blockLoading = loading;
  };

  @action
  setActiveBlock = async (payload) => {
    this.globalStore.selectActionStore.showLogsDrawer = false;
    this.showLeftLoader = true;
    this.leftPanelVisible = true;

    const { userInfo } = this.globalStore.userStore;
    if (!userInfo || !userInfo._id) return;

    let blockData;
    const {
      workflow,
      forgeMode,
      activeVersion,
      activeChildUserId,
      activeWorkflowVersion,
      activeVersionIsCustom,
    } = this.globalStore.workflowStore;
    if (payload?.id) {
      const blockIndex = workflow.blocks.findIndex((n) => n.id === payload.id);
      blockData = workflow.blocks[blockIndex];
    }
    const blockTypeName = this.draggingBlockType
      ? this.draggingBlockType
      : payload.blockType;
    const blockType = await this.globalStore.blockTypeStore.findByName(
      blockTypeName
    );
    this.blockValues = blockType;
    if (
      !blockType ||
      !workflow ||
      !userInfo ||
      !userInfo._id ||
      payload.fromCredentials
    ) {
      this.showLeftLoader = false;
      return; // maybe throw an error here
    }
    const isTrigger = blockType.group.includes('trigger');
    const blockTypeCredentials = get(blockType, `credentials`, []);

    let credentialName;
    if (blockTypeCredentials.length > 0) {
      const { blockValues } = this;
      if (!blockData) {
        blockData = blockValues;
      }
      let apiVersion;

      if (blockData?.parameters?.apiVersion) {
        apiVersion = blockData?.parameters?.apiVersion;
      }
      const currentBlock = workflow.blocks.find((n) => n.id === payload.id);

      const credTypeIndex =
        blockType?.credentials &&
        blockType?.credentials?.filter(
          (cred) => !!get(cred, 'displayOptions.show.apiVersion')
        );

      if (apiVersion && credTypeIndex?.length !== 0) {
        const credential =
          blockType?.credentials &&
          blockType.credentials.length === 1 &&
          !blockType.credentials[0]?.displayOptions
            ? blockType?.credentials[0]
            : blockType.credentials?.find(
                (cred) =>
                  cred?.displayOptions?.show?.apiVersion?.findIndex(
                    (v) => v === apiVersion
                  ) >= 0
              );
        credentialName = credential.name;
      } else {
        credentialName =
          blockType.credentials && blockType?.credentials[0]?.name;
      }

      if (blockType.name === 'alloy.httpRequest') {
        const selectedCreds = get(
          currentBlock,
          'parameters.authentication',
          'none'
        );
        blockType.credentials.forEach((cred) => {
          const displayOptions = get(
            cred,
            'displayOptions.show.authentication',
            'none'
          );
          if (displayOptions && displayOptions.includes(selectedCreds)) {
            credentialName = cred.name;
          }
        });
      }
    }
    await this.globalStore.credentialStore.getBlockCredentials(credentialName);

    // about ifBlockBottonPart
    // null -> common block , not [if] block
    // true -> [if] block , bottom add button clicked
    // false -> [if] block, top add button clicked
    // dragging block
    const { draggingBlock } = this;
    const draggingBlockToIndex =
      draggingBlock && payload.draggingBlockToIndex !== undefined
        ? payload.draggingBlockToIndex
        : this.draggingBlockToIndex;
    const connectionIndex =
      draggingBlock && payload.connectionIndex !== undefined
        ? payload.connectionIndex
        : this.connectionIndex;
    const index =
      draggingBlock && draggingBlockToIndex !== -1
        ? draggingBlockToIndex + 1
        : this.selectedPlusIndex;
    const parentBlockId =
      draggingBlock && payload.parentBlockId
        ? payload.parentBlockId
        : this.parentBlockId;
    const isEnd = draggingBlock ? payload.isEnd : this.isEnd;

    const id = draggingBlock
      ? this.draggingBlockIndex > -1
        ? payload.id
        : null
      : payload.id;

    if (id) {
      this.globalStore.workflowStore.pullFromBlocksWithWarnings(id);
    }

    // save current blockValues when switching blocks since the UI is perpetually in an 'edit' state
    if (this.blockValues && this.blockValues.id && this.blockValues.id !== id) {
      const blockIndex = workflow.blocks.findIndex(
        (n) => n.id === this.blockValues.id
      );
      if (blockIndex > -1) {
        workflow.blocks[blockIndex] = this.blockValues;
        workflow.blocks[blockIndex].updatedAt = new Date();
        if (id) {
          workflow.settings.currentBlockId = id;
        }
        const { stepNum } = this.globalStore.selectActionStore;
        if (stepNum !== undefined) {
          if (!workflow.settings.stepTracker) {
            workflow.settings.stepTracker = {};
          }
          workflow.settings.stepTracker[this.blockValues.id] =
            this.globalStore.selectActionStore.stepNum;
        }
        if (!workflow.settings.disableResults) {
          workflow.settings.disableResults = {};
        }
        workflow.settings.disableResults[this.blockValues.id] =
          this.globalStore.selectActionStore.disableResults;
        const updated = activeVersion
          ? {
              ...workflow,
              activeVersion: this.globalStore.workflowStore.activeVersion,
              isCurrentCustomVersion:
                this.globalStore.workflowStore.isCurrentCustomVersion,
              activeVersionId:
                this.globalStore.workflowStore.activeWorkflowVersion?._id,
              childUserId: activeChildUserId,
            }
          : workflow;
        await updateWorkflow(workflow._id, updated);
      }
    }

    let newBlock = workflow.blocks.find((n) => n.id === id);

    if (!newBlock) {
      const body = {
        parentBlockId,
        isEnd,
        index: isTrigger ? 0 : index && index > 0 ? index : 1,
        connectionIndex,
        replaceTriggerBlock:
          this.globalStore.selectActionStore.replaceTriggerBlock,
        blockType: blockType.name,
        replaceRegularBlock:
          this.globalStore.selectActionStore.replaceRegularBlock,
        ...(activeVersion
          ? {
              version: activeVersion,
              customVersion: workflow.customVersion || activeVersionIsCustom,
              childUserId: activeChildUserId,
              activeVersionId: activeWorkflowVersion?._id?.toString(),
            }
          : {}),
      };
      if (payload.copyBlock) {
        body.copyBlock = payload.copyBlock;
      }

      const workflowId = activeChildUserId
        ? workflow.forgeWorkflowId
        : workflow._id;
      body.forgeInternalUse = this.blockValues.forgeInternalUse;
      const res = await addBlock(workflowId, body);
      const { newBlockId } = res.data;
      const updatedWorkflow = res.data.workflow;
      // if trigger block, check if there are blocks referenced to expression editor of trigger block
      if (this.globalStore.selectActionStore.replaceTriggerBlock) {
        await this.globalStore.workflowStore.removeReferencedExpression(
          res.data.workflow.blocks,
          workflow.blocks[0].id
        );
        await this.globalStore.workflowStore.removeForgeEditableFields(
          this.globalStore.selectActionStore.replaceRegularBlock
        );
      }

      // if replace block,  check if there are blocks referenced to expression editor of trigger block
      if (this.globalStore.selectActionStore.replaceRegularBlock) {
        await this.globalStore.workflowStore.removeReferencedExpression(
          res.data.workflow.blocks,
          this.globalStore.selectActionStore.replaceRegularBlock
        );
        await this.globalStore.workflowStore.removeForgeEditableFields(
          this.globalStore.selectActionStore.replaceRegularBlock
        );
      }

      // should get errors here before updating the workflow
      newBlock = updatedWorkflow.blocks.find((n) => n.id === newBlockId);
      const newBlockIndex = updatedWorkflow.blocks.findIndex(
        (n) => n.id === newBlockId
      );

      if (forgeMode && newBlock.type === 'alloy.webhook') {
        newBlock.forgeInternalUse = true;
      }

      this.globalStore.blockTypeStore.setCurrentBlockType(blockType.name);
      this.blockValues = newBlock;
      this.updateIssues();

      updatedWorkflow.blocks[newBlockIndex] = this.blockValues;

      if (
        Object.keys(this.globalStore.workflowStore.forgeEditableFields).length >
        0
      ) {
        updatedWorkflow.forgeEditableFields =
          this.globalStore.workflowStore.forgeEditableFields;
      } else {
        delete updatedWorkflow.forgeEditableFields;
      }

      newBlock = this.blockValues;
      await this.globalStore.workflowStore.updateWorkflowInStore(
        updatedWorkflow
      );

      if (
        this.globalStore.selectActionStore.replaceTriggerBlock ||
        this.globalStore.selectActionStore.replaceRegularBlock
      ) {
        this.updateAllIssues();
        this.blockValues = newBlock;
      }

      const updated = activeVersion
        ? {
            ...updatedWorkflow,
            activeVersion: this.globalStore.workflowStore.activeVersion,
            isCurrentCustomVersion:
              this.globalStore.workflowStore.isCurrentCustomVersion,
            activeVersionId:
              this.globalStore.workflowStore.activeWorkflowVersion?._id,
            childUserId: activeChildUserId,
          }
        : updatedWorkflow;
      await updateWorkflow(workflow._id, updated);
    }

    runInAction(() => {
      // since this is an async function
      this.globalStore.blockTypeStore.setCurrentBlockType(blockType.name);
      this.blockValues = newBlock;
      this.currentBlockId = newBlock.id;
      this.updateIssues();
      this.showLeftLoader = false;
      this.parameterChanged = false;
      this.showRequiredWarnings = false;
      this.blockLoading = false;
    });

    if (payload.activeEdit) {
      this.globalStore.selectActionStore.setEditMode();
    }
    if (payload.copyBlock && !payload.copyBranch) {
      showNotification('success', 'Block Pasted.');
    }
    return newBlock.id;
  };

  @action
  updateIssues = (nType = null) => {
    // if ntype => it is from updateAllIssues => do not check from cred issues as all block cred wont be available in the credStore
    const { credentialTypes } = this.globalStore.credentialStore;
    const { credentials } = this.globalStore.credentialStore;
    const blockType = nType || this.globalStore.blockTypeStore.currentBlockType;
    const blockId = this.globalStore.blockEditStore.currentBlockId;
    const { forgeEditableFields, forgeMode } = this.globalStore.workflowStore;

    if (!blockType) return;
    const fullBlockIssues = BlockHelpers.getBlockParametersIssues(
      blockType.properties,
      this.blockValues,
      forgeMode && forgeEditableFields,
      blockId,
      forgeMode
    );

    let newIssues = null;

    if (
      fullBlockIssues !== null &&
      blockType &&
      blockType.hasOwnProperty('credentials') &&
      blockType.credentials.length &&
      blockType.credentials[0].required &&
      (!this.oneStepBlocks.includes(blockType.name) ||
        (blockType.group && blockType.group.includes('transform')))
    ) {
      if (
        this.blockValues &&
        ((this.blockValues.hasOwnProperty('credentials') &&
          Object.keys(this.blockValues.credentials).length) ||
          (forgeMode && !this.blockValues?.forgeInternalUse))
      ) {
        newIssues = fullBlockIssues.parameters;
      } else {
        const filteredBlockIssues = Object.keys(fullBlockIssues.parameters)
          .filter((key) => ['operation', 'resource'].includes(key))
          .reduce((obj, key) => {
            obj[key] = fullBlockIssues.parameters[key];
            return obj;
          }, {});
        newIssues = filteredBlockIssues;
      }
    } else if (fullBlockIssues !== null) {
      newIssues = fullBlockIssues.parameters;
    }

    if (newIssues === null) {
      if (!!this.blockValues.issues && !!this.blockValues.issues.parameters) {
        delete this.blockValues.issues.parameters;
      }
    } else {
      if (!this.blockValues.issues) this.blockValues.issues = {};
      this.blockValues.issues.parameters = newIssues;
      this.globalStore.workflowStore.workflowHasIssues = true;
    }
    let newCredIssues = null;
    if (!nType) {
      const fullBlockCredIssues = getBlockCredentialIssues(
        this.blockValues,
        blockType,
        credentialTypes,
        credentials
      );

      if (fullBlockCredIssues !== null) {
        newCredIssues = fullBlockCredIssues.credentials;
      }
    }
    if (newCredIssues === null) {
      if (!!this.blockValues.issues && !!this.blockValues.issues.credentials) {
        delete this.blockValues.issues.credentials;
      }
    } else {
      if (!this.blockValues.issues) this.blockValues.issues = {};
      this.blockValues.issues.credentials = newCredIssues;
      this.globalStore.workflowStore.workflowHasIssues = true;
    }
    if (this.blockValues && this.blockValues.hasOwnProperty('issues')) {
      if (
        this.blockValues.issues.parameters &&
        Object.keys(this.blockValues.issues.parameters).length === 0
      ) {
        delete this.blockValues.issues.parameters;
      }
      const actionIssues = this.blockValues.issues.hasOwnProperty('parameters')
        ? Object.keys(this.blockValues.issues.parameters)
            .filter((key) => ['operation', 'resource'].includes(key))
            .reduce((obj, key) => {
              obj[key] = this.blockValues.issues.parameters[key];
              return obj;
            }, {})
        : null;

      const credIssues = this.blockValues.issues.hasOwnProperty('credentials')
        ? this.blockValues.issues.credentials
        : null;
      const paramIssues = this.blockValues.issues.hasOwnProperty('parameters')
        ? Object.keys(this.blockValues.issues.parameters)
            .filter((key) => !['operation', 'resource'].includes(key))
            .reduce((obj, key) => {
              const blockKeyPath = blockId + '::' + key;
              let forgeSelected = false;
              if (blockType?.name === 'alloy.branch') {
                const blockParam = key.split('::')[0];
                const uuid = key.split('::')[1];
                forgeSelected = Object.values(forgeEditableFields).find(
                  (field) =>
                    field.fieldBlockId === blockId &&
                    field.fieldValue === blockParam &&
                    field.branchUuid === uuid
                );
              }
              if (blockType?.name === 'alloy.if') {
                const blockParam = key.split('::')[0];

                forgeSelected = Object.values(forgeEditableFields).find(
                  (field) =>
                    field.fieldBlockId === blockId &&
                    field.fieldValue === blockParam
                );
              }
              if (!forgeEditableFields[blockKeyPath] && !forgeSelected) {
                obj[key] = this.blockValues.issues.parameters[key];
              }
              return obj;
            }, {})
        : null;
      this.actionIssues = actionIssues;
      this.credIssues = credIssues;
      this.paramIssues = paramIssues;
    }
  };

  @action
  updateAllIssues = () => {
    const { workflow } = this.globalStore.workflowStore;
    workflow.blocks.forEach((block) => {
      const blockType = this.globalStore.blockTypeStore.findByName(block.type);
      this.blockValues = block;
      this.updateIssues(blockType);
    });
  };

  @action
  saveBlockValues = async () => {
    clearTimeout(this.timer);

    const { workflow, activeVersion, activeChildUserId } =
      this.globalStore.workflowStore;
    const isActive = workflow.active;
    const blockId = this.blockValues.id;
    const blockIndex = workflow.blocks.findIndex((n) => n.id === blockId);
    if (blockIndex === -1) {
      return;
    }
    workflow.blocks.splice(blockIndex, 1, this.blockValues);

    if (this.parameterChanged) {
      const { blocks } = workflow;
      this.globalStore.workflowStore.removeReferencedExpression(
        blocks,
        blockId
      );

      workflow.blocks[blockIndex].updatedAt = new Date();
    }

    const updated = activeVersion
      ? {
          ...workflow,
          activeVersion: this.globalStore.workflowStore.activeVersion,
          isCurrentCustomVersion:
            this.globalStore.workflowStore.isCurrentCustomVersion,
          activeVersionId:
            this.globalStore.workflowStore.activeWorkflowVersion?._id,
          childUserId: activeChildUserId,
        }
      : workflow;
    return updateWorkflow(workflow._id, updated)
      .then(
        action((res) => {
          if (isActive && !res.data.active) {
            this.globalStore.executionStore.removeRunningWorkflow(res.data._id);
            this.globalStore.executionStore.workflowRecentlyDeactivated = true;
          }

          if (!res.data.blocks[blockIndex].testBlockRunData) {
            this.globalStore.workflowStore.setExpressionData(blockId, null);
          }
          return this.globalStore.workflowStore.updateWorkflowInStore(res.data);
        })
      )
      .catch(this.addCredentialError);
  };

  @action
  addCredentialError = (error) => {
    // TODO bugsnag
    if (
      error &&
      error.hasOwnProperty('response') &&
      error.response.hasOwnProperty('message') &&
      error.response.message.indexOf('credentials') > -1
    ) {
      const errorMsg = error.response.message;
      const credentialName = Object.keys(this.blockValues.credentials)[0];
      if (!this.blockValues.issues) this.blockValues.issues = {};
      if (!this.blockValues.issues.credentials)
        this.blockValues.issues.credentials = {};
      if (!this.blockValues.issues.credentials[credentialName]) {
        this.blockValues.issues.credentials[credentialName] = [errorMsg];
      } else if (
        this.blockValues.issues.credentials[credentialName].indexOf(
          errorMsg
        ) === -1
      ) {
        this.blockValues.issues.credentials[credentialName].push(errorMsg);
      }
      // workflow.blocks[blockIndex].issues = this.blockValues.issues;
    }
  };

  @action
  resetParameterValue = () => {
    const { resource, operation } = this.blockValues.parameters;
    this.blockValues.parameters = {
      resource,
      operation,
    };
    this.credentials = {};
    this.updateIssues();
  };

  @action
  deleteDependentValues = (values) => {
    if (values.length) {
      values.forEach((value) => {
        const parameterPreviewName = btoa(value.path);

        delete this.blockValues.parameters[value.name];
        if (this.blockValues.preview[parameterPreviewName]) {
          delete this.blockValues.preview[parameterPreviewName];
        }
      });
      this.updateIssues();
    }
  };

  @action
  changeCustomAction = async (operation) => {
    const customAction = this.globalStore.customActionStore.customActions?.find(
      (e) => e._id === operation
    );
    const deepCopyCustomAction =
      customAction && JSON.parse(JSON.stringify(customAction));
    if (customAction) {
      this.blockValues.parameters = deepCopyCustomAction.parameters;
      this.blockValues.preview = deepCopyCustomAction.preview;
      this.blockValues.parameters.operation = deepCopyCustomAction._id;
      this.blockValues.parameters.customActionId = deepCopyCustomAction._id;
      this.blockValues.credentials = deepCopyCustomAction.credentials || {};
      this.blockValues.credentialName = deepCopyCustomAction.credentialName;
      if (Object.keys(this.blockValues.credentials).length > 0) {
        await this.globalStore.credentialStore.getBlockCredentials(
          Object.keys(deepCopyCustomAction.credentials)[0]
        );
      }
    }
    return;
  };

  @action
  parameterValueChanged = async (parameterData) => {
    let newValue;
    const { versionStatus, forgeEditableFields } =
      this.globalStore.workflowStore;
    if (!parameterData || (versionStatus && versionStatus === 'final')) return;

    const parameterPreviewName = btoa(parameterData.name);
    const preventSave = this.blockValues.isTrigger
      ? true
      : !!parameterData.preventSave;
    let parameterActuallyChanged = false;
    let skipTimer = !!parameterData.skipTimer;
    if (parameterData.hasOwnProperty('value')) {
      // New value is given
      newValue = parameterData.value;
    } else {
      // Get new value from blockData where it is set already
      newValue = get(this.blockValues, parameterData.name);
    }
    const blockParameters = JSON.parse(JSON.stringify(this.blockValues));

    // Remove the 'parameters.' from the beginning to just have the
    // actual parameter name
    const parameterPath = parameterData.name;
    // Check if the path is supposed to change an array and if so get
    // the needed data like path and index
    const parameterPathArray = parameterPath.match(/(.*)\[(\d+)\]$/);
    // Apply the new value
    if (parameterData.value === undefined && parameterPathArray !== null) {
      // Delete array item
      const path = parameterPathArray[1];
      const index = parameterPathArray[2];
      const data = get(blockParameters, path);

      if (Array.isArray(data)) {
        parameterActuallyChanged = true;
        const previousMaxIndex = data.length - 1;
        data.splice(parseInt(index, 10), 1);

        set(this.blockValues, path, data);
        const oldPreview = this.blockValues.preview;
        const newPreview = {};
        const currentPreviewKeys = this.blockValues.preview
          ? Object.keys(this.blockValues.preview).map((key) => ({
              b64: key,
              text: atob(key),
            }))
          : [];
        currentPreviewKeys.forEach((key) => {
          if (!key.text.includes(path)) {
            newPreview[key.b64] = oldPreview[key.b64];
          }
        });
        if (previousMaxIndex === parseInt(index, 10)) {
          // just copy all the old data over except the fields that are in the deleted object at the end
          currentPreviewKeys.forEach((key) => {
            if (!key.text.includes(parameterPath)) {
              newPreview[key.b64] = oldPreview[key.b64];
            }
          });
          this.blockValues.preview = newPreview;
        } else {
          let newPreviewIndex = 0;

          for (let i = 0; i <= previousMaxIndex; i++) {
            if (i !== parseInt(index)) {
              const oldParentPath = `${path}[${i}]`;

              const oldFoundKeys = currentPreviewKeys.filter((key) =>
                key.text.includes(oldParentPath)
              );

              const oldParentLength = oldParentPath.length;

              oldFoundKeys.forEach((key) => {
                const child = key.text.substring(oldParentLength); // ex: '.value'
                const newKey = `${path}[${newPreviewIndex}]${child}`;
                set(newPreview, btoa(newKey), oldPreview[key.b64]);
              });

              newPreviewIndex++;
            }
          }

          this.blockValues.preview = newPreview;
        }

        if (
          parameterData?.action === 'delete' &&
          forgeEditableFields &&
          Object.keys(forgeEditableFields)?.length > 0
        ) {
          const variablePath = path.slice(11);
          const forgeEditableFieldPath = `${this.currentBlockId}::${variablePath}[${index}]`;
          const forgeEditableFieldsKeys = Object.keys(forgeEditableFields);
          const forgeEditableKeyPaths = forgeEditableFieldsKeys.filter(
            (field) => field.includes(forgeEditableFieldPath)
          );
          if (forgeEditableKeyPaths?.length) {
            forgeEditableKeyPaths.forEach((field) => {
              delete forgeEditableFields[field];
              this.updateIssues();
            });
          }
        }
      }
    } else {
      // For everything else
      // eslint-disable-next-line no-lonely-if
      if (parameterData.action && parameterData.action === 'delete') {
        unset(this.blockValues, parameterData.name);
        if (
          this.blockValues.preview &&
          this.blockValues.preview[parameterPreviewName]
        )
          unset(this.blockValues.preview, parameterPreviewName);
        parameterActuallyChanged = true;
      } else {
        const currentValue = get(this.blockValues, parameterData.name);

        if (currentValue !== newValue) {
          parameterActuallyChanged = true;
        }

        if (
          (this.blockValues.type === 'alloy.branch' ||
            this.blockValues.type === 'alloy.errorHandler') &&
          parameterPath === 'parameters.branches'
        ) {
          const { workflow } = this.globalStore.workflowStore;
          const connections =
            workflow.connections && workflow.connections[this.currentBlockId];

          if (
            newValue &&
            currentValue &&
            newValue.length > currentValue.length &&
            connections &&
            connections.main &&
            connections.main.length === currentValue.length + 1
          ) {
            workflow.connections[this.currentBlockId].main.splice(
              connections.main.length - 1,
              0,
              []
            );
            skipTimer = true;
          }
        } else if (
          parameterData.unsetExisting &&
          this.blockValues.preview &&
          Array.isArray(currentValue)
        ) {
          // clear existing previews since we're clearing the parameters
          const previewKeys = Object.keys(this.blockValues.preview);
          previewKeys.forEach((key) => {
            const keyText = atob(key);
            if (keyText.includes(parameterData.name)) {
              unset(this.blockValues.preview, key);
            }
          });
        }

        if (
          parameterActuallyChanged &&
          (parameterData.name === 'parameters.resource' ||
            parameterData.name === 'parameters.operation')
        ) {
          this.clearStep3Params();
          if (this.globalStore.workflowStore.forgeMode) {
            this.globalStore.workflowStore.removeForgeEditableFields(
              this.currentBlockId
            );
          }
        }

        if (parameterActuallyChanged) {
          this.globalStore.workflowStore.pullFromBlockPropsWithWarnings(
            this.currentBlockId,
            parameterData.name
          );
        }

        set(this.blockValues, parameterData.name, newValue);

        if (parameterData.preview) {
          if (!this.blockValues.preview) this.blockValues.preview = {};

          if (
            !isEqual(
              parameterData.preview,
              this.blockValues.preview[parameterPreviewName]
            )
          ) {
            parameterActuallyChanged = true;
          }
          this.blockValues.preview[parameterPreviewName] =
            parameterData.preview;
          if (!parameterData.value) {
            delete this.blockValues.preview[parameterPreviewName];
          }
        }
      }
    }

    if (this.blockValues.type === 'alloy.for') {
      const subheader = this.parameterOptions.find(
        (option) => option.value === this.blockValues.parameters.collectionField
      );
      if (subheader) {
        this.blockValues.subtitle = ` Loop through ${subheader.name}`;
      }
    }

    if (
      this.blockValues.type === 'alloy.errorHandler' &&
      get(parameterData, 'name') === 'parameters.executionStyle' &&
      get(parameterData, 'value') === 'process'
    ) {
      this.blockValues.parameters.branches = [
        { uuid: uuid4(), label: 'success' },
        { uuid: uuid4(), label: 'error' },
      ];
      const { workflow } = this.globalStore.workflowStore;
      const currentConnections =
        workflow.connections && workflow.connections[this.currentBlockId];
      const endConnections =
        get(currentConnections, 'main') &&
        get(currentConnections, 'main').length
          ? [
              currentConnections.main[
                get(currentConnections, 'main').length - 1
              ],
            ]
          : [];
      set(currentConnections, 'main', [[], [], ...endConnections]);
    }
    this.updateIssues();
    if (
      parameterActuallyChanged &&
      (this.blockValues.type === 'alloy.for' ||
        this.blockValues.isTrigger ||
        parameterData.name === 'parameters.resource' ||
        parameterData.name === 'parameters.operation' ||
        parameterData.name.includes('credentials'))
    ) {
      this.parameterChanged = true;
    }
    this.globalStore.selectActionStore.setDisableResult(true);
    if (this.blockValues.issues && this.blockValues.issues.execution)
      delete this.blockValues.issues.execution;

    clearTimeout(this.timer);
    if (parameterActuallyChanged && !preventSave) {
      if (skipTimer) {
        this.saveBlockValues();
      }
      this.timer = setTimeout(() => {
        this.saveBlockValuesTimer();
      }, 1000);
    }
  };

  @action
  clearStep3Params = () => {
    const parameterKeys = Object.keys(this.blockValues.parameters);
    unset(this.blockValues, `preview`);
    parameterKeys.forEach((key) => {
      if (key !== 'resource' && key !== 'operation') {
        unset(this.blockValues, `parameters.${key}`);
      }
    });
  };

  @action
  setParameterChangedFlag = () => {
    this.parameterChanged = true;
  };

  @action
  clearSampleData = () => {
    return unset(this.blockValues, 'sampleLiveData');
  };

  @action
  clearTestblockRunData = () => {
    return unset(this.blockValues, 'testBlockRunData');
  };

  saveBlockValuesTimer = () => {
    if (!this.globalStore.executionStore.isWorkflowStillUpdating) {
      this.saveBlockValues();
    }
  };

  getDynamicModeValue = (blockId) => {
    return this.dynamicMode?.[blockId];
  };

  findPreviewText = (path) => {
    if (!path || !this.blockValues) return null;
    const converted = btoa(path);
    if (this.blockValues.preview && this.blockValues.preview[converted]) {
      return this.blockValues.preview[converted];
    }
    return null;
  };

  findPreviewTags = (path) => {
    const converted = btoa(path);

    if (
      this.blockValues.preview &&
      Object.keys(this.blockValues.preview).filter((key) =>
        key.includes(converted)
      ).length
    ) {
      return Object.keys(this.blockValues.preview)
        .filter((key) => key.includes(converted))
        .reduce((obj, key) => {
          obj[key] = this.blockValues.preview[key];
          return obj;
        }, {});
    }
    return null;
  };

  @action
  removePreviewInBlock = (path) => {
    const converted = btoa(path);
    if (this.blockValues.preview && this.blockValues.preview[converted]) {
      delete this.blockValues.preview[converted];
    }
  };

  @action
  toggleReqLabel = (show) => {
    this.showReqLabel = show;
  };

  @action
  toggleAddLabel = (show) => {
    this.showAddLabel = show;
  };

  @action
  saveLatestParameterOptions = (data) => {
    this.parameterOptions = this.parameterOptions.concat(data);
  };

  @action
  saveBlockSubtitle = (subtitle, callApi = false) => {
    if (this.blockValues) {
      this.blockValues.subtitle = subtitle;
    }
    if (callApi) {
      this.saveBlockValues();
    }
  };

  @action
  clearMemoryStep = (blockId) => {
    const { workflow, activeChildUserId } = this.globalStore.workflowStore;
    if (unset(workflow.settings.stepTracker, blockId)) {
      updateWorkflow(workflow._id, {
        ...workflow,
        activeVersion: this.globalStore.workflowStore.activeVersion,
        isCurrentCustomVersion:
          this.globalStore.workflowStore.isCurrentCustomVersion,
        activeVersionId:
          this.globalStore.workflowStore.activeWorkflowVersion?._id,
        childUserId: activeChildUserId,
      });
    }
  };

  @action
  updateErrorBranch = async () => {
    const { workflow, activeChildUserId } = this.globalStore.workflowStore;
    await updateWorkflow(workflow._id, {
      ...workflow,
      activeVersion: this.globalStore.workflowStore.activeVersion,
      isCurrentCustomVersion:
        this.globalStore.workflowStore.isCurrentCustomVersion,
      activeVersionId:
        this.globalStore.workflowStore.activeWorkflowVersion?._id,
      childUserId: activeChildUserId,
    });
  };

  getDisplayOptionValue = (subParam, subValue, parameter, values) => {
    let isEmpty = true;
    Object.keys(subParam.displayOptions.show).find((key) => {
      parameter.options.find((param) => {
        if (subParam.displayOptions.show[key].includes(values[param.name])) {
          if (
            isNil(subValue) ||
            (isString(subValue) && subValue.length === 0)
          ) {
            isEmpty = false;
          } else {
            isEmpty = true;
          }
        }
      });
    });
    return isEmpty;
  };

  validateFixedCollections = (
    initialBlockParamPath,
    values,
    parameter,
    fromOptionalParent,
    parameterIndex = 0
  ) => {
    const { parameters } = this.blockValues;
    const isCustomAction =
      parameters.resource === 'custom' &&
      !['createCustom', 'customGraphqlQuery'].includes(parameters.operation);

    const { forgeEditableFields, forgeMode } = this.globalStore.workflowStore;
    if (Array.isArray(values)) {
      for (let v = 0; v < values.length; v++) {
        const validation = this.validateFixedCollections(
          initialBlockParamPath,
          values[v],
          parameter,
          false,
          v
        );
        if (!validation) return false;
      }
    } else {
      if (
        (fromOptionalParent && !values) ||
        (isCustomAction && !(values && values.name))
      ) {
        return true;
      }
      for (let i = 0; i < parameter.options.length; i++) {
        const subParam = parameter.options[i];
        const blockParamPath = `${initialBlockParamPath}[${parameterIndex}].${subParam.name}`;
        const subValue = values[subParam.name];
        let validateFixedCollectionWithOptionsFields;
        if (
          (subParam.required && !forgeMode) ||
          (subParam.required &&
            forgeMode &&
            !forgeEditableFields?.hasOwnProperty(blockParamPath))
        ) {
          if (subParam.displayOptions) {
            const validation = this.getDisplayOptionValue(
              subParam,
              subValue,
              parameter,
              values
            );
            if (!validation) return false;
          } else if (
            isNil(subValue) ||
            (isString(subValue) && subValue.length === 0)
          ) {
            return false;
          }
        }

        if (subParam.type === 'fixedCollection') {
          if (
            subParam.required &&
            subParam.typeOptions &&
            subParam.typeOptions.multipleValues &&
            subValue.length === 0
          )
            return false;
          if (Array.isArray(subValue)) {
            const subValidation = this.validateFixedCollections(
              blockParamPath,
              subValue,
              subParam
            );
            if (!subValidation) return false;
          }
        }

        // Check for fixedCollection internal fields if fixedcollection is required

        if (parameter.required) {
          const validate = parameter.options.find((o, index) => {
            if (
              o.required ||
              (forgeMode && forgeEditableFields?.hasOwnProperty(blockParamPath))
            ) {
              return true; // stop searching
            }
            return false;
          });
          if (Boolean(validate) === false) {
            const updatedValues = values;
            delete updatedValues.uuid;
            const checkProperties = (obj) => {
              for (const key in obj) {
                if (
                  obj[key] !== null &&
                  obj[key] !== '' &&
                  ((Array.isArray(obj[key]) && obj[key]?.length > 0) ||
                    typeof obj[key] !== 'object')
                ) {
                  return false;
                }
              }
              return true;
            };
            validateFixedCollectionWithOptionsFields =
              checkProperties(updatedValues);
          }
        }
        if (Boolean(validateFixedCollectionWithOptionsFields)) {
          return false; // should be falsed
        }
      }
    }
    return true;
  };

  @action
  validateForm = () => {
    const requiredParams = this.globalStore.blockTypeStore.requiredParameters;
    const optionalParams = this.globalStore.blockTypeStore.optionalParameters;
    const { forgeEditableFields } = this.globalStore.workflowStore;
    const { parameters } = this.blockValues;

    let isValid = true;
    let displayNotification = false;

    requiredParams.forEach((p) => {
      const value = get(parameters, p.name);
      const blockParamPath = this.currentBlockId + '::' + p.name;
      let fixedCollectionValid = true;
      if (p.type === 'fixedCollection') {
        if (
          (p.typeOptions &&
            p.typeOptions.multipleValues &&
            (!value || value.length === 0)) ||
          !this.validateFixedCollections(blockParamPath, value, p)
        ) {
          fixedCollectionValid = false;
        }
        const { forgeMode } = this.globalStore.workflowStore;
        if (
          (p.name === 'branches' || p.name === 'conditionSets') &&
          forgeMode
        ) {
          if (value.length >= 0) {
            fixedCollectionValid = true;
          }
        }
      }
      if (isUndefined(value) || value === '' || !fixedCollectionValid) {
        isValid = false;
      }
      if (!fixedCollectionValid) {
        displayNotification = true; // show a notification since there is nothing to highlight in the form
      }
      if (p.type !== 'boolean' && (!value || value.length === 0)) {
        isValid = false;
        displayNotification = true;
      }
      if (p.type === 'boolean' && isNil(value)) {
        isValid = false;
        displayNotification = true;
      }
      if (
        p.type === 'string' &&
        p.typeOptions &&
        p.typeOptions.multipleValues &&
        (!value ||
          value.length === 0 ||
          (value.length &&
            value.every((element) => isNil(element) || isEmpty(element))))
      ) {
        isValid = false;
        displayNotification = true;
      }

      if (forgeEditableFields[blockParamPath]) {
        isValid = true;
        displayNotification = false;
      }
    });

    optionalParams.forEach((p) => {
      const value = get(parameters, p.name);
      let fixedCollectionValid = true;
      if (p.type === 'fixedCollection') {
        const blockParamPath = this.currentBlockId + '::' + p.name;
        if (!this.validateFixedCollections(blockParamPath, value, p, true)) {
          fixedCollectionValid = false;
        } else {
        }
      }
      if (!fixedCollectionValid) {
        isValid = false;
        displayNotification = true; // show a notification since there is nothing to highlight in the form
      }
    });

    if (displayNotification) {
      showNotification(
        'error',
        'Please fill in all required fields before continuing!'
      );
    }

    this.showRequiredWarnings = true;
    return isValid;
  };

  @action
  moveBranch = (dragIndex, hoverIndex) => {
    const { branches } = this.blockValues.parameters;
    const { preview } = this.blockValues;
    const { connections } = this.globalStore.workflowStore.workflow;

    const indexes = {};
    branches.forEach((branch, index) => {
      indexes[branch.uuid] = index;
    });

    const dragBranch = branches[dragIndex];

    branches.splice(dragIndex, 1);
    branches.splice(hoverIndex, 0, dragBranch);

    if (connections[this.currentBlockId]) {
      const blockConnections = connections[this.currentBlockId].main;
      const dragConnection = blockConnections[dragIndex];
      blockConnections.splice(dragIndex, 1);
      blockConnections.splice(hoverIndex, 0, dragConnection);
    }
    const decodedPreview = [];
    forEach(preview, (val, key) => {
      decodedPreview.push({
        key: atob(key),
        val,
      });
    });

    if (!decodedPreview.length) {
      return;
    }

    const newPreview = {};

    branches.forEach((branch, index) => {
      const oldIndex = indexes[branch.uuid];
      const oldPrefix = `parameters.branches[${oldIndex}]`;
      const newPrefix = `parameters.branches[${index}]`;
      const previews = decodedPreview.filter((row) =>
        row.key.includes(oldPrefix)
      );

      previews.forEach((row) => {
        const newKey = row.key.replace(oldPrefix, newPrefix);
        newPreview[btoa(newKey)] = row.val;
      });
    });

    this.blockValues.preview = newPreview;
  };

  @action
  setCurrentBlockId = (id) => {
    this.currentBlockId = id;
    if (this.globalStore.workflowStore.versionStatus === 'final') {
      this.globalStore.workflowStore.setReleasedWorkflowView(true);
    }
  };

  @action
  toggleForgeInternalUse = () => {
    this.blockValues.forgeInternalUse = !this.blockValues.forgeInternalUse;
    if (this.blockValues.forgeInternalUse) {
      this.globalStore.workflowStore.removeForgeEditableFields(
        this.blockValues.id
      );
    }
    if (!this.blockValues.forgeInternalUse) {
      unset(
        this.blockValues.credentials,
        `${Object.keys(this.blockValues.credentials)[0]}`
      );
    }
    this.saveBlockValues();
    this.updateIssues();
  };

  @action
  updateAccountVariableSelectorValue = (value) => {
    this.accountVariableSelectorValue = value;
  };

  @action
  removeCustomBlockRunData = () => {
    return unset(this.blockValues, 'customBlockRunData');
  };

  getTitleIssue = () => {
    const { currentBlockType } = this.globalStore.blockTypeStore;
    const subtitle = this.blockValues?.subtitle || '';
    const resource =
      this.blockValues &&
      this.blockValues.parameters &&
      this.blockValues.parameters.resource;
    const operationTitleProperty = currentBlockType.properties.find((item) => {
      return (
        item.name === 'operation' &&
        item.displayOptions &&
        item.displayOptions.show &&
        item.displayOptions.show.resource &&
        item.displayOptions.show.resource[0] === resource &&
        item.required
      );
    });

    if (operationTitleProperty?.required && !subtitle) {
      return true;
    }

    return false;
  };

  isStepDisabled = (key, stepName) => {
    const { currentBlockType } = this.globalStore.blockTypeStore;
    const { forgeMode } = this.globalStore.workflowStore;
    const currentBlock = this.blockValues;
    const { stepNum } = this.globalStore.selectActionStore;

    if (currentBlockType.name === 'alloy.dateTime') {
      return false;
    }

    if (key === 0 || key === stepNum) {
      return false;
    }

    if (key === 0 || !stepName) {
      return true;
    }

    const isOptionals = stepName === 'Optionals';
    const isAuthenticate = stepName === 'Authenticate';
    const isSelectAction = stepName === 'Action';
    const isInputs = stepName === 'Inputs';

    if (isOptionals || isSelectAction) {
      return false;
    }

    if (!currentBlock) {
      return true;
    }

    if (
      forgeMode &&
      this.blockValues &&
      !this.blockValues.forgeInternalUse &&
      (isInputs || isAuthenticate) &&
      currentBlock.parameters &&
      currentBlock.parameters.operation
    ) {
      return false;
    }

    if (
      isAuthenticate &&
      currentBlock.parameters &&
      currentBlock.parameters.operation
    ) {
      return false;
    }

    const hasMissingTitle = this.getTitleIssue();
    if (hasMissingTitle) {
      return true;
    }

    const currentBlockCredentials =
      currentBlock.hasOwnProperty('credentials') &&
      Object.keys(currentBlock.credentials).length;

    if (
      isInputs &&
      currentBlockCredentials &&
      currentBlock.parameters &&
      currentBlock.parameters.operation
    ) {
      return false;
    }

    if (
      forgeMode &&
      this.blockValues &&
      !this.blockValues.forgeInternalUse &&
      isInputs &&
      currentBlock.isTrigger
    ) {
      return false;
    }

    const credentialIssues =
      currentBlock?.issues?.hasOwnProperty('credentials');

    if (isInputs) {
      if (
        currentBlockCredentials &&
        !credentialIssues &&
        currentBlockType &&
        currentBlock.hasOwnProperty('parameters') &&
        currentBlock.parameters.hasOwnProperty('operation') &&
        currentBlock.parameters.operation
      ) {
        return false;
      }
    }

    if (stepName === 'Output') {
      if (
        !this.countCredIssues &&
        !this.countParamIssues &&
        currentBlock.parameters &&
        currentBlock.parameters.operation &&
        currentBlock.parameters.resource
      ) {
        return false;
      }
    }

    return true;
  };

  getParameterValue(parameterName) {
    return this.blockValues.parameters[parameterName] || null;
  }
}
