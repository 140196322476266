import { GET, PATCH, POST, DELETE } from './wrappers';

export const generateApiKey = () => {
  return GET(`/api/forge/account/apiKey`);
};

export const generateDevApiKey = () => {
  return GET(`/api/forge/account/devApiKey`);
};

export const getForgeAccount = () => {
  return GET(`/api/forge/account`);
};

export const updateForgeAccount = (data) => {
  return PATCH(`/api/forge/account`, data);
};

export const setEventBridgeCreds = (data) => {
  return PATCH('/api/forge/account/eventBridge', data);
};

export const clearEventBridgeCreds = (data) => {
  return DELETE('/api/forge/account/eventBridge', data);
};

export const toggleEventBridge = () => {
  return PATCH('/api/forge/account/eventBridge/enabled');
};

export const createIntegrationSecret = (data) => {
  return POST('/api/integrationSecrets', data);
};

export const updateIntegrationSecret = (secretId, integrationId, data) => {
  return POST(`/api/integrationSecrets/${secretId}/${integrationId}`, data);
};

export const getIntegrationSecrets = (integrationId, blockId) => {
  return GET(`/api/integrationSecrets/integration/${integrationId}/${blockId}`);
};

export const getSelectedIntegrationSecrets = (secretId) => {
  return GET(`/api/integrationSecrets/${secretId}`);
};

export const getAssociatedAccounts = () => {
  return GET(`/api/forge/account/associatedAccounts`);
};

export const generateInstallUrl = (data) => {
  return POST(
    `/api/forge/account/${data.userId}/integrations/${data.integrationId}/install-url`,
    data
  );
};

export const generateInstallationUrl = (data) => {
  return POST(`/api/forge/account/installation/install-url`, data);
};

export const deleteIntegrationSecret = (secretId) => {
  return DELETE(`/api/integrationSecrets/${secretId}`);
};

export const createForgeUnifiedApiAccount = (data) => {
  return POST('/api/forge/account/unifiedApi/signup', data);
};

export const loginIntoUnifiedApiAccount = (data) => {
  return POST('/api/forge/account/unifiedApi/login', data);
}
