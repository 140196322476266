import { apiUrl, request } from 'Utilities';

export async function getMyRecipes() {
  return request(`${apiUrl}/api/marketplace/my-recipes`, {
    headers: {
      Authorization: localStorage.getItem('workflow-accessToken'),
      refreshToken: localStorage.getItem('workflow-refreshToken'),
    },
  });
}

export async function getReferralData() {
  return request(`${apiUrl}/api/agency/partner-hub`, {
    headers: {
      Authorization: localStorage.getItem('workflow-accessToken'),
      refreshToken: localStorage.getItem('workflow-refreshToken'),
    },
  });
}
