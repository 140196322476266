import React from 'react';
import classNames from 'classnames';
import styles from './index.less';
import { Input } from 'antd';

export const AppTextarea = (props) => {
  return (
    <Input.TextArea
      className={classNames(styles.inputTextarea, {
        [styles.isForge]: props.isForge,
        [props.customClass]: !!props.customClass,
      })}
      {...props}
    />
  );
};
