import bugsnag from '@bugsnag/js';
import BugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import { isUndefined } from 'lodash';

// __ENV__ and __APP_VERSION__ are defined in webpack

const releaseStage =
  // eslint-disable-next-line no-undef
  isUndefined(__ENV__) || __ENV__ === 'local' ? 'local' : __ENV__;

// save on window so that we can see what they are; remove if you're still seeing this
// eslint-disable-next-line no-undef
window.__ENV__ = __ENV__;
// eslint-disable-next-line no-undef
window.__APP_VERSION__ = __APP_VERSION__;
window.releaseStage = releaseStage;
let errorBoundary;

if (
  releaseStage === 'local' ||
  releaseStage === 'development' ||
  releaseStage === 'pr'
) {
  errorBoundary = (props) => <div>{props.children}</div>;
} else {
  bugsnag.start({
    apiKey: 'e807d4fcfae15a8c4c0977605f040cf8',
    releaseStage,
    appVersion: window.__APP_VERSION__,
    enabledReleaseStages: ['production', 'staging', 'QA'],
    notifyReleaseStages: ['production', 'staging', 'QA'],
    plugins: [new BugsnagReact()],
    beforeSend: function beforeSend(report) {
      if (report.errorMessage.includes('ResizeObserver')) {
        report.ignore();
      }
    },
  });
  errorBoundary = bugsnag.getPlugin('react').createErrorBoundary(React);
}
export const ErrorBoundary = errorBoundary;
