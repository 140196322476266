import { GET, PUT, POST, DELETE, PATCH } from './wrappers';

export async function getTeam() {
  return GET('/api/teams/mine');
}

export async function getTeamFromInvite(inviteId) {
  return GET(`/api/team/${inviteId}`);
}

export async function getTeamMembers(teamId) {
  return GET(`/api/teamInvites/${teamId}`);
}

export async function deleteTeam(teamId, body) {
  return DELETE(`/api/teams/${teamId}`, body);
}

export async function editTeam(teamId, body) {
  return PUT(`/api/teams/${teamId}`, body);
}

export async function createTeam(body) {
  return POST('/api/teams', body);
}

export async function inviteTeamMember(teamId, body) {
  return POST(`/api/teamInvites/${teamId}/invite`, body);
}

export async function removeTeamMember(memberId) {
  return DELETE(`/api/teamInvites/${memberId}`);
}

export async function reassignRole(memberId, body) {
  return PATCH(`/api/teamInvites/role/${memberId}`, body);
}

export async function resendInvite(teamId, memberId, member) {
  return POST(`/api/teamInvites/${teamId}/reinvite/${memberId}`, member);
}

export async function dismissCreateTeamMessage(userId) {
  return PATCH(`/api/teams/dismissMessage/${userId}`);
}

export async function reassignTeamAdmin(adminId, userId) {
  return PATCH(`/api/teams/reassign/${userId}`, { adminId });
}

export async function acceptTeamInvite(teamInviteCode) {
  return POST(`/api/teamInvites/${teamInviteCode}/accept`);
}

export async function getTeamInviteInfo(teamInviteCode) {
  return GET(`/api/teamInvites/teamInvite/${teamInviteCode}`);
}

export async function revokeInvite(teamInviteCode) {
  return DELETE(`/api/teamInvites/${teamInviteCode}/revoke`);
}

export async function acceptTeamInviteNewUser(teamInviteCode, isAlternate) {
  return POST(
    `/api/teamInvites/${teamInviteCode}/accept?isAlternate=${isAlternate}`
  );
}

export async function teamMemberInfo() {
  return GET(`/api/teamInvites/teamInvite/info`);
}

export async function getTeamMFA(teamId) {
  return GET(`/api/teams/mfa/${teamId}`);
}

export async function getTeamName(teamId) {
  return GET(`/api/teams/name/${teamId}`);
}

export async function validateAndSaveIDPInformation(data) {
  return PATCH(`/api/teams/saml`, data);
}

export async function disableSSO() {
  return PATCH(`/api/teams/saml/disable`);
}

export async function validateIDPdata(data) {
  return POST(`/api/teams/validatesaml`, data);
}

export async function getSamlConfig() {
  return GET(`/api/teams/getSamlConfig`);
}

export async function getTeamFeatureList() {
  return GET('/api/teams/featurelist');
}

export async function setFeatureAccess(body) {
  return POST(`/api/teamInvites/${body._id}/featureAccess`, body);
}
