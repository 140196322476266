import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router, Switch, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { renderRoutes } from './router';
import { NoPage } from 'Routes/NoPage/NoPage';
import { getRouterData } from './common/router';
import { GlobalStore } from 'Stores/GlobalStore';
import { FallbackError } from 'Components/Popups/FallbackError';
import DashboardMenu from 'Components/DashboardMenu';

import { ErrorBoundary } from 'Utilities/bugsnag';
import { apiUrl, isInIframe, initializeSegment } from 'Utilities';
import 'antd/dist/reset.css';

import './index.less';
import './custom-variables.less';
import './overrides.less';
import { getMenuData, menuData } from './common/menu';

window.process = { nextTick: () => {}, versions: { node: 'lol' } };

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routingStore);
const globalStore = new GlobalStore(routingStore);
window._globalStore = globalStore;
const routerData = getRouterData();
window._fs_namespace = 'FSAlloy';

if (apiUrl === 'https://api.runalloy.com' && !isInIframe()) {
  initializeSegment('FGFRVkgfv5fBrngW5qHtFyTqmYiBgKsI');
}

ReactDOM.render(
  <Provider globalStore={globalStore}>
    <ConfigProvider locale={enUS}>
      <DndProvider backend={Backend}>
        <Router history={history}>
          <DashboardMenu
            menuData={getMenuData(menuData)}
            collapsed={false}
            location={location}
            logout={() => {}}
            sider
          />
          <ErrorBoundary FallbackComponent={FallbackError}>
            <Switch>
              {renderRoutes(routerData)}
              <Route render={() => <NoPage />} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </DndProvider>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);
