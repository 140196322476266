import React from 'react';
import { observable, computed, action, runInAction, toJS } from 'mobx';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import {
  forIn,
  isArray,
  isString,
  each,
  includes,
  set,
  intersection,
  concat,
  uniq,
  merge,
  pull,
  get,
  unset,
  isNil,
} from 'lodash';
import { listToTreeData } from '../routes/WorkflowEditor/WorkflowCanvas/util';
import axios from 'axios';
import {
  getWorkflow,
  getWorkflows,
  getDisabledWorkflow,
  restoreWorkflow,
  createNewWorkflow,
  updateWorkflowName,
  updateWorkflow,
  deleteWorkflow,
  deleteWorkflows,
  validateWorkflow,
  removeBlock,
  runTestBlock,
  duplicateWorkflow,
  updateWorkflowFromLightning,
  getS3PresignedUrl,
  getCsvS3PresignedUrl,
  removeBranch,
  duplicateBranch,
  segmentTrackWorkflowCreation,
  addToWorkspace,
  getActiveWorkflowCount,
  getActiveWorkspaceWorkflowCount,
  getChildWorkflows,
  updateForgeWorkflow,
  getS3CSV,
  deleteS3CSV,
  copyS3CSV,
  saveInfoToForgeWorkflow,
  createAppEvent,
  deleteAppEvent,
  editAppEvent,
  getAppEvent,
  getAppEvents,
  addWorkflowToIntegration,
  overWriteBlockOutput,
  getEmbeddedWorkflows,
  getIntegrationWorkflowCount,
} from 'Services/workflowApi';
import {
  listWorkflowVersions,
  createWorkflowVersion,
  finalizeWorkflowVersion,
  releaseWorkflowVersion,
  releaseCustomWorkflowVersion,
  deleteWorkflowVersion,
  revertAutomationWorkflowToOriginal,
  getWorkflowVersion,
} from 'Services/workflowVersion';
import {
  getIntegrations,
  deleteIntegration,
  getIntegration,
} from 'Services/embeddedIntegrations';
import { countTime, uuid4, numberOfErrors } from 'Utilities';
import { getBlockParametersIssues } from 'Utilities/workflow-classes/BlockHelpers';
import {
  getBlockCredentialIssues,
  isBlockTestable,
} from 'Utilities/block-helpers';
import { showNotification } from 'Components/Notifications/Notification';
import { appMessage } from '../components/Base/Message';
import { createRecipeDraft } from 'Services/marketplace';
import 'core-js-pure/stable/atob';

dayjs.extend(relativeTime);

const hoistedBlockTypes = ['alloy.tally', 'alloy.variable'];
const branchBlockTypes = ['alloy.for', 'alloy.branch', 'alloy.errorHandler'];

export class WorkflowStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  workflows = [];

  @observable
  workflowCount = 0;

  @observable
  workflowInstanceEditorWarning = false;

  @observable
  workflowExecutionsCount = [];

  @observable
  loadingMessage = false;

  @observable
  activeWorkflowId = null;

  @observable
  activeChildUserId = null;

  @observable
  activeEmbeddedUsername = null;

  @observable
  isCurrentCustomVersion = false;

  @observable
  workflowLoading = false;

  @observable
  workflowHasErrors = false;

  @observable
  planValidationErrors = false;

  @observable
  isActive = true;

  @observable
  isTrial = false;

  @observable
  firstWorkflowCreated = false;

  @observable
  executionErrors = {};

  @observable
  hasWorkflowErrors = false;

  @observable
  tempWorkflowErrors = null;

  @observable
  currentWorkflowNotifications = [];

  @observable
  blockRunTestError = null;

  @observable
  showLeftPanel = true;

  @observable
  expressionData = {};

  @observable
  connectionsByDestination = {};

  @observable
  activeWorkflowCount = 0;

  @observable
  blocksWithWarnings = [];

  @observable
  blockPropsWithWarnings = [];

  @observable
  disabledWorkflow = [];

  @observable
  workflowBlocks = [];

  @observable
  workflowConnections = {};

  @observable
  disabledWorkflowCount = 0;

  @observable
  forgeMode = false;

  @observable
  workflowActiveFlag = false;

  @observable
  recipePublisherMode = false;

  @observable
  forgeEditableFields = {};

  @observable
  uploadProgress = 0;

  @observable
  workflowVersions = [];

  @observable
  activeVersion = null;

  @observable
  activeVersionIsCustom = false;

  @observable
  versionStatus = 'draft';

  @observable
  draftId = '';

  @observable
  forgeActiveWorkflow = '';

  @observable
  showConfigureRecipePopover = false;

  @observable
  integrations = [];

  @observable
  integrationsLoading = false;

  @observable
  integrationCount = 0;

  @observable
  integrationInfo = {};

  @observable
  isHeaderLoader = false;

  @observable
  appEventList = [];

  @observable
  hasArchivedWorkflows = false;

  @observable
  selectedWorkflows = [];

  @observable
  revertVersion = null;

  @observable
  isReleasedWorkflowView = false;

  @observable
  showInstallationEditorWarning = false;

  @observable
  activeWorkflowVersion = null;

  @observable
  hasDraftVersion = true;

  @observable
  saveWorkflowBlockOutputLoading = false;

  @observable
  subDrawerOpen = false;

  @observable
  subSubDrawerOpen = false;

  @observable
  isActiveWorkflowCountLoading = false;

  @computed
  get showInstallationEditWarningPopup() {
    return (
      this.showInstallationEditorWarning &&
      this.activeEmbeddedUsername &&
      !this.globalStore.userStore.userInfo?.installationEditorWarningDisabled
    );
  }

  @computed
  get workflow() {
    let workflow;
    if (this.activeChildUserId) {
      workflow = this.workflows.find(
        (wf) =>
          wf.forgeWorkflowId === this.activeWorkflowId &&
          wf.userId === this.activeChildUserId
      );
    } else {
      workflow = this.workflows.find((wf) => wf._id === this.activeWorkflowId);
    }
    if (this.forgeMode && workflow) {
      workflow.forgeEditableFields = this.forgeEditableFields;
    }
    return workflow;
  }

  @action
  handleSubDrawerOpen = (value) => {
    this.subDrawerOpen = value;
  };

  @action
  handleSubSubDrawerOpen = (value) => {
    this.subSubDrawerOpen = value;
    if (!this.subDrawerOpen && value) {
      this.subDrawerOpen = true;
    }
  };

  @action
  hideInstallationWarningModal = () => {
    this.showInstallationEditorWarning = false;
    if (this.workflowInstanceEditorWarning) {
      const user = this.globalStore.userStore.userInfo;
      user.installationEditorWarningDisabled =
        this.workflowInstanceEditorWarning;
      this.globalStore.userStore.updateUser(user);
    }
  };

  @action
  toggleWorkflowInstanceEditorWarning = (checked) => {
    this.workflowInstanceEditorWarning = checked?.target?.checked;
  };

  @action
  setReleasedWorkflowView = (flag) => {
    this.isReleasedWorkflowView = flag;
  };

  @action
  setActiveWorkflowId = (id) => {
    this.activeWorkflowId = id;
  };

  @action
  enableRecipePublisherMode = () => {
    this.recipePublisherMode = true;
    this.resetStep();

    const { blocks, connections } = this.workflow;
    const treeData = listToTreeData(
      JSON.parse(JSON.stringify(blocks)),
      connections
    );
    this.globalStore.recipeCreatorStore.setTreeData(treeData);
    this.showConfigureRecipePopover = true;
    this.globalStore.recipeCreatorStore.setNewPost(this.workflow);
  };

  @action
  hideRecipeConfigurePopover = () => {
    this.showConfigureRecipePopover = false;
  };

  resetStep() {
    if (this.recipePublisherMode) {
      this.globalStore.selectActionStore.resetStep();
    }
  }

  @action
  disableRecipePublisherMode = () => {
    this.recipePublisherMode = false;
    this.globalStore.recipeCreatorStore.reset();
  };

  @action
  enableForge = () => {
    this.forgeMode = true;
  };

  @action
  setUploadProgress = (i) => {
    this.uploadProgress = i;
  };

  @action
  disableForge = () => {
    this.forgeMode = false;
  };

  @action
  toggleForgeVisible = (selectedValue) => {
    const { workflow } = this;

    if (!workflow.forgeVisible) {
      workflow.forgeVisible = true;
      this.releaseWorkflowVersion().then(() => {
        updateWorkflow(workflow._id, workflow)
          .then(() => {
            showNotification(
              'success',
              `Workflow version ${workflow?.version} is active and ready to be installed via Embedded modal.`
            );
            // showNotification(
            //   'success',
            //   'Credentials added are used solely for templating and won’t be used by end users.'
            // );
          })
          .catch(
            action(() => {
              workflow.forgeVisible = false;
            })
          );
      });
    } else {
      workflow.forgeVisible = false;
      updateForgeWorkflow(workflow._id, { selectedValue })
        .then(() => {
          if (selectedValue === 'all') {
            showNotification(
              'success',
              'Workflow has been turned off for new and existing users.'
            );
          } else {
            showNotification(
              'success',
              'Workflow has been turned off for new users.'
            );
          }
        })
        .catch(
          action(() => {
            workflow.forgeVisible = true;
          })
        );
    }
  };

  @action
  getChildWorkflows = async () => {
    const { workflow } = this;
    if (workflow) {
      return getChildWorkflows(workflow._id);
    } else {
      return [];
    }
  };

  getFieldString = (path) => {
    const { currentBlockId } = this.globalStore.blockEditStore;
    return `${currentBlockId}::${path.slice(11)}`;
  };

  @action
  toggleForgeField = (
    path,
    parameter,
    description,
    branchUuid,
    isCustomAction = false
  ) => {
    if (this.versionStatus && this.versionStatus === 'final') {
      return;
    }
    let customFieldName;

    if (isCustomAction) {
      const { blockValues } = this.globalStore.blockEditStore;
      if (path.indexOf('.value')) {
        const newPath = path.replace('.value', '.name');
        const displayParam = get(blockValues, newPath);
        customFieldName = displayParam;
      }
    }
    const fieldKey = this.getFieldString(path);

    const existingRecord = this.forgeEditableFields[fieldKey];
    if (!existingRecord) {
      const { currentBlockType } = this.globalStore.blockTypeStore;
      this.forgeEditableFields[fieldKey] = {
        type: parameter.type,
        icon: currentBlockType.icon,
        fieldName: currentBlockType.name,
        fieldBlockId: this.globalStore.blockEditStore.currentBlockId,
        fieldValue: parameter.name,
        fieldDisplayName: parameter.displayName,
        forgeFieldDisplayName: customFieldName || parameter.displayName || '',
        fieldDescription: parameter.description,
        fieldPlaceholder: '',
        fieldType: parameter.type,
        fieldBlockTypeName: currentBlockType.displayName,
        showHelpText: false,
        helpText: '',
        showInfoIcon: false,
        infoIconText: '',
        fieldKey,
        fieldRequired: true, // this apparently marks the field as visible or not
        isRequired: 'yes',
        children: [{ text: '' }],
        branchUuid,
        forgeFieldDescription:
          description ||
          this.forgeEditableFields[fieldKey]?.forgeFieldDescription ||
          '',
        forgeFieldDataFormat:
          parameter.type === 'options' || parameter.type === 'string'
            ? 'variableText'
            : 'justText',
        variableSources: [],
      };
    } else {
      delete this.forgeEditableFields[fieldKey];
    }
    this.globalStore.blockEditStore.updateIssues();
  };

  @action
  saveForgeFieldSettings = (path, key, value) => {
    if (this.versionStatus && this.versionStatus === 'final') {
      return;
    }
    const fieldKey = this.getFieldString(path);
    const existingRecord = this.forgeEditableFields[fieldKey];
    if (existingRecord) {
      this.forgeEditableFields[fieldKey][key] = value;
      this.globalStore.blockEditStore.updateIssues();
    }
  };

  @action
  resetForgeFieldSettings = (path, parameter) => {
    if (this.versionStatus && this.versionStatus === 'final') {
      return;
    }
    const fieldKey = this.getFieldString(path);
    const existingRecord = this.forgeEditableFields[fieldKey];
    if (existingRecord) {
      this.forgeEditableFields[fieldKey].showHelpText = false;
      this.forgeEditableFields[fieldKey].forgeFieldDisplayName = '';
      this.forgeEditableFields[fieldKey].helpText = '';
      this.forgeEditableFields[fieldKey].showInfoIcon = false;
      this.forgeEditableFields[fieldKey].infoIconText = '';
      this.forgeEditableFields[fieldKey].fieldPlaceholder = '';
      this.forgeEditableFields[fieldKey].variableSources = [];
      this.forgeEditableFields[fieldKey].isRequired = 'yes';
      this.forgeEditableFields[fieldKey].forgeFieldDataFormat =
        parameter.type === 'options' || parameter.type === 'string'
          ? 'variableText'
          : '';
      this.globalStore.blockEditStore.updateIssues();
    }
  };

  @action
  saveInfoToForgeWorkflow = (body) => {
    const { workflow } = this;

    return saveInfoToForgeWorkflow(body).then((data) => {
      const updatedWorkflow = data.workflow;

      runInAction(() => {
        workflow.introData = { ...updatedWorkflow.introData };
        workflow.forgeVisible = updatedWorkflow.forgeVisible;
        workflow.runtimeFlags = { ...updatedWorkflow.runtimeFlags };
      });
      return data;
    });
  };

  @action
  infoToForgeWorkflowQuickSave = (body) => {
    return saveInfoToForgeWorkflow(body).then((res) => {
      return res;
    });
  };

  @action
  setExpressionData = (blockId, data) => {
    this.expressionData[blockId] = data;
  };

  @action
  addExecutionError = (error) => {
    const { workflow } = this;

    const { blockId } = error;
    if (
      workflow &&
      !!workflow.blocks &&
      Array.isArray(workflow.blocks) &&
      workflow.blocks.length
    ) {
      const blockIndex = workflow.blocks.findIndex((block) => {
        return block.id === blockId;
      });

      if (workflow.blocks && workflow.blocks[blockIndex]) {
        if (!workflow.blocks[blockIndex].issues)
          workflow.blocks[blockIndex].issues = {};
        workflow.blocks[blockIndex].issues.execution = error.message;
      }
    }
    this.updateExecutionErrorLookup();
  };

  @action
  updateExecutionErrorLookup = async () => {
    try {
      const { workflowBlocks, workflowExecutionErrors } = this;
      if (!workflowBlocks.length) return;

      if (!workflowExecutionErrors) {
        this.executionErrors = {};
        return;
      }

      workflowBlocks.forEach((block) => {
        if (block.issues && block.issues.execution) {
          if (this.executionErrors[block.id] !== block.issues.execution) {
            this.executionErrors[block.id] = block.issues.execution;
          }
        }
      });
    } catch {
      // do nothing
    }
  };

  @action
  createAppEvent = async (body) => {
    return createAppEvent(body).then(async (res) => {
      const events = await this.getAppEvents();
      this.appEventList = events;
      return res;
    });
  };

  @action
  editAppEvent = async (eventName, body) => {
    return editAppEvent(eventName, body).then(async (res) => {
      const events = await this.getAppEvents();
      this.appEventList = events;
      return res;
    });
  };

  getAppEvent = async (id) => {
    return getAppEvent(id).then((res) => {
      return res.data;
    });
  };

  @action
  deleteAppEvent = (eventName) => {
    if (event) {
      return deleteAppEvent(eventName).then(
        action(() => {
          if (this.appEventList) {
            this.appEventList = this.appEventList.filter(
              (e) => e.eventName !== eventName
            );
          }
        })
      );
    }
  };

  @action
  getAppEvents = async (body) => {
    return getAppEvents(body).then(
      action((res) => {
        if (res?.data) {
          this.appEventList = res.data;
          return res.data;
        } else {
          this.appEventList = [];
          return [];
        }
      })
    );
  };

  findExecutionError = (blockId, path) => {
    if (!this.executionErrors) return null;
    const blockError = this.executionErrors[blockId];

    if (blockError && blockError.path && blockError.path.includes(path))
      return blockError;

    return null;
  };

  @action
  getDisableWorkflowList = (userId, limit, skip) => {
    return getDisabledWorkflow(userId, limit, skip).then((res) => {
      if (res) {
        this.disabledWorkflow = res?.data?.disabledWorkflow;
        this.disabledWorkflowCount = res?.data?.disabledWorkflowCount;
      }
    });
  };

  @action
  restoreWorkflow = (workflowId, userId, limit, skip) => {
    const { push } = this.globalStore.routingStore;
    return restoreWorkflow(workflowId).then((res) => {
      if (res) {
        showNotification(
          'custom',
          '',
          '',
          <p>
            Workflow restore and added back to the{' '}
            <a onClick={() => push(`/dashboard`)} id="back-to-dashboard">
              Dashboard
            </a>{' '}
            page
          </p>,
          'success'
        );
        this.getDisableWorkflowList(userId, limit, skip);
      }
    });
  };

  @action
  selectWorkflow = (workflow) => {
    if (this.selectedWorkflows.length > 0) {
      const index = this.selectedWorkflows.findIndex((n) => {
        return n._id === workflow._id;
      });

      if (index > -1) {
        this.selectedWorkflows.splice(index, 1);
      } else {
        this.selectedWorkflows.push(workflow);
      }
    } else {
      this.selectedWorkflows.push(workflow);
    }
  };

  @action
  deselectWorkflows = () => {
    this.selectedWorkflows = [];
  };

  @action
  callFuncGetInstallationCount() {
    this.globalStore.installationStore.funcGetInstallationCount();
  }

  @action
  getIntegrationList = async (limit, skip) => {
    const accessToken = localStorage.getItem('workflow-accessToken');

    if (!accessToken) return;
    if (this.integrationsLoading) return;

    this.integrationsLoading = true;

    return getIntegrations(limit, skip)
      .then((res) => {
        if (res) {
          this.integrations = res?.integrations;
          this.integrationCount = res?.integrationCount;
          const integrationIds = this.integrations.map((integration) => {
            return {
              integrationId: integration._id,
            };
          });
          runInAction(() => {
            if (integrationIds.length) {
              this.globalStore.executionStore.getEmbeddedExecutionSummaries({
                integrationIds,
              });
            }
          });

          if (
            this.globalStore.userStore?.featureAccess?.has(
              'installationManager'
            )
          ) {
            this.callFuncGetInstallationCount();
          }
        }

        this.integrationsLoading = false;
      })
      .catch(() => {
        this.integrations = [];
        this.integrationCount = 0;
        this.integrationsLoading = false;
      });
  };

  getEmbeddedWorkflowList = async (limit, skip, integrationId) => {
    this.workflowLoading = true;
    const userId = this.globalStore.userStore.userInfo._id;

    this.workflows = observable([]);

    if (!userId || !integrationId) return;
    return getEmbeddedWorkflows(limit, skip, integrationId)
      .then(
        action((res) => {
          if (res.success) {
            const { workflows } = res;

            this.forgeMode = true;

            if (workflows) {
              workflows.forEach(
                action((workflow, i) => {
                  this.workflows.push(workflow);
                })
              );
            }

            this.workflowLoading = false;
            const workflowIds = this.workflows.map((workflow) => {
              return {
                workflowId: workflow._id,
              };
            });

            getIntegrationWorkflowCount(integrationId).then((resp) => {
              if (resp.success) {
                this.workflowCount = resp.workflowCount;
              }
            });

            runInAction(() => {
              this.globalStore.executionStore.getEmbeddedExecutionSummaries({
                workflowIds,
              });
            });
          } else {
            runInAction(() => (this.loadingMessage = true));
          }
        })
      )
      .catch((er) => {
        console.log(er);
        runInAction(() => (this.loadingMessage = true));
        return er;
      });
  };

  // OPTIMISE HERE
  @action
  getWorkflowList = (
    limit,
    skip,
    workspaceId = null,
    forgeMode = false,
    active,
    integrationId,
    manual,
    error,
    fromIntegration = false
  ) => {
    this.workflowLoading = true;
    const userId = this.globalStore.userStore.userInfo._id;
    const nowTime = dayjs(new Date());

    this.workflows = observable([]);

    if (!userId) return;

    return getWorkflows(
      userId,
      limit,
      skip,
      false,
      false,
      workspaceId,
      forgeMode,
      active,
      integrationId,
      manual,
      error,
      fromIntegration
    )
      .then(
        action((res) => {
          if (res.data) {
            const {
              workflowIcons,
              workflows,
              workflowCount,
              workflowExecutionsCount,
            } = res.data;

            if (forgeMode) {
              this.forgeMode = true;
              this.workflowCount = workflowCount;

              if (isNil(integrationId)) {
                if (workflows.length > 0) {
                  this.hasArchivedWorkflows = true;
                } else {
                  this.hasArchivedWorkflows = false;
                  this.globalStore.routingStore.push('/embedded');
                }
              }
            } else {
              this.forgeMode = false;
              this.workflowCount = workflowCount;
              this.workflowExecutionsCount = workflowExecutionsCount;
            }

            if (workflowIcons && workflows) {
              workflows.forEach(
                action((workflow, i) => {
                  let num = 0;
                  const { blocks } = workflow;
                  blocks.forEach((item) => {
                    if (item.isError) {
                      num++;
                    }
                  });

                  workflow.icons = workflowIcons[i];
                  workflow.diff = countTime(workflow.updatedAt, nowTime);
                  workflow.updatedatString = dayjs(workflow.updatedAt).format(
                    'MM/DD/YYYY hh:mm A'
                  );
                  workflow.errorNum = num;

                  this.workflows.push(workflow);

                  if (workflow.active) {
                    this.globalStore.executionStore.runningWorkflowIds.add(
                      workflow._id
                    );
                  }
                })
              );
            }

            this.workflowLoading = false;
            const workflowIds = this.workflows.map((workflow) => {
              return {
                workflowId: workflow._id,
              };
            });

            runInAction(() => {
              this.globalStore.executionStore.getEmbeddedExecutionSummaries({
                workflowIds,
              });
            });
          } else {
            runInAction(() => (this.loadingMessage = true));
          }
        })
      )
      .catch((er) => {
        runInAction(() => (this.loadingMessage = true));
        return er;
      });
  };

  @action
  getWorkflowVersion = async (versionId, userId) => {
    try {
      const workflowResponse = await getWorkflowVersion(versionId, userId);
      runInAction(() => {
        this.activeWorkflowVersion = workflowResponse?.data;
        this.versionStatus = workflowResponse?.data?.status;
        this.activeVersionIsCustom =
          workflowResponse?.data?.customVersion || false;
      });
    } catch (err) {
      console.log('Error fetching workflow version:', err);
    }
  };

  getAccessPermission = () => {
    const { forgeMode } = this;
    if (forgeMode) {
      const accessLevel = this.globalStore.userStore.getAccessPermissions(
        'embedded-ipass',
        'integrations'
      );
      // check for read only mode
      return accessLevel === 'read' || !accessLevel;
    } else {
      const { readOnlyMode } = this.globalStore.userStore;
      return readOnlyMode;
    }
  };

  @action
  getWorkflow = async (workflowId, version, userId, versionId) => {
    const readOnlyMode = this.getAccessPermission();
    this.globalStore.blockTypeStore.currentBlockType = null;
    this.workflowLoading = true;
    this.activeWorkflowId = workflowId;
    this.activeChildUserId = userId;
    this.setReleasedWorkflowView(false);

    if (readOnlyMode) {
      this.setReleasedWorkflowView(true);
    }

    const workflowResponse = await getWorkflow(
      workflowId,
      version,
      userId,
      versionId
    );
    if (
      workflowResponse?.data &&
      workflowResponse.data.forgeEditableFields &&
      Object.keys(workflowResponse.data.forgeEditableFields).length
    ) {
      this.forgeEditableFields = workflowResponse.data.forgeEditableFields;
    } else {
      this.forgeEditableFields = {};
    }
    if (workflowResponse?.data?.version && !version) {
      await workflowResponse.data.version;
      this.activeVersion = workflowResponse.data.version;
      this.versionStatus = workflowResponse.data.versionStatus;
      this.activeVersionId =
        workflowResponse.data.activeVersionId ||
        workflowResponse.data.versionId;
      this.activeEmbeddedUsername = workflowResponse.data.embeddedUsername;
    } else {
      this.activeVersion = null;
      this.versionStatus = 'draft';
      this.activeVersionId = null;
      this.activeEmbeddedUsername = null;
    }
    if (version) {
      this.activeVersion = version;
      this.versionStatus = workflowResponse.data.versionStatus;
      this.activeVersionId =
        workflowResponse.data.activeVersionId ||
        workflowResponse.data.versionId;
      this.activeEmbeddedUsername = workflowResponse.data.embeddedUsername;
    }
    if (this.activeVersionId) {
      await this.getWorkflowVersion(this.activeVersionId);
    } else {
      this.activeVersion = null;
      this.versionStatus = 'draft';
      this.activeVersionId = null;
      this.activeEmbeddedUsername = null;
    }
    await this.updateWorkflowInStore(workflowResponse.data);
    this.getWorkflowIntegration();
    if (this.forgeMode) {
      const versions = await listWorkflowVersions(workflowId, userId);

      if (versions?.data) {
        const finalisedVersions = versions?.data?.filter(
          (v) => v?.status === 'final'
        );
        const missingUpdate = finalisedVersions?.sort((a, b) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);

          return bDate?.getTime() - aDate?.getTime();
        });
        let waitingVersion;
        if (missingUpdate && missingUpdate.length) {
          waitingVersion =
            missingUpdate[0]?.comment === 'missing user update'
              ? missingUpdate[0]?._id
              : undefined;
        }
        this.workflowVersions = versions.data.map((version) => {
          if (version.forgeEditableFields) {
            const forgeEditable = Object.entries(
              version.forgeEditableFields
            ).reduce((obj, [key, value]) => {
              obj[atob(key)] = value;
              return obj;
            }, {});
            set(version, 'forgeEditableFields', forgeEditable);
            if (waitingVersion && waitingVersion === version._id) {
              set(version, 'isWaiting', true);
            }
            return version;
          } else {
            return version;
          }
        });
        this.hasDraftVersion = !!this.workflowVersions.filter(
          (w) => w?.status === 'draft'
        )?.length;
      }
    } else if (get(workflowResponse, 'data.isFromRecipe')) {
      const versions = await listWorkflowVersions(workflowId);
      if (versions?.data) {
        this.revertVersion = versions.data[0];
      }
    }
    return workflowResponse.data;
  };

  getWorkflowForgeEditableFields = (workflow) => {
    if (workflow?.forgeEditableFields) {
      try {
        const forgeEditable = Object.entries(
          workflow.forgeEditableFields
        ).reduce((obj, [key, value]) => {
          let newKey = atob(key);

          // this is bad, we need to find why we are getting multiple encoded keys
          while (!newKey.includes('::')) {
            newKey = atob(newKey);
          }
          obj[newKey] = value;
          return obj;
        }, {});
        return forgeEditable;
      } catch (err) {
        return workflow.forgeEditableFields;
      }
    } else {
      return {};
    }
  };

  @action
  createWorkflowVersion = async () => {
    this.isHeaderLoader = true;
    try {
      const response = await createWorkflowVersion(this.activeWorkflowId, {
        baseVersion: this.activeVersion,
      });
      if (response?.data) {
        await this.useWorkflowVersion(response.data.version, response.data._id);
        showNotification(
          'success',
          `Workflow version ${response.data.version} created successfully`
        );
      }
      this.setReleasedWorkflowView(false);
    } catch (err) {
      console.log(err);
      this.isHeaderLoader = false;
    }
  };

  @action
  createCustomWorkflowVersion = async () => {
    this.isHeaderLoader = true;
    try {
      const response = await createWorkflowVersion(this.workflow._id, {
        baseVersion: this.activeVersion,
        versionId: this.activeWorkflowVersion?._id,
        customVersion: true,
      });
      if (response?.data) {
        await this.useWorkflowVersion(response.data.version, response.data._id);
        showNotification(
          'success',
          `Workflow version ${response.data.version} created successfully`
        );
      }
      this.setReleasedWorkflowView(false);
    } catch (err) {
      console.log(err);
      this.isHeaderLoader = false;
    }
  };

  @action
  cloneVersion = async (version) => {
    try {
      this.isHeaderLoader = true;
      const response = await createWorkflowVersion(this.activeWorkflowId, {
        baseVersion: version,
      });
      if (response?.data) {
        await this.useWorkflowVersion(
          response.data.version,
          null,
          `Workflow version copied successfully`
        );
      }
      this.setReleasedWorkflowView(false);
    } catch (err) {
      console.log(err);
      this.isHeaderLoader = false;
    }
  };

  @action
  deleteWorkflowVersion = async (version) => {
    try {
      this.isHeaderLoader = true;
      const response = await deleteWorkflowVersion(
        this.activeWorkflowId,
        version
      );
      const foundVersion = this.workflowVersions.find((e) => e._id === version);
      if (Number(this.activeVersion) === foundVersion.version) {
        let versionIndex = this.workflowVersions.findIndex(
          (e) => e._id === version
        );
        if (versionIndex === 0) {
          versionIndex += 1;
        } else {
          versionIndex -= 1;
        }
        const newVersion = this.workflowVersions[versionIndex].version;
        await this.useWorkflowVersion(
          newVersion,
          null,
          `Workflow version deleted successfully`
        );
      } else if (response?.data) {
        this.globalStore.loadResources(`Workflow version deleted successfully`);
      }
    } catch (err) {
      this.isHeaderLoader = false;
      console.log(err);
    }
  };

  useWorkflowVersion = async (version, versionId, successMessage) => {
    const { push, location } = this.globalStore.routingStore;
    const customVersionParam = this.activeChildUserId
      ? versionId
        ? `&userId=${this.activeChildUserId}&versionId=${versionId}`
        : `&userId=${this.activeChildUserId}`
      : ``;

    if (this.forgeMode) {
      push(
        `/workflow/build?workflowId=${this.activeWorkflowId}&version=${version}` +
          customVersionParam +
          `&forgeMode=engaged`
      );
      this.globalStore.loadResources(successMessage);
    } else if (location.search !== `?workflowId=${this.activeWorkflowId}`) {
      push(`/workflow/build?workflowId=${this.activeWorkflowId}`);
      this.globalStore.loadResources();
    }

    this.setReleasedWorkflowView(false);
  };

  @action
  releaseCustomWorkflowVersion = async (versionId) => {
    this.isHeaderLoader = true;
    try {
      const response = await releaseCustomWorkflowVersion(
        this.activeWorkflowId,
        versionId,
        this.activeChildUserId
      );
      if (response?.data) {
        runInAction(() => {
          this.versionStatus = 'final';
          this.workflow.version = Number(this.activeVersion);
          const workflowVersion = this.workflowVersions.find(
            (w) => Number(w.version) === Number(this.activeVersion)
          );
          workflowVersion.status = 'final';
          this.isHeaderLoader = false;
          this.workflow.forgeVisible = true;
          this.workflow.customVersion = true;
          this.useWorkflowVersion(this.activeVersion, versionId);
        });

        showNotification(
          'success',
          `“User-specific Version ${this.activeVersion}” released`
        );
      }
    } catch (err) {
      showNotification(
        'error',
        'A workflow with the same title already exists; please choose a unique title before releasing.'
      );
      this.isHeaderLoader = false;
    }
  };

  @action
  releaseWorkflowVersion = async () => {
    this.isHeaderLoader = true;
    try {
      const response = this.isCurrentCustomVersion
        ? await releaseCustomWorkflowVersion(
            this.activeWorkflowId,
            this.activeVersion,
            this.activeChildUserId
          )
        : await releaseWorkflowVersion(
            this.activeWorkflowId,
            this.activeVersion,
            this.isCurrentCustomVersion
          );

      if (response?.data) {
        runInAction(() => {
          this.versionStatus = 'final';
          this.workflow.version = Number(this.activeVersion);
          const workflowVersion = this.workflowVersions.find(
            (w) => Number(w.version) === Number(this.activeVersion)
          );
          workflowVersion.status = 'final';
          this.isHeaderLoader = false;
          this.workflow.forgeVisible = true;
          this.workflow.customVersion = this.isCurrentCustomVersion;
          this.useWorkflowVersion(this.activeVersion, response.data._id);
        });

        this.isCurrentCustomVersion
          ? showNotification(
              'success',
              `“User-specific Version ${this.activeVersion}” released`
            )
          : showNotification(
              'success',
              `“Version ${this.activeVersion}” released`
            );
      }
    } catch (err) {
      showNotification(
        'error',
        'A workflow with same title already exists; please choose a unique title before releasing.'
      );
      this.isHeaderLoader = false;
    }
  };
  @action
  releaseWorkflowVersionFromQuickAction = async (
    version,
    fromAlreadyReleasedVersion = false,
    isCurrentCustomVersion = false
  ) => {
    this.isHeaderLoader = true;
    try {
      const response = isCurrentCustomVersion
        ? await releaseCustomWorkflowVersion(
            this.activeWorkflowId,
            version,
            this.activeChildUserId
          )
        : await releaseWorkflowVersion(
            this.activeWorkflowId,
            version,
            isCurrentCustomVersion
          );

      this.globalStore.loadResources();
      if (response?.data) {
        this.versionStatus = 'final';
        this.workflow.version = Number(this.activeVersion);
        const workflowVersion = this.workflowVersions.find(
          (w) => Number(w.version) === Number(this.activeVersion)
        );
        workflowVersion.status = 'final';
        this.isHeaderLoader = false;
        this.useWorkflowVersion(version);
        if (fromAlreadyReleasedVersion) {
          showNotification(
            'success',
            `“Workflow Version ${version}” reverted successfully.`
          );
        } else {
          showNotification(
            'success',
            `“Workflow Version ${version}” released successfully.`
          );
        }
      }
    } catch (err) {
      this.isHeaderLoader = false;
      console.log(err);
    }
  };

  @action
  finalizeWorkflowVersion = () => {
    this.isHeaderLoader = true;
    finalizeWorkflowVersion(this.activeWorkflowId, this.activeVersion)
      .then(
        action((response) => {
          if (response?.data) {
            this.versionStatus = 'final';
            const workflowVersion = this.workflowVersions.find(
              (w) => Number(w.version) === Number(this.activeVersion)
            );

            workflowVersion.status = 'final';
            this.isHeaderLoader = false;
          }
        })
      )
      .catch((err) => {
        console.log(err);
        this.isHeaderLoader = false;
      });
  };

  @action
  revertAutomationWorkflowToOriginal = () => {
    this.isHeaderLoader = true;
    return revertAutomationWorkflowToOriginal(this.activeWorkflowId)
      .then(
        action((response) => {
          if (response?.data) {
            this.isHeaderLoader = false;
            this.getWorkflow(this.activeWorkflowId);
            showNotification('success', 'Workflow reverted to original.');
            return response;
          }
        })
      )
      .catch((err) => {
        console.log(err);
        this.isHeaderLoader = false;
      });
  };

  @action
  updateWorkflowInStore = async (workflow) => {
    if (workflow) {
      if (this.activeVersionId) {
        await this.getWorkflowVersion(this.activeVersionId);
      }

      const blockTypes = this.globalStore.blockTypeStore.blockTypes;
      const workflowId = workflow._id;
      this.workflowHasErrors = numberOfErrors(workflow.blocks) > 0;
      this.workflowActiveFlag = workflow.active;
      this.workflowExecutionErrors = workflow.executionErrors;
      if (
        JSON.stringify(this.workflowConnections) !==
        JSON.stringify(workflow.connections)
      ) {
        this.workflowConnections = workflow.connections;
        this.workflowBlocks = workflow.blocks;
      }
      if (this.workflowBlocks.length !== workflow.blocks.length) {
        this.workflowBlocks = workflow.blocks;
      }

      const exclusionBlockCall = [];
      if (workflow.blocks.length) {
        await Promise.all(
          workflow.blocks.map(async (block, index) => {
            if (
              JSON.stringify(block.issues) !==
                JSON.stringify(this.workflowBlocks[index]?.issues) &&
              block.id === this.workflowBlocks[index]?.id
            ) {
              this.workflowBlocks[index].issues = block.issues;
            }
            if (exclusionBlockCall.includes[block.types]) {
              return Promise.resolve();
            }
            const blockType = blockTypes.find(
              (blockType) => blockType.name === block.type
            );
            if (
              !blockType?.properties?.length &&
              block.type !== 'alloy.start'
            ) {
              exclusionBlockCall.push(block.type);
              return this.globalStore.blockTypeStore.updateBlockTypeData(
                blockType,
                block.type
              );
            } else {
              return Promise.resolve();
            }
          })
        );
      }

      if (!this.workflows || this.workflows.length === 0) {
        this.workflows = [workflow];

        this.updateConnectionsByDestination();
        this.updateExecutionErrorLookup();
        return;
      }

      const index = this.workflows.findIndex((w) => {
        return w && w._id === workflowId;
      });
      if (index === -1) {
        // we only need one workflow in our store
        this.workflows.length = 0;
        this.workflows.push(workflow);
      } else {
        this.workflows[index] = workflow;
      }

      this.updateExecutionErrorLookup();

      this.updateConnectionsByDestination();
      if (
        !workflow.active &&
        !workflow.forgeWorkflowId &&
        this.globalStore.executionStore.runningWorkflowIds.has(workflow._id)
      ) {
        this.globalStore.executionStore.runningWorkflowIds.delete(workflow._id);
      }
    }
    return Promise.resolve();
  };

  @action
  deleteWorkflow = async (workflowId) => {
    const { push } = this.globalStore.routingStore;
    const workflow = this.workflows.find((wf) => wf._id === workflowId);
    return deleteWorkflow(workflowId).then(
      action((res) => {
        this.workflows = this.workflows.filter((wf) => wf._id !== workflowId);
        if (res) {
          this.globalStore.executionStore.removeRunningWorkflow(workflowId);
          if (!workflow.forgeEditableFields) {
            appMessage(
              'custom',
              '',
              '',
              <p>
                Workflow deleted. Restore it on the{' '}
                <a
                  id="recently-deleted-link"
                  onClick={() => push(`/account/settings#recentlyDeleted`)}
                >
                  Recently Deleted
                </a>{' '}
                page
              </p>,
              'success'
            );
          }
        }
      })
    );
  };

  @action
  deleteWorkflows = async (data) => {
    const { routingStore, executionStore } = this.globalStore;
    const { push } = routingStore;

    return deleteWorkflows(data).then(
      action((res) => {
        this.workflows = this.workflows.filter(
          (a) => !data.workflows.includes(a._id)
        );

        if (res) {
          data.workflows.forEach((id) =>
            executionStore.removeRunningWorkflow(id)
          );

          showNotification(
            'custom',
            '',
            '',
            <p>
              Workflows deleted. Restore it on the{' '}
              <a
                id="recently-deleted-link"
                onClick={() => push(`/account/settings#recentlyDeleted`)}
              >
                Recently Deleted
              </a>{' '}
              page
            </p>,
            'success',
            this.forgeMode
          );
        }
      })
    );
  };

  @action
  deleteIntegration = async (integrationId) => {
    return deleteIntegration(integrationId).then(
      action((res) => {
        this.integrations = this.integrations.filter(
          (wf) => wf._id !== integrationId
        );
        if (res) {
          showNotification('success', ' Integration deleted.');
        }
      })
    );
  };

  @action
  getWorkflowIntegration = async () => {
    const { workflow } = this;

    if (workflow && workflow.integrationId) {
      const integrationResponse = await getIntegration(workflow.integrationId);
      if (integrationResponse.integration) {
        this.integrationInfo = integrationResponse.integration;
        return integrationResponse.integration;
      }
    } else {
      this.integrationInfo = null;
      return null;
    }
  };

  @action
  validateWorkflow = () => {
    return validateWorkflow()
      .then(
        action((res) => {
          if (res) {
            this.planValidationErrors = false;
            this.isActive = true;
            this.firstWorkflowCreated = res.data.firstWorkflowCreated;
          }
        })
      )
      .catch((er) => {
        // user doesn't billWithShopify, and their account is inactive

        if (er.name === 400 || er.name === 404) {
          this.planValidationErrors = true;
          this.isActive = false;
        }

        // user billsWithShopify and their account isn't active, get them to pay us
        if (er.name === 402) {
          this.planValidationErrors = true;
          this.isActive = false;
        }

        // user is active, but their plan validation failed
        if (er.name === 403) {
          this.planValidationErrors = true;
        }

        if (er.name === 406) {
          showNotification(
            'error',
            `Your plan does not have access to create a workflow`
          );
        }
      });
  };

  @action
  createWorkflow = (
    workflowName,
    forgeMode = false,
    workspaceId = null,
    integrationId = null
  ) => {
    const { userInfo } = this.globalStore.userStore;
    const workflow = {
      userId: userInfo._id,
      settings: {},
      active: false,
      name: workflowName,
      connections: {},
      blocks: [
        {
          name: 'Default Block',
          type: 'alloy.defaultBlock',
          typeVersion: '',
          isError: false,
          icon: null,
          hasCredential: false,
          isTrigger: true,
          parameters: {},
          credentials: {},
          id: uuid4(),
          issues: {},
        },
      ],
    };

    if (forgeMode) {
      workflow.forgeEditableFields = {};
    }

    return createNewWorkflow(workflow)
      .then(
        action((res) => {
          // Track segment event
          segmentTrackWorkflowCreation(
            res.data,
            this.workflowCount,
            userInfo.email
          );

          this.workflows.unshift(res.data);
          if (workspaceId) {
            addToWorkspace(workspaceId, res.data._id);
          }

          if (integrationId) {
            addWorkflowToIntegration(integrationId, {
              integrationId,
              workflowId: res.data._id,
            });
          }

          let redirectUrl = `/workflow/build?workflowId=${res.data._id}`;
          if (forgeMode) {
            redirectUrl += '&forgeMode=engaged&version=1';
          }
          this.globalStore.routingStore.push(redirectUrl);
          this.updateExecutionErrorLookup();
        })
      )
      .catch((err) => {
        if (err.name === 406) {
          showNotification(
            'error',
            `Your plan does not have access to create a workflow`
          );
          return err;
        } else return err;
      });
  };

  @action
  updateWorkflowName = (name) => {
    const workflowId = this.workflow._id;
    return updateWorkflowName(workflowId, {
      name,
      workflowId,
    })
      .then(
        action(() => {
          this.workflow.name = name;
          showNotification('success', 'Workflow name saved');
        })
      )
      .catch((error) => {
        // TODO bugsnag
        showNotification('error', error);
      });
  };

  @action
  updateWorkflowFromLightning = async (
    postId,
    credentialsData,
    formData,
    preview,
    fixedCollectionKeys
  ) => {
    try {
      const userId = this.globalStore.userStore.userInfo._id;
      const response = await updateWorkflowFromLightning({
        postId,
        userId,
        credentialsData,
        formData,
        preview,
        fixedCollectionKeys,
      });
      if (response.success && response.updatedWorkflow) {
        showNotification('success', 'Added workflow successfully!');
        const updatedBlocks =
          await this.globalStore.marketplaceStore.updateWorkflowIssues(
            response.updatedWorkflow
          );
        response.updatedWorkflow.blocks = updatedBlocks;
        const updatedResponse = await updateWorkflow(
          response.updatedWorkflow._id,
          response.updatedWorkflow
        );
        return updatedResponse;
      }
    } catch (e) {
      // TODO bugsnag
      showNotification(
        'error',
        'Unable to update this workflow, please contact support'
      );
    }
  };

  @action
  removeForgeEditableFields = async (blockId) => {
    const keys = Object.keys(this.forgeEditableFields).filter((key) =>
      key.startsWith(blockId)
    );

    keys.forEach((key) => {
      delete this.forgeEditableFields[key];
    });
  };

  @action
  removeBlock = (blockId, keepIndex, mode) => {
    const { blockValues } = this.globalStore.blockEditStore;

    if (this.forgeMode) {
      this.removeForgeEditableFields(blockId);
    }
    return removeBlock(this.activeWorkflowId, {
      blockId,
      keepIndex,
      mode,
      ...(this.activeVersion
        ? {
            version: this.activeVersion,
            isCurrentCustomVersion: this.workflow.isCurrentCustomVersion,
            childUserId: this.activeChildUserId,
            activeVersionId: this.activeWorkflowVersion?._id,
          }
        : {}),
    })
      .then(
        action(async (res) => {
          // remove all referenced expression by the deleted block using expression editor
          if (this.forgeMode) {
            res.data.forgeEditableFields = this.forgeEditableFields;
          }
          this.removeReferencedExpression(res.data.blocks, blockId);
          await this.updateWorkflowInStore(res.data);
          return updateWorkflow(res.data._id, {
            ...res.data,
            ...(this.activeEmbeddedUsername
              ? { activeVersionId: this.activeWorkflowVersion?._id }
              : {}),
          });
        })
      )
      .then(
        action(() => {
          if (blockValues?.id === blockId || keepIndex !== undefined) {
            const lastBlock =
              this.workflow.blocks[this.workflow.blocks.length - 1];
            return this.globalStore.blockEditStore.setActiveBlock({
              blockType: lastBlock.type,
              id: lastBlock.id,
              activeEdit: true,
            });
          } else {
            this.globalStore.blockEditStore.blockValues =
              this.workflow.blocks.find((block) => block.id === blockValues.id);
          }
        })
      );
  };

  @action removeBranch = (blockId, branchIndex) => {
    return removeBranch(this.activeWorkflowId, {
      blockId,
      branchIndex,
      ...(this.activeVersion ? { version: this.activeVersion } : {}),
    }).then(
      action((res) => {
        const index = this.workflows.findIndex((w) => {
          return w && w._id === res.data._id;
        });

        this.workflows[index] = res.data;
      })
    );
  };

  @action duplicateBranch = (blockId, branchIndex) => {
    return duplicateBranch(this.activeWorkflowId, {
      blockId,
      branchIndex,

      ...(this.activeVersion ? { version: this.activeVersion } : {}),
    }).then(
      action((res) => {
        if (res.data.workflowId) {
          const index = this.workflows.findIndex((w) => {
            return w && w._id === res.data.workflowId;
          });

          if (index > -1) {
            // we just need these two fields not the other workflow data when updating the workflow version
            this.workflows[index].blocks = res.data.blocks;
            this.workflows[index].connection = res.data.connections;
          }
        } else {
          const index = this.workflows.findIndex((w) => {
            return w && w._id === res.data._id;
          });
          if (index > -1) {
            this.workflows[index] = res.data;
          }
        }
        const { blockValues, currentBlockId } = this.globalStore.blockEditStore;
        const branchBlock = res.data.blocks.find(
          (b) => b.id === currentBlockId
        );
        blockValues.parameters.branches = branchBlock.parameters.branches;
        blockValues.preview = branchBlock.preview;
      })
    );
  };

  updateIssues = (blockValues, blockType) => {
    const { credentialTypes } = this.globalStore.credentialStore;
    const { credentials } = this.globalStore.credentialStore;
    if (!blockType) return {};
    const issues = {};

    const fullBlockIssues = getBlockParametersIssues(
      blockType.properties,
      blockValues
    );
    if (fullBlockIssues !== null) {
      issues.parameters = fullBlockIssues.parameters;
    }

    const fullBlockCredIssues = getBlockCredentialIssues(
      blockValues,
      blockType,
      credentialTypes,
      credentials
    );
    if (fullBlockCredIssues !== null) {
      issues.credentials = fullBlockCredIssues.credentials;
    }
    return issues;
  };

  getFilteredString = (blockId, paramString) => {
    const indexOfId = paramString.indexOf(blockId);
    const indexOfIdWithEquals = paramString.indexOf('=' + blockId);
    const indexOfExpressionEnd = paramString.indexOf('}}', indexOfId);
    if (indexOfId > -1 && indexOfExpressionEnd > -1) {
      const newString = paramString.replace(
        paramString.substring(
          indexOfIdWithEquals > -1 ? indexOfIdWithEquals : indexOfId,
          indexOfExpressionEnd + 2
        ),
        ''
      );
      if (newString.indexOf(blockId) > -1) {
        return this.getFilteredString(blockId, newString);
      } else {
        return newString;
      }
    }
    return paramString;
  };

  @action
  removeReferencedExpression = (blocks, initialBlockId) => {
    const blockIds = [initialBlockId];
    const blockIdsWithExpressions = [];
    const propsOfBlocksChanged = {};
    blocks.forEach((block) => {
      let changedParam = false;
      const propsChanged = [];
      blockIds.forEach((blockId) => {
        if (block.preview) {
          // get all keys of preview
          const keys = Object.keys(block.preview);
          keys.forEach((key) => {
            const fields = block.preview[key][0].children;
            // find all that has referenced to the block to be deleted
            if (fields) {
              const retainFields = fields.filter((field) =>
                field.fieldKey
                  ? !String(field.fieldKey).includes(blockId)
                  : true
              );
              block.preview[key][0].children = retainFields;
            }

            if (
              block.preview[key][0].value &&
              typeof block.preview[key][0].value === 'string' &&
              block.preview[key][0].value.includes(blockId)
            ) {
              delete block.preview[key];
            }
          });
        }
        // also check for parameters
        this.recursiveParameterChange(
          block.parameters,
          blockId,
          propsChanged,
          changedParam
        );
        if (propsChanged.length > 0) {
          changedParam = true;
        }
      });
      if (changedParam) {
        blockIds.push(block.id);
        blockIdsWithExpressions.push(block.id);
        set(propsOfBlocksChanged, block.id, propsChanged);
        const blockType = this.globalStore.blockTypeStore.findByName(
          block.type
        );
        const allIssues = getBlockParametersIssues(blockType.properties, block);
        if (allIssues) set(block, 'issues.parameters', allIssues.parameters);
      }
    });

    if (blockIdsWithExpressions.length) {
      this.blocksWithWarnings = uniq(
        concat(this.blocksWithWarnings, blockIdsWithExpressions)
      );

      this.blockPropsWithWarnings = merge(
        this.blockPropsWithWarnings,
        propsOfBlocksChanged
      );
    }
  };

  @action
  getReferencedExpression = (blocks, blockId) => {
    const filteredBlocks = blocks.filter((block) => {
      // check for parameters
      for (const prop in block.parameters) {
        if (
          typeof block.parameters[prop] === 'string' &&
          block.parameters[prop].indexOf(blockId) > -1
        ) {
          return true;
        }

        if (typeof block.parameters[prop] === 'object') {
          const blockParametersProp = block.parameters[prop];
          let hasExpression = false;

          for (const subProp in blockParametersProp) {
            if (isArray(blockParametersProp[subProp])) {
              each(blockParametersProp[subProp], (arrValue) => {
                if (typeof arrValue === 'object') {
                  forIn(arrValue, (innerValue) => {
                    if (
                      typeof innerValue === 'string' &&
                      includes(innerValue, blockId)
                    ) {
                      hasExpression = true;
                    }
                  });
                }
              });
            } else if (
              typeof blockParametersProp[subProp] === 'string' &&
              blockParametersProp[subProp].indexOf(blockId) > -1
            ) {
              hasExpression = true;
            }
          }

          if (hasExpression) return hasExpression;
        }
      }

      return false;
    });

    return filteredBlocks;
  };

  @action
  runTestBlockinWorkflow = async (
    blockId,
    isFromTemplate = false,
    loadMore = false
  ) => {
    const { blocks } = this.workflow;
    const indexOfBlock = blocks.findIndex((block) => block.id === blockId);
    if (indexOfBlock === -1) return false;
    const existingBlock = blocks[indexOfBlock];
    const blockIdBefore = indexOfBlock > 0 ? blocks[indexOfBlock - 1].id : null;
    const blockValues =
      this.globalStore.blockEditStore.blockValues || existingBlock;

    const body = {
      blockId,
      blockIdBefore,
      blockType: blockValues.type,
      blockValues,
      ...(this.activeChildUserId
        ? { childUserId: this.activeChildUserId }
        : {}),
      ...(this.activeVersion ? { version: this.activeVersion } : {}),
      customVersion: this.workflow?.isCurrentCustomVersion,
      activeVersionId: this.activeWorkflowVersion?._id,
    };

    this.blockRunTestError = null;

    try {
      const response = await runTestBlock(this.workflow._id, body, loadMore);
      await this.updateWorkflowInStore(response.data);
      if (!isFromTemplate) {
        const currentBlock = response.data.blocks.find(
          (block) => block.id === blockId
        );
        this.globalStore.blockEditStore.blockValues = currentBlock;
      }
      return true;
    } catch (e) {
      runInAction(() => {
        // const message =
        //   'Please verify your Inputs and ensure that any accounts linked to this block are still authenticated.';
        const message = `Not able to send the test data. Try again.`;
        this.addExecutionError({
          message,
          blockId,
        });
        this.blockRunTestError = {
          message,
          blockId,
        };
      });
      return false;
    }
  };

  @action
  duplicateWorkflow = async (workflowId) => {
    return duplicateWorkflow(workflowId);
  };

  @action
  addNotification = (msg) => {
    // check if message already exists in notifications
    const exists = this.currentWorkflowNotifications.filter(
      (notif) => notif.message !== msg.message
    );
    this.currentWorkflowNotifications = exists;
    this.currentWorkflowNotifications.push({
      message: msg.message,
      title: msg.title,
      parameterName: msg.parameterName,
      timestamp: dayjs().startOf('minute').fromNow(),
    });
  };

  @action
  clearNotifications = () => {
    this.currentWorkflowNotifications = [];
  };

  @action
  executeWorkflowTest = () => {
    const { blockTypes } = this.globalStore.blockTypeStore;
    const { workflow } = this;
    const runData = workflow.blocks.map((block) => {
      let blockId;
      const blockType = blockTypes.find(
        (blockTyp) => blockTyp.name === block.type
      );
      const { blockIsTestable, blockManuallyTestable } = isBlockTestable(
        blockType,
        block
      );
      if (blockIsTestable && !blockManuallyTestable) {
        blockId = block.id;
      }
      return blockId;
    });
    return this.recursiveGetTestData(runData).then(() => true);
  };

  recursiveGetTestData = (arrayOfBlockIds) => {
    const id = arrayOfBlockIds.shift();

    if (id) {
      return this.runTestBlockinWorkflow(id, true).then(() =>
        this.recursiveGetTestData(arrayOfBlockIds)
      );
    } else {
      return Promise.resolve();
    }
  };

  @action
  getS3PresignedUrl = async (filename, filetype) => {
    try {
      const response = await getS3PresignedUrl({ filename, filetype });
      return response;
    } catch (e) {
      throw Error(e);
    }
  };

  @action
  putFileS3 = async (signedRequest, file) => {
    let imageUploaded = false;
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };

    await axios
      .put(signedRequest, file, options)
      .then(() => {
        imageUploaded = true;
      })
      .catch(() => {
        // alert('ERROR ' + JSON.stringify(error));
        imageUploaded = false;
      });

    return imageUploaded;
  };

  @action
  setForgeActiveWorkflow = async (id) => {
    const parentId = id;
    this.forgeActiveWorkflow = id;
    const { draftId } = await createRecipeDraft({ parentId });
    this.draftId = draftId;
  };

  @action
  getCsvS3PresignedUrl = async (filename, filetype, blockId, draftId) => {
    const response = await getCsvS3PresignedUrl({
      filename,
      filetype,
      blockId,
      workflowId: this.activeWorkflowId || this.forgeActiveWorkflow,
      draftId: draftId || this.draftId,
    });

    return response;
  };

  getCsvData = async (filename, filetype, blockId, draftId) => {
    const response = await getS3CSV({
      filename,
      filetype,
      blockId,
      workflowId: this.activeWorkflowId || this.forgeActiveWorkflow,
      draftId: draftId || this.draftId,
    });

    return response;
  };
  @action
  overWriteBlockOutputAction = async (data, fromStep1 = false) => {
    const body = {
      blockOutputData: data,
      ...(this.activeChildUserId
        ? { childUserId: this.activeChildUserId }
        : {}),
      ...(this.activeVersion ? { version: this.activeVersion } : {}),
      ...(this.activeWorkflowVersion
        ? { activeVersionId: this.activeWorkflowVersion?._id }
        : {}),
      fromStep1,
    };
    const { currentBlockId } = this.globalStore.blockEditStore;
    this.saveWorkflowBlockOutputLoading = true;
    overWriteBlockOutput(this.workflow._id, currentBlockId, body)
      .then(() => {
        if (!fromStep1) {
          this.saveWorkflowBlockOutputLoading = false;
          showNotification('success', `Output data overwritten.`);
        }
      })
      .catch(() => {
        if (!fromStep1) {
          this.saveWorkflowBlockOutputLoading = false;
          showNotification(
            'error',
            'something went wrong while saving output data'
          );
        }
      });
  };

  copyCSVFile = async (filename, blockId, draftId, workflowId) => {
    const response = await copyS3CSV({
      filename,
      blockId,
      workflowId,
      draftId,
    });

    return response;
  };

  deleteCsvFile = async (filename, blockId, draftId) => {
    const response = await deleteS3CSV({
      filename,
      blockId,
      workflowId: this.activeWorkflowId || this.forgeActiveWorkflow,
      draftId: draftId || this.draftId,
    });

    return response;
  };

  @action
  putFileCsvS3 = async (signedRequest, file) => {
    let csvUploaded = false;
    const options = {
      headers: {
        'Content-Type': 'text/csv',
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (percent === 100) {
          setTimeout(() => this.setUploadProgress(100), 1000);
        }
        this.setUploadProgress(percent);
      },
    };
    await axios
      .put(
        signedRequest,
        file.data && isString(file.data) ? file.data : file,
        options
      )
      .then(() => {
        csvUploaded = true;
      })
      .catch(() => {
        csvUploaded = false;
      });

    return csvUploaded;
  };

  @action
  getActiveWorkflowCount = async (forgeMode = false) => {
    const accessToken = localStorage.getItem('workflow-accessToken');
    if (!accessToken) return;
    if (this.isActiveWorkflowCountLoading) return;

    this.isActiveWorkflowCountLoading = true;

    try {
      const response = await getActiveWorkflowCount(forgeMode);
      if (response && (response.data || response.data === 0)) {
        runInAction(() => {
          this.activeWorkflowCount = response.data;
          if (forgeMode && response.data > 0) {
            this.hasArchivedWorkflows = true;
          } else {
            this.hasArchivedWorkflows = false;
          }
        });
      }
    } catch (error) {
      showNotification(
        'error',
        'There was an error fetching your account information. Please refresh and try again'
      );
    } finally {
      this.isActiveWorkflowCountLoading = false;
    }
  };

  @action
  getActiveWorkspaceWorkflowCount = async (workspaceId) => {
    try {
      const response = await getActiveWorkspaceWorkflowCount(workspaceId);
      if (response && (response.data || response.data === 0)) {
        runInAction(() => {
          this.workflowCount = response.data;
        });
      }
    } catch (error) {
      showNotification(
        'error',
        'There was an error fetching your account information. Please refresh and try again'
      );
    }
  };

  @action
  updateConnectionsByDestination = () => {
    const connections = this.workflowConnections;
    const returnConnection = {};

    let connectionInfo;
    for (const sourceBlock in connections) {
      if (!connections[sourceBlock]) {
        continue;
      }
      for (const type in connections[sourceBlock]) {
        if (!connections[sourceBlock][type]) {
          continue;
        }
        for (const inputIndex in connections[sourceBlock][type]) {
          if (!connections[sourceBlock][type][inputIndex]) {
            continue;
          }
          for (connectionInfo of connections[sourceBlock][type][inputIndex]) {
            if (!returnConnection[connectionInfo.block]) {
              returnConnection[connectionInfo.block] = {};
            }
            if (
              !returnConnection[connectionInfo.block].hasOwnProperty(
                connectionInfo.type
              )
            ) {
              returnConnection[connectionInfo.block][connectionInfo.type] = [];
            }
            returnConnection[connectionInfo.block][connectionInfo.type].push([
              {
                block: sourceBlock,
                type,
                index: parseInt(inputIndex, 10),
              },
            ]);
          }
        }
      }
    }

    if (
      JSON.stringify(returnConnection) !==
      JSON.stringify(this.connectionsByDestination)
    ) {
      this.connectionsByDestination = returnConnection;
    }
  };

  getChildBlockIds = (blockId, type = 'main', depth = -1) => {
    return this.getConnectedBlocks(
      this.workflow.connections,
      blockId,
      type,
      depth
    );
  };

  /**
   * Returns all the blocks before the given one
   *
   */
  getParentBlockIds = (blockId, type = 'main', depth = -1) => {
    const parentIds = this.getConnectedBlocks(
      this.connectionsByDestination,
      blockId,
      type,
      depth
    );

    // determine if there are even any branching blocks before this block

    const parentBranchBlocks = [];
    parentIds.forEach((id) => {
      const block = this.workflowBlocks.find((b) => b.id === id);

      if (block && branchBlockTypes.includes(block.type)) {
        parentBranchBlocks.push(block);
      }
    });

    if (!parentBranchBlocks.length) {
      return parentIds;
    }

    // get blocks after the parent branch blocks
    const blockIdsAfter = [];
    parentBranchBlocks.forEach((b) => {
      const connection = this.workflowConnections[b.id];
      if (!connection) return;
      switch (b.type) {
        case 'alloy.errorHandler':
        case 'alloy.branch': {
          const numBranches = b.parameters.branches.length;
          if (
            connection[type][numBranches] &&
            connection[type][numBranches][0] &&
            connection[type][numBranches][0].block
          ) {
            blockIdsAfter.push({
              id: connection[type][numBranches][0].block,
              parent: b,
            });
          }
          break;
        }
        case 'alloy.for': {
          if (
            connection[type][1] &&
            connection[type][1][0] &&
            connection[type][1][0].block
          ) {
            blockIdsAfter.push({
              id: connection[type][1][0].block,
              parent: b,
            });
          }
          break;
        }
        case 'alloy.if':
        default: {
          if (
            connection[type][2] &&
            connection[type][2][0] &&
            connection[type][2][0].block
          ) {
            blockIdsAfter.push({
              id: connection[type][2][0].block,
              parent: b,
            });
          }
        }
      }
    });

    const blockIdsToRemove = [];
    parentBranchBlocks.forEach((b) => {
      const connection = this.workflowConnections[b.id];

      if (!connection) return;
      switch (b.type) {
        case 'alloy.errorHandler':
        case 'alloy.branch': {
          const numBranches = b.parameters.branches.length;
          if (
            connection[type][numBranches] &&
            connection[type][numBranches][0] &&
            connection[type][numBranches][0].block
          ) {
            // blockIdsToRemove.push(b.id);
          }
          break;
        }
        case 'alloy.for': {
          const initialLoopBlock = connection[type][0][0];
          if (initialLoopBlock && initialLoopBlock.block !== blockId) {
            const hasConnection = this.recursiveBlockConnectionId(
              this.workflowConnections,
              blockId,
              initialLoopBlock.block
            );
            !hasConnection && blockIdsToRemove.push(b.id);
          }
          break;
        }
      }
    });

    blockIdsToRemove.forEach((b) => {
      const index = parentIds.findIndex((id) => id === b);
      if (index > -1) {
        parentIds.splice(index, 1);
      }
    });
    // determine if this block is (or is a child of) a block after and make array of branch blocks
    // and after blocks that fit criteria
    const matchingBlockAfters = [];
    blockIdsAfter.forEach((blockAfter) => {
      if (blockAfter.id === blockId) {
        matchingBlockAfters.push(blockAfter);
      } else {
        const children = this.getChildBlockIds(blockAfter.id);
        if (children.includes(blockId)) {
          matchingBlockAfters.push(blockAfter);
        }
      }
    });

    if (!matchingBlockAfters.length) {
      return parentIds;
    }

    // determine if there are any hoistable blocks
    const hoistableBlocks = this.workflowBlocks.filter((b) =>
      hoistedBlockTypes.includes(b.type)
    );

    if (!hoistableBlocks.length) {
      return parentIds;
    }

    // if it is, get parents of each hoistable block, and check intersection of that and parent
    // blocks from previous step
    const matchingBlockAfterParentIds = matchingBlockAfters.map(
      (b) => b.parent.id
    );
    hoistableBlocks.forEach((b) => {
      const thisParentIds = this.getConnectedBlocks(
        this.connectionsByDestination,
        b.id,
        type,
        depth
      );
      if (thisParentIds.includes(blockId)) {
        return;
      }
      const intersectingIds = intersection(
        thisParentIds,
        matchingBlockAfterParentIds
      );
      if (intersectingIds.length) {
        parentIds.push(b.id);
      }
    });

    return parentIds;
  };

  /**
   * Gets all the blocks which are connected blocks starting from
   * the given one
   */
  getConnectedBlocks = (
    connections,
    blockId,
    type = 'main',
    depth = -1,
    checkedBlocks
  ) => {
    depth = depth === -1 ? -1 : depth;
    const newDepth = depth === -1 ? depth : depth - 1;
    if (depth === 0) {
      // Reached max depth
      return [];
    }

    if (!connections.hasOwnProperty(blockId)) {
      // Block does not have incoming connections
      return [];
    }

    if (!connections[blockId].hasOwnProperty(type)) {
      // Block does not have incoming connections of given type
      return [];
    }

    checkedBlocks = checkedBlocks || [];

    if (checkedBlocks.includes(blockId)) {
      // Block got checked already before
      return [];
    }

    checkedBlocks.push(blockId);

    const returnBlocks = [];
    let addBlocks;
    let blockIndex;
    let i;
    let parentBlockId;
    connections[blockId][type].forEach((connectionsByIndex) => {
      connectionsByIndex.forEach((connection) => {
        if (checkedBlocks.includes(connection.block)) {
          // Block got checked already before
          return;
        }

        returnBlocks.unshift(connection.block);

        addBlocks = this.getConnectedBlocks(
          connections,
          connection.block,
          type,
          newDepth,
          checkedBlocks
        );

        for (i = addBlocks.length; i--; i > 0) {
          // Because blocks can have multiple parents it is possible that
          // parts of the tree is parent of both and to not add blocks
          // twice check first if they already got added before.
          parentBlockId = addBlocks[i];
          blockIndex = returnBlocks.indexOf(parentBlockId);

          if (blockIndex !== -1) {
            // Block got found before so remove it from current location
            // that block-order stays correct
            returnBlocks.splice(blockIndex, 1);
          }

          returnBlocks.unshift(parentBlockId);
        }
      });
    });

    return returnBlocks;
  };

  getBlocksWithWarnings = () => {
    return this.blocksWithWarnings;
  };

  pullFromBlocksWithWarnings = (id) => {
    this.blocksWithWarnings = pull(this.blocksWithWarnings, id);
  };

  getBlockPropsWithWarnings = () => {
    return this.blockPropsWithWarnings;
  };

  getParameterWarningMessage = (parameter, path) => {
    const { currentBlockId } = this.globalStore.blockEditStore;
    const propsWithWarning = get(this.blocksWithWarnings, currentBlockId, null);
    let warningMessage = null;

    if (propsWithWarning && propsWithWarning.includes(parameter.name)) {
      warningMessage = 'Missing variables due to changes in blocks actions.';
    } else if (propsWithWarning && propsWithWarning.length > 0) {
      for (const propWithWarning of propsWithWarning) {
        const parameterPath = path.split('[').join('.').split(']').join('');
        if (Array.isArray(propWithWarning)) {
          const propWithWarningPath = propWithWarning.join('.');
          parameterPath.includes(propWithWarningPath) &&
            (warningMessage =
              'Missing variables due to changes in blocks actions.');
        }
      }
    }
    return warningMessage;
  };

  pullFromBlockPropsWithWarnings = (blockId, parameterName) => {
    const parameters = get(this.blockPropsWithWarnings, blockId, null);
    if (
      parameters &&
      parameters.includes(parameterName.replace('parameters.', ''))
    ) {
      unset(this.blockPropsWithWarnings, blockId);
    }
  };

  recursiveParameterChange = (
    blockParameters,
    blockId,
    propsChanged,
    changedParam,
    parentPath
  ) => {
    const innerParentPath = parentPath || [];
    for (const prop in blockParameters) {
      const expression = `{{$block["${blockId}`;

      try {
        if (
          typeof blockParameters[prop] === 'string' &&
          blockParameters[prop].indexOf(expression) > -1
        ) {
          // remove value in parameters, too
          blockParameters[prop] = this.getFilteredString(
            expression,
            blockParameters[prop]
          );
          if (innerParentPath.length > 0) {
            const propPath = [...innerParentPath, prop];
            propsChanged.push(propPath);
          } else {
            propsChanged.push(prop);
          }
        } else if (
          typeof blockParameters[prop] === 'string' &&
          blockParameters[prop].includes(blockId)
        ) {
          blockParameters[prop] = '';
          if (innerParentPath.length > 0) {
            const propPath = [...innerParentPath, prop];
            propsChanged.push(propPath);
          } else {
            propsChanged.push(prop);
          }
        } else if (
          Array.isArray(blockParameters[prop]) &&
          prop === 'conditionSets'
        ) {
          const conditionSets = blockParameters[prop];
          conditionSets.forEach((condition, index) => {
            condition.conditionSet.forEach((item, i) => {
              const value1 = item?.value1;
              const value2 = item?.value2;

              if (value1 && value1.indexOf(expression) > -1) {
                blockParameters[prop][index].conditionSet[i].value1 = '';
                if (innerParentPath.length > 0) {
                  const propPath = [...innerParentPath, prop];
                  propsChanged.push(propPath);
                } else {
                  propsChanged.push(prop);
                }
              }
              if (value2 && value2.indexOf(expression) > -1) {
                blockParameters[prop][index].conditionSet[i].value2 = '';
                if (innerParentPath.length > 0) {
                  const propPath = [...innerParentPath, prop];
                  propsChanged.push(propPath);
                } else {
                  propsChanged.push(prop);
                }
              }
            });
          });
        } else if (typeof blockParameters[prop] === 'object') {
          this.recursiveParameterChange(
            blockParameters[prop],
            blockId,
            propsChanged,
            changedParam,
            [...innerParentPath, prop]
          );
        }
      } catch (e) {
        console.log('error: ', e);
      }
    }
  };

  recursiveBlockConnectionId = (connections, blockId, connectionId) => {
    try {
      const type = 'main';
      const blockConnections = connections[connectionId];
      if (blockConnections) {
        for (const blockConnection of blockConnections[type]) {
          if (blockConnection[0]) {
            if (blockConnection[0].block === blockId) {
              return true;
            } else if (
              this.recursiveBlockConnectionId(
                connections,
                blockId,
                blockConnection[0].block
              )
            ) {
              return true;
            }
          }
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  };
}
