import { parse } from 'url';
import { isNull, isUndefined, delay } from 'lodash';
import { WorkflowStore } from './WorkflowStore';
import { ExecutionStore } from './ExecutionStore';
import { BlockTypeStore } from './BlockTypeStore';
import { CredentialStore } from './CredentialStore';
import { UserStore } from './UserStore';
import { BlockEditStore } from './BlockEditStore';
import { SelectActionStore } from './SelectActionStore';
import { PopupStore } from './PopupStore';
import { MarketplaceStore } from './MarketplaceStore';
import { MyAccountStore } from './MyAccountStore';
import { EmailStore } from './EmailStore';
import { IntegrationStore } from './IntegrationStore';
import { ActionStore } from './ActionStore';
import { RecipeCreatorStore } from './RecipeCreatorStore';
import { RecipeImportStore } from './RecipeImportStore';
import { WorkspaceStore } from './WorkspaceStore';
import { BillingStore } from './BillingStore';
import { WalkThroughStore } from './WalkThroughStore';
import { PartnerHubStore } from './PartnerHubStore';
import { TeamsStore } from './TeamsStore';
import { EmbeddedInstallerStore } from './EmbeddedInstallerStore';
import { OneStore } from './OneStore';
import { InstallationStore } from './InstallationStore';
import { CustomActionStore } from './CustomActionStore';
import { CustomIntegrationStore } from './CustomIntegrationStore';
import { showNotification } from 'Components/Notifications/Notification';

export class GlobalStore {
  constructor(routingStore) {
    this.workflowStore = new WorkflowStore(this);
    this.executionStore = new ExecutionStore(this);
    this.blockTypeStore = new BlockTypeStore(this);
    this.credentialStore = new CredentialStore(this);
    this.userStore = new UserStore(this);
    this.blockEditStore = new BlockEditStore(this);
    this.selectActionStore = new SelectActionStore(this);
    this.workspaceStore = new WorkspaceStore(this);
    this.popupStore = new PopupStore(this);
    this.marketplaceStore = new MarketplaceStore(this);
    this.myAccountStore = new MyAccountStore(this);
    this.emailStore = new EmailStore(this);
    this.integrationStore = new IntegrationStore(this);
    this.actionStore = new ActionStore(this);
    this.recipeCreatorStore = new RecipeCreatorStore(this);
    this.recipeImportStore = new RecipeImportStore(this);
    this.billingStore = new BillingStore(this);
    this.walkThroughStore = new WalkThroughStore(this);
    this.partnerHubStore = new PartnerHubStore(this);
    this.teamsStore = new TeamsStore(this);
    this.embeddedInstallerStore = new EmbeddedInstallerStore(this);
    this.oneStore = new OneStore(this);
    this.installationStore = new InstallationStore(this);
    this.customActionStore = new CustomActionStore(this);
    this.customIntegrationStore = new CustomIntegrationStore(this);
    this.routingStore = routingStore;
  }

  activateWorkflow = async (data) => {
    this.executionStore.runWorkflow(null, data);
  };

  loadResources = async (successMessage) => {
    const { workflowId, blockId, versionId, forgeMode, version, userId } =
      parse(this.routingStore.location.search, true).query;

    if (workflowId && forgeMode && !versionId && !userId) {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('version', version || '1');
      this.routingStore.push(
        window.location.pathname + '?' + currentUrlParams.toString()
      );
    }

    if (forgeMode && this.userStore.userInfo.forgeId) {
      this.workflowStore.enableForge();
    }
    const workflow = await this.workflowStore.getWorkflow(
      workflowId,
      version,
      userId,
      versionId
    );
    // if workflow does not exists, go to dashboard
    if (!workflow || isUndefined(workflow) || isNull(workflow)) {
      this.workflowStore.isHeaderLoader = false;
      this.routingStore.push('/dashboard');
    }
    // await this.workspaceStore.initialize();
    await this.blockTypeStore.initialize();
    await this.customActionStore.initialize();
    await this.credentialStore.initialize();
    // await this.teamsStore.initialize();
    if (this.marketplaceStore.templateImported) {
      await this.workflowStore.executeWorkflowTest();
      const { blocks } = this.workflowStore.workflow;
      if (blocks.length && blocks[0].type !== 'alloy.start') {
        // do not show the activation message,
        this.marketplaceStore.templateImported = false;
        // auto activate the workflow
        delay(this.activateWorkflow, 3000, this.workflowStore.workflow);
      }
    }
    let lastBlock;

    if (workflow && workflow.blocks.length) {
      if (workflow.blocks[0].type === 'alloy.start') {
        lastBlock = 1;
      } else {
        lastBlock = 0;
      }
    }

    const workflowSettingsCurrentBlockId = workflow
      ? workflow.settings.currentBlockId
      : null;

    const currentBlockId = blockId || workflowSettingsCurrentBlockId || null;

    let blockToEdit = currentBlockId
      ? workflow.blocks.find((block) => block.id === currentBlockId)
      : workflow.blocks[lastBlock];

    if (blockId && !blockToEdit) {
      blockToEdit = workflow.blocks[lastBlock];
    }
    if (blockToEdit == null || blockToEdit === undefined) {
      blockToEdit = workflow.blocks[lastBlock];
    }
    if (blockToEdit) {
      await this.blockEditStore.setActiveBlock({
        blockType: blockToEdit.type,
        id: blockToEdit.id,
      });
      this.blockTypeStore.setCurrentBlockType(blockToEdit.type);
      this.selectActionStore.setEditMode();
    }

    if (this.marketplaceStore.templateImported) {
      this.blockEditStore.updateAllIssues();
    }
    if (forgeMode) {
      this.executionStore.startNotificationPolling({
        forgeWorkflowId: workflowId,
      });
    } else {
      this.executionStore.startNotificationPolling({ workflowId });
    }

    this.executionStore.findLatestExecution();
    this.workflowStore.clearNotifications();
    this.myAccountStore.getPlans();
    this.workflowStore.isHeaderLoader = false;
    if (successMessage) {
      showNotification('success', successMessage);
    }
  };
  resetStores() {
    this.workflowStore = new WorkflowStore(this);
    this.executionStore = new ExecutionStore(this);
    this.blockTypeStore = new BlockTypeStore(this);
    this.credentialStore = new CredentialStore(this);
    this.userStore = new UserStore(this);
    this.blockEditStore = new BlockEditStore(this);
    this.selectActionStore = new SelectActionStore(this);
    this.workspaceStore = new WorkspaceStore(this);
    this.popupStore = new PopupStore(this);
    this.marketplaceStore = new MarketplaceStore(this);
    this.myAccountStore = new MyAccountStore(this);
    this.emailStore = new EmailStore(this);
    this.integrationStore = new IntegrationStore(this);
    this.actionStore = new ActionStore(this);
    this.recipeCreatorStore = new RecipeCreatorStore(this);
    this.recipeImportStore = new RecipeImportStore(this);
    this.billingStore = new BillingStore(this);
    this.walkThroughStore = new WalkThroughStore(this);
    this.partnerHubStore = new PartnerHubStore(this);
    this.teamsStore = new TeamsStore(this);
    this.embeddedInstallerStore = new EmbeddedInstallerStore(this);
    this.oneStore = new OneStore(this);
    this.installationStore = new InstallationStore(this);
    this.customActionStore = new CustomActionStore(this);
    this.customIntegrationStore = new CustomIntegrationStore(this);
  }
}
