import dayjs from 'dayjs';
import { observable, action, computed, toJS } from 'mobx';
import {
  getOneIntegrations,
  getOneConnections,
  updateOneIntegration,
  getIntegration,
  credentialAnalytics,
  getBlockConnection,
  getBlockConnections,
  getSyncStatus,
  updateUapiInfo,
} from 'Services/one';
import { deleteOneConnections } from '../services/one';
import { showNotification } from '../components/Notifications';

export class OneStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  integrationsList = [];

  @observable
  loadingIntegrationsList = false;

  @observable
  connectionsList = [];

  @observable
  loadingConnectionsList = false;

  @observable
  limit = 20;

  @observable
  skip = 0;

  @observable
  currentPage = 1;

  @observable
  sortBy = 'createdAt';

  @observable
  sortOrder = 'desc';

  @observable
  sortOrderFull = 'descend';

  @observable
  filters = [];

  @observable
  totalConnections = 0;

  @observable
  filteredConnectionsCount = 0;

  @observable
  totalIntegrations = 0;

  @observable
  topIntegration = { name: '', icon: '' };

  @observable
  totalUsers = 0;

  @observable
  connectionsLoading = false;

  @observable
  currentPage = 1;

  @observable
  integrationsCount = 0;

  @action
  setIntegrationsList = (integrationsList) => {
    this.integrationsList = integrationsList;
    this.loadingIntegrationsList = false;
  };

  @action
  setIntegrationsCount = (integrationsCount) => {
    this.integrationsCount = integrationsCount;
  };

  @action
  setConnectionsLoading = (loading) => {
    this.connectionsLoading = loading;
  };

  @action
  setConnectionsList = (connectionsList) => {
    const { blockTypeStore } = this.globalStore;
    const { blockTypes } = blockTypeStore;

    const totalIntegrations = new Set();
    const totalUsers = new Set();
    const topIntegration = {};

    if (connectionsList?.length) {
      this.connectionsList = connectionsList.map((connection) => {
        if (!totalIntegrations.has(connection.blockType)) {
          totalIntegrations.add(connection.blockType);
          topIntegration[connection.blockType] = 1;
        } else {
          topIntegration[connection.blockType] += 1;
        }

        if (!totalUsers.has(connection?.user?._id)) {
          totalUsers.add(connection.user._id);
        }

        const overallStatus =
          connection.status.length &&
          connection.status.some((status) => status?.syncType === 'resync');

        const cleanStatus = connection.status.map((status) => {
          return {
            syncDataType: status?.syncDataType.split(
              /commerce\/|crm\/|accounting\//
            )?.[1],
            syncType: status?.syncType === 'complete-sync' ? 'Done' : 'Syncing',
            updatedAt: dayjs(status.updatedAt).format('DD MMM YYYY hh:mm a'),
          };
        });

        return {
          ...connection,
          integration: {
            icon: connection.integration?.icon || '',
            name: connection.blockType || '',
          },
          status: cleanStatus,
          overallStatus: overallStatus ? 'Syncing' : 'Synced',
          key: connection._id,
        };
      });

      this.totalUsers = totalUsers.size;
      this.totalIntegrations = totalIntegrations.size;

      const topIntegrationValue = Object.entries(topIntegration).reduce(
        (max, entry) => (entry[1] >= max[1] ? entry : max),
        [0, -Infinity]
      );

      if (topIntegrationValue?.length) {
        const block = blockTypes.find(
          (type) =>
            type.name.toLowerCase() === topIntegrationValue[0]?.toLowerCase()
        );

        this.topIntegration = { name: block?.displayName, icon: block?.icon };
      }
    } else {
      this.totalUsers = 0;
      this.totalIntegrations = 0;
      this.topIntegration.name = '';
      this.topIntegration.icon = '';
      this.connectionsList = [];
    }
  };

  @action
  deleteConnections = async (connections) => {
    try {
      const connectionIds =
        connections?.length &&
        connections.map((connection) => connection._id || connection);
      await deleteOneConnections({ connectionIds });

      if (connections.length === 1) {
        showNotification('success', 'Connection deleted');
      } else if (connections.length > 1) {
        showNotification(
          'success',
          `${connections.length} Connections deleted`
        );
      }
      await this.fetchConnectionsList();
    } catch (e) {
      showNotification(
        'error',
        'Connection could not be deleted, please contact your System Administrator'
      );
    }
  };

  @computed
  get getIntegrationsList() {
    return this.integrationsList;
  }

  @action
  fetchIntegrationsList = async () => {
    const accessToken = localStorage.getItem('workflow-accessToken');
    if (!accessToken) return;
    if (this.loadingIntegrationsList) return;

    this.loadingIntegrationsList = true;
    try {
      const response = await getOneIntegrations().then((res) => res);
      this.setIntegrationsList(response?.data);
      this.setIntegrationsCount(response?.integrationsCount);
    } catch (error) {
      // this fails for every user that doesn't have Alloy One
      this.loadingIntegrationsList = false;
    } finally {
      this.loadingIntegrationsList = false;
    }
  };

  @action
  fetchConnectionsList = async (filters, sorter) => {
    const accessToken = localStorage.getItem('workflow-accessToken');
    if (!accessToken) return;
    if (this.connectionsLoading) return;

    this.setConnectionsLoading(true);

    this.filters = filters?.filterRows || [];

    try {
      const { connections, filteredConnectionsCount, totalConnections } = await getOneConnections({
        ...filters,
        limit: this.limit,
        skip: this.skip,
        sortBy: sorter?.columnKey,
        sortOrder: sorter?.order.includes('asc') ? 'asc' : 'desc',
      });

      this.setConnectionsList(connections);
      this.filteredConnectionsCount = filteredConnectionsCount;
      this.totalConnections = totalConnections;
    } catch (e) {
      console.error(e);
    } finally {
      this.setConnectionsLoading(false);
    }
  };

  @action
  updateIntegration = async (data) => {
    const info = await updateOneIntegration(data);

    return info?.integration;
  };

  @action
  getIntegration = async (id, source, preview) => {
    const integration = await getIntegration(id, source, preview).then(
      (response) => response.data
    );

    return integration;
  };

  setCredentialAnalytics = async (data) => {
    await credentialAnalytics(data);
  };

  getBlockConnection = async (id) => {
    try {
      const connections = await getBlockConnection(id).then(
        (response) => response.connections
      );
      return connections;
    } catch (error) {}
  };

  getSyncStatus = async (credentialId) => {
    try {
      const status = await getSyncStatus(credentialId).then(
        (response) => response.syncStatuses
      );
      // const syncStatuses = [
      //   {
      //     _id: '64c802dd1b5a0ee0215b385b',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'resync',
      //     syncDataType: 'commerce/customers',
      //     syncPercentage: 100,
      //     lastSyncFromDate: '2023-08-01T18:52:30.174Z',
      //     createdAt: '2023-07-31T18:52:13.880Z',
      //     updatedAt: '2023-08-01T18:52:30.832Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64c802e01b5a0ee0215b3864',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'resync',
      //     syncDataType: 'commerce/orders',
      //     syncPercentage: 100,
      //     lastSyncFromDate: '2023-08-01T18:52:30.562Z',
      //     createdAt: '2023-07-31T18:52:16.021Z',
      //     updatedAt: '2023-08-01T18:52:30.849Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64c802e11b5a0ee0215b386d',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/products',
      //     syncPercentage: 100,
      //     lastSyncFromDate: '2022-01-31T18:52:33.021Z',
      //     createdAt: '2023-07-31T18:52:17.187Z',
      //     updatedAt: '2023-07-31T18:58:23.946Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64c802efe0ee5f20d363ae4f',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/customers',
      //     syncPercentage: 100,
      //     lastSyncFromDate: '2021-08-03T13:44:49.430Z',
      //     createdAt: '2023-07-31T18:52:31.128Z',
      //     updatedAt: '2023-08-03T14:02:29.230Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64c802f0e0ee5f20d363ae56',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/orders',
      //     syncPercentage: 100,
      //     lastSyncFromDate: '2021-08-03T13:44:50.537Z',
      //     createdAt: '2023-07-31T18:52:32.257Z',
      //     updatedAt: '2023-08-03T14:02:29.167Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64c802f1e0ee5f20d363ae5e',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/products',
      //     syncPercentage: 0,
      //     lastSyncFromDate: '2023-01-31T18:52:33.021Z',
      //     createdAt: '2023-07-31T18:52:33.022Z',
      //     updatedAt: '2023-07-31T18:52:33.022Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64cbaf5159ae97e7ae1fede1',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/customers',
      //     syncPercentage: 0,
      //     lastSyncFromDate: '2023-02-03T13:44:49.430Z',
      //     createdAt: '2023-08-03T13:44:49.431Z',
      //     updatedAt: '2023-08-03T13:44:49.431Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64cbaf5259ae97e7ae1fee2b',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/orders',
      //     syncPercentage: 0,
      //     lastSyncFromDate: '2023-02-03T13:44:50.537Z',
      //     createdAt: '2023-08-03T13:44:50.538Z',
      //     updatedAt: '2023-08-03T13:44:50.538Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64cbaf5359ae97e7ae1fee34',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/products',
      //     syncPercentage: 0,
      //     lastSyncFromDate: '2023-02-03T13:44:51.220Z',
      //     createdAt: '2023-08-03T13:44:51.220Z',
      //     updatedAt: '2023-08-03T13:44:51.220Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64cbb5dc47b97ac6af81d6fe',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/customers',
      //     syncPercentage: 0,
      //     lastSyncFromDate: '2023-02-03T14:12:44.181Z',
      //     createdAt: '2023-08-03T14:12:44.182Z',
      //     updatedAt: '2023-08-03T14:12:44.182Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64cbb5dd47b97ac6af81d752',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/orders',
      //     syncPercentage: 0,
      //     lastSyncFromDate: '2023-02-03T14:12:45.097Z',
      //     createdAt: '2023-08-03T14:12:45.098Z',
      //     updatedAt: '2023-08-03T14:12:45.098Z',
      //     __v: 0,
      //   },
      //   {
      //     _id: '64cbb5dd47b97ac6af81d757',
      //     credentialId: '64c802dd8103fbba50d947a7',
      //     syncType: 'complete-sync',
      //     syncDataType: 'commerce/products',
      //     syncPercentage: 0,
      //     lastSyncFromDate: '2023-02-03T14:12:45.868Z',
      //     createdAt: '2023-08-03T14:12:45.868Z',
      //     updatedAt: '2023-08-03T14:12:45.868Z',
      //     __v: 0,
      //   },
      // ];

      // return new Promise((resolve) => {
      //   return setTimeout(resolve, 1000, syncStatuses);
      // });

      // return syncStatuses;
      return status;
    } catch (error) {}
  };

  getBlockConnections = async () => {
    try {
      const connections = await getBlockConnections().then(
        (response) => response.connections
      );
      return connections;
    } catch (error) {}
  };

  updateUapiInfo = async (data) => {
    try {
      const info = await updateUapiInfo(data).then(
        (response) => response.integration
      );

      if (this.globalStore.userStore?.forgeAccount?.uapiInfo) {
        this.globalStore.userStore.forgeAccount.uapiInfo = {
          ...this.globalStore.userStore.forgeAccount.uapiInfo,
          ...data,
        };
      }

      return info;
    } catch (error) {}
  };

  @action
  handlePageNavigation = async (pagination, sorter) => {
    this.setConnectionsLoading(true);
    this.skip = (pagination.current - 1) * pagination.pageSize;
    this.limit = pagination.pageSize;
    await this.fetchConnectionsList({ filterRows: this.filters }, sorter);
    this.setConnectionsLoading(false);
    this.currentPage = pagination.current;
  };

  @action
  resetSortOrder = () => {
    this.sortBy = 'createdAt';
    this.sortOrder = 'desc';
    this.sortOrderFull = 'descend';
  };

  @action
  handleSorting = async (sorter) => {
    this.setConnectionsLoading(true);
    if (sorter.field && sorter.order) {
      this.sortBy = sorter.field;
      this.sortOrder = sorter.order === 'descend' ? 'desc' : 'asc';
      this.sortOrderFull = sorter.order;
    }

    if (sorter.field === 'createdAt' && !sorter.order) {
      this.sortBy = 'createdAt';
      this.sortOrder = 'asc';
      this.sortOrderFull = 'ascend';
    }

    if (sorter.field && !sorter.order && sorter.field !== 'createdAt') {
      this.sortBy = 'createdAt';
      this.sortOrder = 'desc';
      this.sortOrderFull = 'descend';
    }
    this.handleShowLoader(false);

    await this.fetchConnectionsList({ filterRows: this.filters });
    this.setConnectionsLoading(false);
  };
}
