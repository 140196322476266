import { isUrl } from 'Utilities';

export const menuData = [
  {
    name: 'Dashboard',
    icon: 'dashboard',
    path: 'dashboard',
  },
  {
    name: 'Marketplace',
    icon: 'marketplace',
    path: 'marketplace',
  },
  {
    name: 'Usage',
    icon: 'analytics',
    path: 'analytics',
  },
  {
    name: 'Documentation',
    icon: 'docs',
    path: 'https://docs.runalloy.com/',
  },
  {
    name: 'Integrations',
    icon: 'integrations',
    path: 'integrations',
  },
];

export const ALLOWED_ROUTES = [
  'dashboard',
  'embedded',
  'marketplace',
  'analytics',
  'account/',
  'integrations',
  'installation',
  'installations',
  'partner-hub',
];

export const NON_ALLOWED_ROUTES = [
  '/executions',
  '/template-create',
  '/template-setup',
  '/forge-install',
  '/embedded-install',
  '/authenticate-app',
  '/template-presetup',
  '/shopify-sync',
  '/workflow/build',
  '/workflow/oauth',
  '/workflow/errors',
  '/postmessage',
  '/shopify/billing',
  '/integration/builder',
  '/shopify-login',
  '/sso-login',
  '/mfa',
  '/reset',
  '/change-password',
  '/finish-setup',
  '/404',
  '/bwp-invalid',
  '/clear',
  '/link-expired',
  '/team-invitation/:id',
  '/reauthed',
  '/enterprise-template-setup',
  '/bwp-update',
  '/shopify-dashboard',
  '/login',
  '/signup',
  '/magento',
];

function formatter(data, parentPath = '/') {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`);
    }
    return result;
  });
}

export const getMenuData = () => formatter(menuData);
