import { GET, POST, DELETE } from './wrappers';

export function getOneIntegrations() {
  return GET(`/api/alloyone`);
}

export function getOneConnections(data) {
  return POST(`/api/alloyone/connections`, data);
}

export function deleteOneConnections(data) {
  return DELETE(`/api/alloyone/connections`, data);
}

export function updateOneIntegration(data) {
  return POST(`/api/alloyone/${data.blockId}`, data);
}

export function getIntegration(id) {
  return GET(`/api/alloyone/${id}`);
}

export function credentialAnalytics(data) {
  return POST(`/api/alloyone/credentials/analytics`, data);
}

export function getBlockConnection(blockType) {
  return GET(`/api/credentials/connections/${blockType}`);
}

export function getBlockConnections() {
  return GET(`/api/credentials/connections/all`);
}

export function getSyncStatus(credentialId) {
  return GET(`/api/alloyone/sync-status/${credentialId}`);
}

export function updateUapiInfo(data) {
  return POST(`/api/alloyone/uapi/updateInfo`, data);
}
