import { isUndefined, kebabCase } from 'lodash';

export const getId = (componentName, append) => {
  if (!isUndefined(window.__ENV__) && window.__ENV__ === 'production')
    return null;
  let id = componentIds[componentName];

  if (isUndefined(id)) {
    id = kebabCase(componentName);
  }

  if (isUndefined(append)) {
    return id;
  } else {
    return `${id}-${append}`;
  }
};

const componentIds = {
  basicLayoutAccount: 'my-account', // BasicLayout
  newWorkflowButton: 'new-workflow', // Dashboard
  useWorkflowButton: 'use-workflow', // CardContent
  nameWorkflowInput: 'name-workflow-input', // NameWorkflow
  nameWorkflowButton: 'name-workflow-next', // NameWorkflow
  createWorkflow: 'create-workflow', // NameWorkflow
  Inputs: 'steps-configure',
  Output: 'steps-results',
  Authenticate: 'steps-authenticate',
  Complete: 'steps-complete',
  Action: 'steps-select-action',
  // ChangePassword
  changePasswordContinue: 'change-pw-continue',
  changePasswordSignup: 'change-pw-signup',

  // Login
  loginContinue: 'login-continue',
  loginSignup: 'login-signup',
  loginForgotPassword: 'login-forgot-pw',
  ssoLogin: 'sso-login',

  // Reset
  resetContinue: 'reset-continue',
  resetSignup: 'reset-signup',

  // SignUpInner
  signupBlockSearch: 'signup-block-search',

  // BlockSelection
  blockSelectionSearch: 'block-selection-search',
  blockSelectionContinue: 'block-selection-continue',

  // Configure
  requiredParams: 'required-params',
  optionalParams: 'optional-params',
};
