import { request, apiUrl } from 'Utilities';
import axios from 'axios';
import { GET, POST } from './wrappers';

export async function signUp(params) {
  return request(apiUrl + '/api/auth/signup', {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function login(params) {
  return request(apiUrl + '/api/auth/login', {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function requestReset(params) {
  return request(apiUrl + '/api/auth/reset', {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function resetPassword(params) {
  return request(apiUrl + '/api/auth/reset/new', {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function changePassword(params) {
  return request(apiUrl + '/api/auth/change-password', {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export function checkEmail(email) {
  return request(apiUrl + '/api/auth/validate/' + email, {
    hideErrorNotifications: true,
  });
}
export function checkInvitationCode(invitationCode) {
  return request(`${apiUrl}/api/auth/invitation/${invitationCode}`, {
    hideErrorNotifications: true,
  });
}

export function updateUser(params) {
  return request(apiUrl + '/api/auth/update', {
    headers: {
      Authorization: localStorage.getItem('workflow-accessToken'),
      refreshToken: localStorage.getItem('workflow-refreshToken'),
    },
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export function updateApps(params) {
  return request(apiUrl + '/api/auth/updateApps', {
    headers: {
      Authorization: localStorage.getItem('workflow-accessToken'),
      refreshToken: localStorage.getItem('workflow-refreshToken'),
    },
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export function userInfo() {
  return request(apiUrl + '/api/auth/user', {
    headers: {
      Authorization: localStorage.getItem('workflow-accessToken'),
      refreshToken: localStorage.getItem('workflow-refreshToken'),
    },
  });
}

export function logout() {
  return request(apiUrl + '/api/auth/logout', {
    headers: {
      Authorization: localStorage.getItem('workflow-accessToken'),
      refreshToken: localStorage.getItem('workflow-refreshToken'),
    },
    method: 'POST',
  });
}

export function getHealthStatus() {
  return request(apiUrl + '/health');
}

export const getAllAccessLogs = (page) => {
  return GET(`/api/auth/access-logs?page=${page}`);
};

export async function resendAuthLink(email) {
  return axios
    .get(`${apiUrl}/api/auth/resend-unblock-link?email=${email}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export async function magentoAuth(params) {
  return request(apiUrl + '/api/strategy/magento/finalize', {
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function requestMFA(body) {
  return POST(`/api/auth/request-mfa`, body);
}

export async function verifyMFA(body) {
  return POST(`/api/auth/verify-mfa`, body);
}

export async function verifyPassword(body) {
  return POST(`/api/auth/verify-password`, body);
}

export async function apiEnableSso(body) {
  return POST(`/api/auth/enable-sso`, body);
}

export async function validateSSO(teamId) {
  return GET(`/api/saml/validate?teamId=${teamId}`);
}

export const getMagentoCredentials = (oauth_consumer_key) => {
  return GET(`/api/credentials/magento/${oauth_consumer_key}`);
};
