/* eslint-disable */
let webhookUrl = 'http://localhost:4040';

if (window.location.hostname == 'localhost') {
  webhookUrl = 'http://localhost:4040';
}

if (
  window.location.hostname == 'runalloy.com' ||
  window.location.hostname == 'www.runalloy.com' ||
  window.location.hostname == 'app.runalloy.com'
) {
  webhookUrl = 'https://webhooks.runalloy.com';
}

if (window.location.hostname == 'dev-app.runalloy.com') {
  webhookUrl = 'https://webhooks-dev.runalloy.com';
}

if (window.location.hostname == 'qa-app.runalloy.com') {
  webhookUrl = 'https://nhpginc1i9.execute-api.us-east-1.amazonaws.com/api';
}

if (window.location.hostname == 'staging.runalloy.com') {
  webhookUrl = 'https://kbfpanhh15.execute-api.us-east-1.amazonaws.com/api';
}

export const getWebhookInfoUrl = () => {
  if (webhookUrl === 'http://localhost:4040') return `${webhookUrl}/webhook`;

  return webhookUrl;
};

export default webhookUrl;
