import React from 'react';

export const IconDataEmptyState = () => (
  <span role="img" aria-label="icon-data" className="anticon">
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48Z"
        fill="#2B2E32"
        fillOpacity="0.05"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5297 54.001L28.6356 55.1336C27.7881 55.4653 27.7882 56.6645 28.6356 56.9961L48.8741 64.9146C49.1187 65.0103 49.3911 65.006 49.6324 64.9025L68.1111 56.9831C68.9192 56.6368 68.9192 55.4911 68.1111 55.1448L65.8736 54.1859L50.0848 60.9056C49.8447 61.0077 49.5742 61.012 49.331 60.9176L31.5297 54.001Z"
        fill="#2B2E32"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5297 46.001L28.6356 47.1336C27.7881 47.4653 27.7882 48.6645 28.6356 48.9961L48.8741 56.9146C49.1187 57.0103 49.3911 57.006 49.6324 56.9025L68.1111 48.9831C68.9192 48.6368 68.9192 47.4911 68.1111 47.1448L65.8736 46.1859L50.0848 52.9056C49.8447 53.0077 49.5742 53.012 49.331 52.9176L31.5297 46.001Z"
        fill="#2B2E32"
      />
      <path
        d="M48.6184 31.1493C48.8629 31.0536 49.1353 31.058 49.3767 31.1614L67.8553 39.0808C68.6634 39.4272 68.6634 40.5728 67.8553 40.9191L49.3767 48.8386C49.1353 48.942 48.8629 48.9463 48.6184 48.8507L28.3799 40.9321C27.5324 40.6005 27.5324 39.4013 28.3798 39.0696L48.6184 31.1493Z"
        fill="#2B2E32"
      />
    </svg>
  </span>
);

export const IconEmailEmptyState = () => (
  <span role="img" aria-label="icon-email" className="anticon">
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48Z"
        fill="#2B2E32"
        fillOpacity="0.05"
      />
      <path
        d="M30 37C30 35.8954 30.8954 35 32 35H65C66.1046 35 67 35.8954 67 37V59C67 60.1046 66.1046 61 65 61H32C30.8954 61 30 60.1046 30 59V37Z"
        fill="#2B2E32"
        fillOpacity="0.3"
      />
      <path
        d="M65.0264 35H31.7279C29.7386 35 28.9691 37.5885 30.6353 38.6752L46.9304 49.3024C47.5826 49.7278 48.4225 49.7359 49.0829 49.3232L66.0864 38.696C67.7904 37.631 67.0358 35 65.0264 35Z"
        fill="#2B2E32"
      />
    </svg>
  </span>
);

export const IconErrorEmptyState = () => (
  <span role="img" aria-label="icon-error" className="anticon">
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48Z"
        fill="#2B2E32"
        fillOpacity="0.05"
      />
      <path
        d="M50 55C50 56.1046 49.1046 57 48 57C46.8954 57 46 56.1046 46 55C46 53.8954 46.8954 53 48 53C49.1046 53 50 53.8954 50 55Z"
        fill="#2B2E32"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 39C49.1046 39 50 39.8954 50 41V49C50 50.1046 49.1046 51 48 51C46.8954 51 46 50.1046 46 49V41C46 39.8954 46.8954 39 48 39Z"
        fill="#2B2E32"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 31C38.6112 31 31 38.6112 31 48C31 57.3888 38.6112 65 48 65C57.3888 65 65 57.3888 65 48C65 38.6112 57.3888 31 48 31ZM27 48C27 36.402 36.402 27 48 27C59.598 27 69 36.402 69 48C69 59.598 59.598 69 48 69C36.402 69 27 59.598 27 48Z"
        fill="#2B2E32"
      />
    </svg>
  </span>
);

export const IconLogsEmptyState = () => (
  <span role="img" aria-label="icon-logs" className="anticon">
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48Z"
        fill="#2B2E32"
        fillOpacity="0.05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 37C30.5 35.6193 31.6193 34.5 33 34.5H65C66.3807 34.5 67.5 35.6193 67.5 37C67.5 38.3807 66.3807 39.5 65 39.5H33C31.6193 39.5 30.5 38.3807 30.5 37Z"
        fill="#2B2E32"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 50C30.5 48.6193 31.6193 47.5 33 47.5H65C66.3807 47.5 67.5 48.6193 67.5 50C67.5 51.3807 66.3807 52.5 65 52.5H33C31.6193 52.5 30.5 51.3807 30.5 50Z"
        fill="#2B2E32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 63C30.5 61.6193 31.6193 60.5 33 60.5H49C50.3807 60.5 51.5 61.6193 51.5 63C51.5 64.3807 50.3807 65.5 49 65.5H33C31.6193 65.5 30.5 64.3807 30.5 63Z"
        fill="#2B2E32"
      />
    </svg>
  </span>
);

export const IconSearchEmptyState = () => (
  <span role="img" aria-label="icon-search" className="anticon">
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48Z"
        fill="#2B2E32"
        fillOpacity="0.05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.8031 52.8032C52.5841 52.0221 53.8505 52.0221 54.6315 52.8032L62.7185 60.8902C63.4995 61.6712 63.4995 62.9375 62.7185 63.7186C61.9374 64.4996 60.6711 64.4996 59.89 63.7186L51.8031 55.6316C51.022 54.8506 51.022 53.5842 51.8031 52.8032Z"
        fill="#2B2E32"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.1196 36C39.083 36 35 40.083 35 45.1196C35 50.1562 39.083 54.2391 44.1196 54.2391C49.1562 54.2391 53.2391 50.1562 53.2391 45.1196C53.2391 40.083 49.1562 36 44.1196 36ZM31 45.1196C31 37.8738 36.8738 32 44.1196 32C51.3653 32 57.2391 37.8738 57.2391 45.1196C57.2391 52.3653 51.3653 58.2391 44.1196 58.2391C36.8738 58.2391 31 52.3653 31 45.1196Z"
        fill="#2B2E32"
      />
    </svg>
  </span>
);

export const IconTeamEmptyState = () => (
  <span role="img" aria-label="icon-team" className="anticon">
    <svg
      width="52"
      height="53"
      viewBox="0 0 52 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26" cy="26.5" r="26" fill="#2B2E32" fillOpacity="0.1" />
      <path
        d="M30 34C30 36.7614 25.9706 39 21 39C16.0294 39 12 36.7614 12 34C12 31.2386 16.0294 29 21 29C25.9706 29 30 31.2386 30 34Z"
        fill="#2B2E32"
      />
      <path
        d="M26 20C26 22.7614 23.7614 25 21 25C18.2386 25 16 22.7614 16 20C16 17.2386 18.2386 15 21 15C23.7614 15 26 17.2386 26 20Z"
        fill="#2B2E32"
      />
      <path
        d="M40 34C40 36.7614 35.9706 39 31 39C26.0294 39 22 36.7614 22 34C22 31.2386 26.0294 29 31 29C35.9706 29 40 31.2386 40 34Z"
        fill="#2B2E32"
        fillOpacity="0.3"
      />
      <path
        d="M36 20C36 22.7614 33.7614 25 31 25C28.2386 25 26 22.7614 26 20C26 17.2386 28.2386 15 31 15C33.7614 15 36 17.2386 36 20Z"
        fill="#2B2E32"
        fillOpacity="0.3"
      />
    </svg>
  </span>
);

export const IconLinkEmptyState = () => (
  <span role="img" aria-label="icon-link" className="anticon">
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="white" />
      <rect
        x="16"
        y="16"
        width="48"
        height="48"
        rx="24"
        fill="#2B2E32"
        fill-opacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7282 31.7052C27.1673 31.0027 28.0927 30.7891 28.7952 31.2282L52.7952 46.2282C53.4977 46.6673 53.7113 47.5927 53.2722 48.2952C52.8331 48.9977 51.9077 49.2113 51.2052 48.7722L27.2052 33.7722C26.5027 33.3331 26.2891 32.4077 26.7282 31.7052Z"
        fill="#2B2E32"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2261 41.8042L35.7007 44.4463C35.2865 45.1637 35.5323 46.0811 36.2498 46.4953C36.9672 46.9095 37.8846 46.6637 38.2988 45.9463L39.7717 43.3952L37.2261 41.8042ZM42.023 39.4957L40.5221 42.0954L37.9766 40.5044L39.4775 37.9047L42.023 39.4957ZM42.7735 38.1959L44.2988 35.554C44.713 34.8365 44.4672 33.9191 43.7498 33.5049C43.0323 33.0907 42.1149 33.3365 41.7007 34.054L40.2279 36.6049L42.7735 38.1959Z"
        fill="#2B2E32"
        fill-opacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5049 31.0538C38.576 27.4666 43.1629 26.2375 46.7501 28.3086C50.3373 30.3797 51.5663 34.9666 49.4953 38.5538L48.7453 39.8528C48.331 40.5703 47.4137 40.8161 46.6962 40.4019C45.9788 39.9876 45.733 39.0703 46.1472 38.3528L46.8972 37.0538C48.1398 34.9015 47.4024 32.1493 45.2501 30.9067C43.0978 29.664 40.3456 30.4015 39.103 32.5538L38.353 33.8528C37.9387 34.5703 37.0214 34.8161 36.3039 34.4019C35.5865 33.9876 35.3407 33.0703 35.7549 32.3528L36.5049 31.0538Z"
        fill="#2B2E32"
        fill-opacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.4951 48.9462C41.4241 52.5334 36.8371 53.7625 33.2499 51.6914C29.6627 49.6203 28.4337 45.0334 30.5047 41.4462L31.2547 40.1472C31.6689 39.4297 32.5863 39.1839 33.3038 39.5981C34.0212 40.0124 34.267 40.9297 33.8528 41.6472L33.1028 42.9462C31.8602 45.0985 32.5976 47.8507 34.7499 49.0933C36.9022 50.336 39.6544 49.5985 40.897 47.4462L41.647 46.1472C42.0613 45.4297 42.9786 45.1839 43.6961 45.5981C44.4135 46.0124 44.6593 46.9297 44.2451 47.6472L43.4951 48.9462Z"
        fill="#2B2E32"
        fill-opacity="0.5"
      />
      <rect
        x="0.5"
        y="0.5"
        width="79"
        height="79"
        rx="39.5"
        stroke="#2B2E32"
        strokeOpacity="0.2"
      />
    </svg>
  </span>
);
