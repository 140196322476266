import { GET, PATCH, POST, DELETE, PUT } from './wrappers';

export const createCustomIntegration = async (body) => {
  return POST('/api/custom-integrations', body);
};

export const getCustomIntegrations = async () => {
  return GET('/api/custom-integrations');
};

export const getCustomIntegrationById = async (id) => {
  return GET(`/api/custom-integrations/${id}`);
};

export const editCustomIntegration = async (id, body) => {
  return PATCH(`/api/custom-integrations/${id}`, body);
};

export const editCustomAuthentication = async (id, body) => {
  return PATCH(`/api/custom-authentication/${id}`, body);
};

export const getCustomAuthentication = async (id, body) => {
  return GET(`/api/custom-authentication/${id}`, body);
};

export const deleteCustomIntegration = async (id) => {
  return DELETE(`/api/custom-integrations/${id}`);
};

export const sendTestRequest = async (id, body) => {
  return POST(`/api/custom-authentication/test/${id}`, body);
};

export const deleteTestCredential = async (id) => {
  return DELETE(`/api/custom-authentication/${id}`);
};
