import React from 'react';

export const MessageSuccessIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 3.79289C14.0976 4.18342 14.0976 4.81658 13.7071 5.20711L6.70713 12.2071C6.51016 12.4041 6.23998 12.51 5.96164 12.4993C5.6833 12.4886 5.42204 12.3623 5.24076 12.1508L2.24076 8.65079C1.88134 8.23147 1.9299 7.60017 2.34923 7.24074C2.76855 6.88132 3.39985 6.92988 3.75927 7.34921L6.0565 10.0293L12.2929 3.79289C12.6834 3.40237 13.3166 3.40237 13.7071 3.79289Z"
      fill="#7DD96E"
    />
  </svg>
);

export const MessageCloseIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="anticon"
  >
    <rect width="16" height="16" rx="8" fill="white" fill-opacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8.00016 6.58563L5.70743 4.29289C5.3169 3.90237 4.68374 3.90237 4.29321 4.29289C3.90269 4.68342 3.90269 5.31658 4.29321 5.70711L6.58595 7.99984L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8.00016 9.41405L10.2932 11.7071C10.6837 12.0976 11.3169 12.0976 11.7074 11.7071C12.098 11.3166 12.098 10.6834 11.7074 10.2929L9.41437 7.99984L11.7071 5.70711Z"
      fill="white"
      fill-opacity="0.5"
    />
  </svg>
);

export const MessageErrorIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 4C8.55229 4 9 4.44772 9 5V8C9 8.55229 8.55228 9 8 9C7.44771 9 7 8.55228 7 8L7 5C7 4.44772 7.44772 4 8 4ZM8 12C8.55229 12 9 11.5523 9 11C9 10.4477 8.55229 10 8 10C7.44771 10 7 10.4477 7 11C7 11.5523 7.44771 12 8 12Z"
      fill="#F04242"
    />
  </svg>
);

export const MessageInfoIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="white" fill-opacity="0.2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 5.5C8.69036 5.5 9.25 4.94036 9.25 4.25C9.25 3.55964 8.69036 3 8 3C7.30964 3 6.75 3.55964 6.75 4.25C6.75 4.94036 7.30964 5.5 8 5.5ZM9 7.25C9 6.69772 8.55228 6.25 8 6.25H7C6.44772 6.25 6 6.69772 6 7.25C6 7.80228 6.44772 8.25 7 8.25V10.25C6.44772 10.25 6 10.6977 6 11.25C6 11.8023 6.44772 12.25 7 12.25H8H9C9.55228 12.25 10 11.8023 10 11.25C10 10.6977 9.55228 10.25 9 10.25V7.25Z"
      fill="white"
      fill-opacity="0.7"
    />
  </svg>
);

export const MessageIconLoader = () => (
  <span role="img" aria-label="icon-loader" className="anticon">
    <div
      className={`${styles.loader} ${
        color === 'blue' ? styles.loader__blue : null
      }`}
    />
  </span>
);
