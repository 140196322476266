import React from 'react';
import styles from './index.less';
import { Dropdown } from 'antd';
import { AppCheckbox, AppSearch } from '..';

export const AppDropdown = ({
  id,
  items,
  open,
  children = <div />,
  width = 240,
  dropdownRender,
  trigger,
  showSearch,
  checkboxCallback = null,
  searchCallback = (event) => console.log(event.currentTarget.value),
  ...props
}) => {
  const dropdownItems = [];
  items.forEach((item, index) => {
    if (item.danger && index === items.length - 1) {
      dropdownItems.push({
        type: 'divider',
      });
    }
    if (item.description) {
      dropdownItems.push({
        ...item,
        label: (
          <div className={styles.itemContainer} style={{ display: 'flex' }}>
            <div className={styles.labelContainer}>
              {item.label}
              <div className={styles.description}>{item.description}</div>
            </div>
            {item.suffix && !item.check ? (
              <div className={styles.suffix}>{item.suffix || null}</div>
            ) : null}
            {item.check ? (
              <AppCheckbox
                disabled={item.disabled}
                onChange={checkboxCallback || item.checkboxCallback}
              />
            ) : null}
          </div>
        ),
        ...(item.icon ? { icon: <div>{item.icon}</div> } : {}),
      });
    } else {
      dropdownItems.push({
        ...item,
        ...(item.icon ? { icon: <div>{item.icon}</div> } : {}),
        label: (
          <div className={styles.itemContainer} style={{ display: 'flex' }}>
            {item.label}
            {item.check ? (
              <AppCheckbox
                disabled={item.disabled}
                onChange={checkboxCallback || item.checkboxCallback}
              />
            ) : null}
            {item.suffix && !item.check ? (
              <div className={styles.suffix}>{item.suffix || null}</div>
            ) : null}
          </div>
        ),
      });
    }
  });

  return (
    <div>
      <Dropdown
        overlayClassName={styles.dropdown}
        menu={{ items: dropdownItems }}
        trigger={trigger}
        getPopupContainer={(triggerBlock) => triggerBlock.parentElement}
        overlayStyle={{ width: width }}
        {...(showSearch && !dropdownRender
          ? {
              dropdownRender: (menu) => (
                <div className={styles.searchDropdown}>
                  <div className={styles.searchInput}>
                    <AppSearch placeholder="Search" onChange={searchCallback} />
                  </div>
                  {React.cloneElement(menu)}
                </div>
              ),
            }
          : {})}
        {...(props.disabled ? { open: false } : {})}
        {...props}
      >
        {children}
      </Dropdown>
    </div>
  );
};
