import dayjs from 'dayjs';

// get time difference
export function countTime(updatedAt, nowTime) {
  if (updatedAt) {
    const diff = dayjs(nowTime).diff(dayjs(updatedAt), 'minute');

    if (diff < 1) {
      return `Now`;
    } else if (diff !== 0 && diff <= 1) {
      return `${diff} minute ago`;
    } else if (diff < 60 && diff !== 0) {
      return `${diff} minutes ago`;
    } else if (diff <= 60 * 2) {
      return `${dayjs(nowTime).diff(dayjs(updatedAt), 'hours')} hour ago`;
    } else if (diff > 60 && diff <= 60 * 24) {
      return `${dayjs(nowTime).diff(dayjs(updatedAt), 'hours')} hours ago`;
    } else if (diff > 60 * 24 && diff <= 60 * 48) {
      return `${dayjs(nowTime).diff(dayjs(updatedAt), 'days')} day ago`;
    } else if (diff > 60 * 48 && diff <= 60 * 24 * 30) {
      return `${dayjs(nowTime).diff(dayjs(updatedAt), 'days')} days ago`;
    } else if (diff > 60 * 24 * 30 && diff <= 60 * 24 * 30 * 365) {
      return `${dayjs(nowTime).diff(dayjs(updatedAt), 'months')} months ago`;
    } else {
      return `Now`;
    }
  } else {
    return `Now`;
  }
}
