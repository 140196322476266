import React, { PureComponent } from 'react';
import { Layout, Menu, Tooltip, Divider } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { getId } from 'Utilities';
import { get } from 'lodash';
import { UsageAnalytics } from 'Components/UsageAnalytics/UsageAnalytics';
import Hamburger from 'hamburger-react';
import ChangePlanModal from '../../routes/Settings/Billing/ChangePlanModal';
import UpgradeConfirmationModal from '../../routes/Settings/Billing/UpgradeConfirmationModal';
import classNames from 'classnames';
import styles from './index.less';
import {
  PartnerHubIcon,
  DashboardIcon,
  MarketplaceIcon,
  IntegrationsIcon,
  SwitchIcon,
  SettingsIcon,
  WorkflowsIcon,
  DocumentationIcon,
  DocSupportIcon,
  InstallationsIcon,
  FlowIcon,
  LogoutIcon,
  GeneralIcon,
  SecurityIcon,
  CredentialIcon,
  TeamIcon,
  ApiKeyIcon,
  LoggingIcon,
  BillingIcon,
  ConnectionsIcon,
  GetStartedIcon,
} from './Icons';
import { IconSwap, IconLock, AppButton } from '../Base';
import SupportModal from '../Popups/Support';
import { ALLOWED_ROUTES, NON_ALLOWED_ROUTES } from '../../common/menu';

const { Sider, Header } = Layout;

@inject('globalStore')
@observer
class SiderMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: '',
      upgradeModalVisible: false,
      mobileMenuVisible: false,
      showBenefits: null,
      upgradeConfirmationVisible: false,
      forgeSelected: false,
      isSupportModal: false,
      storesInitialized: false,
      sider: window.innerWidth >= 1024,
    };
  }

  componentDidMount() {
    const { globalStore, location } = this.props;

    const { pathname, hash } = location;
    addEventListener('resize', (event) => {
      const { sider } = this.state;
      if (event.target?.innerWidth >= 1024 && !sider) {
        this.setState({ sider: true });
      } else if (event.target?.innerWidth < 1024 && sider) {
        this.setState({ sider: false });
      }
    });

    const isAllowed =
      ALLOWED_ROUTES.some((route) => {
        return pathname.includes(route);
      }) && !NON_ALLOWED_ROUTES.includes(pathname);
    if (!isAllowed) {
      return null;
    } else {
      if (pathname.includes('dashboard')) {
        this.setState({ selectedKey: 'dashboard' });
      } else if (pathname.includes('marketplace')) {
        this.setState({ selectedKey: 'marketplace' });
      } else if (pathname.includes('documentation')) {
        this.setState({ selectedKey: 'documentation' });
      } else if (pathname.includes('embedded-settings')) {
        globalStore.workflowStore.enableForge();
        let selectedKey = 'embedded-settings';

        if (hash && !hash.includes('general')) {
          const hashWithoutQuery = hash.split('?')[0];
          selectedKey += '-' + hashWithoutQuery
            .replace('#', '')
            .toLowerCase();
        }

        this.setState({
          selectedKey,
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (
        pathname.includes('embedded-unified-api') &&
        hash.includes('#get-started')
      ) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'embedded-unified-api-get-started',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (
        pathname.includes('embedded-unified-api') &&
        hash.includes('#connections')
      ) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'embedded-unified-api-connections',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (pathname.includes('embedded-unified-api')) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'embedded-unified-api',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (pathname.includes('unified-api-get-started')) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'unified-api-get-started',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (pathname.includes('embedded-workflows')) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'embedded-workflows',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (pathname.includes('embedded')) {
        globalStore.workflowStore.enableForge();
        this.setState({ selectedKey: 'embedded', forgeSelected: true });
        this.changeFavicon('embedded');
      } else if (pathname.includes('installation')) {
        globalStore.workflowStore.enableForge();
        this.setState({ selectedKey: 'installations', forgeSelected: true });
        this.changeFavicon('embedded');
      } else if (pathname.includes('account')) {
        this.setState({ selectedKey: 'my-account' });
      }

      this.setState({ storesInitialized: true });
      globalStore.blockTypeStore.initialize();
      globalStore.workspaceStore.initialize();
      globalStore.myAccountStore.getAnalytics();
      globalStore.myAccountStore.getPlans();
      globalStore.workflowStore.getActiveWorkflowCount(
        pathname.includes('embedded')
      );
      globalStore.oneStore.fetchIntegrationsList();
      globalStore.teamsStore.initialize();
      globalStore.customActionStore.initialize();
      globalStore.oneStore.fetchConnectionsList();
      globalStore.installationStore.funcGetInstallationCount();
      globalStore.workflowStore.getIntegrationList(12, 0);
    }
  }

  componentDidUpdate(prevProps) {
    const { mobileMenuVisible, storesInitialized } = this.state;
    const { pathname, hash } = window.location;
    const { location, globalStore } = this.props;

    const isAllowed =
      ALLOWED_ROUTES.some((route) => {
        return (
          pathname.includes(route) &&
          !pathname.includes('-install') &&
          !pathname.includes('-template-setup')
        );
      }) && !NON_ALLOWED_ROUTES.includes(pathname);

    if (!storesInitialized && isAllowed) {
      globalStore.userStore.getUserInfo();
      globalStore.workspaceStore.initialize();
      globalStore.myAccountStore.getAnalytics();
      globalStore.myAccountStore.getPlans();
      globalStore.workflowStore.getActiveWorkflowCount(
        pathname.includes('embedded')
      );
      globalStore.oneStore.fetchIntegrationsList();
      globalStore.teamsStore.initialize();
      globalStore.blockTypeStore.initialize();
      globalStore.oneStore.fetchConnectionsList();
      globalStore.installationStore.funcGetInstallationCount();
      this.setState({ storesInitialized: true });
    }
    if (!pathname.includes('embedded') && !pathname.includes('installation')) {
      this.changeFavicon('alloy');
    }

    if (pathname.includes('embedded-unified-api')) {
      if (
        hash.includes('integrations') &&
        this.state.selectedKey !== 'embedded-unified-api'
      ) {
        this.setState({ selectedKey: 'embedded-unified-api' });
      }
    }

    if (mobileMenuVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    if (
      prevProps &&
      prevProps?.location &&
      prevProps.location.pathname !== location.pathname
    ) {
      if (pathname.includes('dashboard')) {
        this.setState({ selectedKey: 'dashboard', forgeSelected: false });
        globalStore.workflowStore.disableForge();
      } else if (pathname.includes('marketplace')) {
        this.setState({ selectedKey: 'marketplace', forgeSelected: false });
        globalStore.workflowStore.disableForge();
      } else if (pathname.includes('documentation')) {
        this.setState({ selectedKey: 'documentation', forgeSelected: false });
        globalStore.workflowStore.disableForge();
      } else if (pathname.includes('embedded-settings')) {
        globalStore.workflowStore.enableForge();
        let selectedKey = 'embedded-settings';

        if (hash && !hash.includes('general')) {
          selectedKey += '-' + hash
            .replace('#', '')
            .toLowerCase();
        }

        this.setState({
          selectedKey,
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (
        pathname.includes('embedded-unified-api') &&
        hash.includes('#get-started')
      ) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'embedded-unified-api-get-started',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (
        pathname.includes('embedded-unified-api') &&
        hash.includes('#connections')
      ) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'embedded-unified-api-connections',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (pathname.includes('embedded-unified-api')) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'embedded-unified-api',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (pathname.includes('unified-api-get-started')) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'unified-api-get-started',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (pathname.includes('embedded-workflows')) {
        globalStore.workflowStore.enableForge();
        this.setState({
          selectedKey: 'embedded-workflows',
          forgeSelected: true,
        });
        this.changeFavicon('embedded');
      } else if (pathname.includes('embedded')) {
        globalStore.workflowStore.enableForge();
        this.setState({ selectedKey: 'embedded', forgeSelected: true });
        this.changeFavicon('embedded');
      } else if (pathname.includes('installation')) {
        globalStore.workflowStore.enableForge();
        this.setState({ selectedKey: 'installations', forgeSelected: true });
        this.changeFavicon('embedded');
      } else if (pathname.includes('account')) {
        this.setState({ selectedKey: 'my-account' });
      }
    }
  }

  closeUpgradeModal = () => {
    this.setState({
      upgradeModalVisible: false,
      upgradeUnifiedApiModalVisible: false,
    });
  };

  upgradeConfirmationModalSuccess = () => {
    const { globalStore } = this.props;
    this.setState(
      {
        upgradeConfirmationVisible: false,
      },
      async () => {
        await globalStore.myAccountStore.getAnalytics();
        await globalStore.userStore.getUserInfo();
      }
    );
  };

  closeUpgradeConfirmationModal = () => {
    this.setState({
      upgradeConfirmationVisible: false,
    });
  };

  handleOpenChange = () => {
    const { push } = this.props.globalStore.routingStore;
    if (latestOpenKey === 'forge-integrations-container') {
      push('/embedded');
    } else if (latestOpenKey === 'unified-api-container') {
      push('/embedded-unified-api#integrations');
    } else if (latestOpenKey === 'embedded-settings-container') {
      push('/embedded-settings#general');
    }
  };

  handleClick = ({ key }) => {
    const { push } = this.props.globalStore.routingStore;
    this.setState({ selectedKey: key });

    if (
      key === 'embedded' ||
      key === 'embedded-settings' ||
      key === 'embedded-workflows' ||
      key === 'embedded-unified-api' ||
      key === 'embedded-unified-api-get-started' ||
      key === 'embedded-unified-api-connections' ||
      key === 'installations' ||
      key.startsWith('embedded-settings')
    ) {
      this.setState({ forgeSelected: true });
    } else {
      this.setState({ forgeSelected: false });
    }

    switch (key) {
      case 'dashboard':
        push('/dashboard');
        break;
      case 'embedded':
        push('/embedded');
        break;
      case 'installations':
        push('/installations');
        break;
      case 'embedded-workflows':
        push('/embedded-workflows');
        break;
      case 'embedded-settings':
        push('/embedded-settings#general');
        break;
      case 'embedded-settings-security':
        push('/embedded-settings#security');
        break;
      case 'embedded-settings-team':
        push('/embedded-settings#team');
        break;
      case 'embedded-settings-apikeys':
        push('/embedded-settings#apiKeys');
        break;
      case 'embedded-settings-credentials':
        push('/embedded-settings#credentials');
        break;
      case 'embedded-settings-logging':
        push('/embedded-settings#logging');
        break;
      case 'embedded-settings-billing':
        push('/embedded-settings#billing');
        break;
      case 'embedded-unified-api':
        push('/embedded-unified-api#integrations');
        break;
      case 'embedded-unified-api-connections':
        push('/embedded-unified-api#connections');
        break;
      case 'embedded-unified-api-get-started':
        push('/embedded-unified-api#get-started');
        break;
      case 'marketplace':
        push('/marketplace');
        break;
      case 'my-account':
        push('/account/settings');
        break;
      case 'partner-hub':
        push('/partner-hub');
        break;
      case 'docs':
        window.open('https://docs.runalloy.com/');
        break;
      case 'support':
        window.open(
          'https://alloyautomation.atlassian.net/servicedesk/customer/portal/3'
        );
        break;
      default:
        break;
    }
  };

  getPercentage = (result) => {
    return result ? parseFloat((result * 100).toPrecision(2)) : 0;
  };

  onClickUpgrade = (unifiedApi) => {
    if (unifiedApi) {
      this.setState({
        upgradeUnifiedApiModalVisible: true,
      });
    } else {
      this.setState({
        upgradeModalVisible: true,
      });
    }
  };

  changeFavicon = () => {
    let link = document.querySelector("link[rel~='icon']");
    const favicon = 'https://cdn.runalloy.com/favicon.ico';

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.href = favicon;
  };

  onSupportToggle = () => {
    this.setState((prevState) => ({
      isSupportModal: !prevState.isSupportModal,
    }));
  };

  logout = async () => {
    const { push } = this.props.globalStore.routingStore;

    try {
      await this.props.globalStore.userStore.logout();
      push('/login');
    } catch (_) {
      push('/login');
    }
  };

  truncateString = (str, num) => {
    if (!str) return '';
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  render() {
    const { globalStore } = this.props;
    const { userInfo, showOnboarding, forgeAccount, getAccessPermissions } =
      globalStore.userStore;
    const router = globalStore.routingStore;
    const isUserLoaded = Object.keys(userInfo).length > 0;

    if (!isUserLoaded) return null;

    let showUnifiedDashboard = false;
    let hideAutomation = true;
    let hideEmbeddedIPaaS = true;
    const { analytics, isEnterpriseAccount } = globalStore.myAccountStore;
    const { plans } = globalStore.myAccountStore;
    const { team } = globalStore.teamsStore;
    const { featureAccess, openMenuKeys: openKeys } = globalStore.userStore;
    const {
      activeWorkflowCount,
      enableForge,
      disableForge,
      hasArchivedWorkflows,
      integrationCount,
    } = globalStore.workflowStore;

    const isAllowed =
      ALLOWED_ROUTES.some((route) => {
        return router.location.pathname.includes(route);
      }) && !NON_ALLOWED_ROUTES.includes(router.location.pathname);

    if (!isAllowed) {
      return null;
    }
    const { installationCount } = globalStore.installationStore;
    const { totalConnections, integrationsCount, loadingIntegrationsList } = globalStore.oneStore;

    const {
      selectedKey,
      upgradeModalVisible,
      mobileMenuVisible,
      upgradeConfirmationVisible,
      showBenefits,
      forgeSelected,
      isSupportModal,
      sider,
    } = this.state;

    let isGetStartedPending = false;
    const isEnterprise = !plans || (plans ? plans.isEnterprise : false);
    const theme = 'light';
    const siderClass = classNames(styles.sider, {
      [styles.light]: theme === 'light',
    });
    const isForge = get(userInfo, `forgeId`, false);
    const onboardingStep = get(userInfo, `onboardingStep`, null);

    if (forgeAccount) {
      showUnifiedDashboard =
        forgeAccount.showUnifiedDashboard &&
        featureAccess.has('unifiedApiSelfServe');
      hideAutomation = forgeAccount.hideAutomation !== false;
      hideEmbeddedIPaaS = forgeAccount.hideEmbeddedIPaaS || false;

      if (forgeAccount.uapiInfo) {
        const {
          connectionStepCompleted,
          tryConnectionStepCompleted,
          goLiveStepCompleted,
        } = forgeAccount.uapiInfo;

        isGetStartedPending = !(
          connectionStepCompleted &&
          tryConnectionStepCompleted &&
          goLiveStepCompleted
        );
      }
    }

    let logoLink = forgeSelected ? '/embedded' : '/dashboard';
    if (forgeSelected && showUnifiedDashboard && hideEmbeddedIPaaS) {
      logoLink = '/embedded-unified-api';
    }

    const userId = globalStore?.userStore?.userInfo?._id;
    const isAdmin = userId === team?.admin?._id;
    const localOnboardingStep =
      onboardingStep !== null && onboardingStep !== undefined
        ? onboardingStep
        : 0;

    if (sider) {
      return (
        <Sider
          trigger={null}
          collapsible
          collapsed={false}
          breakpoint="lg"
          width={256}
          className={siderClass}
        >
          {isSupportModal && (
            <SupportModal
              open={isSupportModal}
              onSupportToggle={this.onSupportToggle}
              globalStore={globalStore}
            />
          )}
          <div className={styles.top}>
            <Link to={logoLink}>
              <div id="alloy-logo" className={styles.logo} key="logo">
                <img
                  src="https://alloy-assets.s3.amazonaws.com/icons/alloy-integrations-light-logo.svg"
                  alt="logo"
                />
                {isForge && !hideAutomation && (
                  <Tooltip
                    title={`Switch to ${
                      forgeSelected ? 'Automation' : 'Embedded'
                    }`}
                    placement="bottom"
                  >
                    <div
                      id={getId('forge')}
                      className={styles.toggle}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        if (forgeSelected) {
                          this.handleClick({ key: 'dashboard' });
                          this.changeFavicon('alloy');
                          disableForge();
                        } else {
                          this.handleClick({
                            key:
                              showUnifiedDashboard || !hideEmbeddedIPaaS
                                ? 'embedded'
                                : 'dashboard',
                          });
                          this.changeFavicon('embedded');
                          enableForge();
                        }
                      }}
                    >
                      <IconSwap />
                    </div>
                  </Tooltip>
                )}
              </div>
            </Link>
            <Divider />

            <Menu
              onClick={this.handleClick}
              mode="inline"
              rootClassName={styles.dashboardMenu}
              selectedKeys={[selectedKey]}
              openKeys={openKeys}
              defaultOpenKeys={[openKeys]}
            >
              {!forgeSelected ? (
                <>
                  {!isEnterprise && (
                    <Menu.Item
                      key="dashboard"
                      icon={<DashboardIcon />}
                      className={styles.flowMenuItem}
                    >
                      <span className={styles.label} id={getId('dashboard')}>
                        Dashboard
                      </span>
                    </Menu.Item>
                  )}
                  {isEnterprise ? (
                    <Menu.Item
                      key="dashboard"
                      icon={
                        <div className={styles.menuIcon}>
                          <FlowIcon />
                        </div>
                      }
                      className={styles.flowMenuItem}
                    >
                      <span className={styles.label} id={getId('dashboard')}>
                        Flow
                      </span>
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      key="marketplace"
                      icon={<MarketplaceIcon />}
                      className={classNames(styles.flowMenuItem, {
                        [styles.onboardingOn]:
                          showOnboarding && localOnboardingStep === 8,
                      })}
                    >
                      <span className={styles.label} id={getId('marketplace')}>
                        Marketplace
                      </span>
                    </Menu.Item>
                  )}
                </>
              ) : (
                <>
                  <Menu.SubMenu
                    key="forge-integrations-container"
                    className={classNames(
                      styles.dashboardSubMenu,
                      styles.label
                    )}
                    title={
                      <span
                        className={styles.label}
                        id="forge-integrations-container"
                      >
                        Embedded iPaaS
                      </span>
                    }
                    disabled={!getAccessPermissions('embedded-ipass')}
                  >
                    <Menu.Item
                      key="embedded"
                      className={styles.label}
                      disabled={
                        !getAccessPermissions(
                          'embedded-ipass',
                          'integrations'
                        )
                      }
                      icon={<IntegrationsIcon />}
                    >
                      <div className={styles.itemContainer}>
                        <div id={getId('forge-integrations')}>
                          Integrations
                        </div>
                        <div
                          id="integrations-length"
                          className={styles.rightItem}
                        >
                          {integrationCount}
                        </div>
                      </div>
                    </Menu.Item>

                    {featureAccess.has('installationManager') && (
                      <Menu.Item
                        key="installations"
                        className={styles.label}
                        disabled={
                          !getAccessPermissions(
                            'embedded-ipass',
                            'installations'
                          )
                        }
                        icon={<InstallationsIcon />}
                      >
                        <div className={styles.itemContainer}>
                          <div id={getId('installations')}>Installations</div>
                          <div
                            id="installation-manager-length"
                            className={styles.rightItem}
                            style={{
                              display: 'inline-block',
                              textAlign: 'right',
                            }}
                          >
                            {installationCount}
                          </div>
                        </div>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                  {showUnifiedDashboard && (
                    <>
                      <Menu.SubMenu
                        key="unified-api-container"
                        className={classNames(
                          styles.dashboardSubMenu,
                          styles.label
                        )}
                        title={
                          <span
                            className={styles.label}
                            id="unified-api-container"
                          >
                            Unified API
                          </span>
                        }
                        disabled={!getAccessPermissions('unified-api')}
                      >
                        <Menu.Item
                          key="embedded-unified-api-get-started"
                          icon={<GetStartedIcon />}
                        >
                          <span id="embedded-unified-api-get-started">
                            Get Started
                            {isGetStartedPending && (
                              <span className={styles.tag}>Pending</span>
                            )}
                          </span>
                        </Menu.Item>
                        <Menu.Item
                          key="embedded-unified-api"
                          className={styles.label}
                          icon={<IntegrationsIcon />}
                        >
                          <div className={styles.itemContainer}>
                            <div id="embedded-unified-api-integrations">
                              Integrations
                            </div>
                            <div
                              id="embedded-unified-api-integrations-length"
                              className={styles.rightItem}
                            >
                              {!loadingIntegrationsList
                                ? integrationsCount
                                : ''}
                            </div>
                          </div>
                        </Menu.Item>
                        <Menu.Item
                          key="embedded-unified-api-connections"
                          className={styles.label}
                          icon={<ConnectionsIcon />}
                        >
                          <div className={styles.itemContainer}>
                            <div id="embedded-unified-api-connections">
                              Connections
                            </div>
                            <div
                              id="embedded-unified-api-connections-length"
                              className={styles.rightItem}
                              style={{
                                display: 'inline-block',
                                textAlign: 'right',
                              }}
                            >
                              {totalConnections}
                            </div>
                          </div>
                        </Menu.Item>
                      </Menu.SubMenu>
                    </>
                  )}

                  <Menu.SubMenu
                    key="embedded-settings-container"
                    className={classNames(
                      styles.dashboardSubMenu,
                      styles.label
                    )}
                    title={
                      <span id="embedded-settings-container">Settings</span>
                    }
                    disabled={!getAccessPermissions('settings')}
                  >
                    <Menu.Item
                      key="embedded-settings"
                      icon={<GeneralIcon />}
                      className={styles.dashboardMenuItem}
                      disabled={!getAccessPermissions('settings', 'general')}
                    >
                      <span id={getId('forge-settings')}>General</span>
                    </Menu.Item>
                    <Menu.Item
                      key="embedded-settings-security"
                      icon={<SecurityIcon />}
                      className={styles.dashboardMenuItem}
                    >
                      <span id={getId('forge-settings-security')}>
                        Security
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="embedded-settings-team"
                      icon={<TeamIcon />}
                      className={styles.dashboardMenuItem}
                    >
                      <span id={getId('forge-settings-team')}>Team</span>
                    </Menu.Item>
                    {(isAdmin ||
                      getAccessPermissions('settings', 'api-keys') ===
                        'edit') && (
                      <Menu.Item
                        key="embedded-settings-apikeys"
                        icon={<ApiKeyIcon />}
                        className={styles.dashboardMenuItem}
                      >
                        <span id={getId('forge-settings-apikeys')}>
                          API Keys
                        </span>
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key="embedded-settings-credentials"
                      icon={<CredentialIcon />}
                      disabled={
                        !getAccessPermissions('settings', 'credentials')
                      }
                      className={styles.dashboardMenuItem}
                    >
                      <span id={getId('forge-settings-credentials')}>
                        Credentials
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="embedded-settings-logging"
                      icon={<LoggingIcon />}
                      disabled={!getAccessPermissions('settings', 'logging')}
                      className={styles.dashboardMenuItem}
                    >
                      <span id={getId('forge-settings-logging')}>
                        Logging
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="embedded-settings-billing"
                      icon={<BillingIcon />}
                      className={styles.dashboardMenuItem}
                    >
                      <span id={getId('forge-settings-billing')}>
                        Billing
                      </span>
                    </Menu.Item>
                  </Menu.SubMenu>
                </>
              )}
              {userInfo.partnerHubEnabled && (
                <Menu.Item key="partner-hub" icon={<PartnerHubIcon />}>
                  <span className={styles.label} id={getId('partner-hub')}>
                    Partner Hub
                  </span>
                </Menu.Item>
              )}
              {!forgeSelected && (
                <Menu.Item
                  key="my-account"
                  icon={<SettingsIcon />}
                  className={styles.flowMenuItem}
                >
                  <span className={styles.label} id={getId('my-account')}>
                    Settings
                  </span>
                </Menu.Item>
              )}
            </Menu>
          </div>
          <div className={styles.bottom}>
            {forgeSelected ? (
              <>
                <div className={styles.forgeActionBtn}>
                  <AppButton
                    id={getId('forgeDocumentationButton')}
                    target="_blank"
                    href="https://docs.runalloy.com/docs/embedded-quick-start"
                    aria-label="Knowledge Base"
                    className={styles.embeddedDocsButton}
                  >
                    <DocSupportIcon />
                    <span id="documentation">Go to Documentation ↗</span>
                  </AppButton>
                </div>
              </>
            ) : (
              <>
                <div
                  className={classNames(styles.alloyActionBtn, {
                    [styles.noPlanCard]: forgeSelected || isEnterpriseAccount,
                  })}
                >
                  <AppButton
                    id={'help-center'}
                    style={{ display: 'flex' }}
                    aria-label="Knowledge Base"
                    onClick={this.onSupportToggle}
                  >
                    <DocumentationIcon />
                    <span id="knowledge-base">Feel stuck? Get help</span>
                  </AppButton>
                </div>
                {(
                  <>
                    {!isEnterpriseAccount && (
                      <UsageAnalytics
                        activeWorkflowCount={activeWorkflowCount}
                        analytics={analytics}
                        plans={plans}
                        userInfo={userInfo}
                        getPercentage={this.getPercentage}
                        onClickUpgrade={this.onClickUpgrade}
                        team={team}
                        forgeSelected={forgeSelected}
                      />
                    )}
                    {upgradeModalVisible && (
                      <ChangePlanModal
                        open
                        closeFxn={this.closeUpgradeModal}
                        upgradeSuccessFxn={this.showUpgradeConfirmationModal}
                        plan={plans}
                        closeSuccessFxn={() => {
                          location.reload();
                        }}
                      />
                    )}
                    {upgradeConfirmationVisible && (
                      <UpgradeConfirmationModal
                        open
                        showBenefits={showBenefits}
                        closeFxn={this.closeUpgradeConfirmationModal}
                      />
                    )}
                  </>
                )}
              </>
            )}
            <Divider className={styles.bottomDivider} />
            <div className={styles.alloyUserInfo}>
              <div className={styles.user}>
                <div className={styles.name}>
                  {this.truncateString(userInfo?.fullName, 50)}
                </div>
                <div className={styles.info}>
                  {userInfo?.email}
                  {team ? ` • ` + this.truncateString(team?.name, 30) : ''}
                </div>
              </div>
              <div className={styles.menuActionBtn}>
                <Tooltip title="Logout" placement="top">
                  <AppButton
                    id={getId('logoutButton')}
                    size="small"
                    type="text"
                    icon={<LogoutIcon />}
                    onClick={this.logout}
                    customClass={styles.logoutButtonIcon}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </Sider>
      );
    } else {
      return (
        <Header
          style={{
            position: 'relative',
            padding: '0 24px',
            background: '#2B2E32',
            height: '76px',
          }}
          className={classNames({
            [styles.noOverflow]: !mobileMenuVisible,
          })}
          id="main-header"
        >
          <div className={styles.headerContainer}>
            <Link to={forgeSelected ? '/embedded' : '/'}>
              <div
                id={forgeSelected ? 'alloy-forge-logo' : 'alloy-logo'}
                className={styles.logo}
                key="logo"
              >
                <img
                  src="https://cdn.runalloy.com/icons/alloy-automation-light-logo.svg"
                  alt="logo"
                />
              </div>
            </Link>
            <Hamburger
              direction="right"
              color="#FFFFFF"
              size={25}
              duration={0.2}
              onToggle={(toggled) => {
                if (toggled) {
                  this.setState({ mobileMenuVisible: true });
                } else {
                  this.setState({ mobileMenuVisible: false });
                }
              }}
              hideOutline={false}
            />
          </div>
          <div
            className={classNames(styles.bodyContainer, {
              [styles.opened]: mobileMenuVisible,
              [styles.closed]: !mobileMenuVisible,
            })}
          >
            <div className={styles.top}>
              {isForge && (
                <div
                  id={getId('forge')}
                  className={classNames(styles.productToggle, {
                    [styles.isForge]: forgeSelected,
                  })}
                  onClick={() => {
                    if (forgeSelected) {
                      this.handleClick({ key: 'dashboard' });
                      this.changeFavicon('alloy');
                      disableForge();
                    } else {
                      this.handleClick({ key: 'embedded' });
                      this.changeFavicon('embedded');
                      enableForge();
                    }
                  }}
                >
                  {isForge && !hideAutomation && (
                    <>
                      <span>
                        {forgeSelected
                          ? 'Switch to Automation'
                          : 'Switch to Embedded'}
                      </span>
                      <SwitchIcon />
                    </>
                  )}
                </div>
              )}
              <Menu
                onClick={this.handleClick}
                mode="inline"
                className={styles.dashboardMenu}
                selectedKeys={[selectedKey]}
              >
                {!forgeSelected ? (
                  <>
                    <Menu.Item key="dashboard" icon={<DashboardIcon />}>
                      <span className={styles.label} id={getId('dashboard')}>
                        Dashboard
                      </span>
                    </Menu.Item>
                    {isEnterprise ? (
                      <Menu.Item
                        key="marketplace"
                        icon={<MarketplaceIcon />}
                        className={classNames(styles.flowMenuItem, {
                          [styles.onboardingOn]:
                            showOnboarding && localOnboardingStep === 8,
                        })}
                        disabled
                      >
                        <Tooltip
                          placement="bottom"
                          title="You cannot access Marketplace under an enterprise plan. Please, upgrade to unlock it."
                        >
                          <span
                            className={styles.label}
                            id={getId('marketplace')}
                          >
                            Marketplace <IconLock />
                          </span>
                        </Tooltip>
                      </Menu.Item>
                    ) : (
                      <Menu.Item
                        key="marketplace"
                        icon={<MarketplaceIcon />}
                        className={classNames(styles.flowMenuItem, {
                          [styles.onboardingOn]:
                            showOnboarding && localOnboardingStep === 8,
                        })}
                      >
                        <span
                          className={styles.label}
                          id={getId('marketplace')}
                        >
                          Marketplace
                        </span>
                      </Menu.Item>
                    )}
                    <Menu.Item key="my-account" icon={<SettingsIcon />}>
                      <span className={styles.label} id={getId('my-account')}>
                        Settings
                      </span>
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item key="embedded" icon={<IntegrationsIcon />}>
                      <span
                        className={styles.label}
                        id={getId('forge-integrations')}
                      >
                        Integrations
                      </span>
                    </Menu.Item>

                    <Menu.Item key="installations" icon={<InstallationsIcon />}>
                      <span
                        className={styles.label}
                        id={getId('installations')}
                      >
                        Installations
                      </span>
                    </Menu.Item>

                    {hasArchivedWorkflows && (
                      <Menu.Item
                        key="embedded-workflows"
                        icon={<WorkflowsIcon />}
                      >
                        <span className={styles.label} id="forge-workflows">
                          Archived
                        </span>
                      </Menu.Item>
                    )}
                    <Menu.Item key="embedded-settings" icon={<SettingsIcon />}>
                      <span
                        className={styles.label}
                        id={getId('forge-settings')}
                      >
                        Settings
                      </span>
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </div>
            {forgeSelected ? (
              <div className={styles.bottom}>
                <div className={styles.forgeActionBtn}>
                  <AppButton
                    id={getId('forgeDocumentationButton')}
                    target="_blank"
                    href="https://docs.runalloy.com/docs/embedded-quick-start"
                    aria-label="Knowledge Base"
                  >
                    <DocumentationIcon />
                    <span id="documentation">Feel stuck? Get help</span>
                  </AppButton>
                  <AppButton
                    id={getId('forgeDemoButton')}
                    target="_blank"
                    href="https://embedded-demo.runalloy.com/"
                    rel="noreferrer"
                    aria-label="Forge Demo"
                  >
                    <DocumentationIcon />
                    <span id="documentation">Embedded Demo ↗</span>
                  </AppButton>
                </div>
              </div>
            ) : (
              <div className={styles.bottom}>
                <div className={styles.alloyActionBtn}>
                  <AppButton
                    id="help-center"
                    style={{ display: 'flex' }}
                    aria-label="Knowledge Base"
                    onClick={this.onSupportToggle}
                    className={styles.feelStuckBtn}
                  >
                    <DocumentationIcon />
                    <span id="knowledge-base"> Feel stuck? Get help</span>
                  </AppButton>
                </div>
                <UsageAnalytics
                  activeWorkflowCount={activeWorkflowCount}
                  analytics={analytics}
                  plans={plans}
                  userInfo={userInfo}
                  getPercentage={this.getPercentage}
                  onClickUpgrade={this.onClickUpgrade}
                  team={team}
                />
                {upgradeModalVisible && (
                  <ChangePlanModal
                    open
                    closeFxn={this.closeUpgradeModal}
                    upgradeSuccessFxn={this.showUpgradeConfirmationModal}
                    plan={plans}
                    closeSuccessFxn={() => {
                      location.reload();
                    }}
                  />
                )}
                {upgradeConfirmationVisible && (
                  <UpgradeConfirmationModal
                    open
                    showBenefits={showBenefits}
                    closeFxn={this.closeUpgradeConfirmationModal}
                  />
                )}
              </div>
            )}
          </div>
        </Header>
      );
    }
  }
}

export default withRouter(SiderMenu);
