import { GET, POST, DELETE, PATCH } from './wrappers';

export async function getAnalytics() {
  return GET(`/api/analytics/`);
}

export async function getUserStore() {
  return GET(`/api/analytics/store`);
}

export async function getPlans() {
  return GET(`/api/plan/`);
}

export async function payShopify(plan, discountCode) {
  return POST(`/api/payments/shopify`, {
    plan,
    discountCode,
  });
}

export async function dismissTrialMessage() {
  return PATCH('/api/payments/dismissTrialMessage');
}

export async function changePlan(
  plan,
  creditCard,
  discountCode,
  billingSchedule
) {
  return POST(`/api/payments/change-plan`, {
    plan,
    creditCard,
    discountCode,
    billingSchedule,
  });
}

export async function subscribeCapacityPlan(
  creditCard,
  billingSchedule,
  anticipatedConnections,
  currentConnections
) {
  return POST(`/api/payments/create-subscription`, {
    cardToken: creditCard,
    billingSchedule,
    anticipatedConnections,
    currentConnections,
  });
}

export async function cancelCapacityPlan() {
  return POST(`/api/payments/cancel-subscription`);
}

export async function cancelPlan(cancellationReason) {
  return DELETE(`/api/payments/cancel`, {
    cancellationReason,
  });
}

export async function activateShopify() {
  return POST(`/api/payments/shopify/activate`);
}

export async function activateInitialShopify() {
  return POST(`/api/payments/initShopify`);
}

export async function confirmShopifySubscription() {
  return POST(`/api/payments/shopify/confirmSubscription`);
}

export const getReferral = () => {
  return GET(`/api/referral`);
};

export async function getBillingHistory() {
  return GET(`/api/plan/billing-history`);
}

export async function getCredentials() {
  return GET(`/api/credentials`);
}

export async function getWorkflowsWithCredentials(credentialId) {
  return GET(`/api/credentials/${credentialId}/workflows`);
}

export async function deleteCredential(id) {
  return DELETE(`/api/credentials/${id}`);
}

export async function getApiKey() {
  return GET(`/api/credentials/decode/apikey`);
}
