import React from 'react';

export const IconAccountPlaceholder = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#2B2E32" fillOpacity="0.05" />
    <path
      d="M35.4067 32.9302C35.4067 36.08 30.2997 38.6335 24 38.6335C17.7002 38.6335 12.5933 36.08 12.5933 32.9302C12.5933 29.7803 17.7002 27.2268 24 27.2268C30.2997 27.2268 35.4067 29.7803 35.4067 32.9302Z"
      fill="#2B2E32"
    />
    <path
      d="M30.8438 16.21C30.8438 19.9898 27.7796 23.054 23.9998 23.054C20.2199 23.054 17.1558 19.9898 17.1558 16.21C17.1558 12.4301 20.2199 9.36597 23.9998 9.36597C27.7796 9.36597 30.8438 12.4301 30.8438 16.21Z"
      fill="#2B2E32"
      fillOpacity="0.3"
    />
  </svg>
);
