import React from 'react';
import classNames from 'classnames';
import styles from './index.less';
import { Radio } from 'antd';

export const AppRadio = (props) => {
  if (props?.type === 'group') {
    return (
      <Radio.Group
        className={classNames(styles.radio, {
          [props.customClass]: !!props.customClass,
        })}
        {...props}
      />
    );
  } else {
    return (
      <Radio
        className={classNames(styles.radio, {
          [props.customClass]: !!props.customClass,
        })}
        {...props}
      />
    );
  }
};
