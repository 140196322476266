export const ItemTypes = {
  // an existing block in workflow editor
  NODE: 'block',
  // a block option in select menu
  NODE_OPTION: 'block_option',
  SHARE_FIELD: 'share_field',

  // block type
  TRIGGER: 'trigger',
};
