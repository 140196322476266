export const planTypes = {
  free: 0,
  starter: 1,
  silver: 2,
  gold: 3,
  platinum: 4,
  forge: 5,
  admin: 99,
  business: 6,
};

export const newPlanTypes = {
  free: 0,
  individual: 1,
  professional: 2,
  business: 3,
};
