import React from 'react';
import styles from './index.less';
import classNames from 'classnames';
import {
  IconSuccess,
  IconError,
  IconWarning,
  IconUndefined,
} from '../Icons/status';

export const AppIntegrationBadge = ({
  id,
  icon,
  size = 'medium',
  bordered,
  status,
  className = null,
}) => {
  return (
    <div
      id={id}
      className={classNames(styles.integrationBadge, {
        [styles.notBordered]: !bordered,
        [styles.sml]: size === 'smallest',
        [styles.xs]: size === 'extra-small',
        [styles.sm]: size === 'small',
        [styles.md]: size === 'medium',
        [styles.lg]: size === 'large',
        [styles.xl]: size === 'extra-large',
        [className]: !!className,
      })}
    >
      <img src={icon} alt="integration-icon" />
      {status && (
        <div className={styles.status}>
          {status === 'success' ? (
            <IconSuccess />
          ) : status === 'error' ? (
            <IconError />
          ) : status === 'warn' ? (
            <IconWarning />
          ) : status === 'undefined' ? (
            <IconUndefined />
          ) : null}
        </div>
      )}
    </div>
  );
};
