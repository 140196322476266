import React from 'react';
import styles from './index.less';
import classNames from 'classnames';
import { Table } from 'antd';

export const AppTable = (props) => {
  return (
    <Table
      className={classNames(styles.tableContainer, {
        [props.customClass]: !!props.customClass,
      })}
      {...props}
    />
  );
};
