import { get, isObjectLike } from 'lodash';
import { toJS } from 'mobx';

export function displayParameter(
  blockValues,
  parameter,
  blockValuesRoot,
  forgeMode
) {
  if (!parameter.displayOptions) {
    return true;
  }

  blockValuesRoot = blockValuesRoot || blockValues;

  let value;

  if (parameter.displayOptions.show) {
    for (const propertyName of Object.keys(parameter.displayOptions.show)) {
      if (propertyName.charAt(0) === '/') {
        value = blockValuesRoot[propertyName.slice(1)];
      } else {
        value = blockValues ? blockValues[propertyName] : undefined;
      }

      // apiVersion is a special case, it is not set by default so it blocks the validation of the resource property
      // because we'll do resource validation once apiVersion is set
      // if (forgeMode && propertyName === 'apiVersion' && value === undefined) {
      //   return true;
      // }
      if (
        value === undefined ||
        !parameter.displayOptions.show[propertyName].includes(value)
      ) {
        return false;
      }
    }
  }
  if (parameter.displayOptions.hide) {
    for (const propertyName of Object.keys(parameter.displayOptions.hide)) {
      if (propertyName.charAt(0) === '/') {
        value = blockValuesRoot[propertyName.slice(1)];
      } else {
        value = blockValues[propertyName];
      }
      if (
        value !== undefined &&
        parameter.displayOptions.hide[propertyName].includes(value)
      ) {
        return false;
      }
    }
  }
  return true;
}

export function displayParameterPath(blockValues, parameter, path, forgeMode) {
  let resolvedBlockValues = blockValues;
  if (path !== '') {
    resolvedBlockValues = get(blockValues, path);
  }
  let blockValuesRoot = blockValues;
  if (path && path.split('.').indexOf('parameters') === 0) {
    blockValuesRoot = get(blockValues, 'parameters');
  }
  return displayParameter(
    resolvedBlockValues,
    parameter,
    blockValuesRoot,
    forgeMode
  );
}

export function getContext(runExecutionData, type, block) {
  if (runExecutionData.executionData === undefined) {
    throw new Error('The "executionData" is not initialized!');
  }
  let key;
  if (type === 'flow') {
    key = 'flow';
  } else if (type === 'block') {
    if (block === undefined) {
      throw new Error(
        `The request data of context type "block" the block parameter has to be set!`
      );
    }
    key = `block:${block.id}`;
  } else {
    throw new Error(
      `The context type "${type}" is not know. Only "flow" and block" are supported!`
    );
  }
  if (runExecutionData.executionData.contextData[key] === undefined) {
    runExecutionData.executionData.contextData[key] = {};
  }
  return runExecutionData.executionData.contextData[key];
}

export function getParameterDependencies(blockPropertiesArray) {
  const dependencies = {};
  let displayRule;
  let parameterName;
  if (!blockPropertiesArray) return dependencies;
  for (const blockProperties of blockPropertiesArray) {
    if (dependencies[blockProperties.name] === undefined) {
      dependencies[blockProperties.name] = [];
    }
    if (blockProperties.displayOptions === undefined) {
      continue;
    }
    for (displayRule of Object.keys(blockProperties.displayOptions)) {
      for (parameterName of Object.keys(
        blockProperties.displayOptions[displayRule]
      )) {
        if (!dependencies[blockProperties.name].includes(parameterName)) {
          dependencies[blockProperties.name].push(parameterName);
        }
      }
    }
  }
  return dependencies;
}

export function getParameterResolveOrder(
  blockPropertiesArray,
  parameterDependencies
) {
  const executionOrder = [];
  const indexToResolve = Array.from(
    {
      length: blockPropertiesArray.length,
    },
    (v, k) => k
  );
  const resolvedParameters = [];
  let index;
  let property;
  let lastIndexLength = indexToResolve.length;
  let lastIndexReduction = -1;
  let iterations = 0;
  while (indexToResolve.length !== 0) {
    iterations += 1;
    index = indexToResolve.shift();
    property = blockPropertiesArray[index];
    if (parameterDependencies[property.name].length === 0) {
      executionOrder.push(index);
      resolvedParameters.push(property.name);
      continue;
    }
    for (const dependency of parameterDependencies[property.name]) {
      if (!resolvedParameters.includes(dependency)) {
        if (dependency.charAt(0) === '/') {
          continue;
        }
        indexToResolve.push(index);
        continue;
      }
    }
    executionOrder.push(index);
    resolvedParameters.push(property.name);
    if (indexToResolve.length < lastIndexLength) {
      lastIndexReduction = iterations;
    }
    if (iterations > lastIndexReduction + blockPropertiesArray.length) {
      throw new Error('Could not resolve parameter dependencies!');
    }
    lastIndexLength = indexToResolve.length;
  }
  return executionOrder;
}

export function getBlockParameters(
  blockPropertiesArray,
  blockValues,
  returnDefaults,
  returnNoneDisplayed,
  onlySimpleTypes = false,
  dataIsResolved = false,
  blockValuesRoot,
  parentType,
  parameterDependencies,
  getDefaultValue = false
) {
  if (parameterDependencies === undefined) {
    parameterDependencies = getParameterDependencies(blockPropertiesArray);
  }
  if (!blockPropertiesArray) return {};
  const duplicateParameterNames = [];
  const parameterNames = [];
  blockPropertiesArray.forEach((blockProperties) => {
    if (parameterNames.includes(blockProperties.name)) {
      if (!duplicateParameterNames.includes(blockProperties.name)) {
        duplicateParameterNames.push(blockProperties.name);
      }
    } else {
      parameterNames.push(blockProperties.name);
    }
  });
  const blockParameters = {};
  const blockParametersFull = {};
  let blockValuesDisplayCheck = blockParametersFull;
  if (dataIsResolved !== true && returnNoneDisplayed === false) {
    blockValuesDisplayCheck = getBlockParameters(
      blockPropertiesArray,
      blockValues,
      true,
      true,
      true,
      true,
      blockValuesRoot,
      parentType,
      parameterDependencies
    );
  }
  blockValuesRoot = blockValuesRoot || blockValuesDisplayCheck;
  const parameterIterationOrderIndex = getParameterResolveOrder(
    blockPropertiesArray,
    parameterDependencies
  );
  for (const parameterIndex of parameterIterationOrderIndex) {
    const blockProperties = blockPropertiesArray[parameterIndex];
    if (
      blockValues &&
      blockValues[blockProperties.name] === undefined &&
      (returnDefaults === false || parentType === 'collection')
    ) {
      continue;
    }
    if (
      returnNoneDisplayed === false &&
      !displayParameter(
        blockValuesDisplayCheck,
        blockProperties,
        blockValuesRoot
      )
    ) {
      if (returnNoneDisplayed === false) {
        continue;
      }
      if (returnDefaults === false) {
        continue;
      }
    }
    if (!['collection', 'fixedCollection'].includes(blockProperties.type)) {
      if (duplicateParameterNames.includes(blockProperties.name)) {
        if (
          !displayParameter(
            blockValuesDisplayCheck,
            blockProperties,
            blockValuesRoot
          )
        ) {
          continue;
        }
      }
      // if (!!blockParameters) continue;

      if (returnDefaults === true) {
        if (['boolean', 'number'].includes(blockProperties.type)) {
          blockParameters[blockProperties.name] =
            blockValues[blockProperties.name] !== undefined
              ? blockValues[blockProperties.name]
              : blockProperties.default !== undefined
              ? blockProperties.default
              : '';
        } else {
          blockParameters[blockProperties.name] =
            blockValues[blockProperties.name] ||
            (blockProperties.default !== undefined
              ? blockProperties.default
              : '');
        }
        if (getDefaultValue && blockProperties.name === 'operation') {
          blockParameters[blockProperties.name] =
            blockProperties.default !== undefined
              ? blockProperties.default
              : '';
        }
        blockParametersFull[blockProperties.name] =
          blockParameters[blockProperties.name];
      } else if (
        blockValues[blockProperties.name] !== blockProperties.default ||
        (blockValues[blockProperties.name] !== undefined &&
          parentType === 'collection')
      ) {
        blockParameters[blockProperties.name] =
          blockValues[blockProperties.name];
        blockParametersFull[blockProperties.name] =
          blockParameters[blockProperties.name];
        continue;
      }
    }
    if (onlySimpleTypes === true) {
      continue;
    }
    let tempValue;
    if (blockProperties.type === 'collection') {
      if (
        blockProperties.typeOptions !== undefined &&
        blockProperties.typeOptions.multipleValues === true
      ) {
        if (blockValues[blockProperties.name] !== undefined) {
          blockParameters[blockProperties.name] =
            blockValues[blockProperties.name];
        } else if (returnDefaults === true) {
          blockParameters[blockProperties.name] = [];
        }
        blockParametersFull[blockProperties.name] =
          blockParameters[blockProperties.name];
      } else if (blockValues[blockProperties.name] !== undefined) {
        const tempBlockParameters = getBlockParameters(
          blockProperties.options,
          blockValues[blockProperties.name],
          returnDefaults,
          returnNoneDisplayed,
          false,
          false,
          blockValuesRoot,
          blockProperties.type
        );
        if (tempBlockParameters !== null) {
          blockParameters[blockProperties.name] = tempBlockParameters;
          blockParametersFull[blockProperties.name] =
            blockParameters[blockProperties.name];
        }
      } else if (returnDefaults === true && blockProperties.default) {
        blockParameters[blockProperties.name] = JSON.parse(
          JSON.stringify(blockProperties.default)
        );
        blockParametersFull[blockProperties.name] =
          blockParameters[blockProperties.name];
      }
    } else if (blockProperties.type === 'fixedCollection') {
      const collectionValues = {};
      let tempBlockParameters;
      let tempBlockPropertiesArray;
      let blockPropertyOptions;
      let propertyValues = blockValues[blockProperties.name];
      if (returnDefaults === true) {
        if (propertyValues === undefined) {
          propertyValues = JSON.parse(JSON.stringify(blockProperties.default));
        }
      }
      for (const itemName of Object.keys(propertyValues)) {
        if (
          blockProperties.typeOptions !== undefined &&
          blockProperties.typeOptions.multipleValues === true
        ) {
          const tempArrayValue = [];
          for (const blockValue of propertyValues[itemName]) {
            blockPropertyOptions = blockProperties.options.find(
              (option) => option.name === itemName
            );
            if (blockPropertyOptions === undefined) {
              throw new Error(
                `Could not find property option "${itemName}" for "${blockProperties.name}"`
              );
            }
            tempBlockPropertiesArray = blockPropertyOptions.values;
            tempValue = getBlockParameters(
              tempBlockPropertiesArray,
              blockValue,
              returnDefaults,
              returnNoneDisplayed,
              false,
              false,
              blockValuesRoot,
              blockProperties.type
            );
            if (tempValue !== null) {
              tempArrayValue.push(tempValue);
            }
          }
          collectionValues[itemName] = tempArrayValue;
        } else {
          tempBlockParameters = {};
          const option = blockProperties.options.find(
            (data) => data.name === itemName
          );
          if (option !== undefined) {
            tempBlockPropertiesArray = option.values;
            tempValue = getBlockParameters(
              tempBlockPropertiesArray,
              blockValues[blockProperties.name][itemName],
              returnDefaults,
              returnNoneDisplayed,
              false,
              false,
              blockValuesRoot,
              blockProperties.type
            );
            if (tempValue !== null) {
              Object.assign(tempBlockParameters, tempValue);
            }
          }
          if (Object.keys(tempBlockParameters).length !== 0) {
            collectionValues[itemName] = tempBlockParameters;
          }
        }
      }
      if (
        Object.keys(collectionValues).length !== 0 ||
        returnDefaults === true
      ) {
        if (returnDefaults === true) {
          if (collectionValues === undefined) {
            blockParameters[blockProperties.name] = JSON.parse(
              JSON.stringify(blockProperties.default)
            );
          } else {
            blockParameters[blockProperties.name] = collectionValues;
          }
          blockParametersFull[blockProperties.name] =
            blockParameters[blockProperties.name];
        } else if (collectionValues !== blockProperties.default) {
          blockParameters[blockProperties.name] = collectionValues;
          blockParametersFull[blockProperties.name] =
            blockParameters[blockProperties.name];
        }
      }
    }
  }
  return blockParameters;
}

export async function prepareOutputData(outputData, outputIndex = 0) {
  const returnData = [];
  for (let i = 0; i < outputIndex; i++) {
    returnData.push([]);
  }
  returnData.push(outputData);
  return returnData;
}

export function getBlockWebhooks(workflow, block, additionalData) {
  if (block.disabled === true) {
    return [];
  }
  if (workflow._id === undefined) {
    return [];
  }
  const blockType = workflow.blockTypes.getByName(block.type);
  if (!blockType) {
    throw new Error(`Failed to find block type ${block.type}`);
  }
  if (blockType.description.webhooks === undefined) {
    return [];
  }
  const returnData = [];
  for (const webhookDescription of blockType.description.webhooks) {
    let blockWebhookPath = workflow.getSimpleParameterValue(
      block,
      webhookDescription.path,
      'GET'
    );
    if (blockWebhookPath === undefined) {
      console.error(
        `No webhook path could be found for block "${block.id}" in workflow "${workflow._id}".`
      );
      continue;
    }
    blockWebhookPath = blockWebhookPath.toString();
    if (blockWebhookPath.charAt(0) === '/') {
      blockWebhookPath = blockWebhookPath.slice(1);
    }
    const path = getBlockWebhookPath(
      workflow._id.toString(),
      block,
      blockWebhookPath
    );
    const httpMethod = workflow.getSimpleParameterValue(
      block,
      webhookDescription.httpMethod,
      'GET'
    );
    if (httpMethod === undefined) {
      console.error(
        `The webhook "${path}" for block "${block.id}" in workflow "${workflow._id}" could not be added because the httpMethod is not defined.`
      );
      continue;
    }
    returnData.push({
      httpMethod: httpMethod.toString(),
      block: block.id,
      path,
      webhookDescription,
      workflow,
      workflowExecuteAdditionalData: additionalData,
    });
  }
  return returnData;
}

export function getBlockWebhookPath(workflowId, block, path) {
  return `${workflowId}/${encodeURIComponent(block.id)}/${path}`;
}

export function getBlockWebhookUrl(baseUrl, workflowId, block, path) {
  return `${baseUrl}/${getBlockWebhookPath(workflowId, block, path)}`;
}

export function getBlockParametersIssues(
  blockPropertiesArray,
  block,
  forgeEditableFields,
  blockId,
  forgeMode
) {
  const foundIssues = {};
  let propertyIssues;
  if (block.disabled === true) {
    return null;
  }
  if (!Array.isArray(blockPropertiesArray)) {
    return;
  }

  for (const blockProperty of blockPropertiesArray) {
    propertyIssues = getParameterIssues(
      blockProperty,
      block.parameters,
      '',
      forgeEditableFields,
      blockId,
      forgeMode
    );
    mergeIssues(foundIssues, propertyIssues);
  }
  if (Object.keys(foundIssues).length === 0) {
    return null;
  }
  return foundIssues;
}

export function blockIssuesToString(issues, block) {
  const blockIssues = [];
  if (issues.execution !== undefined) {
    blockIssues.push(`Execution Error.`);
  }
  const objectProperties = ['parameters', 'credentials'];
  let issueText;
  let parameterName;
  for (const propertyName of objectProperties) {
    if (issues[propertyName] !== undefined) {
      for (parameterName of Object.keys(issues[propertyName])) {
        for (issueText of issues[propertyName][parameterName]) {
          blockIssues.push(issueText);
        }
      }
    }
  }
  if (issues.typeUnknown !== undefined) {
    if (block !== undefined) {
      blockIssues.push(`Block Type "${block.type}" is not known.`);
    } else {
      blockIssues.push(`Block Type is not known.`);
    }
  }
  return blockIssues;
}

const checkFixedCollection = (
  foundIssues,
  blockProperties,
  value,
  forgeEditableFields,
  blockId,
  index
) => {
  if (foundIssues.parameters === undefined) {
    foundIssues.parameters = {};
  }

  if (value === undefined || (Array.isArray(value) && value.length === 0)) {
    if (foundIssues.parameters[blockProperties.name] === undefined) {
      foundIssues.parameters[blockProperties.name] = [];
    }
    foundIssues.parameters[blockProperties.name].push(
      `Parameter "${blockProperties.displayName}" is required.`
    );
    return;
  }

  if (
    value &&
    value.emailTemplate &&
    (value.emailTemplate === 'plain' || value.emailTemplate === 'html')
  ) {
    return;
  }

  if (value && typeof value === 'object') {
    Object.keys(value).forEach((key) => {
      if (key === 'conditionSet') {
        // If-else block
        value[key].forEach((a) => {
          Object.keys(a).forEach((r) => {
            blockProperties.options[0].options.forEach((option) => {
              if (
                option.required &&
                option.name === r &&
                typeof a[r] === 'string' &&
                a[r] === ''
              ) {
                foundIssues.parameters[option.name] = [
                  `Parameter "${option.displayName}" is required.`,
                ];
              }
            });
          });
        });
      } else if (key === 'conditionSets') {
        const uuid = value['uuid'];
        // Branch block
        value[key].forEach((a) => {
          a.conditionSet.forEach((b) => {
            Object.keys(b).forEach((x) => {
              blockProperties.options.forEach((option) => {
                if (option.required) {
                  option.options.forEach((o) => {
                    o.options.forEach((w) => {
                      if (
                        w.required &&
                        w.name === x &&
                        typeof b[x] === 'string' &&
                        b[x] === ''
                      ) {
                        const name = `${w.name}::${uuid}`;
                        foundIssues.parameters[name] = [
                          `Parameter "${w.displayName}" is required.`,
                        ];
                      }
                    });
                  });
                }
              });
            });
          });
        });
      } else {
        blockProperties.options.forEach((option) => {
          const forgeFields = `${blockId}::${blockProperties.name}[${index}].${option.name}`;
          if (
            forgeEditableFields &&
            option.required &&
            option.name === key &&
            typeof value[key] === 'string' &&
            value[key] === '' &&
            !forgeEditableFields.hasOwnProperty(forgeFields)
          ) {
            foundIssues.parameters[option.name] = [
              `Parameter "${option.displayName}" is required.`,
            ];
          } else if (
            !forgeEditableFields &&
            option.required &&
            option.name === key &&
            typeof value[key] === 'string' &&
            value[key] === ''
          ) {
            foundIssues.parameters[option.name] = [
              `Parameter "${option.displayName}" is required.`,
            ];
          }
        });
      }
    });
  }
};

export function addToIssuesIfMissing(
  foundIssues,
  blockProperties,
  value,
  forgeEditableFields,
  blockId,
  index = 0
) {
  const validTypes = [
    'string',
    'email',
    'phoneNumber',
    'options',
    'timeIntervalPicker',
    'timePicker',
    'dateTime',
    'image',
    'number',
    'multiOptions',
    'boolean',
  ];

  if (blockProperties.type === 'fixedCollection') {
    checkFixedCollection(
      foundIssues,
      blockProperties,
      value,
      forgeEditableFields,
      blockId,
      index
    );
  } else if (
    (validTypes.includes(blockProperties.type) &&
      (value === '' ||
        value === undefined ||
        value === null ||
        Object.keys(value.toString()).length === 0)) ||
    (blockProperties.type === 'multiOptions' &&
      Array.isArray(value) &&
      value.length === 0) ||
    (blockProperties.name === 'operation' && value === 'createCustomAction')
  ) {
    if (foundIssues.parameters === undefined) {
      foundIssues.parameters = {};
    }
    if (foundIssues.parameters[blockProperties.name] === undefined) {
      foundIssues.parameters[blockProperties.name] = [];
    }
    foundIssues.parameters[blockProperties.name].push(
      `Parameter "${blockProperties.displayName}" is required.`
    );
  }

  if (
    foundIssues.parameters &&
    Object.keys(foundIssues.parameters).length === 0
  ) {
    delete foundIssues.parameters;
  }
}

export function getParameterValueByPath(blockValues, parameterName, path) {
  return get(blockValues, path ? path + '.' + parameterName : parameterName);
}

export function getParameterIssues(
  blockProperties,
  blockValues,
  path,
  forgeEditableFields,
  blockId,
  forgeMode
) {
  const foundIssues = {};
  let value;

  if (blockProperties.name) {
    if (
      blockProperties.required === 'true' ||
      blockProperties.required === true ||
      blockProperties.name === 'resource' ||
      blockProperties.name === 'operation'
    ) {
      if (displayParameterPath(blockValues, blockProperties, path, forgeMode)) {
        value = getParameterValueByPath(
          blockValues,
          blockProperties.name,
          path
        );
        if (
          blockProperties.typeOptions !== undefined &&
          blockProperties.typeOptions.multipleValues !== undefined
        ) {
          if (Array.isArray(value)) {
            if (value.length) {
              for (const [i, singleValue] of value.entries()) {
                addToIssuesIfMissing(
                  foundIssues,
                  blockProperties,
                  singleValue,
                  forgeEditableFields,
                  blockId,
                  i
                );
              }
              // for fixedCollection required param
            } else {
              addToIssuesIfMissing(
                foundIssues,
                blockProperties,
                undefined,
                forgeEditableFields,
                blockId
              );
            }
          }
          // for fixedCollection required param
          if (blockProperties.type === 'fixedCollection') {
            if (value === undefined) {
              addToIssuesIfMissing(
                foundIssues,
                blockProperties,
                value,
                forgeEditableFields,
                blockId
              );
            } else if (value && Object.keys(value).length === 0) {
              addToIssuesIfMissing(
                foundIssues,
                blockProperties,
                value,
                forgeEditableFields,
                blockId
              );
            }
          }
          // for fixedCollection required param
          if (
            isObjectLike(value) &&
            Object.keys(value).length === 1 &&
            Array.isArray(value[Object.keys(value)[0]]) &&
            value[Object.keys(value)[0]].length === 0
          ) {
            addToIssuesIfMissing(
              foundIssues,
              blockProperties,
              undefined,
              forgeEditableFields,
              blockId
            );
          }
        } else {
          addToIssuesIfMissing(
            foundIssues,
            blockProperties,
            value,
            forgeEditableFields,
            blockId
          );
        }
      }
    }
  }

  if (blockProperties.options === undefined) {
    return foundIssues;
  }
  let basePath = path ? `${path}.` : '';
  const checkChildBlockProperties = [];
  if (blockProperties.type === 'collection') {
    for (const option of blockProperties.options) {
      checkChildBlockProperties.push({
        basePath,
        data: option,
      });
    }
  } else if (blockProperties.type === 'fixedCollection') {
    basePath = basePath ? `${basePath}.` : '' + blockProperties.name + '.';
    let propertyOptions;
    for (propertyOptions of blockProperties.options) {
      value = getParameterValueByPath(
        blockValues,
        propertyOptions.name,
        basePath.slice(0, -1)
      );
      if (value === undefined) {
        continue;
      }
      if (
        blockProperties.typeOptions !== undefined &&
        blockProperties.typeOptions.multipleValues !== undefined
      ) {
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i += 1) {
            for (const option of propertyOptions.values) {
              checkChildBlockProperties.push({
                basePath: `${basePath}${propertyOptions.name}[${i}]`,
                data: option,
              });
            }
          }
        }
      } else if (
        propertyOptions &&
        propertyOptions.values &&
        Array.isArray(propertyOptions.values)
      ) {
        for (const option of propertyOptions.values) {
          checkChildBlockProperties.push({
            basePath: basePath + propertyOptions.name,
            data: option,
          });
        }
      }
    }
  } else {
    return foundIssues;
  }
  let propertyIssues;
  for (const optionData of checkChildBlockProperties) {
    propertyIssues = getParameterIssues(
      optionData.data,
      blockValues,
      optionData.basePath,
      forgeEditableFields,
      blockId,
      forgeMode
    );
    mergeIssues(foundIssues, propertyIssues);
  }
  return foundIssues;
}

export function mergeIssues(destination, source) {
  if (source === null) {
    return;
  }
  if (source.execution === true) {
    destination.execution = true;
  }
  const objectProperties = ['parameters', 'credentials'];
  let destinationProperty;
  for (const propertyName of objectProperties) {
    if (source[propertyName] !== undefined) {
      if (destination[propertyName] === undefined) {
        destination[propertyName] = {};
      }
      let parameterName;
      for (parameterName of Object.keys(source[propertyName])) {
        destinationProperty = destination[propertyName];
        if (destinationProperty[parameterName] === undefined) {
          destinationProperty[parameterName] = [];
        }
        destinationProperty[parameterName].push(
          ...source[propertyName][parameterName]
        );
      }
    }
  }
  if (source.typeUnknown === true) {
    destination.typeUnknown = true;
  }
}
