import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export function renderRoutes(routerData) {
  return Object.keys(routerData).map((r) => {
    const item = routerData[r];
    if (item.redirect) {
      return <Redirect from={r} to={item.redirect} key={`redirect-${r}`} />;
    }

    const routeRender = (props) => {
      // redirect to external link
      if (item.link) {
        window.location.replace(item.link);

        return null;
      }

      if (item.layout) {
        return (
          <>
            <item.layout {...props}>
              <item.component {...props} />
            </item.layout>
          </>
        );
      }
      return <item.component {...props} />;
    };

    return (
      <Route
        key={item.key || r}
        path={r}
        render={routeRender}
        exact={item.exact}
      />
    );
  });
}
