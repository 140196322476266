import { isUndefined } from 'lodash';
import { DELETE, GET, POST, PATCH } from './wrappers';

export const deleteRecipe = (recipeId) => {
  return DELETE(`/api/marketplace/${recipeId}`);
};

export const getBasicRecipeInfo = (recipeId, isCollection) => {
  return GET(`/api/basicMarketplace/basic-info/${recipeId}`, { isCollection });
};

export function getMarketplaceTemplates(params) {
  return GET('/api/marketplace/templates', params);
}

export function getRecommendedRecipe(params) {
  return GET('/api/marketplace/recommended', params);
}

export function getCollections() {
  return GET(`/api/marketplace/collections`);
}

export function getTemplateSummary() {
  return GET(`/api/marketplace/templateSummary`);
}

export function recipeTitleTypeahead(title) {
  return GET(`/api/marketplace/titleTypeahead`, { title });
}

export function marketplaceSearch(params) {
  return GET('/api/marketplace/search', params);
}

export function getPendingRecipes() {
  return GET('/api/marketplace/pending');
}

export function getCollectionRecipes(collectionId) {
  return GET(`/api/marketplace/collections/${collectionId}`);
}

export function getRecipe(id, forgeMode, enterpriseCredential) {
  const params = {
    ...(forgeMode ? { forgeMode: true } : {}),
    ...(enterpriseCredential ? { enterpriseCredential } : {}),
  };
  return GET('/api/marketplace/' + id, params);
}

export function getForgeRecipes(params) {
  return GET('/api/marketplace/forge-recipes', params);
}

export function getRecipes(
  collectionId,
  enterpriseCredential,
  recipeIds,
  selectedIntegrationId
) {
  if (enterpriseCredential || recipeIds || selectedIntegrationId) {
    return GET(`/api/marketplace/recipe/collection/${collectionId}`, {
      ...(enterpriseCredential ? { enterpriseCredential } : {}),
      ...(recipeIds ? { recipeIds } : {}),
      ...(selectedIntegrationId
        ? { integrationId: selectedIntegrationId }
        : {}),
    });
  } else {
    return GET(`/api/marketplace/recipe/collection/${collectionId}`);
  }
}

export function createRecipeDraft(recipe) {
  return POST('/api/marketplace/draft', recipe);
}

export function addRecipeRequest(params) {
  return POST('/api/marketplace/addRecipeRequest', params);
}

export function newCommunity(params) {
  return POST('/api/marketplace/new', params);
}

export function updateRecipe(id, params) {
  return PATCH(`/api/marketplace/${id}`, params);
}

export function userPosts(params) {
  return POST('/api/marketplace/userPosts', params);
}

export function useWorkflow(params) {
  return POST('/api/marketplace/useWorkflow', params);
}

export function useRecipeWorkflow(params) {
  return POST('/api/marketplace/useRecipeWorkflow', params);
}

export function saveForgeWorkflow(params) {
  return POST('/api/marketplace/saveForgeWorkflow', params);
}

// Track segment events when a user click on `Use Workflow` button
export async function segmentTrackRecipeInstallationStarted(
  recipeId,
  recipeTitle,
  userId,
  email
) {
  if (!isUndefined(window.analytics)) {
    const stringifiedUserId = userId.toString();
    const emailMatch = email.match(
      /^.*runalloy.com*|^.*alloytestuser*|^.*alloylightninguser*|^.*justanothertestuser*|^.*ark.ph*|^.+test*/
    );

    if (emailMatch === null) {
      window.analytics.track('Recipe install started', {
        userId: stringifiedUserId,
        properties: {
          recipeId,
          recipeName: recipeTitle,
        },
        context: {
          active: true,
        },
      });
    }
  }
}

// Track segment events when a user successfully finishes setting up a recipe
export async function segmentTrackRecipeInstallationFinished(
  userId,
  recipeId,
  workflow,
  email
) {
  try {
    if (!isUndefined(window.analytics)) {
      const stringifiedUserId = userId.toString();
      const emailMatch = email.match(
        /^.*runalloy.com*|^.*alloytestuser*|^.*alloylightninguser*|^.*justanothertestuser*|^.*ark.ph*|^.+test*/
      );

      if (emailMatch === null && workflow && workflow.name && recipeId) {
        window.analytics.track('Recipe install finished', {
          userId: stringifiedUserId,
          properties: {
            recipeId,
            recipeName: workflow.name,
          },
          context: {
            active: true,
          },
        });
      }
    }
  } catch (e) {
    //don't throw errors in optional analytics functions plz
  }
}
