export const messages = {
  SUCCESS_IF_CONDITION_MET: 'True conditions met',
  SUCCESS_ELSE_CONDITION_MET: 'False conditions met',
  SUCCESS_LOWER_CASE: 'success',
  FAILED_LOWER_CASE: 'failed',
  SUCCESSFULL: 'Successful',
  FAILED: 'Failed',
  FAILED_UPPER_CASE: 'FAILED',
};

export const namingValidationErrors = {
  EMOJI: 'Use of emojis in name is not allowed',
  WORKFLOW_RENAME_EMPTY: 'Workflow name is required',
  WORKSPACE_RENAME_EMPTY: 'Workspace name is required',
  MULTIPLE_SPACE: 'Name must not have more than one empty space',
};
