import { isBoolean, isNil, isUndefined, set } from 'lodash';
import { GET, PUT, PATCH, POST, DELETE } from './wrappers';

window.process = { nextTick: () => {}, versions: { node: 'lol' } };

export const generateApiKey = () => {
  return GET('/api/api-key');
};

export const getBlockOutput = (blockType, query) => {
  return POST(`/api/block-output/${blockType}`, query);
};

export const overWriteBlockOutput = (workflowId, blockId, body) => {
  return PUT(`/api/block-output/${workflowId}/${blockId}`, body);
};

export const removeBranch = (workflowId, body) => {
  return DELETE(`/api/workflows/${workflowId}/block/branch`, body);
};

export const duplicateBranch = (workflowId, body) => {
  return POST(`/api/workflows/${workflowId}/duplicate/branch`, body);
};

export const updateWorkflowFromLightning = (params) => {
  return POST(`/api/workflows/updateWorkflowFromLightning`, params);
};

export const moveBlock = (workflowId, body) => {
  return PATCH(`/api/workflows/${workflowId}/block`, body);
};

export const addBlock = (workflowId, body) => {
  return POST(`/api/workflows/${workflowId}/block`, body);
};

export const removeBlock = (workflowId, body) => {
  return DELETE(`/api/workflows/${workflowId}/block`, body);
};

export const getCurrentExecutions = (filter) => {
  if (filter) {
    return GET(`/api/executions-current`, { filter });
  } else {
    return GET(`/api/executions-current`);
  }
};

export const getLatestNotifications = (data) => {
  return GET('/api/executions-current/latest', data);
};

export const getExecutionSummaries = (userId) => {
  return GET(
    `/api/executions-current/execution-summaries${
      userId ? `?childUserId=${userId}` : ``
    }`
  );
};

export const getEmbeddedExecutionSummaries = (
  userId,
  workflowIds,
  integrationIds
) => {
  return POST(`/api/embedded-executions-current/execution-summaries`, {
    userId,
    workflowIds,
    integrationIds,
  });
};

export const getLatestExecution = (workflowId) => {
  return GET(`/api/executions-current/latest/${workflowId}`);
};

export const getWorkflowLogs = (
  workflowId,
  alreadyLoaded,
  startDate,
  endDate,
  activeVersionIsCustom,
  activeChildUserId,
  filterFailed,
  filterSucceeded,
  value
) => {
  if (activeVersionIsCustom && activeChildUserId) {
    return GET(
      `/api/executions-current/logs/${workflowId}?alreadyLoaded=${alreadyLoaded}&startDate=${startDate}&endDate=${endDate}&customVersion=${activeVersionIsCustom}&childUserId=${activeChildUserId}&filterFailed=${filterFailed}&filterSucceeded=${filterSucceeded}&value=${value}`
    );
  } else {
    return GET(
      `/api/executions-current/logs/${workflowId}?alreadyLoaded=${alreadyLoaded}&startDate=${startDate}&endDate=${endDate}&filterFailed=${filterFailed}&filterSucceeded=${filterSucceeded}&value=${value}`
    );
  }
};

export const rerunExecution = (executionId) => {
  return POST(`/api/executions-current/rerun/${executionId}`);
};

export const getSettings = () => {
  return GET('/api/settings');
};

export const getBlockTypes = (body) => {
  return POST('/api/block-types', body);
};

export const getBlockTypeByName = (blockTypeName) => {
  return GET(`/api/block-types/${blockTypeName}`);
};

export const getBlockParameterOptions = (body) => {
  return POST('/api/parameter-options', body);
};

export const removeTestWebhook = (workflowId) => {
  return DELETE(`/api/test-webhook/${workflowId}`);
};

export const runWorkflow = (startRunData) => {
  return POST('/api/workflows/run', startRunData);
};

export const createNewWorkflow = (body) => {
  return POST('/api/workflows', body);
};

export const updateWorkflow = (id, body) => {
  const workflow = preProcessWorkflow(body);
  return PATCH(`/api/workflows/${id}`, workflow);
};

export const createAppEvent = (body) => {
  return POST(`/api/appEvent`, body);
};

export const getAppEvents = () => {
  return GET(`/api/appEvent`);
};

export const getAppEvent = (id) => {
  return GET(`/api/appEvent/${id}`);
};

export const deleteAppEvent = (id) => {
  return DELETE(`/api/appEvent/${id}`);
};

export const editAppEvent = (id, body) => {
  return PUT(`/api/appEvent/${id}`, body);
};

export const getChildWorkflows = (id) => {
  return GET(`/api/workflows/forge/${id}`);
};
export const updateForgeWorkflow = (id, body) => {
  return POST(`/api/workflows/forge/${id}`, body);
};

export const deleteWorkflow = (workflowId) => {
  return DELETE(`/api/workflows/${workflowId}`);
};

export const deleteWorkflows = (data) => {
  return POST(`/api/workflows/bulk-delete`, data);
};

export const getWorkflow = async (id, version, userId, versionId) => {
  let request = `/api/workflows/${id}?workflowId=${id}`;

  if (version) {
    request += `&version=${version}`;
  }

  if (userId) {
    request += `&userId=${userId}`;
  }

  if (versionId) {
    request += `&versionId=${versionId}`;
  }

  const workflow = await GET(request);
  const processedWorkflow = postProcessWorkflow(workflow);
  return processedWorkflow;
};

export const getDisabledWorkflow = (id, limit, skip) => {
  return GET(
    `/api/workflows/disabled-workflow/${id}?limit=${limit}&skip=${skip}`
  );
};

export const restoreWorkflow = (id) => {
  return PUT(`/api/workflows/restore-workflow/${id}`);
};

export const getEmbeddedWorkflows = (limit, skip, integrationId) => {
  const request = `/api/embedded-integrations/workflows/${integrationId}?limit=${limit}&skip=${skip}`;

  return GET(request);
};

export const getIntegrationWorkflowCount = (integrationId) => {
  const request = `/api/embedded-integrations/count/${integrationId}`;

  return GET(request);
};

// OPTIMISE HERE
export const getWorkflows = (
  userId,
  limit,
  skip,
  hasExecutions = false,
  includeExecutions = false,
  workspaceId = null,
  forgeMode = false,
  active,
  integrationId = null,
  manual,
  error,
  fromIntegration
) => {
  let request = `/api/workflows/user/${userId}?limit=${limit}&skip=${skip}`;

  if (!isNil(active)) {
    request += `&active=${active}`;
  }

  if (!isNil(manual)) {
    request += `&manual=${manual}`;
  }

  if (!isNil(error)) {
    request += `&error=${error}`;
  }

  if (hasExecutions) {
    request += '&hasExecutions=true';
  }

  if (workspaceId) {
    request += `&workspaceId=${workspaceId}`;
  }

  if (includeExecutions) request += '&includeExecutions=true';

  if (forgeMode) request += '&forgeMode=true';

  if (integrationId) request += `&integrationId=${integrationId}`;
  if (isBoolean(fromIntegration))
    request += `&fromIntegration=${fromIntegration}`;

  return GET(request);
};

export const updateWorkflowName = (id, body) => {
  return PUT(`/api/workflows/${id}`, body);
};

// Validates that a user can create a new workflow
export const validateWorkflow = () => {
  return GET(`/api/workflows/validate`);
};

// Creates a new workflow
export const createNewCredentials = (body) => {
  return POST(`/api/credentials`, body);
};

export const connectExpiredTokens = (id, body) => {
  return GET(`/api/credentials/expired/${id}`, body);
};

// Deletes a credentials
export const deleteCredentials = (id) => {
  return DELETE(`/api/credentials/${id}`);
};

export const associateCredential = (credentialJwt) => {
  return POST(`/api/credentials/associate`, { credentialJwt });
};

// Updates existing credentials
export const updateCredentials = (id, body) => {
  return PATCH(`/api/credentials/${id}`, body);
};

// Disable credentials
export const disableCredentials = (id, body) => {
  return PUT(`/api/credentials/disable/${id}`, body);
};

// Rename credentials
export const renameCredentials = (id, body) => {
  return PUT(`/api/credentials/rename/${id}`, body);
};

// Returns the credentials with the given id
export const getCredentials = (id, includeData) => {
  let body;
  if (includeData) {
    body = {
      includeData,
    };
  }
  return GET(`/api/credentials/${id}`, body);
};

// Returns all saved credentials
export const getAllCredentials = (filter) => {
  if (filter) {
    return GET(`/api/credentials`, { ...filter });
  } else {
    return GET(`/api/credentials`);
  }
};

// Returns all credential types
export const getCredentialTypes = () => {
  return GET(`/api/credential-types`);
};

//Shares credentials
export const shareCredentials = (body) => {
  return POST(`/api/credentials/share`, body);
};

// Returns credentials for a given user and block type
export const getCredentialsForBlock = (
  userId,
  type,
  workflowId,
  isUserSpecific
) => {
  return POST('/api/credentials/userlist', {
    userId,
    type,
    workflowId,
    isUserSpecific,
  });
};

export const getAllVerifiedApps = (userId) => {
  return POST('/api/credentials/getAllVerifiedApps', { userId });
};

export const getExecution = (id) => {
  return GET(`/api/executions-current/${id}`).then((res) => {
    return unflattenExecutionData(res);
  });
};

export const getTimezones = () => {
  return GET('/api/options/timezones');
};

export const getCredentialsCheck = () => {
  return GET('/api/credentials/check');
};

function unflattenExecutionData(fullExecutionData) {
  // Unflatten the data
  // const returnData = {
  //   ...fullExecutionData,
  //   workflowData: fullExecutionData.workflowData,
  //   data: parse(fullExecutionData.data),
  // };
  const returnData = {
    ...fullExecutionData.data,
  };

  returnData.finished = returnData.finished ? returnData.finished : false;

  if (fullExecutionData.id) {
    returnData.id = fullExecutionData.id;
  }

  return returnData;
}

export const addToWorkspace = (workspace, workflowId) => {
  return POST('/api/workspaces/workflow', { workspace, workflowId });
};

// Run test on specific block
export const runTestBlock = (workflowId, body, loadMore = false) => {
  let query = '';
  if (loadMore) {
    query = '?loadMore=true';
  }
  return POST(`/api/workflows/test/${workflowId}${query}`, body);
};

export const testCustomAction = (body) => {
  return POST(`/api/workflows/test/customAction`, body);
};

export const saveCustomAction = (body) => {
  return POST(`/api/custom-action`, body);
};

export const getAllCustomAction = () => {
  return GET(`/api/custom-action`);
};

export const deleteCustomAction = (id) => {
  return DELETE(`/api/custom-action/${id}`);
};

export const updateCustomAction = (id, body) => {
  return PUT(`/api/custom-action/${id}`, body);
};

// Run test on Trigger Blocks
export const runTestBlockTrigger = (body) => {
  return POST('/api/test-trigger', body);
};

// Duplicate workflow
export function duplicateWorkflow(workflowId) {
  return POST(`/api/workflows/duplicate/${workflowId}`);
}

export const uploadImage = (body) => {
  return POST('/api/util/image-upload', body);
};

export const getS3PresignedUrl = (body) => {
  return POST('/api/util/s3-upload', body);
};

export const getCsvS3PresignedUrl = (body) => {
  return POST('/api/util/s3-csv-upload', body);
};

export const getS3CSV = (body) => {
  return POST('/api/util/get-s3-csv', body);
};

export const copyS3CSV = (body) => {
  return POST('/api/util/copy-s3-csv', body);
};

export const deleteS3CSV = (body) => {
  return POST('/api/util/delete-s3-csv', body);
};

export const getActiveWorkflowCount = (forgeMode = false) => {
  return GET(`/api/workflows/active/count?forgeMode=${forgeMode}`);
};

export const getActiveWorkspaceWorkflowCount = (workspaceId) => {
  return GET(
    `/api/workflows/active/workspace/count?workspaceId=${workspaceId}`
  );
};

export const saveInfoToForgeWorkflow = (body) => {
  return POST(`/api/workflows/saveIntro/${body.id}`, body);
};

export const addWorkflowToIntegration = async (integrationId, body) => {
  return POST(`/api/embedded-integrations/${integrationId}/workflow`, body);
};

const preProcessWorkflow = (workflow) => {
  if (workflow.forgeEditableFields) {
    const forgeEditable = Object.entries(workflow.forgeEditableFields).reduce(
      (obj, [key, value]) => {
        obj[btoa(key)] = value;
        return obj;
      },
      {}
    );
    set(workflow, 'forgeEditableFields', forgeEditable);
    return workflow;
  } else {
    return workflow;
  }
};

const postProcessWorkflow = (workflow) => {
  if (workflow?.data?.forgeEditableFields) {
    const forgeEditable = Object.entries(
      workflow.data.forgeEditableFields
    ).reduce((obj, [key, value]) => {
      obj[atob(key)] = value;
      return obj;
    }, {});
    set(workflow, 'data.forgeEditableFields', forgeEditable);
    return workflow;
  } else {
    return workflow;
  }
};

// Track segment events when workflow is activated
export async function segmentTrackWorkflowActivation(
  params,
  activeWorkflowCount,
  email
) {
  if (!isUndefined(window.analytics)) {
    const stringifiedUserId = params.userId.toString();
    const emailMatch = email.match(
      /^.*runalloy.com*|^.*alloytestuser*|^.*alloylightninguser*|^.*justanothertestuser*|^.*ark.ph*|^.+test*/
    );

    if (emailMatch === null) {
      window.analytics.track('Workflow activated', {
        userId: stringifiedUserId,
        properties: {
          workflowName: params.name,
          workflowId: params._id,
        },
        context: {
          active: true,
        },
      });
      // window.analytics.identify(stringifiedUserId, {
      //   activeWorkflowCount: activeWorkflowCount + 1,
      // });
    }
  }
}

// Track segment events when workflow is created
export async function segmentTrackWorkflowCreation(
  params,
  workflowCount,
  email
) {
  if (!isUndefined(window.analytics) && params && email) {
    const stringifiedUserId = params.userId.toString();
    const emailMatch = email.match(
      /^.*runalloy.com*|^.*alloytestuser*|^.*alloylightninguser*|^.*justanothertestuser*|^.*ark.ph*|^.+test*/
    );

    if (emailMatch === null) {
      window.analytics.track('Workflow created', {
        userId: stringifiedUserId,
        properties: {
          workflowName: params.name,
          workflowId: params._id,
        },
        context: {
          active: true,
        },
      });
      window.analytics.identify(stringifiedUserId, {
        totalWorkflowCount: workflowCount + 1,
      });
    }
  }
}
