import React from 'react';
import classNames from 'classnames';
import styles from './index.less';
import { Input } from 'antd';
import { IconSearch } from '../Icons/actions';

export const AppSearch = ({
  id,
  placeholder,
  disabled,
  size,
  name,
  onChange,
  onSearch,
  value,
  isForge,
  isUapi,
  allowClear,
  suffix,
}) => {
  return (
    <Input.Search
      id={id}
      className={classNames(styles.inputSearch, {
        [styles.isForge]: isForge,
        [styles.isUapi]: isUapi,
      })}
      name={name}
      placeholder={placeholder}
      size={size ? size : 'large'}
      value={value}
      disabled={disabled}
      onChange={onChange}
      onSearch={onSearch}
      allowClear={allowClear}
      prefix={<IconSearch />}
      enterButton={false}
      suffix={suffix}
      autoComplete="off"
    />
  );
};
