import { Card, Col, Progress, Row, Tooltip } from 'antd';
import * as dayjs from 'dayjs';
import React from 'react';
import { isNumber } from 'lodash';
import { getId } from 'Utilities';
import { kFormatter } from '../../utils/utils';
import styles from './UsageAnalytics.less';

const planInfoHoverText = (plan) => {
  switch (plan) {
    case 'starter':
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>Up to 20 active workflows</li>
          <li>Up to 5,000 app actions per month</li>
          <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
          <li>Full access to marketplace</li>
        </ul>
      );
    case 'individual':
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>Up to 20 active workflows</li>
          <li>Up to 5,000 app actions per month</li>
          <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
          <li>Full access to marketplace</li>
        </ul>
      );

    case 'silver':
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>Unlimited active workflows</li>
          <li>Up to 30,000 app actions per month</li>
          <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
          <li>Full access to marketplace</li>
        </ul>
      );
    case 'gold':
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>Unlimited active workflows</li>
          <li>Up to 300,000 app actions per month</li>
          <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
          <li>Full access to marketplace</li>
          <li>Custom API call</li>
          <li>Workspaces</li>
          <li>Unlimited team members</li>
          <li>Personalized training</li>
        </ul>
      );
    case 'platinum':
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>Unlimited active workflows</li>
          <li>Unlimited app actions per month</li>
          <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
          <li>Full access to marketplace</li>
          <li>Custom API call</li>
          <li>Workspaces</li>
          <li>Unlimited team members</li>
          <li>Personalized training</li>
          <li>Early acess to new features</li>
        </ul>
      );
    case 'professional':
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>Unlimited active workflows</li>
          <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
          <li>Full access to marketplace</li>
          <li>Custom API call</li>
          <li>Early acess to new features</li>
        </ul>
      );
    case 'business':
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>Unlimited active workflows</li>
          <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
          <li>Full access to marketplace</li>
          <li>Custom API call</li>
          <li>Workspaces & Teams</li>
          <li>Early acess to new features</li>
        </ul>
      );
    case 'forge':
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>Unlimited Actions</li>
          <li>Unlimited Workflows</li>
          <li>Dedicated Customer Support</li>
        </ul>
      );
    case 'buyWithPrime':
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>20,000 Actions</li>
          <li>Unlimited Workflows</li>
        </ul>
      );

    case 'free':
    default:
      return (
        <ul>
          <li
            style={{
              listStyleType: 'none',
              marginLeft: '-20px',
              marginBottom: '5px',
            }}
            className={styles.hideBullet}
          >
            What is included:
          </li>
          <li>Up to 20 active workflows</li>
          <li>Up to 1,000 app actions per month</li>
          <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
          <li>Full access to marketplace</li>
        </ul>
      );
  }
};

export const UsageAnalytics = ({
  analytics,
  plans,
  userInfo,
  getPercentage,
  activeWorkflowCount,
  team,
  forgeSelected,
}) => {
  let numberOfWorkflowsLimit = '';
  let numberOfWorkflowExecutionsLimit = '';
  if (!analytics || forgeSelected) return <div />;
  const { plan, numberOfWorkflowRuns, blockRuns, legacyBilling } = analytics;

  let daysTilTrialEnds = 0;

  if (analytics.renewalDate) {
    const today = dayjs();
    const renewalDate = dayjs(analytics.renewalDate);

    daysTilTrialEnds = renewalDate.diff(today, 'days') + 1;
  }

  if (plans) {
    numberOfWorkflowsLimit = plans.workflowsLimit;
    numberOfWorkflowExecutionsLimit = plans.executionsLimit;
  }

  const usageMessage = legacyBilling ? 'Workflow runs' : 'App actions';
  const usageMetric = legacyBilling ? numberOfWorkflowRuns : blockRuns;

  const getColor = (percentage) => {
    let color = '#7DD96E';

    if (percentage >= 50) {
      color = '#FFBB56';
    }

    if (percentage >= 100) {
      color = '#F04242';
    }

    return color;
  };

  return (
    <div className={styles.analyticsWrap}>
      <Card className={styles.wrap} bordered={false}>
        {team?._id && team?.active && (
          <div className={styles.team} title={team?.name}>
            {team && team.name}
          </div>
        )}
        <div className={styles.planType} id={getId(`planType`)}>
          <div>{plan === 'forge' ? `Embedded Plan` : `Alloy Flow`}</div>
          {/*<Tooltip
            placement="right"
            title={planInfoHoverText(plan)}
            overlayClassName={styles.planInfoHoverOverlay}
          >
            <div className={styles.planInfo}>
              <img
                src="https://cdn.runalloy.com/graphics/svg/icon-info-white.svg"
                alt="infoIcon"
              />
            </div>
        </Tooltip> */}
          {(plan === 'starter' || plan === 'individual') &&
            userInfo.isTrial && (
              <div className={styles.planValidity}>
                {daysTilTrialEnds > 0
                  ? `${daysTilTrialEnds} days trial`
                  : 'Trial Ended'}
              </div>
            )}
        </div>

        {/* Workflow */}
        {isNumber(numberOfWorkflowsLimit) ? (
          <>
            <Row justify="space-between" align="top">
              <Col sm={16} className={styles.title}>
                Active workflows
              </Col>
              <Col sm={8} className={styles.value}>
                {kFormatter(activeWorkflowCount || 0)} /{' '}
                {kFormatter(numberOfWorkflowsLimit)}
              </Col>
            </Row>
            <Progress
              id={getId(`workflowsUsage`)}
              showInfo={false}
              strokeColor={getColor(
                getPercentage(
                  (activeWorkflowCount || 0) / numberOfWorkflowsLimit
                )
              )}
              percent={getPercentage(
                (activeWorkflowCount || 0) / numberOfWorkflowsLimit
              )}
              style={{ marginBottom: 16 }}
            />
          </>
        ) : (
          <Row
            justify="space-between"
            align="top"
            className={styles.noWorkflowLimit}
          >
            <Col sm={16} className={styles.title}>
              Active workflows
            </Col>
            <Col sm={8} className={styles.value}>
              {kFormatter(activeWorkflowCount || 0)}
            </Col>
          </Row>
        )}

        {/* Workflow Runs */}
        {isNumber(numberOfWorkflowExecutionsLimit) ? (
          <>
            <Row justify="space-between" align="top">
              <Col sm={12} className={styles.title}>
                {usageMessage}
                <Tooltip
                  placement="right"
                  title={
                    <div>
                      An app action is counted every time a workflow
                      successfully moves data or takes action for you.{' '}
                      <a
                        href="https://docs.runalloy.com/docs/calculating-app-actions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more↗
                      </a>
                    </div>
                  }
                  overlayClassName={styles.appActionsHoverOverlay}
                >
                  <div className={styles.planInfo}>
                    <img
                      src="https://cdn.runalloy.com/graphics/svg/icon-info-white.svg"
                      alt="infoIcon"
                    />
                  </div>
                </Tooltip>
              </Col>
              <Col sm={12} className={styles.value}>
                {kFormatter(usageMetric)} /{' '}
                {kFormatter(numberOfWorkflowExecutionsLimit)}
              </Col>
            </Row>
            <Progress
              id={getId(`executionsUsage`)}
              showInfo={false}
              strokeColor={getColor(
                getPercentage(usageMetric / numberOfWorkflowExecutionsLimit)
              )}
              percent={getPercentage(
                usageMetric / numberOfWorkflowExecutionsLimit
              )}
            />
          </>
        ) : (
          <Row justify="space-between" align="top">
            <Col sm={12} className={styles.title}>
              {usageMessage}
              <Tooltip
                placement="right"
                title={
                  <div>
                    An app action is counted every time a workflow successfully
                    moves data or takes action for you.{' '}
                    <a
                      href="https://docs.runalloy.com/docs/calculating-app-actions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more↗
                    </a>
                  </div>
                }
                overlayClassName={styles.appActionsHoverOverlay}
              >
                <div className={styles.planInfo}>
                  <img
                    src="https://cdn.runalloy.com/graphics/svg/icon-info-white.svg"
                    alt="infoIcon"
                  />
                </div>
              </Tooltip>
            </Col>
            <Col sm={12} className={styles.value}>
              {numberOfWorkflowExecutionsLimit === 'unlimited'
                ? `${kFormatter(usageMetric)}`
                : `${kFormatter(usageMetric)} / ${kFormatter(
                    numberOfWorkflowExecutionsLimit
                  )}`}
            </Col>
          </Row>
        )}

        <div className={styles.info}>
          Your plan renews on{' '}
          {dayjs(
            analytics.changePlanData
              ? analytics.changePlanData.changeOn
              : analytics.renewalDate
          ).format('MMM D, YYYY')}
        </div>
      </Card>
    </div>
  );
};
