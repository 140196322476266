const codeMessage = {
  200: 'The server successfully returned the requested data',
  201: 'Modified data successfully',
  202: 'An async background task has been queued',
  204: 'Data deleted successfully',
  400: 'There was an error in the request, and the server did not create or modify data.',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found.',
  406: 'Not Acceptable',
  410: 'The requested resource is permanently deleted and is no longer available.',
  411: 'Some of block credential is not valid',
  422: 'When creating an object, a validation error occurred.',
  500: 'A server error occurred. Please check the server.',
  502: 'Bad Gateway.',
  503: 'Service unavailable',
  504: 'Gateway timed out.',
};
function checkStatus(response, options) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const errortext = codeMessage[response.status] || response.statusText;
  const error = new Error(errortext);
  error.name = response.status;
  error.response = '';
  error.statusCode = response.status;

  if (response.status === 401) {
    localStorage.removeItem('workflow-refreshToken');
    localStorage.removeItem('workflow-accessToken');
    localStorage.removeItem('workflow-userInfo');
    localStorage.removeItem('workflow-tempAccessToken');
    localStorage.removeItem('workflow-tempRefreshToken');

    if (!response.url.includes('login')) {
      window.location.href = '/login';
    }
    return response;
  }

  return response
    .json()
    .then((body) => {
      if (options.hideErrorNotifications) {
        error.hideErrorNotifications = true;
      }
      error.response = body;

      if (body.message) {
        error.message = body.message;
      }

      // throw error;
      return Promise.reject(error);
    })
    .catch(() => {
      if (options.hideErrorNotifications) {
        error.hideErrorNotifications = true;
      }
      return Promise.reject(error);
    });
}

function isDefined(obj) {
  return typeof obj !== 'undefined' && obj !== null;
}
function encodeQueryParams(params) {
  return Object.keys(params).reduce((result, key, index) => {
    const value = params[key];
    let returnVal = result;
    if (index) returnVal += '&';
    if (Array.isArray(value)) {
      value.forEach((el, i) => {
        if (!isDefined(el)) return;
        returnVal +=
          (i ? '&' : '') + key + '=' + encodeURIComponent(el.toString());
      });
      return returnVal;
    } else {
      return (
        returnVal +
        (isDefined(value)
          ? key +
            '=' +
            encodeURIComponent(
              typeof value === 'object'
                ? JSON.stringify(value)
                : value.toString()
            )
          : '')
      );
    }
  }, '');
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options) {
  const accessToken = localStorage.getItem('workflow-accessToken');
  const refreshToken = localStorage.getItem('workflow-refreshToken');
  const tempAccessToken = localStorage.getItem('workflow-tempAccessToken');
  const tempRefreshToken = localStorage.getItem('workflow-tempRefreshToken');
  const defaultOptions = {
    credentials: 'include',
  };
  const newOptions = { ...defaultOptions, ...options };
  let newUrl = url;
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE' ||
    newOptions.method === 'PATCH'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }
  if (newOptions.method === 'GET' && newOptions.body) {
    newUrl = `${url}?${encodeQueryParams(newOptions.body)}`;
    delete newOptions.body;
  }

  if (refreshToken && accessToken) {
    newOptions.headers = Object.assign({}, newOptions.headers, {
      Authorization: accessToken,
      refreshToken,
    });
  } else if (tempAccessToken) {
    newOptions.headers = Object.assign({}, newOptions.headers, {
      Authorization: tempAccessToken,
      refreshToken: tempRefreshToken,
    });
  }

  return fetch(newUrl, newOptions)
    .then((response) => checkStatus(response, newOptions))
    .then((response) => {
      if (response.status === 204) {
        return response.text();
      }
      return response.json();
    });
}
