import React, { createElement } from 'react';
import pathToRegexp from 'path-to-regexp';
import Loadable from 'react-loadable';
import { getMenuData } from './menu';

// wrapper of dynamic
const dynamicWrapper = (component) => {
  if (component.toString().indexOf('.then(') < 0) {
    return (props) => {
      return createElement(component().default, {
        ...props,
        // routerData: getRouterDataCache(app),
      });
    };
  }

  return Loadable({
    loader: () => {
      return component().then((raw) => {
        const Component = raw.default || raw;
        return (props) =>
          createElement(Component, {
            ...props,
          });
      });
    },
    loading: () => {
      return <div className="loading-screen" />;
    },
  });
};

function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach((item) => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });
  return keys;
}

function findMenuKey(menuData, path) {
  const menuKey = Object.keys(menuData).find((key) =>
    pathToRegexp(path).test(key)
  );
  if (menuKey == null) {
    if (path === '/') {
      return null;
    }
    const lastIdx = path.lastIndexOf('/');
    if (lastIdx < 0) {
      return null;
    }
    if (lastIdx === 0) {
      return findMenuKey(menuData, '/');
    }
    // If there isn't one, use the previous configuration
    return findMenuKey(menuData, path.substr(0, lastIdx));
  }
  return menuKey;
}

export const getRouterData = () => {
  // Files starting with "/" load BasicLayout, BasicLayout contains SideMenu
  // Files starting with "/user" load UserLayout, UserLayout does not contain SideMenu

  const BasicLayout = dynamicWrapper(() => import('../layouts/BasicLayout'));
  const UserLayout = dynamicWrapper(() => import('../layouts/UserLayout'));
  const EmptyLayout = dynamicWrapper(() => import('../layouts/EmptyLayout'));

  const routerConfig = {
    '/': {
      component: dynamicWrapper(() => import('../containers/unified/Login')),
      exact: true,
    },
    '/teams/login': {
      component: dynamicWrapper(() => import('../routes/SignUp/Login')),
      exact: true,
    },
    '/teams/signup': {
      component: dynamicWrapper(() => import('../routes/SignUp/SignUp')),
      exact: true,
    },
    '/bwpLogin': {
      component: dynamicWrapper(() => import('../routes/Bwp/BwpRedirect')),
      layout: BasicLayout,
    },

    '/dashboard': {
      component: dynamicWrapper(() => import('../routes/Dashboard/Dashboard')),
      layout: BasicLayout,
    },

    '/embedded': {
      component: dynamicWrapper(() => import('../routes/Forge/index')),
      exact: true,
      layout: BasicLayout,
    },

    '/embedded/integrations/:block': {
      component: dynamicWrapper(() => import('../routes/Forge/Integration')),
      exact: true,
      layout: BasicLayout,
    },

    '/embedded-workflows': {
      component: dynamicWrapper(() => import('../routes/EmbeddedWorkflows')),
      exact: true,
      layout: BasicLayout,
    },

    '/embedded-settings': {
      component: dynamicWrapper(() => import('../routes/EmbeddedSettings')),
      exact: true,
      layout: BasicLayout,
    },

    '/embedded-modal-preview': {
      component: dynamicWrapper(() =>
        import('../routes/Forge/Modals/ModalPreview')
      ),
      exact: true,
      layout: EmptyLayout,
    },

    '/marketplace': {
      component: dynamicWrapper(() =>
        import('../routes/Marketplace/Marketplace/Marketplace')
      ),
      exact: true,
      layout: BasicLayout,
    },

    '/marketplace/published': {
      component: dynamicWrapper(() =>
        import('../routes/Marketplace/Marketplace/Marketplace')
      ),
      exact: true,
      layout: BasicLayout,
    },

    '/marketplace/detail/:id': {
      component: dynamicWrapper(() => import('../routes/Marketplace/Detail')),
      exact: true,
      layout: BasicLayout,
    },

    '/marketplace/app/:appName': {
      component: dynamicWrapper(() => import('../routes/Marketplace/App')),
      exact: true,
      layout: BasicLayout,
    },

    '/marketplace/collection/:id': {
      component: dynamicWrapper(() =>
        import('../routes/Marketplace/Collection')
      ),
      exact: true,
      layout: BasicLayout,
    },

    '/marketplace/my-recipes': {
      component: dynamicWrapper(() =>
        import('../routes/Marketplace/MyRecipes')
      ),
      exact: true,
      layout: BasicLayout,
    },

    '/analytics': {
      component: dynamicWrapper(() => import('../routes/Analytics/Analytics')),
      exact: true,
      layout: BasicLayout,
    },

    // Added this as hubspot CRM is redirecting emails to /account/billings
    '/account/billing': {
      redirect: '/account/settings#billing',
    },

    '/account/settings': {
      component: dynamicWrapper(() => import('../routes/Settings')),
      exact: true,
      layout: BasicLayout,
    },
    '/account/refer': {
      component: dynamicWrapper(() => import('../routes/Settings/Refer/Refer')),
      exact: true,
      layout: BasicLayout,
    },

    '/account/workspace-settings': {
      component: dynamicWrapper(() =>
        import('../routes/Settings/WorkspaceSettings/WorkspaceSettings')
      ),
      exact: true,
      layout: BasicLayout,
    },
    '/account/workspace-settings/create-team': {
      component: dynamicWrapper(() =>
        import('../routes/Settings/WorkspaceSettings/CreateTeam')
      ),
      exact: true,
      layout: BasicLayout,
    },
    // '/account/forge-settings': {
    //   component: dynamicWrapper(() =>
    //     import('../routes/ForgeSettings/IntegrationSettings/IntegrationSettings')
    //   ),
    // },
    '/settings/pricing': {
      redirect: '/account/billing',
    },

    '/integrations': {
      component: dynamicWrapper(() =>
        import('../routes/IntegrationBuilder/Integrations')
      ),
      layout: BasicLayout,
    },

    '/installations': {
      component: dynamicWrapper(() =>
        import('../routes/Installations/Installations')
      ),
      layout: BasicLayout,
    },

    '/installation': {
      component: dynamicWrapper(() =>
        import('../routes/Installations/Installation')
      ),
      layout: BasicLayout,
    },

    '/executions': {
      component: dynamicWrapper(() =>
        import('../routes/Executions/Executions')
      ),
      layout: UserLayout,
    },

    '/template-create': {
      component: dynamicWrapper(() =>
        import('../containers/recipe/RecipePublisher')
      ),
      layout: UserLayout,
    },

    '/template-setup': {
      component: dynamicWrapper(() =>
        import('../containers/recipe/RecipeImporter')
      ),
      layout: UserLayout,
    },

    '/forge-install': {
      component: dynamicWrapper(
        // () => import('../containers/forge/ForgeInstaller')
        () => import('../containers/embedded/EmbeddedModal/Modal')
      ),
      layout: UserLayout,
    },
    '/embedded-install': {
      component: dynamicWrapper(() =>
        import('../containers/forge/EmbeddedInstaller')
      ),
      layout: UserLayout,
    },
    '/authenticate-app': {
      component: dynamicWrapper(() =>
        import('../containers/universal/AlloyModal/Preview')
      ),
      layout: UserLayout,
    },
    '/authenticate-app-legacy': {
      component: dynamicWrapper(() =>
        import('../containers/universal/AuthApp/AuthApp')
      ),
      layout: UserLayout,
    },
    '/template-presetup': {
      component: dynamicWrapper(() => import('../routes/Marketplace/Track')),
      layout: UserLayout,
    },

    '/shopify-sync': {
      component: dynamicWrapper(() =>
        import('../routes/SignUp/Shopify/ShopifySync')
      ),
      layout: UserLayout,
    },

    '/workflow/build': {
      component: dynamicWrapper(() =>
        import('../routes/WorkflowEditor/WorkflowEditor')
      ),
      layout: UserLayout,
    },

    '/workflow/oauth': {
      component: dynamicWrapper(() => import('../routes/WorkflowEditor/Oauth')),
      layout: UserLayout,
    },

    '/workflow/errors': {
      component: dynamicWrapper(() =>
        import('../routes/WorkflowErrors/WorkflowErrors')
      ),
      layout: UserLayout,
    },

    '/postmessage': {
      component: dynamicWrapper(() =>
        import('../routes/PostMessage/PostMessage')
      ),
      layout: UserLayout,
    },
    '/shopify/billing': {
      component: dynamicWrapper(() => import('../routes/Shopify/Billing')),
      layout: UserLayout,
    },

    '/integration/builder': {
      component: dynamicWrapper(() =>
        import('../routes/IntegrationBuilder/integration/IntegrationLayout')
      ),
      layout: UserLayout,
    },

    '/shopify-login': {
      component: dynamicWrapper(() => import('../routes/SignUp/ShopifyLogin')),
      layout: UserLayout,
    },

    '/sso-login': {
      component: dynamicWrapper(() => import('../routes/SignUp/SsoLogin')),
      layout: UserLayout,
    },

    '/mfa': {
      component: dynamicWrapper(() => import('../routes/SignUp/MFA')),
      layout: UserLayout,
    },

    '/reset': {
      component: dynamicWrapper(() => import('../routes/SignUp/Reset')),
      layout: UserLayout,
    },

    '/change-password': {
      component: dynamicWrapper(() =>
        import('../routes/SignUp/ChangePassword')
      ),
      layout: UserLayout,
    },
    '/finish-setup': {
      component: dynamicWrapper(() =>
        import('../routes/SignUp/ChangePassword')
      ),
      layout: UserLayout,
    },

    '/404': {
      component: dynamicWrapper(() => import('../routes/NoPage/NoPage')),
      layout: UserLayout,
    },

    '/bwp-invalid': {
      component: dynamicWrapper(() => import('../routes/NoPage/BwpInvalid')),
      layout: EmptyLayout,
    },

    '/clear': {
      component: dynamicWrapper(() => import('../routes/Clear/Clear')),
      layout: EmptyLayout,
    },

    '/link-expired': {
      component: dynamicWrapper(() => import('../routes/NoPage/LinkExpired')),
      layout: EmptyLayout,
    },

    '/team-invitation/:id': {
      component: dynamicWrapper(() => import('../routes/Workspace/Invitation')),
      exact: true,
      layout: UserLayout,
    },

    '/reauthed': {
      component: dynamicWrapper(() => import('../routes/Reauth/Close')),
      layout: UserLayout,
    },

    '/magento': {
      component: dynamicWrapper(() => import('../routes/Reauth/Magento')),
      layout: UserLayout,
    },

    '/partner-hub': {
      component: dynamicWrapper(() =>
        import('../routes/PartnerHub/PartnerHub')
      ),
      exact: true,
      layout: BasicLayout,
    },
    '/enterprise-template-setup': {
      component: dynamicWrapper(() =>
        import('../containers/enterprise/Enterprise')
      ),
      layout: UserLayout,
    },
    '/bwp-update': {
      component: dynamicWrapper(() => import('../routes/Bwp/SettingsRedirect')),
      layout: UserLayout,
    },
    '/shopify-dashboard': {
      component: dynamicWrapper(() =>
        import('../routes/Dashboard/ShopifyBookModal')
      ),
      layout: EmptyLayout,
    },

    '/embedded-unified-api': {
      component: dynamicWrapper(() => import('../routes/UnifiedAPI')),
      exact: true,
      layout: BasicLayout,
    },
    '/login': {
      component: dynamicWrapper(() => import('../containers/unified/Login')),
      exact: true,
    },
    '/signup': {
      link: 'https://runalloy.com/get-started/',
      exact: true,
    },
  };
  // Get name from ./menu.js or just set it in the router data.
  const menuData = getFlatMenuData(getMenuData());

  // Route configuration data
  // eg. {name,authority ...routerConfig }
  const routerData = {};
  // The route matches the menu
  Object.keys(routerConfig).forEach((path) => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    let menuKey = Object.keys(menuData).find((key) =>
      pathToRegexp(path).test(`${key}`)
    );
    const inherited = menuKey == null;
    if (menuKey == null) {
      menuKey = findMenuKey(menuData, path);
    }
    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = routerConfig[path];
    // If you need to configure complex parameter routing,
    // https://github.com/ant-design/ant-design-pro-site/blob/master/docs/router-and-nav.md#%E5%B8%A6%E5%8F%82%E6%95%B0%E7%9A%84%E8%B7%AF%E7%94%B1%E8%8F%9C%E5%8D%95
    // eg . /list/:type/user/info/:id
    router = {
      ...router,
      name: router.name || menuItem.name,
      hideInBreadcrumb: router.hideInBreadcrumb || menuItem.hideInBreadcrumb,
      inherited,
    };
    routerData[path] = router;
  });
  return routerData;
};
