import { observable, action, computed, toJS } from 'mobx';
import { set, debounce } from 'lodash';
import {
  newCommunity,
  marketplaceSearch,
  getTemplateSummary,
  getCollections,
  userPosts,
  useWorkflow,
  useRecipeWorkflow,
  saveForgeWorkflow,
  getRecipe,
  getRecipes,
  getForgeRecipes,
  segmentTrackRecipeInstallationStarted,
  segmentTrackRecipeInstallationFinished,
  deleteRecipe,
  recipeTitleTypeahead,
} from 'Services/marketplace';
import * as BlockHelpers from 'Utilities/workflow-classes/BlockHelpers';
import { getBlockCredentialIssues } from 'Utilities/block-helpers';
import { showNotification } from 'Components/Notifications/Notification';
import {
  updateWorkflow,
  segmentTrackWorkflowCreation,
} from 'Services/workflowApi';
import {
  getRecommendedRecipe,
  addRecipeRequest,
} from '../services/marketplace';
import { createWorkflowVersion } from 'Services/workflowVersion';

export class MarketplaceStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @observable
  collectionsLoading = true;

  @observable
  appIntegrationsLoading = true;

  @observable
  searchLoading = false;

  @observable
  featuredCollections = [];

  @observable
  searchResults = [];

  @observable
  currentPage = 1;

  @observable
  resultsPerPage = 12;

  @observable
  detail = {};

  @observable
  newPost = {};

  @observable
  userPosts = [];

  @observable
  availableBlocks = [];

  @observable
  template = {};

  @observable
  updatedRecipeLoader = true;

  @observable
  recommendedRecipes = [];

  @observable
  activeRecipeTags = [];

  templateImported = false;

  @observable
  appList = [];

  @observable
  allApps = [];

  @observable
  categoryList = [];

  @observable
  allTags = [];

  @observable
  triggerApps = [];

  @observable
  searchValue = '';

  @observable
  searchString = '';

  @observable
  selectedBlock = {};

  @observable
  appsSelected = [];

  @observable
  categorySelected = [];

  @observable
  searchBlocks = [];

  @observable
  forgeTemplates = [];

  @observable
  parentIds = [];

  @computed
  get authenticatedApps() {
    return this.allApps.filter((app) => app.hasCreds);
  }

  @action
  initialize = ({ currentPage, searchString }) => {
    this.collectionsLoading = true;
    this.appIntegrationsLoading = true;
    this.currentPage = currentPage || 1;
    this.resultsPerPage = 12;
    this.searchString = searchString || '';

    getCollections()
      .then(
        action((response) => {
          this.collectionsLoading = false;
          this.featuredCollections = response.collections;
        })
      )
      .catch(
        action(() => {
          this.collectionsLoading = false;
        })
      );

    getTemplateSummary()
      .then(
        action((response) => {
          this.appList = response.allBlocks;
          this.allApps = response.allBlocks;
          this.categoryList = response.tags;
          this.allTags = response.tags;
          this.triggerApps = response.triggerBlocks;
          this.appIntegrationsLoading = false;
        })
      )
      .catch(
        action(() => {
          this.appIntegrationsLoading = false;
        })
      );

    this.searchRecipes();
    if (this.searchString.length) {
      this.generateSearchDropdown();
    }
  };

  @action
  onPageChange = (currentPage, resultsPerPage) => {
    this.currentPage = currentPage;
    this.resultsPerPage = resultsPerPage;
    const skip = (currentPage - 1) * resultsPerPage;
    this.globalStore.routingStore.push(
      `/marketplace?page=${currentPage}&limit=${resultsPerPage}&skip=${skip}`
    );
    this.searchRecipes();
  };

  @action
  handleOnSearch = (searchValue, type) => {
    const { allApps, allTags } = this;
    if (type === 'app') {
      // User searched for an app inside the filter box
      const filteredApps = allApps.filter((app) => {
        return (
          app.displayName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        );
      });

      this.appList = filteredApps;
      this.searchValue = searchValue;
    } else {
      // User searched for a category inside the filter box
      const filteredCategories = allTags.filter((category) => {
        return category.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
      });

      this.categoryList = filteredCategories;
      this.searchValue = searchValue;
    }
  };

  @action
  onAppCheckboxChange = (checkedValues) => {
    this.appsSelected = checkedValues;
    this.currentPage = 1;
    this.searchRecipes();
  };

  @action
  onCategoryCheckboxChange = (checkedValues) => {
    this.categorySelected = checkedValues;
    this.currentPage = 1;
    this.searchRecipes();
  };

  @action
  clearSearchResults = () => {
    this.searchResults = [];
  };

  @action
  clearFilters = () => {
    this.appsSelected = [];
    this.categorySelected = [];
    this.searchString = '';
    this.searchLoading = false;
    this.searchViewVisible = false;
    this.currentPage = 1;

    this.searchRecipes();
    this.globalStore.routingStore.push('/marketplace?page=1');
  };

  @action
  clearSelectedBlock = () => {
    this.selectedBlock = {};

    this.searchRecipes();
    this.globalStore.routingStore.push('/marketplace?page=1');
  };

  @action
  onSearchChange = (e) => {
    if (e.target.value === '') {
      this.searchString = '';
      this.searchLoading = false;
      this.searchViewVisible = false;
      this.selectedBlock = {};
      this.currentPage = 1;

      this.searchRecipes();
      this.globalStore.routingStore.push('/marketplace?page=1');
    } else {
      this.searchString = e.target.value;
      this.generateSearchDropdown();
    }
  };

  generateSearchDropdown = debounce(
    action(() => {
      const { globalStore } = this;
      const foundResult = [];
      const title = this.searchString.toLowerCase();

      globalStore.blockTypeStore.blockTypes.forEach((blockItem) => {
        if (foundResult.length > 5) return;
        const operationProps = blockItem?.properties?.filter(
          (properties) =>
            properties.displayName === 'Operation' ||
            properties.displayName === 'operation'
        );
        if (operationProps.length) {
          operationProps.forEach((prop) => {
            if (foundResult.length > 5) return;
            prop.options.forEach((optionItem) => {
              if (optionItem.name.toLowerCase().includes(title)) {
                foundResult.push({
                  name: optionItem.name,
                  operation: optionItem.value,
                  type: blockItem.name,
                  icon: blockItem.icon,
                  isRecipe: false,
                });
              }
            });
          });
        }
      });

      recipeTitleTypeahead(title).then(
        action((response) => {
          const recipeBlocks = response.data.map((row) => ({
            recipeId: row._id,
            name: row.title,
            isRecipe: true,
          }));
          this.searchBlocks = [...foundResult, ...recipeBlocks];
        })
      );
    }),
    500
  );

  @action
  onSearchEnter = (searchSelection) => {
    this.currentPage = 1;
    this.searchViewVisible = true;

    if (searchSelection.isRecipe) {
      this.searchString = searchSelection.name;
      this.selectedBlock = {};
    } else {
      this.selectedBlock = searchSelection;
      this.searchString = '';
    }

    this.searchRecipes().then(
      action(() => {
        this.globalStore.routingStore.push(
          `/marketplace?search=${this.searchString}&page=1`
        );
      })
    );
  };

  @action
  searchRecipes = () => {
    this.searchLoading = true;

    const { triggerApps } = this;
    const selectedTriggerApps = [];

    this.appsSelected.forEach((app) => {
      if (triggerApps.indexOf(app + 'Trigger') > -1) {
        // search for recipes with trigger apps as well
        selectedTriggerApps.push(app + 'Trigger');
      }
    });

    if (this.selectedBlock && this.selectedBlock.type) {
      // just shove it in here to keep things easy
      selectedTriggerApps.push(this.selectedBlock.type);
    }

    const qsData = {
      apps: this.appsSelected.concat(selectedTriggerApps).join(','),
      category: this.categorySelected.join(','),
      page: this.currentPage,
      limit: this.resultsPerPage,
      ...(this.searchString ? { q: this.searchString } : {}),
      ...(this.selectedBlock && this.selectedBlock.type
        ? {
            blockOperation: this.selectedBlock.operation,
          }
        : {}),
    };

    if (localStorage.getItem('alloy-bigcLoad')) {
      set(qsData, 'hide', 1);
    }

    return marketplaceSearch(qsData)
      .then(
        action(({ count, templates }) => {
          this.recipeCount = count;
          this.searchLoading = false;
          this.searchResults = templates;
        })
      )
      .catch(
        action((error) => {
          if (error.statusCode === 406) {
            showNotification('error', 'Not allow to access marketplace ');
          }

          this.searchLoading = false;
          this.appIntegrationsLoading = false;
          this.searchResults = [];
        })
      );
  };

  @action
  getRecommendedRecipe = async (payload) => {
    try {
      const response = await getRecommendedRecipe(payload);
      if (response.success) {
        // Get all active recipe tags
        const tags = new Set();
        response.templates.forEach((recipe) => {
          recipe.tags.forEach((tag) => {
            tags.add(tag);
          });
        });
        const filteredTags = [...tags];
        this.activeRecipeTags = filteredTags.sort();

        this.recommendedRecipes = response.templates;
        this.updatedRecipeLoader = false;
      }
    } catch (e) {
      // TODO bugsnag
      this.updatedRecipeLoader = false;
      showNotification(
        'error',
        "Something went wrong, can't connect to server!"
      );
    }
  };

  @action
  addRecipeRequest = async (payload) => {
    try {
      const response = await addRecipeRequest(payload);
      if (response.success) {
        showNotification('success', 'Recipe request send successfully');
      }
    } catch (e) {
      // TODO bugsnag
      this.updatdRecipeLoader = false;
      showNotification(
        'error',
        "Something went wrong, can't connect to server!"
      );
    }
  };

  @action
  getRecipe = async (templateId, forgeMode, enterpriseCredential) => {
    try {
      const response = await getRecipe(
        templateId,
        forgeMode,
        enterpriseCredential
      );
      if (response.success) {
        this.detail = response.post;
        return response.post;
      }
    } catch (e) {
      // TODO bugsnag
      showNotification(
        'error',
        "Something went wrong, can't connect to server!"
      );
    }
  };

  @action
  getRecipes = async (
    collectionId,
    enterpriseCredential,
    recipeIds,
    selectedIntegrationId
  ) => {
    try {
      const response = await getRecipes(
        collectionId,
        enterpriseCredential,
        recipeIds,
        selectedIntegrationId
      );
      if (response.success) {
        this.collectionTemplates = response.recipes;
        return {
          recipes: response.recipes,
          integrationId: response?.integrationId,
          integration: response?.integrationData,
        };
      }
    } catch (e) {
      showNotification(
        'error',
        "Something went wrong, can't connect to server!"
      );
    }
  };

  @action
  getForgeRecipes = async (payload) => {
    try {
      const response = await getForgeRecipes(payload);
      if (response.success) {
        this.forgeTemplates = response.posts;
        this.parentIds = response.parentIds;

        return response.posts;
      }
    } catch (e) {
      showNotification(
        'error',
        "Something went wrong, can't connect to server!"
      );
    }
  };

  @action
  selectCommunity = (community) => {
    this.detail = community;
  };

  @action
  setNewPost = (newPost) => {
    this.newPost = newPost;
  };

  newCommunityPost = (payload) => {
    return newCommunity(payload);
  };

  @action
  getUserPosts = async (payload) => {
    try {
      const response = await userPosts(payload);
      if (response.success) {
        this.userPosts = response.posts;
      }
    } catch (e) {
      // TODO bugsnag
      showNotification(
        'error',
        "Something went wrong, can't connect to server!"
      );
    }
  };

  @action
  useWorkflow = async (recipeId, recipeTitle) => {
    try {
      const { _id: userId, email } = this.globalStore.userStore.userInfo;
      segmentTrackRecipeInstallationStarted(
        recipeId,
        recipeTitle,
        userId,
        email
      );
    } catch (e) {}
  };

  @action
  saveForgeWorkflow = async (
    postId,
    credentialsData,
    formData,
    preview,
    fixedCollectionKeys,
    forgeMode,
    draftId,
    forgeWorkflows,
    isEmbeddedLinkModal = false,
    token,
    versionId
  ) => {
    try {
      const { _id: userId, email } = this.globalStore.userStore.userInfo;
      const { workflowCount } = this.globalStore.workflowStore;
      const response = await saveForgeWorkflow({
        ...(forgeMode ? { forgeId: postId } : { postId }),
        userId,
        credentialsData,
        formData,
        preview,
        fixedCollectionKeys,
        draftId,
        forgeWorkflows,
        isEmbeddedLinkModal,
        token,
        versionId,
      });
      if (
        response.success &&
        response.newWorkflows &&
        response.newWorkflows.length
      ) {
        segmentTrackRecipeInstallationFinished(
          userId,
          postId,
          response.newWorkflows[0],
          email
        );
        segmentTrackWorkflowCreation(
          response.newWorkflows[0],
          workflowCount,
          email
        );
        /* This is causing valid workflows to deactivate themselves for some reason
        response.newWorkflows.forEach(async (workflow) => {
          const updatedBlocks = await this.updateWorkflowIssues(workflow);
          workflow.blocks = updatedBlocks;
          await updateWorkflow(workflow._id, workflow);
        });
        */
        return response;
      }
    } catch (e) {
      this.globalStore.recipeImportStore.setForgeError(true);
    }
  };

  @action
  saveWorkflow = async (
    postId,
    credentialsData,
    formData,
    preview,
    fixedCollectionKeys,
    draftIds,
    collectionTemplateIds,
    workflowSettings,
    forgeWorkflowIds,
    fromMarketplace = false
  ) => {
    try {
      const { _id: userId } = this.globalStore.userStore.userInfo;
      const useWorkflowFn = fromMarketplace ? useRecipeWorkflow : useWorkflow;
      const response = await useWorkflowFn({
        ...(collectionTemplateIds && collectionTemplateIds.length
          ? { collectionTemplateIds }
          : { postId }),
        userId,
        credentialsData,
        formData,
        preview,
        fixedCollectionKeys,
        draftIds,
        workflowSettings,
        forgeWorkflowIds,
      });

      if (
        response.success &&
        response.newWorkflows &&
        response.newWorkflows.length > 0
      ) {
        return response;
      }
    } catch (e) {
      // TODO bugsnag
      showNotification(
        'error',
        'Unable to use this workflow, please contact support'
      );
    }
  };

  @action
  deleteRecipe = (recipeId) => {
    return deleteRecipe(recipeId).then(
      action((res) => {
        if (res) {
          showNotification('success', 'Workflow recipe deleted successfully!');
        }
      })
    );
  };

  @action
  updateWorkflowIssues = async (workflow) => {
    const updatedWorkflow = workflow;
    if (
      updatedWorkflow &&
      Object.keys(updatedWorkflow).includes('blocks') &&
      updatedWorkflow.blocks.length
    ) {
      return Promise.all(
        updatedWorkflow.blocks.map((block) => this.updateIssue(block))
      );
    }
  };

  updateIssue = async (blockValues) => {
    const { credentialTypes } = this.globalStore.credentialStore;
    const blockType = this.globalStore.blockTypeStore.blockTypes.find(
      (block) => block.name === blockValues.type
    );
    const hasCredentials = blockType.hasOwnProperty('credentials')
      ? blockType.credentials[0].name
      : null;
    const credentials = hasCredentials
      ? await this.globalStore.credentialStore.getBlockCredentials(
          hasCredentials
        )
      : null;
    if (!blockType) return;
    const fullBlockIssues = BlockHelpers.getBlockParametersIssues(
      blockType.properties,
      blockValues
    );

    let newIssues = null;

    if (
      fullBlockIssues !== null &&
      blockType &&
      blockType.hasOwnProperty('credentials') &&
      blockType.credentials.length &&
      blockType.credentials[0].required &&
      (!this.globalStore.blockEditStore.oneStepBlocks.includes(
        blockType.name
      ) ||
        (blockType.group && blockType.group.includes('transform')))
    ) {
      if (
        blockValues &&
        blockValues.hasOwnProperty('credentials') &&
        Object.keys(blockValues.credentials).length
      ) {
        newIssues = fullBlockIssues.parameters;
      } else {
        const filteredBlockIssues = Object.keys(fullBlockIssues.parameters)
          .filter((key) => ['operation', 'resource'].includes(key))
          .reduce((obj, key) => {
            obj[key] = fullBlockIssues.parameters[key];
            return obj;
          }, {});
        newIssues = filteredBlockIssues;
      }
    } else if (fullBlockIssues !== null) {
      newIssues = fullBlockIssues.parameters;
    }

    if (newIssues === null) {
      if (!!blockValues.issues && !!blockValues.issues.parameters) {
        delete blockValues.issues.parameters;
      }
    } else {
      if (!blockValues.issues) blockValues.issues = {};
      blockValues.issues.parameters = newIssues;
    }

    const fullBlockCredIssues = getBlockCredentialIssues(
      blockValues,
      blockType,
      credentialTypes,
      credentials
    );
    let newCredIssues = null;
    if (fullBlockCredIssues !== null) {
      newCredIssues = fullBlockCredIssues.credentials;
    }

    if (newCredIssues === null) {
      if (!!blockValues.issues && !!blockValues.issues.credentials) {
        delete blockValues.issues.credentials;
      }
    } else {
      if (!blockValues.issues) blockValues.issues = {};
      blockValues.issues.credentials = newCredIssues;
      this.globalStore.workflowStore.workflowHasIssues = true;
    }
    return blockValues;
  };
}
