import { apiUrl, request } from 'Utilities';

export async function shopifySignup(params) {
  return request(apiUrl + '/api/strategy/shopify/setup-account', {
    headers: {},
    method: 'POST',
    body: {
      ...params,
    },
  });
}

export async function shopifyLinkAccount(params) {
  return request(apiUrl + '/api/strategy/shopify/link-account', {
    headers: {
      Authorization: localStorage.getItem('workflow-accessToken'),
      refreshToken: localStorage.getItem('workflow-refreshToken'),
    },
    method: 'POST',
    body: {
      ...params,
    },
  });
}
