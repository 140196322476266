import React from 'react';

export const IconSuccess = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#7DD96E" />
    <circle cx="8" cy="8" r="7.5" stroke="#2B2E32" strokeOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0303 5.96967C11.3232 6.26256 11.3232 6.73744 11.0303 7.03033L7.53034 10.5303C7.38262 10.6781 7.17999 10.7575 6.97123 10.7494C6.76248 10.7414 6.56653 10.6467 6.43057 10.4881L4.93057 8.73809C4.661 8.4236 4.69743 7.95012 5.01192 7.68056C5.32641 7.41099 5.79989 7.44741 6.06946 7.76191L7.04238 8.89698L9.96968 5.96967C10.2626 5.67678 10.7375 5.67678 11.0303 5.96967Z"
      fill="white"
    />
  </svg>
);

export const IconWarning = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#FFBB56" />
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="7.5"
      stroke="#2B2E32"
      strokeOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 7.5V4.5C8.75 4.08579 8.41421 3.75 8 3.75C7.58579 3.75 7.25 4.08579 7.25 4.5L7.25 7.5C7.25 7.91421 7.58579 8.25 8 8.25C8.41421 8.25 8.75 7.91421 8.75 7.5ZM8 12C8.55229 12 9 11.5523 9 11C9 10.4477 8.55229 10 8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12Z"
      fill="white"
    />
  </svg>
);

export const IconError = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#F04242" />
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="7.5"
      stroke="#2B2E32"
      strokeOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5303 6.53033C10.8232 6.23744 10.8232 5.76256 10.5303 5.46967C10.2374 5.17678 9.76256 5.17678 9.46967 5.46967L8 6.93934L6.53033 5.46968C6.23744 5.17678 5.76256 5.17678 5.46967 5.46968C5.17678 5.76257 5.17678 6.23744 5.46967 6.53034L6.93934 8L5.46967 9.46967C5.17678 9.76256 5.17678 10.2374 5.46967 10.5303C5.76256 10.8232 6.23744 10.8232 6.53033 10.5303L8 9.06066L9.46967 10.5303C9.76256 10.8232 10.2374 10.8232 10.5303 10.5303C10.8232 10.2374 10.8232 9.76257 10.5303 9.46968L9.06066 8L10.5303 6.53033Z"
      fill="white"
    />
  </svg>
);

export const IconUndefined = () => (
  <span role="img" aria-label="icon-undefined" className="anticon">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="white" />
      <circle cx="8" cy="8" r="8" fill="#2B2E32" fillOpacity="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5784 8C11.5784 8.41421 11.2426 8.75 10.8284 8.75H5.17157C4.75736 8.75 4.42157 8.41421 4.42157 8C4.42157 7.58579 4.75736 7.25 5.17157 7.25H10.8284C11.2426 7.25 11.5784 7.58579 11.5784 8Z"
        fill="white"
      />
    </svg>
  </span>
);

export const IconLocked = () => (
  <span role="img" aria-label="icon-locked" className="anticon">
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C3.79086 0 2 1.79086 2 4V6C0.895431 6 0 6.89543 0 8V13C0 14.1046 0.895431 15 2 15H10C11.1046 15 12 14.1046 12 13V8C12 6.89543 11.1046 6 10 6V4C10 1.79086 8.20914 0 6 0ZM8 6V4C8 2.89543 7.10457 2 6 2C4.89543 2 4 2.89543 4 4V6H8Z"
        fill="#2B2E32"
        fillOpacity="0.3"
      />
    </svg>
  </span>
);

export const AdminIcon = () => (
  <span role="img" aria-label="icon-admin" className="anticon">
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.666687"
        width="16"
        height="16"
        rx="8"
        fill="#2B2E32"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.14639 3.14172C8.34165 2.95276 8.65823 2.95276 8.85349 3.14172L8.94881 3.23397C9.89228 4.14701 11.1163 4.73929 12.4372 4.9219L12.5706 4.94035C12.8406 4.97767 13.0299 5.21748 12.996 5.47937L12.6514 8.14768C12.4291 9.86893 11.4014 11.3957 9.86434 12.2882L8.75718 12.931C8.59885 13.023 8.40103 13.023 8.24269 12.931L7.13553 12.2882C5.59852 11.3957 4.57083 9.86893 4.3485 8.14768L4.00385 5.47937C3.97002 5.21748 4.15929 4.97767 4.42927 4.94035L4.56272 4.9219C5.88358 4.73929 7.1076 4.14701 8.05107 3.23397L8.14639 3.14172ZM5.05595 5.8226C6.33941 5.58157 7.5305 5.00522 8.4998 4.15616V7.99999H11.6627L11.6591 8.02765C11.4749 9.45383 10.6234 10.7189 9.34985 11.4583L8.49994 11.9518L8.4998 7.99999L5.3372 8L5.05595 5.8226Z"
        fill="#2B2E32"
      />
    </svg>
  </span>
);

export const IconExpandArrow = () => (
  <span role="img" aria-label="icon-expand-arrow" className="anticon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L8.58579 8L5.29289 4.70711C4.90237 4.31658 4.90237 3.68342 5.29289 3.29289C5.68342 2.90237 6.31658 2.90237 6.70711 3.29289L10.7071 7.29289C11.0976 7.68342 11.0976 8.31658 10.7071 8.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071Z"
        fill="#2B2E32"
      />
    </svg>
  </span>
);
