import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'antd';
import { AppButton, AppCloseIcon } from '../../../components/Base';

import { upperFirst } from 'lodash';

import { CloseIcon } from 'Components/Icons';

import styles from '../Account.less';

@inject('globalStore')
@observer
export default class UpgradeConfirmationModal extends Component {
  getBenefits = () => {
    const { showBenefits } = this.props;

    switch (showBenefits) {
      case 'starter':
        return (
          <ul>
            <li>Up to 20 active workflows</li>
            <li>Up to 5,000 app actions per month</li>
            <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
            <li>Full access to marketplace</li>
          </ul>
        );
      case 'individual':
        return (
          <ul>
            <li>Up to 20 active workflows</li>
            <li>Up to 5,000 app actions per month</li>
            <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
            <li>Full access to marketplace</li>
          </ul>
        );
      case 'silver':
        return (
          <ul>
            <li>Unlimited active workflows</li>
            <li>Up to 30,000 app actions per month</li>
            <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
            <li>Full access to marketplace</li>
          </ul>
        );
      case 'gold':
        return (
          <ul>
            <li>Unlimited active workflows</li>
            <li>Up to 300,000 app actions per month</li>
            <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
            <li>Full access to marketplace</li>
            <li>Custom API call</li>
            <li>Workspaces</li>
            <li>Unlimited team members</li>
            <li>Personalized training</li>
            <li>1 custom integration</li>
          </ul>
        );
      case 'platinum':
        return (
          <ul>
            <li>Unlimited active workflows</li>
            <li>Unlimited app actions per month</li>
            <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
            <li>Full access to marketplace</li>
            <li>Custom API call</li>
            <li>Workspaces</li>
            <li>Unlimited team members</li>
            <li>Personalized training</li>
            <li>2 custom integrations</li>
            <li>Early acess to new features</li>
          </ul>
        );
      default:
      case 'free':
        return (
          <ul>
            <li>Up to 20 active workflows</li>
            <li>Up to 1,000 app actions per month</li>
            <li>Custom logic (&ldquo;Start from Scratch&rdquo;)</li>
            <li>Full access to marketplace</li>
          </ul>
        );
    }
  };

  render() {
    const { open, showBenefits } = this.props;

    return (
      <Modal
        closeIcon={
          <AppButton icon={<AppCloseIcon />} size="small" type="tertiary" />
        }
        maskClosable={false}
        title="Upgrade Confirmation"
        open={open}
        onCancel={this.props.closeFxn}
        wrapClassName={styles.accountModal}
        className={styles.upgradeConfirmationModal}
        destroyOnClose
        width={740}
        centered
        footer={[
          <AppButton onClick={this.props.closeFxn} type="primary">
            Done
          </AppButton>,
        ]}
      >
        <div className={styles.confirmationHeader}>
          Thanks for upgrading!{' '}
          <span role="img" aria-label="confetti">
            🎉
          </span>
        </div>
        <div className={styles.confirmationSubheader}>
          <span>Now you can get even more from Alloy.</span> Here are your new
          plan benefits:
        </div>
        <div className={styles.benefitsBox}>
          <div className={styles.boxHeader}>
            What's included in the <span>{upperFirst(showBenefits)} Plan</span>
          </div>
          <div className={styles.boxBody}>{this.getBenefits()}</div>
        </div>
        <div className={styles.confirmationContact}>
          <div className={styles.contactText}>
            Need help setting up your activated workflows?{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://alloyautomation.atlassian.net/servicedesk/customer/portal/3"
            >
              Contact us ↗
            </a>
          </div>
        </div>
      </Modal>
    );
  }
}
