import React from 'react';
import styles from './index.less';
import classNames from 'classnames';
import { Badge } from 'antd';

export const AppCounter = ({
  id,
  count,
  type,
  disabled,
  isForge,
  showZero,
  style,
  hover = true,
  size = 'default',
}) => {
  return (
    <Badge
      id={id}
      className={classNames(styles.counter, {
        [styles.isForge]: isForge,
        [styles.default]: type === 'default',
        [styles.secondary]: type === 'secondary',
        [styles.noHover]: !hover,
      })}
      count={count}
      type={type ? type : 'default'}
      disabled={disabled}
      showZero={showZero}
      style={style}
      size={size}
    />
  );
};
