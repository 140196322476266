import { GET, PUT, PATCH, POST, DELETE } from './wrappers';

export const listWorkflowVersions = (workflowId, userId) => {
  let query = '';
  if (userId) {
    query = `?userId=${userId}`;
  }
  return GET(`/api/workflow-version/${workflowId}${query}`);
};

export const getWorkflowVersion = (versionId, userId) => {
  let query = '';
  if (userId) {
    query = `?userId=${userId}`;
  }
  return GET(`/api/workflow-version/version/${versionId}${query}`);
};

export const createWorkflowVersion = (workflowId, body) => {
  return POST(`/api/workflow-version/${workflowId}`, body);
};

export const releaseWorkflowVersion = (workflowId, version) => {
  return POST(`/api/workflow-version/${workflowId}/${version}/release`);
};

export const releaseCustomWorkflowVersion = (workflowId, version, userId) => {
  return POST(
    `/api/workflow-version/${userId}/${workflowId}/${version}/release`
  );
};

export const finalizeWorkflowVersion = (workflowId, version) => {
  return POST(`/api/workflow-version/${workflowId}/${version}/finalize`);
};

export const deleteWorkflowVersion = (workflowId, workflowVersion) => {
  return DELETE(`/api/workflow-version/${workflowId}/${workflowVersion}`);
};

export const revertAutomationWorkflowToOriginal = (workflowId) => {
  return POST(`/api/workflow-version/revert/${workflowId}`);
};
