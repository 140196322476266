import { PARAM_TYPES } from '../constants';

export const getCommonRules = (description) => [
  {
    required: true,
    message: `Field is required. ${description || ''}`,
  },
];

export const COMMON_RULES_FIELDS = {
  [PARAM_TYPES.NUMBER]: true,
  [PARAM_TYPES.BOOLEAN]: true,
};

export const getFieldRules = (field) => {
  const commonRules = getCommonRules(field?.description);
  if (COMMON_RULES_FIELDS[field?.type]) {
    return commonRules;
  }

  return [...commonRules, { whitespace: true }];
};

export const getEditorFieldRules = (field) => {
  const commonRules = getCommonRules(field?.description);

  if (COMMON_RULES_FIELDS[field?.type]) {
    return commonRules;
  }

  if (field?.minLength) {
    commonRules.push({
      min: field.minLength,
      message: `Field must be at least ${field.minLength} characters long.`,
    });
  }

  if (field?.maxLength) {
    commonRules.push({
      validator: (_, value) => {
        if (value && value.length > field.maxLength) {
          return Promise.reject(
            `Field must be at most ${field.maxLength} characters long.`
          );
        }
        return Promise.resolve();
      },
    });
  }

  return [...commonRules, { whitespace: true }];
};
