import { action } from 'mobx';
import {
  getPaymentData,
  applyPromoCode,
  updateCreditCard,
  validatePromoCode,
} from 'Services/billing';

export class BillingStore {
  constructor(globalStore) {
    this.globalStore = globalStore;
  }

  @action
  getPaymentData = async () => {
    return getPaymentData();
  };

  @action
  applyPromoCode = async (params) => {
    return applyPromoCode(params);
  };

  @action
  validatePromoCode = async (params) => {
    return validatePromoCode(params);
  };

  @action
  updateCreditCard = async (cardToken) => {
    return updateCreditCard(cardToken);
  };
}
